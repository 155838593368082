import { importCSV as types } from '../actions/actiontypes'

const initState = {
  ongoing: 'nothing',
  emails: [],
  error: null,
}

export function importCSVReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return { ...initState }

    case types.GET:
      return {
        ...state,
        ongoing: 'fetching',
      }

    case types.GET_RESPONSE:
      return {
        ...state,
        ongoing: 'nothing',
        emails: action.emails,
      }

    case types.GET_FAIL:
      return {
        ...state,
        ongoing: 'nothing',
        error: action,
      }

    default:
      return state
  }
}
