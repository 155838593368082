import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Image, Icon, Modal, Button } from 'semantic-ui-react'
import {
  advagymGreen,
  gymGridColorCss,
  lightGrey,
  mediumGrey,
  partnerGridColorCss,
  suColorCss,
} from 'app/protected/roles/util/colors'
import i18next from 'i18next'

export function getBadge(badgeLabel) {
  const badgeLength = badgeLabel.length
  if (badgeLength == 3) {
    if (
      badgeLabel[0].match(/^[A-Z]+$/) &&
      badgeLabel[1] >= '0' &&
      badgeLabel[1] <= '9'
    ) {
      if (badgeLabel[1] == '1' || badgeLabel[1] == '2') {
        return badgeLabel[0] + badgeLabel[1] + badgeLabel[2]
      }
    }
  }
  if (badgeLength > 2) {
    const tempLetters = badgeLabel.match(/\b(\w)/g)
    if (tempLetters) {
      if (tempLetters[1]) {
        return tempLetters[0] + tempLetters[1]
      }
      return tempLetters[0]
    } else {
      const specialCharLength = badgeLabel.length
      if (specialCharLength > 2) {
        return badgeLabel[0] + badgeLabel[1]
      } else if (specialCharLength === 1) {
        return badgeLabel[0]
      }
      return 'NN'
    }
  } else {
    if (badgeLength == 1) {
      return badgeLabel[0]
    }
    const tempTwoLetter = badgeLabel[0] + badgeLabel[1]
    return tempTwoLetter
  }
}
function Badge(props) {
  const initialState = { modalOpen: false }
  const [{ modalOpen }, setState] = useState(initialState)
  const toggleModal = () => {
    setState(prevState => ({ ...prevState, modalOpen: !prevState.modalOpen }))
  }

  const { label, icon, customIcon, imageUrl, type, size, square, disabled, preview } = props
  const isPuck = type === 'puck'
  const iconIsSet = icon ? icon.length > 0 : false
  const customIconIsSet = customIcon
  const imageUrlIsSet = imageUrl ? imageUrl.length > 0 : false
  const isPng = imageUrl ? imageUrl.slice(-4) === '.png' : false

  const labelIsSet = label ? label.trim().length > 0 : false

  let badgeLabel = 'NN'
  if (labelIsSet) {
    badgeLabel = label
  }

  const color = () => {
    switch (type) {
      case 'su':
        return { badge: suColorCss, icon: 'white' }
      case 'partner':
        return { badge: partnerGridColorCss, icon: 'white' }
      case 'gym':
        return { badge: gymGridColorCss, icon: 'white' }
      case 'coach':
        return { badge: advagymGreen, icon: 'white' }
      case 'puck':
        return { badge: 'black', icon: 'white' }
      case 'error':
        return { badge: 'red', icon: 'white' }
      case 'basic':
        return { badge: lightGrey, icon: mediumGrey }

      default:
        return { badge: lightGrey, icon: mediumGrey }
    }
  }

  const sizeOf = () => {
    switch (size) {
      case 'big':
        return {
          circleSize: '70px',
          fontSize: '32px',
          puckFont: '30px',
          fontHeight: '28px',
          width: '40px',
          imageSize: 'huge',
        }
      case 'medium':
        return {
          circleSize: '50px',
          fontSize: '23px',
          puckFont: '21px',
          fontHeight: '20px',
          width: '30px',
          imageSize: 'small',
        }
      case 'small':
        return {
          circleSize: '35px',
          fontSize: '14px',
          puckFont: '14px',
          fontHeight: '14px',
          width: '20px',
          imageSize: 'mini',
        }
      case 'tiny':
        return {
          circleSize: '20px',
          fontSize: '10px',
          puckFont: '10px',
          fontHeight: '10px',
          width: '10px',
          imageSize: 'tiny',
        }
      default:
        return {
          circleSize: '35px',
          fontSize: '14px',
          puckFont: '14px',
          fontHeight: '14px',
          width: '20px',
          imageSize: 'mini',
        }
    }
  }

  let setCounts = 0
  if (iconIsSet) setCounts += 1
  if (customIconIsSet) setCounts += 1
  if (imageUrlIsSet) setCounts += 1

  if (setCounts > 1)
    throw Error('just on of icon, customIcon and image should be set')

  //if (!iconIsSet && !imageUrlIsSet && !labelIsSet && !customIcon)
  //throw Error('label must be set')
  const basicBadgeProps = {
    opacity: disabled ? '0.7' : null,
    display: 'flex',
    maxWidth: sizeOf().circleSize,
    maxHeight: sizeOf().circleSize,
    minWidth: sizeOf().circleSize,
    minHeight: sizeOf().circleSize,
    width: sizeOf().circleSize,
    height: sizeOf().circleSize,
    backgroundColor: isPng ? 'white' : color().badge,
    border: `${!imageUrlIsSet && '1px'} solid ${color().badge}`,
    boxSizing: 'border-box',
    borderRadius: square ? '25%' : '50%',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: ' center',
    fontSize: '14px',
    textAlign: 'center',
  }
  const hasPreview = imageUrlIsSet && preview

  const modalPreview = () => {
    return (<Modal open={modalOpen} onClose={toggleModal} size="mini">
      <Modal.Header>
        {i18next.t('picture')}
      </Modal.Header>
      <Modal.Content image>
        <Image centered size='medium' src={imageUrl} wrapped />
      </Modal.Content>
      <Modal.Actions style={{ display: 'flex', justifyContent: "center" }}>
        <Button onClick={toggleModal}>{i18next.t('close')}</Button>
      </Modal.Actions>
    </Modal>)
  }
  return (
    <>
      {imageUrlIsSet && !isPng ? (
        <div
          style={{
            ...basicBadgeProps,
            background: `url(${imageUrl}) no-repeat center center`,
            backgroundSize: 'cover',
            cursor: hasPreview ? 'pointer' : null,
          }}
          onClick={hasPreview ? () => toggleModal() : null}
        />
      ) : (
        <div
          style={{
            ...basicBadgeProps,
            borderWidth: isPuck ? '4px' : '',
            borderStyle: isPuck ? 'solid' : '',
            borderColor: isPuck ? advagymGreen : '',
          }}
        >
          {iconIsSet && (
            <Icon
              name={icon}
              style={{
                fontSize: sizeOf().fontSize,
                margin: 0,
                color: color().icon,
                lineHeight: sizeOf().lineHeight,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            />
          )}

          {customIconIsSet && (
            <Image
              src={customIcon}
              style={{
                display: 'inline-block',
                //width: sizeOf().width,
              }}
            />
          )}

          {imageUrlIsSet && (
            <Image src={imageUrl} circular size={sizeOf().sizeOf} />
          )}

          {!iconIsSet && !imageUrlIsSet && !customIconIsSet && (
            <span
              style={{
                fontSize: sizeOf().fontSize,
                lineHeight: sizeOf().lineHeight,
                color: type ? 'white' : 'black',//'inherit',
                textTransform: 'uppercase',
              }}
            >
              {/* {badgeLabel.length > 1
              ? badgeLabel.length > 2
                ? letters[1]
                  ? letters[0] + letters[1]
                  : letters[0]
                : twoLetter
              : letters[0]} */}
              {getBadge(badgeLabel)}
            </span>
          )}
        </div>
      )}
      {hasPreview && modalPreview()}
    </>
  )
}


Badge.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  customIcon: PropTypes.string,
  imageUrl: PropTypes.string,
  type: PropTypes.oneOf(['su', 'partner', 'gym', 'coach', 'puck', 'basic', 'error']),
  square: PropTypes.bool,
  preview: PropTypes.bool,
}

export default Badge
