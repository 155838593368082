import { myClientGroups as types } from '../actions/actiontypes'
import { sortAlphabetically } from 'util/util'

const initState = {
  ongoing: 'nothing',
  groups: [],
  group: {},
  error: null,
  cancel: null,

  getMoreOngoing: false,
  nextResult: [],
  searchResult: [],
  searchOngoing: 'nothing',

  unreadMessages: 0,
  count: 0,
  getCountOngoing: false,
}

export function clientGroupsReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return { ...initState }

    case types.CREATE:
      return {
        ...state,
        ongoing: 'creating',
      }
    case types.CREATE_RESPONSE:
      return {
        ...state,
        group: action.group,
        ongoing: 'created',
        count: state.count + 1,
        groups: sortAlphabetically([...state.groups, action.group], "name")
      }
    case types.CREATE_FAIL:
      return {
        ...state,
        ongoing: 'fail',
        error: action,
      }

    case types.GET:
      return {
        ...state,
        ongoing: 'fetching',
      }

    case types.CANCEL_SET:
      return {
        ...state,
        cancel: action.email,
      }

    case types.CANCEL_CLEAR:
      return {
        ...state,
        cancel: null,
      }

    case types.RESPONSE:
      return {
        ...state,
        ongoing: 'nothing',
        groups: action.groups === '' ? [] : action.groups, //If the response is empty, it can deliver an empty string, temporary fix.
        nextResult: action.groups === '' ? [] : action.groups, //If the response is empty, it can deliver an empty string, temporary fix.
      }

    case types.GET_FAIL:
      return {
        ...state,
        ongoing: 'nothing',
        error: action,
      }

    case types.GET_MORE_CLIENT_GROUPS:
      return {
        ...state,
        getMoreOngoing: true,
        nextResult: [],
        addMemberOngoing: 'nothing',
      }
    case types.GET_MORE_CLIENT_GROUPS_RESPONSE:
      return {
        ...state,
        getMoreOngoing: false,
        nextResult: action.groups,
        groups: state.groups.concat(action.groups),
      }
    case types.GET_MORE_CLIENT_GROUPS_FAILED:
      return {
        ...state,
        getMoreOngoing: false,
        nextResult: [],
        error: action.error,
      }

    case types.SEARCH_FOR_CLIENT_GROUPS:
      return {
        ...state,
        searchOngoing: 'searching',
      }
    case types.SEARCH_FOR_CLIENT_GROUPS_RESPONSE:
      return {
        ...state,
        searchOngoing: 'done',
        nextResult: [],
        searchResult: action.groups,
      }
    case types.SEARCH_FOR_CLIENT_GROUPS_FAILED:
      return {
        ...state,
        searchOngoing: 'failed',
        searchResult: [],
        nextResult: [],
        error: action.error,
      }
    case types.SEARCH_FOR_CLIENT_GROUPS_CLEAR:
      return {
        ...state,
        searchOngoing: 'nothing',
        nextResult: [],
        searchResult: [],
      }

    case types.GET_ALL_UNREAD_MESSAGES_RESPONSE:
      return {
        ...state,
        unreadMessages: action.unreadMessages,
      }

    case types.GET_CLIENT_GROUPS_COUNT:
      return {
        ...state,
        count: 0,
        getCountOngoing: true,
      }
    case types.GET_CLIENT_GROUPS_COUNT_RESPONSE:
      return {
        ...state,
        count: action.groups,
        getCountOngoing: false,
      }
    case types.GET_CLIENT_GROUPS_COUNT_FAILED:
      return {
        ...state,
        count: 0,
        getCountOngoing: false,
      }

    default:
      return state
  }
}
