import _cloneDeep from 'lodash/cloneDeep'
import { stripToIdOnly } from 'redux/actions/util'
import i18next from 'i18next'
import i18n from 'i18n/i18n'
import moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { Grid } from 'semantic-ui-react'
import Badge from 'app/protected/roles/common/Badge'
import { isMobile as isMobileCheck, isTablet } from 'react-device-detect'
import { isMachine } from 'app/protected/common/constants/machineType'
import { defaultCategory } from 'app/protected/common/constants/categoryType'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import { browserName } from 'react-device-detect'

//importing all strength icons
import BallIcon from 'assets/images/Strength/Ball.svg'
// import BallIcon from 'assets/images/Strength/Ball.svg'
import BarbellIcon from 'assets/images/Strength/Barbell.svg'
import BodyweightIcon from 'assets/images/Strength/BodyWeight.svg'
import CableIcon from 'assets/images/Strength/Cable.svg'
import DumbbellIcon from 'assets/images/Strength/Dumbbell.svg'
import HexbarIcon from 'assets/images/Strength/Hexbar.svg'
import KettlebellIcon from 'assets/images/Strength/Kettlebell.svg'
import MachineIcon from 'assets/images/Strength/Machine.svg'
import MinibandIcon from 'assets/images/Strength/MiniBand.svg'
import ResistanceBandIcon from 'assets/images/Strength/ResistanceBand.svg'
import StabilityballIcon from 'assets/images/Strength/StabilityBall.svg'
import SuspensionstrapIcon from 'assets/images/Strength/SuspensionStrap.svg'
import Other from 'assets/images/Strength/Other Icon.svg'



export function getWindowSize() {
  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight || e.clientHeight || g.clientHeight
  return { x, y }
}

export function getWindowRect() {
  let winSize = getWindowSize()
  return {
    top: 0,
    bottom: winSize.y,
    left: 0,
    right: winSize.x,
  }
}

export function getCssValue(element, key) {
  let style = window.getComputedStyle(element)
  let value = style.getPropertyValue(key)
  return value
}

export function firstLetterToUppercase(text) {
  return text.charAt(0).toUpperCase() + text.slice(1)
}
export function firstLetterToLowercase(text) {
  return text.charAt(0).toLowerCase() + text.slice(1)
}

export function getl10nName(text) {
  if (text) {
    let l10nLowerCase = text.toLowerCase() //to lower case
    let l10nNoWhiteSpace = l10nLowerCase.replace(/ /g, '') //Remove white spaces
    let l10nNoDash = l10nNoWhiteSpace.replace(/-/g, '') //Remove dashes
    let l10nNoSemiColons = l10nNoDash.replace(/[^\w\s]/gi, '') //Remove special characters
    let l10nTranslated = i18next.t(l10nNoSemiColons)

    if (l10nNoSemiColons != l10nTranslated) {
      return l10nNoWhiteSpace
    }
  }
  return text
}

const equipmentIcons = {
  Ball: BallIcon,
  Barbell: BarbellIcon,
  Bodyweight: BodyweightIcon,
  Cable: CableIcon,
  Dumbbell: DumbbellIcon,
  HexBar: HexbarIcon,
  Kettlebell: KettlebellIcon,
  Machine: MachineIcon,
  Miniband: MinibandIcon,
  Resistanceband: ResistanceBandIcon,
  Stabilityball: StabilityballIcon,
  Suspensionstrap: SuspensionstrapIcon,
}

export function getEquipmentIcon(equipmentType) {
  if(equipmentType)
    equipmentType = equipmentType.replace(/\s/g, '')
  return equipmentIcons[equipmentType] || Other
}

export function getEqupimentName(data){
  return data?.equipment[0]?.name
}

export function getl10nDescription(name, description) {
  let l10nLowerCase = name.toLowerCase() //to lower case
  let l10nNoWhiteSpace = l10nLowerCase.replace(/ /g, '') //Remove white spaces
  let l10nNoDash = l10nNoWhiteSpace.replace(/-/g, '') //Remove dashes
  let l10n = l10nNoDash.replace(/[^\w\s]/gi, '') + '_desc' //Remove special characters

  let l10nTranslated = i18next.t(l10n)

  if (l10n != l10nTranslated) {
    return l10n
  }
  return description
}

export function getl10nInstruction(name, index, instruction) {
  let l10nLowerCase = name.toLowerCase() //to lower case
  let l10nNoWhiteSpace = l10nLowerCase.replace(/ /g, '') //Remove white spaces
  let l10nNoDash = l10nNoWhiteSpace.replace(/-/g, '') //Remove dashes
  let l10n = l10nNoDash.replace(/[^\w\s]/gi, '') + '_step' + (index + 1) //Remove special characters

  let l10nTranslated = i18next.t(l10n)

  if (l10n != l10nTranslated) {
    return l10n
  }

  return instruction
}

export function move(array, oldIndex, newIndex) {
  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0])
  return [...array]
}

export function reIndexArrayField(field, array) {
  return array.map((v, i) => {
    return {
      ...v,
      [field]: i,
    }
  })
}

export const createRgba = (colorBase, alpha) => {
  return `rgba(${colorBase[0]}, ${colorBase[1]}, ${colorBase[2]}, ${alpha})`
}

export const cloneMongoDocument = (source) => {
  return JSON.parse(JSON.stringify(source), function (k, v) {
    return k === '_id' || k === 'createdAt' || k === 'updatedAt' || k === 'uuid'
      ? undefined
      : v
  })
}

export const cloneMyProgram = (program, newName) => {
  const tmp = _cloneDeep(program)
  stripToIdOnly(['site', 'owner'], tmp)
  const newProgam = cloneMongoDocument(tmp)
  newProgam.name = newName
  return JSON.parse(JSON.stringify(newProgam), function (k, v) {
    return k === '_id' || k === 'createdAt' || k === 'updatedAt' || k === 'uuid'
      ? undefined
      : v
  })
}

export const isNotUndefinedAndNull = (value) =>
  typeof value != 'undefined' && value != null

export const isValidEmail = (email) => {
  var match = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return match.test(email)
}

export const getPermissionLabel = (permissions, partnerPermissions) => {
  if (permissions.some((p) => p.role === 'su')) {
    return ['Super user']
  }

  let list = []
  const checkIfValueIsSet = (value) => list.indexOf(value) > -1

  for (let i = 0; i < permissions.length; i++) {
    const p = permissions[i]

    if (!checkIfValueIsSet(i18next.t('partner_administrator')) && !checkIfValueIsSet(i18next.t('chain_administrator')) && p.role === 'partner') {
      const isChain = partnerPermissions.find(p => p.partner.type === "chain")
      const isPartner = partnerPermissions.find(p => p.partner.type !== "chain")
      isPartner && list.push(i18next.t('partner_administrator'))
      isChain && list.push(i18next.t('chain_administrator'))
    }

    if (!checkIfValueIsSet(i18next.t('administrator')) && p.role === 'admin')
      list.push(i18next.t('administrator'))

    if (!checkIfValueIsSet(i18next.t('staff')) && p.role === 'coach')
      list.push(i18next.t('staff'))
  }

  return list
}

export const isFunction = (func) =>
  func && {}.toString.call(func) === '[object Function]'

export function lbToKg(value) {
  return value * 0.45359237
}

export function kgToLb(value) {
  return value / 0.45359237
}

export function cmToInches(value) {
  return value * 2.54
}

export function meterToMiles(value) {
  return value / 1609.344
}

export function milesToMeters(value) {
  return value * 1609.344
}

export const setLanguageAndTimeFormat = (languageCode, statisticsLocale) => {
  moment.locale(
    statisticsLocale ?
      statisticsLocale === 'jp' ? 'ja' : statisticsLocale
      : languageCode === 'jp' ? 'ja' : languageCode
  )
  i18next.changeLanguage(languageCode)
}

export const getToday = () => moment().startOf('day')

export const getSevenDaysAgo = () => moment().subtract(7, 'd')

export const isValidDate = (date) => moment(date).isValid()

export const getProfilePicture = (media) =>
  isNotUndefinedAndNull(media) ? media.find((m) => m.name === 'icon') : null

export const getGymLogo = (media) =>
  isNotUndefinedAndNull(media) ? media.find((m) => m.name === 'logo') : null

export const durationToHHMMSS = (value) => {
  let seconds = value / 1000
  let hours = parseInt(seconds / 3600)
  seconds = seconds % 3600
  let minutes = parseInt(seconds / 60)
  seconds = seconds % 60
  return (
    (hours > 9 ? hours : '0' + hours) +
    ':' +
    (minutes > 9 ? minutes : '0' + minutes) +
    ':' +
    (seconds > 9 ? seconds : '0' + seconds)
  )
}

export const durationToMinutes = (value) => {
  let result = 0
  value == null || value == 0
    ? (result = 0)
    : (result = Number(moment.duration(value).asMinutes()).toFixed())
  return result + ' ' + firstLetterToLowercase(i18next.t('minutes'))
}

export const validURL = (str) => {
  var re = /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi
  return re.test(str)
}

export const validLinkURL = (str) => {
  var re = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim
  return re.test(str)
}

export const generateLinkText = (string) => {
  const chunks = string.split(' ')

  return chunks.map((c, index) => {
    if (validURL(c) && !isWebApp) {
      const isHttp = c.substring(0, 4) === 'http'
      const refinedLink = isHttp ? c : 'http://' + c
      if (index === chunks.length - 1) {
        return (
          <a key={index} href={refinedLink} target="_blank">
            {c}
          </a>
        )
      } else {
        return (
          <React.Fragment key={index}>
            <a href={refinedLink} target="_blank">
              {c}
            </a>{' '}
          </React.Fragment>
        )
      }
    } else {
      if (index === chunks.length - 1) {
        return c
      } else {
        return c + ' '
      }
    }
  })
}

const ranges = [
  '\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]',
  ' ', // Also allow spaces
].join('|')

const removeEmoji = str => str.replace(new RegExp(ranges, 'g'), '')

export const isOnlyEmojis = str => {
  if (str) {
    if (str === "") {
      return false
    }
    return !removeEmoji(str).length
  }
  return false
}

export const useDraggableInPortal = () => {
  const self = useRef({}).current

  useEffect(() => {
    const div = document.createElement('div')
    div.style.position = 'absolute'
    div.style.pointerEvents = 'none'
    div.style.top = '0'
    div.style.width = '100%'
    div.style.height = '100%'
    self.elt = div
    document.body.appendChild(div)
    return () => {
      document.body.removeChild(div)
    }
  }, [self])

  return (render) => (provided, ...args) => {
    const element = render(provided, ...args)
    if (provided.draggableProps.style.position === 'fixed') {
      return createPortal(element, self.elt)
    }
    return element
  }
}

export function getOrginalExercise(list, exercises) {
  let updated = []
  list.map(l => {
    const idMatch = exercises.find(e => e._id === l._id)
    if (idMatch) {
      updated.push(idMatch)
    } else {
      const l10nMatch = l.l10n ? exercises.find(e => {
        if (e.l10n && e.l10n) {
          return e.l10n === l.l10n
        }
      }) : null
      if (l10nMatch) {
        updated.push(l10nMatch)
      } else {
        const nameMatch = exercises.find(e => e.name === l.name)
        if (nameMatch) {
          updated.push(nameMatch)
        } else {
          const nameLowerCaseMatch = exercises.find(e => e.name.replace(/[ #–&-1234567890]/g, '').toLowerCase() === l.name.replace(/[ #–&-1234567890]/g, '').toLowerCase())
          if (nameLowerCaseMatch) {
            updated.push(nameLowerCaseMatch)
          } else {
            updated.push(l)
          }
        }
      }
    }
  })
  return updated
}

export function sortAlphabetically(list, value) {
  let sortedList = list
    ? list.sort(function (a, b) {
      const itemA = value ? isFunction(a[value]) ? a[value]() : a[value] : a
      const itemB = value ? isFunction(b[value]) ? b[value]() : b[value] : b
      if (itemA < itemB) { return -1 }
      if (itemA > itemB) { return 1 }
      return 0
    })
    : []
  return sortedList
}

export function siteOptions(uniqueSites, myText) {
  let mappedList = []
  if (myText) [
    mappedList.push({
      key: 'user',
      text: myText,
      value: 'user',
      content: getContent({}, 'user', myText),
    })
  ]

  uniqueSites.map((item) => {
    !item.isRemoved
      ? mappedList.push({
        key: item._id,
        text: item.name,
        value: item._id,
        media: item.media ? item.media.find(m => m.name === "logo") : null,
        content: getContent(item, 'gym'),
        type: 'site',
      })
      : null
  })
  return mappedList
}

const getContent = (gym, type) => {
  console.log("calling Badge from util.js")
  let gymLogo = ''
  gym.media
    ? gym.media.map((m) => {
      if (m.name == 'logo') gymLogo = m.url
    })
    : null
  const isUserAndNoMedia = type === 'user' && gymLogo === ''
  return (
    <Grid>
      <Grid.Row columns={1}>
        <Grid.Column>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Badge
              label={gym.name}
              type={isUserAndNoMedia ? 'coach' : 'gym'}
              imageUrl={gymLogo}
              icon={isUserAndNoMedia ? 'user' : ''}
            />
            <span style={{ paddingLeft: '7px' }}>
              {type === 'user' ? i18next.t('my_programs') : ''}
              {gym.name}
            </span>
            <div
              style={{
                display: 'flex',
                flex: '1 0 0px',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
export const isMobile = isMobileCheck && !isTablet && window.innerWidth < 768
//https://semantic-ui.com/elements/container.html

export const weighIncreaseAdjustment = program => {
  if (program.exercises) {
    let updatedExercises = program.exercises.map(e => {
      if (e.weightIncrease) {
        const { timesCompleted, count, kg, lb, percentage, name } = e.weightIncrease
        if (timesCompleted >= count) {
          const incrementTimes = Math.floor(timesCompleted / count)
          let updatedSets = []
          if (name === "percentage") {
            e.sets.map(s => {
              e.subtype === "assisted" ?
                updatedSets.push({
                  ...s,
                  old: { kg: s.kg, lb: s.lb },
                  kg: s.kg * (1 - (percentage * incrementTimes)),
                  lb: s.lb * (1 - (percentage * incrementTimes)),
                })
                : updatedSets.push({
                  ...s,
                  old: { kg: s.kg, lb: s.lb },
                  kg: s.kg * (1 + (percentage * incrementTimes)),
                  lb: s.lb * (1 + (percentage * incrementTimes)),
                })
            })
          } else {
            e.sets.map(s => {
              e.subtype === "assisted" ?
                updatedSets.push({
                  ...s,
                  old: { kg: kg ? s.kg : lbToKg(s.lb), lb: lb ? s.lb : kgToLb(s.kg) },
                  kg: kg !== 0 ? s.kg - (kg * incrementTimes) : 0,
                  lb: lb !== 0 ? s.lb - (lb * incrementTimes) : 0,
                })
                : updatedSets.push({
                  ...s,
                  old: { kg: kg ? s.kg : lbToKg(s.lb), lb: lb ? s.lb : kgToLb(s.kg) },
                  kg: kg !== 0 ? s.kg + (kg * incrementTimes) : 0,
                  lb: lb !== 0 ? s.lb + (lb * incrementTimes) : 0,
                })
            })
          }
          return {
            ...e,
            sets: updatedSets,
            weightIncrease: {
              ...e.weightIncrease,
              timesCompleted: timesCompleted % count,
              progress: incrementTimes > 0 ? incrementTimes : null,
            }
          }
        } else {
          return e
        }
      } else {
        return e
      }
    })
    return ({ ...program, exercises: updatedExercises })
  } else {
    return program
  }
}

export const getSiteHardware = gym => {
  const observers = gym ? gym.observers : []
  const machines = gym ? gym.machines : []
  let zones = []
  let onlyMachines = []
  let cardioMachine = []
  machines
    ? machines.map((m) => {
      if (m.enabled) {
        if (m.type === 'tag_only') {
          if (m.exercises) {
            if (m.exercises.length > 0) {
              let exerciseNames = ''
              m.exercises.map((e, index) => {
                if (index === 0) {
                  e.l10n
                    ? (exerciseNames = i18next.t(e.l10n))
                    : (exerciseNames = e.name)
                } else {
                  e.l10n
                    ? (exerciseNames = exerciseNames + ', ' + i18next.t(e.l10n))
                    : (exerciseNames = exerciseNames + ', ' + e.name)
                }
              })
              m.exerciseNames = exerciseNames
            }
          }
          const updatedItem = {
            ...m,
            numberOfItems: 0,
            numberOfExercises: m.exercises.length,
            numberOfRoutines: 0,
          }
          zones.push(updatedItem)
        }
        if (m.type === 'info_only') {
          const updatedItem = {
            ...m,
            numberOfItems: m.media ? m.media.length : 0,
            numberOfExercises: 0,
            numberOfRoutines: 0,
          }
          zones.push(updatedItem)
        }
        if (m.type === "puck") {
          const updatedItem = {
            ...m,
            numberOfItems: m.puck.info ? m.puck.info.items.length : 0,
            numberOfExercises: m.puck.exercises.length,
            numberOfRoutines: m.puck.routines.length,
          }
          zones.push(updatedItem)
        }
        if (isMachine(m)) {
          let exerciseNames = ''
          let labels = ''
          if (m.exercises) {
            if (m.exercises.length > 0) {
              m.exercises.map((e, index) => {
                if (index === 0) {
                  e.l10n
                    ? (exerciseNames = e.label + " " + i18next.t(e.l10n))
                    : (exerciseNames = e.label + " " + e.name)
                  labels = labels + e.label
                } else {
                  e.l10n
                    ? (exerciseNames = exerciseNames + ', ' + e.label + " " + i18next.t(e.l10n))
                    : (exerciseNames = exerciseNames + ', ' + e.label + " " + e.name)
                  labels = labels + "/" + e.label
                }
              })
            }
          }
          onlyMachines.push({
            ...m,
            exerciseNames: exerciseNames !== '' ? exerciseNames : i18next.t('no_exercises'),
            labels: labels !== '' ? labels : 'None'
          })
        }
        if (m.type.startsWith('connected')) {
          const updatedItem = {
            ...m,
            numberOfItems: m.puck ? m.puck.info ? m.puck.info.items.length : 0 : 0,
            numberOfExercises: m.puck ? m.puck.exercises.length : 0,
            numberOfRoutines: m.puck ? m.puck.routines.length : 0,
          }
          cardioMachine.push(updatedItem)
        }
      }
    })
    : null

  let beacons = []
  if (_get(gym, "beacon", []).length > 0) {
    _get(gym, "beacon", []).map(b => {
      let bat = _get(gym, "beaconBat", []).find(f => f.mac === b)
      if (bat) {
        beacons.push({ ...bat, _id: b })
      } else {
        beacons.push({ _id: b, mac: b })
      }
    })
  }

  return {
    machines: onlyMachines,
    zones: zones,
    cardio: cardioMachine,
    observers: observers,
    beacons: beacons,
  }
}

const init = {
  name: '',
  duration: '',
  rest: 0,
  media: [],
  instruction: [],
  description: [],
  scope: 'shared',
  crumbs: [],
  exercises: [],
  enabled: false,
  newMedia: {},
  oldMedia: [],
  category: defaultCategory,
  timesPerWeek: 0,
  muscles: [],
  showFilters: false,
  createExerciseModal: false,
}

const correctDecimals = weight => {
  const res = weight.toFixed(2) //Apps rounds to one decimal..
  if (res[res.length - 1] === '0' && res[res.length - 2] === '0') {
    return weight
  }
  return res
}

export const formatWorkoutToProgram = (workout) => {
  if (workout) {
    const programWorkout = init
    programWorkout.exercises = workout.exercises
      ? workout.exercises.map(e => {
        if (e.units.includes("weight")) {
          return {
            ...e,
            sets: e.sets.map(s => {
              return {
                ...s,
                kg: s.kg ? correctDecimals(s.kg) : s.kg,
                lb: s.lb ? correctDecimals(s.lb) : s.lb,
              }
            })
          }
        } else {
          return e
        }
      })
      : []
    programWorkout.duration = workout.duration ? workout.duration : ''
    return programWorkout
  }
  return null
}



export const isIOSWebApp = browserName === "WebKit" && !_isNil(_get(window, "webkit.messageHandlers.bridge.postMessage", null))

export const isAndroidWebApp = browserName.includes("WebView") && window.Android

export const isWebApp = isIOSWebApp || isAndroidWebApp