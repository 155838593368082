import { connect } from 'react-redux'
import Root from '../component/Root'
import { user } from 'redux/actions/thunks.js'
import { menu as menuAction } from 'redux/actions/actions.js'
import { countryToLangCode } from 'app/protected/common/constants/countriesList'
import { gymMemberStatistics } from 'redux/actions/actions'

const refreshInterval = 1 * 60 * 1000

const mapStateToProps = state => {
  const userReducer = state.userReducer
  const refreshUser = userReducer.status == 'unknown'

  const { gym: site } = state.myGymInfoReducer
  const country = site ? site.country ? site.country : null : null
  const siteLangCode = (country && countryToLangCode[country]) ? countryToLangCode[country] : 'sv' //sv -> isoWeek

  return {
    refreshInterval,
    refreshUser,
    isAuthorized: userReducer.status == 'authorized',
    isOpen: state.menuReducer.isOpen,
    siteLangCode,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUser: () => {
      dispatch(user.get())
    },
    closeMenu: () => {
      dispatch(menuAction.close())
    },
    setSiteLang: (lang) => {
      dispatch(gymMemberStatistics.setLangFormat(lang))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Root)
