/* global process*/
import React from 'react'
import PropTypes from 'prop-types'

export default class OnRouteChange extends React.PureComponent {
  state = {
    pathname: undefined,
  }

  analytics = props => {
    if (window.gtag && process.env.GA_TRACKING_ID) {
      const { pathname: page_path } = props.location
      window.gtag('config', process.env.GA_TRACKING_ID, {
        page_path,
      })
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { closeMenu } = this.props
    const { pathname: nextPathName } = this.props.location
    if (nextPathName != this.state.pathname) {
      this.setState({ pathname: nextPathName })
      closeMenu()
      window.scrollTo(0, 0)
      this.analytics(this.props)
    }
  }
  render() {
    return this.props.children
  }
}

OnRouteChange.defaultProps = {}

OnRouteChange.propTypes = {
  children: PropTypes.element.isRequired,
  closeMenu: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
}
