import axios from 'redux/actions/cgAxios'

function manageMedia(route, data, extraFormData, callback) {
  const { newMedia, message, folder } = data

  const removeFile = fileUrl => {
    if (fileUrl === "") return
    const parsed = fileUrl.split("/")
    const isGroup = parsed.find(p => p.startsWith("cg-group-fileuploads"))
    const deleteRoute = isGroup ? route : '/api/file-upload'
    axios({
      method: 'delete',
      data: {
        fileUrl,
      },
      url: deleteRoute,
    })
  }

  function uploadFile(file, key, subBucket) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('key', key)
    formData.append('subBucket', subBucket)
    Object.keys(extraFormData).forEach((key) => {
      formData.append(key, extraFormData[key])
    })

    // switch (key) {
    //   case 'bg':
    //     formData.append('width', 1200)
    //     break
    //   case 'icon':
    //     formData.append('width', 512)
    //     break
    //   default:
    // }

    return axios.post(route, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  const newMediakeyList = newMedia ? Object.keys(newMedia) : []
  if (newMediakeyList.length > 0) {
    Promise.all(
      newMediakeyList.map((key) => uploadFile(newMedia[key], 'pic', folder ? folder : 'messages'))
    )
      .then((uploads) => {
        uploads.forEach((upload) => {
          const { s3path, source } = upload.data
          const { key } = source

          const toRemove = message.media
            ? message.media.filter((m) => m.name == key)
            : []
          const toKeep = message.media
            ? message.media.filter((m) => m.name != key)
            : []

          toRemove.map((m) => removeFile(m.url))

          message.media = toKeep
          console.log('Updating ' + key + ' with s3 path ' + s3path)
          message.media.push({
            name: key,
            url: s3path,
          })
        })
        callback(null)
      })
      .catch((error) => {
        callback(error)
      })
  } else {
    callback(null)
  }
}

function deleteMessageMedia(route, data, extraFormData, callback) {
  const { message } = data

  const removeFile = fileUrl => {
    if (fileUrl === "") return
    const parsed = fileUrl.split("/")
    const isGroup = parsed.find(p => p.startsWith("cg-group-fileuploads"))
    const deleteRoute = isGroup ? route : '/api/file-upload'
    axios({
      method: 'delete',
      data: {
        fileUrl,
      },
      url: deleteRoute,
    })
  }

  message.media && message.media.map((m) => removeFile(m.url))
  callback(null)
}

export function manageUserMessageMedia(data, callback) {
  return manageMedia('/api/file-upload-group', data, {}, callback)
}

export function manageGroupProgramMedia(data, extraFormData, callback) {
  return manageMedia('/api/file-upload-group', data, extraFormData, callback)
}

export function deleteUserMessageMedia(data, callback) {
  return deleteMessageMedia('/api/file-upload-group', data, {}, callback)
}
