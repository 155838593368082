import flatMap from 'lodash/flatMap'
import {
  addL10nExercise,
  addL10nMachine,
  addL10nRoutine,
  addL10nProgram,
} from 'util/l10n'
import * as actions from './actions'
import axios from './cgAxios'
import externalAxios from 'axios'
import { stripToIdOnly } from './util'
import Crypto from 'crypto'
import { manageUserMessageMedia } from 'redux/actions/thunks/util/manageUserMessageMedia'
import {
  feedback,
  feedbackWithPic,
  crashFeedback,
  crashFeedbackWithPic,
} from 'app/protected/common/constants/slackDataFormat'

export const join = {
  post: function (info) {
    return function (dispatch) {
      dispatch(actions.join.request(info.data))
      //CREATE ACCOUNT
      axios({ method: 'post', url: '/api/web/join', data: info.data })
        .then((response) => {
          dispatch(actions.join.response(response.status, response.statusText))
          dispatch(actions.join.clearForm())
          dispatch(actions.join.showFormStatus(response.statusText, 'success')
          )
          dispatch(actions.join.showWelcomeScreen())
        })
        .catch((error) => {
          if (error.response) {
            console.log('postJoin response error', error.response)
            let msg = error.response.data.error || 'unknown response error'
            let status = error.response.status
            dispatch(actions.join.response(status, msg))
            dispatch(actions.join.showFormStatus(msg, 'error'))
          } else {
            console.log('postJoin error', error)
            dispatch(actions.join.response(-1, 'Unknown error'))
            dispatch(actions.join.showFormStatus('Unknown error', 'error'))
          }
        })
    }
  },
}

export const resendVerification = {
  post: function (info) {
    return function (dispatch) {
      dispatch(actions.join.request(info.data))
      axios({ method: 'post', url: '/api/user/confirmationEmail', data: info.data })
        .then((response) => {
          dispatch(actions.join.response(response.status, response.statusText))
          dispatch(actions.join.clearForm())
          // dispatch(actions.join.showFormStatus(response.statusText, 'success'))
          // dispatch(actions.join.showWelcomeScreen())
        })
        .catch((error) => {
          if (error.response) {
            console.log('postJoin response error', error.response)
            let msg = error.response.data.error || 'unknown response error'
            let status = error.response.status
            dispatch(actions.join.response(status, msg))
            dispatch(actions.join.showFormStatus(msg, 'error'))
          } else {
            console.log('postJoin error', error)
            dispatch(actions.join.response(-1, 'Unknown error'))
            dispatch(actions.join.showFormStatus('Unknown error', 'error'))
          }
        })
    }
  },
}

export const signIn = {
  post: function (info) {
    return function (dispatch) {
      dispatch(actions.signIn.request(info.data))
      axios({ method: 'post', url: '/api/web/signin', data: info.data })
        .then((response) => {
          if (
            response.data &&
            response.data.roles &&
            !response.data.roles.includes('observer')
          ) {
            axios({
              method: 'get',
              url: '/api/user',
              params: {
                populate: 'sites, permissions.site, partnerPermissions.partner',
              },
            })
              .then((response) => {
                if (
                  response.data &&
                  response.data.roles &&
                  !response.data.roles.includes('observer')
                ) {
                  dispatch(actions.user.deAuthorize())
                  dispatch(actions.storeReset())
                  dispatch(actions.user.authorized(response.data))
                  dispatch(
                    actions.signIn.response(
                      response.status,
                      response.statusText
                    )
                  )
                  dispatch(actions.signIn.clearForm())
                  dispatch(
                    actions.signIn.showFormStatus(
                      response.statusText,
                      'success'
                    )
                  )
                  dispatch(actions.signIn.showWelcomeScreen())
                } else {
                  console.log('getUser no data')
                  dispatch(actions.user.unAuthorized())
                }
              })
              .catch((error) => {
                console.log('getUser error', error)
                dispatch(actions.user.unAuthorized())
              })
          } else {
            dispatch(actions.signIn.response(-1, 'No user data available'))
            dispatch(
              actions.signIn.showFormStatus('No user data available', 'error')
            )
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('postSignIn response error', error.response)
            let msg = error.response.data.error || 'unknown response error'
            let status = error.response.status
            dispatch(actions.signIn.response(status, msg))
            dispatch(actions.signIn.showFormStatus(msg, 'error'))
          } else {
            console.log('postSignIn error', error)
            dispatch(actions.signIn.response(-1, 'Unknown error'))
            dispatch(actions.signIn.showFormStatus('Unknown error', 'error'))
          }
        })
    }
  },
}

export const passwordForgotten = {
  post: function (info) {
    return function (dispatch) {
      dispatch(actions.passwordForgotten.request(info.data))
      axios({ method: 'post', url: '/api/forgot', data: info.data })
        .then((response) => {
          dispatch(
            actions.passwordForgotten.response(
              response.status,
              response.statusText
            )
          )
          dispatch(actions.passwordForgotten.clearForm())
          dispatch(
            actions.passwordForgotten.showFormStatus(
              response.statusText,
              'success'
            )
          )
          dispatch(actions.passwordForgotten.showWelcomeScreen())
        })
        .catch((error) => {
          if (error.response) {
            console.log('postPasswordForgotten response error', error.response)
            let msg = error.response.data.error || 'unknown response error'
            let status = error.response.status
            dispatch(actions.passwordForgotten.response(status, msg))
            dispatch(actions.passwordForgotten.showFormStatus(msg, 'error'))
          } else {
            console.log('postPasswordForgotten error', error)
            dispatch(actions.passwordForgotten.response(-1, 'Unknown error'))
            dispatch(
              actions.passwordForgotten.showFormStatus('Unknown error', 'error')
            )
          }
        })
    }
  },
}

export const passwordChange = {
  // 'api/reset/:id'
  post: function (info) {
    return function (dispatch) {
      dispatch(actions.passwordChange.request(info.data))
      //CHANGE PASSWORD
      axios({ method: 'post', url: info.url, data: info.data })
        .then((response) => {
          dispatch(
            actions.passwordChange.response(
              response.status,
              response.statusText
            )
          )
          dispatch(actions.passwordChange.clearForm())
          dispatch(
            actions.passwordChange.showFormStatus(
              response.statusText,
              'success'
            )
          )
          dispatch(actions.passwordChange.showWelcomeScreen())
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              'postPasswordChange response error',
              error.response
            )
            let msg =
              error.response.data.error || 'unknown response error'
            let status = error.response.status
            dispatch(actions.passwordChange.response(status, msg))
            dispatch(
              actions.passwordChange.showFormStatus(
                'Could not change password, please try again.',
                'error'
              )
            )
          } else {
            console.log('postPasswordChange error', error)
            dispatch(actions.passwordChange.response(-1, 'Unknown error'))
            dispatch(
              actions.passwordChange.showFormStatus('Unknown error', 'error')
            )
          }
        })
    }
  },
}

export const signOut = {
  request: function () {
    return function (dispatch) {
      dispatch(actions.signOut.requested())
      axios({ method: 'get', url: '/api/signout' })
        .then((response) => {
          dispatch(actions.signIn.reset())
          dispatch(actions.user.deAuthorize())
          dispatch(actions.signOut.success())
          dispatch(actions.storeReset())
        })
        .catch((error) => {
          if (error.response) {
            console.log('getSignOut response error', error.response)
            let msg = error.response.data.error || 'unknown response error'
            let status = error.response.status
            dispatch(actions.signOut.failed(status, msg))
          } else {
            console.log('getSignOut error', error)
            dispatch(actions.signOut.failed(-1, 'Unknown error'))
          }
        })
    }
  },
}

export const user = {
  get: function () {
    return function (dispatch) {
      dispatch(actions.user.posted())
      axios({
        method: 'get',
        url: '/api/user',
        params: {
          populate: 'sites, permissions.site, partnerPermissions.partner',
        },
      })
        .then((response) => {
          if (
            response.data &&
            response.data.roles &&
            !response.data.roles.includes('observer')
          ) {
            dispatch(actions.user.authorized(response.data))
          } else {
            console.log('getUser no data')
            dispatch(actions.user.unAuthorized())
          }
        })
        .catch((error) => {
          console.log('getUser error', error)
          dispatch(actions.user.unAuthorized())
        })
    }
  },
}

export const users = {
  get: function () {
    return function (dispatch) {
      dispatch(actions.users.get())
      axios({ method: 'get', url: '/api/users' })
        .then((response) => {
          dispatch(actions.users.response({ data: response.data }))
        })
        .catch((error) => {
          console.log('getUsers error', error)
          dispatch(actions.users.getFailed(error))
        })
    }
  },
}

export const sites = {
  getSites: function (skip, limit, sortAttribute, direction) {
    return function (dispatch) {
      dispatch(actions.sites.get())
      axios({
        method: 'get',
        url: `/api/store/sites?skip=${skip}&limit=${limit}&sort=${direction}${sortAttribute}`,
      })
        .then((response) => {
          const data = response.data
          const { total, items, more } = data
          dispatch(actions.sites.response(total, items))
        })
        .catch((error) => {
          console.log('getSites error', error)
          dispatch(actions.sites.getFailed(error))
        })
    }
  },
  searchForSites: function (string, sortAttribute, direction) {
    return function (dispatch) {
      dispatch(actions.sites.searchForSites())
      axios({
        method: 'get',
        url: `/api/store/sites?q=${string}&sort=${direction}${sortAttribute}`,
      })
        .then((response) => {
          const data = response.data
          const { total, items, more } = data
          dispatch(actions.sites.searchForSitesResponse(items))
        })
        .catch((error) => {
          console.log('getSites error', error)
          dispatch(actions.sites.searchForSitesFailed(error))
        })
    }
  },
  searchForSitesClear: function () {
    return function (dispatch) {
      dispatch(actions.sites.searchForSitesClear())
    }
  },
}

export const exercises = {
  get: function () {
    return function (dispatch) {
      dispatch(actions.exercises.get())
      axios({ method: 'get', url: '/api/v2/store/exercises' })
        .then((response) => {
          dispatch(actions.exercises.response({ data: response.data }))
        })
        .catch((error) => {
          console.log('getExercises error', error)
          dispatch(actions.exercises.getFailed(error))
        })
    }
  },
}

export const stacks = {
  get: function () {
    return function (dispatch) {
      dispatch(actions.stacks.get())
      axios({ method: 'get', url: '/api/stacks' })
        .then((response) => {
          dispatch(actions.stacks.response({ data: response.data }))
        })
        .catch((error) => {
          console.log('getStacks error', error)
          dispatch(actions.stacks.getFailed(error))
        })
    }
  },
}

export const stack = {
  get: function (id, what) {
    return function (dispatch) {
      dispatch(actions.stack.get(id, what))
      axios({
        method: 'get',
        url: `/api/stack/${id}`,
        params: {
          populate: 'site',
        },
      })
        .then((response) => {
          console.log('getStack response', response.data)
          dispatch(actions.stack.response(id, response.data))
        })
        .catch((error) => {
          console.log('getStack error', error)
          dispatch(actions.stack.getFailed(id, error))
        })
    }
  },
  save: function (id, stack, what) {
    return function (dispatch) {
      stripToIdOnly(['site'], stack)
      !stack.l10n && addL10nExercise(stack)

      dispatch(actions.stack.save(id, stack, what))
      axios({
        method: 'put',
        url: `/api/stack/${id}`,
        data: stack,
        params: {
          populate: 'site',
        },
      })
        .then((response) => {
          dispatch(actions.stack.saveResponse(id, response.data))
        })
        .catch((error) => {
          console.log('saveStack error', error)
          dispatch(actions.stack.saveFailed(id, error))
        })
    }
  },
  delete: function (id, what) {
    return function (dispatch) {
      dispatch(actions.stack.delete(id, what))
      axios({ method: 'delete', url: `/api/stack/${id}` })
        .then((response) => {
          dispatch(actions.stack.deleteResponse(id, response.data))
        })
        .catch((error) => {
          console.log('deleteStack error', error)
          dispatch(actions.stack.deleteFailed(id, error))
        })
    }
  },
}


export const exercise = {
  get: function (id, what) {
    return function (dispatch) {
      dispatch(actions.exercise.get(id, what))
      axios({
        method: 'get',
        url: `/api/store/exercise/${id}`,
        params: {
          populate: 'site',
        },
      })
        .then((response) => {
          dispatch(actions.exercise.response(id, response.data))
        })
        .catch((error) => {
          console.log('getExercise error', error)
          dispatch(actions.exercise.getFailed(id, error))
        })
    }
  },
  save: function (id, exercise, what) {
    return function (dispatch) {
      stripToIdOnly(['site'], exercise)
      !exercise.l10n && addL10nExercise(exercise)

      dispatch(actions.exercise.save(id, exercise, what))
      axios({
        method: 'put',
        url: `/api/exercise/${id}`,
        data: exercise,
        params: {
          populate: 'site',
        },
      })
        .then((response) => {
          dispatch(actions.exercise.saveResponse(id, response.data))
        })
        .catch((error) => {
          console.log('saveExercise error', error)
          dispatch(actions.exercise.saveFailed(id, error))
        })
    }
  },
  delete: function (id, what) {
    return function (dispatch) {
      dispatch(actions.exercise.delete(id, what))
      axios({ method: 'delete', url: `/api/exercise/${id}` })
        .then((response) => {
          dispatch(actions.exercise.deleteResponse(id, response.data))
        })
        .catch((error) => {
          console.log('deleteExercise error', error)
          dispatch(actions.exercise.deleteFailed(id, error))
        })
    }
  },
}

export const categories = {
  get: function () {
    return function (dispatch) {
      dispatch(actions.categories.get())
      axios({ method: 'get', url: '/api/store/categories' }) //TODO
        // axios({ method: 'get', url: '/api/categories' }) //TODO
        .then((response) => {
          dispatch(actions.categories.response({ data: response.data }))
        })
        .catch((error) => {
          console.log('getCategories error', error)
          dispatch(actions.categories.getFailed(error))
        })
    }
  },
}

export const category = {
  get: function (id, what) {
    return function (dispatch) {
      dispatch(actions.category.get(id, what))
      axios({
        method: 'get',
        url: `/api/store/category/${id}`,
      })
        .then((response) => {
          dispatch(actions.category.response(id, response.data))
        })
        .catch((error) => {
          console.log('getCategory error', error)
          dispatch(actions.category.getFailed(id, error))
        })
    }
  },
  save: function (id, category, what) {
    return function (dispatch) {
      stripToIdOnly(['site'], category)
      addL10nRoutine(category)

      dispatch(actions.category.save(id, category, what))
      axios({
        method: 'put',
        url: `/api/store/category/${id}`,
        data: category,
      })
        .then((response) => {
          dispatch(actions.category.saveResponse(id, response.data))
        })
        .catch((error) => {
          console.log('saveCategory error', error)
          dispatch(actions.category.saveFailed(id, error))
        })
    }
  },
  delete: function (id, what) {
    return function (dispatch) {
      dispatch(actions.category.delete(id, what))
      axios({ method: 'delete', url: `/api/store/category/${id}` })
        .then((response) => {
          dispatch(actions.category.deleteResponse(id, response.data))
        })
        .catch((error) => {
          console.log('deleteCategory error', error)
          dispatch(actions.category.deleteFailed(id, error))
        })
    }
  },
}

export const collections = {
  get: function () {
    return function (dispatch) {
      dispatch(actions.collections.get())
      axios({ method: 'get', url: '/api/store/programs' }) //TODO
        .then((response) => {
          dispatch(actions.collections.response({ data: response.data }))
        })
        .catch((error) => {
          console.log('getCollections error', error)
          dispatch(actions.collections.getFailed(error))
        })
    }
  },
}

export const collection = {
  get: function (id, what) {
    return function (dispatch) {
      dispatch(actions.collection.get(id, what))
      axios({
        method: 'get',
        url: `/api/program/${id}`,
      })
        .then((response) => {
          dispatch(actions.collection.response(id, response.data))
        })
        .catch((error) => {
          console.log('getExercise error', error)
          dispatch(actions.collection.getFailed(id, error))
        })
    }
  },
  save: function (id, collection, what) {
    return function (dispatch) {
      stripToIdOnly(['site'], collection)
      addL10nProgram(collection)

      dispatch(actions.collection.save(id, collection, what))
      axios({
        method: 'put',
        url: `/api/program/${id}`,
        data: collection,
      })
        .then((response) => {
          dispatch(actions.collection.saveResponse(id, response.data))
        })
        .catch((error) => {
          console.log('saveCollection error', error)
          dispatch(actions.collection.saveFailed(id, error))
        })
    }
  },
  delete: function (id, what) {
    return function (dispatch) {
      dispatch(actions.collection.delete(id, what))
      axios({ method: 'delete', url: `/api/program/${id}` })
        .then((response) => {
          dispatch(actions.collection.deleteResponse(id, response.data))
        })
        .catch((error) => {
          console.log('deleteCollection error', error)
          dispatch(actions.collection.deleteFailed(id, error))
        })
    }
  },
}

export const programs = {
  get: function () {
    return function (dispatch) {
      dispatch(actions.programs.get())
      axios({ method: 'get', url: '/api/v2/store/routines' })
        .then((response) => {
          dispatch(actions.programs.response({ data: response.data }))
        })
        .catch((error) => {
          console.log('getPrograms error', error)
          dispatch(actions.programs.getFailed(error))
        })
    }
  },
}

export const zones = {
  get: function (populate) {
    return function (dispatch) {
      dispatch(actions.gymZones.get())
      axios({
        method: 'get',
        url: populate
          ? `/api/store/zones?populate=routines,exercises`
          : `/api/store/zones`,
      })
        .then((response) => {
          const data = response.data
          if (populate && data.zones) {
            const { zones, routines, exercises } = data
            dispatch(
              actions.gymZones.response('global', zones, routines, exercises)
            )
          } else {
            dispatch(actions.gymZones.response('global', data))
          }
        })
        .catch((error) => {
          console.log('getZone error', error)
          dispatch(actions.gymZones.getFailed('global', error))
        })
    }
  },
}

export const program = {
  get: function (id, what) {
    return function (dispatch) {
      dispatch(actions.program.get(id, what))
      axios({
        method: 'get',
        url: `/api/routine/${id}`,
        params: {
          populate: 'site',
        },
      })
        .then((response) => {
          dispatch(actions.program.response(id, response.data))
        })
        .catch((error) => {
          console.log('getProgram error', error)
          dispatch(actions.program.getFailed(id, error))
        })
    }
  },
  save: function (id, program, what) {
    return function (dispatch) {
      stripToIdOnly(['site'], program)
      addL10nRoutine(program)

      dispatch(actions.program.save(id, program, what))
      axios({
        method: 'put',
        url: `/api/routine/${id}`,
        data: program,
        params: {
          populate: 'site',
        },
      })
        .then((response) => {
          dispatch(actions.program.saveResponse(id, response.data))
        })
        .catch((error) => {
          console.log('saveProgram error', error)
          dispatch(actions.program.saveFailed(id, error))
        })
    }
  },
  delete: function (id, what) {
    return function (dispatch) {
      dispatch(actions.program.delete(id, what))
      axios({ method: 'delete', url: `/api/routine/${id}` })
        .then((response) => {
          dispatch(actions.program.deleteResponse(id, response.data))
        })
        .catch((error) => {
          console.log('deleteProgram error', error)
          dispatch(actions.program.deleteFailed(id, error))
        })
    }
  },
}

export const machine = {
  get: function (id, what) {
    return function (dispatch) {
      dispatch(actions.machine.get(id, what))
      axios({
        method: 'get',
        url: `/api/machine/${id}`,
        params: {
          populate: 'site, user',
        },
      })
        .then((response) => {
          if (response.data) {
            dispatch(actions.machine.response(id, response.data))
          } else {
            dispatch(actions.machine.getFailed(id, 'Could not find machine'))
          }
        })
        .catch((error) => {
          console.log('getMachine error', error)
          dispatch(actions.machine.getFailed(id, error))
        })
    }
  },
  getZone: function (id, what) {
    return function (dispatch) {
      dispatch(actions.machine.get(id, what))
      axios({
        method: 'get',
        url: `/api/store/zone/${id}`,
      })
        .then((response) => {
          if (response.data) {
            const machine = response.data
            let exercises = []
            let exerciseIds = []
            if (machine.puck) {
              if (machine.puck.exercises) {
                machine.puck.exercises.map((e) => {
                  if (e.exercise) {
                    exercises.push(e.exercise)
                    exerciseIds.push({
                      _id: e._id,
                      prio: e.prio,
                      exercise: e.exercise._id,
                    })
                  }
                })
                machine.puck.exercises = exerciseIds
                machine.puck.routines ? null : (machine.puck.routines = [])
                machine.puck.items ? null : (machine.puck.items = [])
              }
            }

            if (
              machine.type === 'connected_cardio' &&
              machine.connectedCardio
            ) {
              if (machine.connectedCardio.type === 'matrix') {
                machine.hasAdvertisementName = true
              }
            }
            dispatch(actions.gymZone.response(machine._id, machine, exercises))
            dispatch(actions.machine.response(id, machine))
          } else {
            dispatch(actions.machine.getFailed(id, 'Could not find zone'))
          }
        })
        .catch((error) => {
          console.log('getMachine error', error)
          dispatch(actions.machine.getFailed(id, error))
        })
    }
  },
  save: function (id, machine, what) {
    return function (dispatch) {
      stripToIdOnly(['site', 'user'], machine)
      addL10nMachine(machine)

      if (machine.type === 'puck' && machine.puck && machine.translations.length > 0) {
        machine.translations.map((t) => {
          t.puck.info.items.map((item, index) => {
            item.name = machine.puck.info.items[index]
              ? machine.puck.info.items[index].name
              : 'info_link'
          })
        })

        //POSSIBLE FIX FOR ANDROID WANTING FULL ZONE INFO
        if (machine.translations) {
          if (machine.translations.length > 0) {
            const { translations, puck } = machine
            const populatedTranslations = translations.map((t) => {
              const populatedPuck = {
                ...t.puck,
                exercises: puck.exercises,
                routines: puck.routines,
                image: puck.image,
                video: puck.video,
                info: {
                  ...puck.info,
                  ...t.puck.info,
                  items: t.puck.info.items.map((i, index) => {
                    const populatedItem = puck.info.items.find(
                      (j) => j.prio === index
                    )
                    return { ...populatedItem, ...i }
                  }),
                },
              }
              return { ...t, puck: populatedPuck }
            })
            machine.translations = populatedTranslations
          }
        }
      }
      console.log('')

      dispatch(actions.machine.save(id, machine, what))
      axios({
        method: 'put',
        url: `/api/machine/${id}`,
        data: machine,
        params: {
          populate: 'site, user',
        },
      })
        .then((response) => {
          dispatch(actions.machine.saveResponse(id, response.data))
        })
        .catch((error) => {
          console.log('saveExercise error', error)
          dispatch(actions.machine.saveFailed(id, error))
        })
    }
  },
  delete: function (id, gymId, what) {
    return async function (dispatch) {
      dispatch(actions.machine.delete(id, what))

      try {
        const getGym = await axios({ method: 'get', url: `/api/site/${gymId}` })
        const gym = getGym.data
        gym.machines = gym.machines.filter((m) => m != id)
        const updateGym = await axios({
          method: 'put',
          url: `/api/site/${gymId}`,
          data: gym,
        })
      } catch (error) {
        console.log('deleteMachine, site update, error', error)
        dispatch(actions.machine.deleteFailed(id, error))
      }

      axios({ method: 'delete', url: `/api/machine/${id}` })
        .then((response) => {
          dispatch(actions.machine.deleteResponse(id, response.data))
        })
        .catch((error) => {
          console.log('deleteMachine error', error)
          dispatch(actions.machine.deleteFailed(id, error))
        })
    }
  },
  deleteGlobalZone: function (id, what) {
    return async function (dispatch) {
      dispatch(actions.machine.delete(id, what))

      axios({ method: 'delete', url: `/api/store/zone/${id}` })
        .then((response) => {
          dispatch(actions.machine.deleteResponse(id, response.data))
        })
        .catch((error) => {
          console.log('deleteMachine error', error)
          dispatch(actions.machine.deleteFailed(id, error))
        })
    }
  },
}

export const observer = {
  get: function (id, what) {
    return function (dispatch) {
      dispatch(actions.observer.get(id, what))
      axios({
        method: 'get',
        url: `/api/observer/${id}`,
        params: {
          populate: 'permissions.site',
        },
      })
        .then((response) => {
          dispatch(actions.observer.response(id, response.data))
        })
        .catch((error) => {
          console.log('getObserver error', error)
          dispatch(actions.observer.getFailed(id, error))
        })
    }
  },
  save: function (id, observer, what) {
    return function (dispatch) {
      // TODO: does not support path of array/object
      // stripToIdOnly(['permissions.site'], observer)

      dispatch(actions.observer.save(id, observer, what))
      axios({
        method: 'put',
        url: `/api/observer/${id}`,
        data: observer,
        params: {
          populate: 'permissions.site',
        },
      })
        .then((response) => {
          dispatch(actions.observer.saveResponse(id, response.data))
        })
        .catch((error) => {
          console.log('save observer error', error)
          dispatch(actions.observer.saveFailed(id, error))
        })
    }
  },
  delete: function (id, gymId, what) {
    return async function (dispatch) {
      try {
        const getGym = await axios({ method: 'get', url: `/api/site/${gymId}` })
        const gym = getGym.data
        gym.observers = gym.observers.filter((o) => o != id)
        const updateGym = await axios({
          method: 'put',
          url: `/api/site/${gymId}`,
          data: gym,
        })
      } catch (error) {
        console.log('deleteObserver, site update, error', error)
        dispatch(actions.observer.deleteFailed(id, error))
      }

      dispatch(actions.observer.delete(id, what))
      axios({ method: 'delete', url: `/api/observer/${id}` })
        .then((response) => {
          dispatch(actions.observer.deleteResponse(id, response.data))
        })
        .catch((error) => {
          console.log('delete observer error', error)
          dispatch(actions.observer.deleteFailed(id, error))
        })
    }
  },
}

export const partners = {
  get: function () {
    return function (dispatch) {
      dispatch(actions.partners.get())
      axios({
        method: 'get',
        url: '/api/partners',
        params: {
          populate: 'sites',
        },
      })
        .then((response) => {
          dispatch(actions.partners.response({ data: response.data }))
        })
        .catch((error) => {
          console.log('getPartners error', error)
          dispatch(actions.partners.getFailed(error))
        })
    }
  },
}

export const crashReport = {
  //TO DO BEFORE LF LAUNCH - CHANGE TO LIFE FITNESS WEB HOOKS
  post: function (data) {
    return function (dispatch) {
      axios.post('/api/slack/runstone_crash_report',
        JSON.stringify(data))
        .then((response) => {
          console.log('Crash log sent')
        })
        .catch((error) => {
          console.log('Crash log failed', error)
        })
    }
  },
}

export const feedbackReport = {
  post: function (
    _id,
    name,
    userEmail,
    lang,
    device,
    date,
    text,
    newMedia,
    crash
  ) {
    return function (dispatch) {
      dispatch(actions.profileSettings.sendFeedback())
      const message = { text: text, media: [] }
      const uploadMedia = newMedia ? newMedia : null
      manageUserMessageMedia(
        { message, newMedia: uploadMedia, folder: 'feedback' },
        (error) => {
          if (error) {
            dispatch(actions.profileSettings.sendFeedbackFailed(error))
            console.log(error)
          } else {
            const pic = message.media
              ? message.media.find((m) => m.name === 'pic')
              : null
            const picUrl = pic ? pic.url : null
            let data = null
            if (picUrl) {
              if (crash) {
                data = crashFeedbackWithPic(
                  name,
                  userEmail,
                  lang,
                  device,
                  date,
                  text,
                  picUrl
                )
              } else {
                data = feedbackWithPic(
                  name,
                  userEmail,
                  lang,
                  device,
                  date,
                  text,
                  picUrl
                )
              }
            } else {
              if (crash) {
                data = crashFeedback(name, userEmail, lang, device, date, text)
              } else {
                data = feedback(name, userEmail, lang, device, date, text)
              }
            }
            axios.post(crash
              ? '/api/slack/runstone_feedback_crash'
              : '/api/slack/runstone_feedback',
              JSON.stringify(data))
              .then((response) => {
                dispatch(actions.profileSettings.sendFeedbackResponse())
              })
              .catch((error) => {
                dispatch(actions.profileSettings.sendFeedbackFailed(error))
                console.log('SendFeedback error', error)
              })
          }
        }
      )
    }
  },
}
