export const userColor = [18, 162, 92]
export const suColor = [0, 0, 0]
export const gymGridColor = [53, 67, 89]
export const partnerGridColor = [103, 103, 18]

export const userColorCss = '#12a25c'
export const suColorCss = '#000000'
export const gymGridColorCss = '#354359'
export const gymGridColorCssLighten = '#4F5D73'
export const gymGridColorCssLightest = '#7185a3'
export const partnerGridColorCss = '#676712'

export const lightGrey = '#ddd'
export const lineLightGrey = '#22242626'
export const mediumGrey = '#777'
export const mediumGrey_1 = '#646161'
export const darkGrey = '#282828'
export const advagymGreen = '#3db77a'
export const advagymGreenLighten = '#57D194'
export const advagymGreen_1 = '#376d51'
export const notificationGreen = "#91b29c"
export const advagymRed = '#c91818'
export const advagymOrange = '#e6853c'
export const advagymOrange2 = '#e7a63e'
export const linkBlue = '#3475e0'
export const standardBackground = '#f6f7f8'
