import React from 'react'
import { Grid } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import Breadcrumb from 'app/root/breadcrumb/Breadcrumb'
import PageActions from 'app/protected/roles/common/page-actions/PageActions.js'

const PageTop = props => {
  const { showActions, actionsConfig } = props

  return (
    <Grid style={{ margin: '0' }}>
      <Grid.Row
        style={{ margin: '0', paddingBottom: '0', paddingTop: '0' }}
        columns={2}
      >
        <Grid.Column
          textAlign="left"
          verticalAlign="middle"
          style={{ paddingLeft: 0 }}
        >
          <Breadcrumb />
        </Grid.Column>

        {showActions && actionsConfig && (
          <Grid.Column
            textAlign="right"
            verticalAlign="middle"
            style={{ paddingRight: 0 }}
          >
            <PageActions actionsConfig={actionsConfig} />
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  )
}

PageTop.propTypes = {
  actionsConfig: PropTypes.array,
  showActions: PropTypes.bool,
}

export default PageTop
