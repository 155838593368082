import { client as types, client } from '../actions/actiontypes'

const initState = {
  ongoing: "nothing",
  client: null,
  shares: [],
  sharesOngoing: "nothing",
  progress: 'initial',
  errors: null,
  notes: [],
  ongoingNotes: 'nothing',
}

const updateNotes = (notes, note) => {
  let list = []
  notes && notes.map(n => {
    if (n._id === note._id) {
      list.push(note)
    } else {
      list.push(n)
    }
  })
  return list
}

export function clientReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return {
        ...initState,
      }
    case types.GET:
      return {
        ...state,
        client: null,
        shares: [],
        errors: null,
        ongoing: "fetching",
      }
    case types.UPDATE:
      return {
        ...state,
        ongoing: "updating",
      }
    case types.SHARE:
      return {
        ...state,
        ongoing: "sharing",
      }
    case types.REMOVE:
      return {
        ...state,
        ongoing: "removing",
      }
    case types.RESPONSE:
    case types.UPDATE_RESPONSE:
      return {
        ...state,
        ongoing: "nothing",
        client: action.client,
      }
    case types.GET_FAIL:
    case types.UPDATE_FAIL:
    case types.SHARE_FAIL:
    case types.REMOVE_FAIL:
      return {
        ...state,
        ongoing: "nothing",
        errors: action,
      }
    case types.SHARE_RESPONSE:
      return {
        ...state,
        ongoing: "nothing",
      }
    case types.REMOVE_RESPONSE:
      return {
        ...state,
        //client: null,
        ongoing: "removed",
        client: null,
      }
    case types.REMOVE_SHARE:
      return {
        ...state,
        sharesOngoing: "removing",
      }
    case types.REMOVE_SHARE_RESPONSE:
      return {
        ...state,
        sharesOngoing: "nothing",
        shares: state.shares.filter((s) => s._id !== action.routineId)
      }
    case types.REMOVE_SHARE_FAIL:
      return {
        sharesOngoing: "error",
        ...state,
      }

    case types.SET_READ_ALL:
      return {
        ...state,
        client: {
          ...state.client,
          unreadMessages: 0,
        }
      }

    case types.GET_SHARES:
      return {
        ...state,
        sharesOngoing: "fetching",
        shares: []
      }
    case types.SHARES_RESPONSE:
      return {
        ...state,
        sharesOngoing: "nothing",
        shares: action.shares.map(s => { return ({ ...s.routine, endedAt: s.endedAt, count: s.count, group: s.group }) }),
      }
    case types.SHARES_FAILED:
      return {
        ...state,
        sharesOngoing: "nothing",
        errors: action.error,
      }
    case types.ADD_SHARE:
      return {
        ...state,
        shares: [...state.shares, action.share],
        progress: action.clear ? 'initial' : 'created',
      }
    case types.PROGRESS_CLEAR:
      return {
        ...state,
        progress: 'initial',
      }

    case types.GET_NOTES:
      return {
        ...state,
        ongoingNotes: 'fetching',
      }
    case types.GET_NOTES_RESPONSE:
      return {
        ...state,
        ongoingNotes: 'nothing',
        notes: action.notes,
      }
    case types.GET_NOTES_FAILED:
      return {
        ...state,
        ongoingNotes: 'nothing',
        errors: action.error,
      }
    case types.CREATE_NOTE:
      return {
        ...state,
        ongoingNotes: 'creating',
      }
    case types.CREATE_NOTE_RESPONSE:
      return {
        ...state,
        ongoingNotes: 'nothing',
        notes: [...state.notes, action.note],
      }
    case types.CREATE_NOTE_FAILED:
      return {
        ...state,
        ongoingNotes: 'nothing',
        errors: action.error,
      }
    case types.REMOVE_NOTE:
      return {
        ...state,
        ongoingNotes: 'removing',
      }
    case types.REMOVE_NOTE_RESPONSE:
      return {
        ...state,
        notes: state.notes.filter((m) => m._id !== action.note),
        ongoingNotes: 'nothing',
      }
    case types.REMOVE_NOTE_FAIL:
      return {
        ...state,
        ongoingNotes: 'error',
        errors: action.error,
      }
    case types.SAVE_NOTE:
      return {
        ...state,
        ongoingNotes: 'saving',
      }
    case types.SAVE_NOTE_RESPONSE:
      return {
        ...state,
        notes: updateNotes(state.notes, action.note),
        ongoingNotes: 'nothing',
      }
    case types.SAVE_NOTE_FAIL:
      return {
        ...state,
        ongoingNotes: 'error',
        errors: action.error,
      }

    case types.SAVE_SHARE:
      return {
        ...state,
        progress: 'saving',
      }
    case types.SAVE_SHARE_RESPONSE:
      return {
        ...state,
        shares: state.shares.map(s => { return s._id === action.share._id ? action.share : s }),
        progress: "saved",
      }
    case types.SAVE_SHARE_FAILED:
      return {
        ...state,
        progress: "nothing",
        errors: action.error,
      }

    default:
      return state
  }
}