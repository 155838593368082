import React from 'react'
import { connect } from 'react-redux'
import { menu as menuAction } from 'redux/actions/actions'
import { withRouter, matchPath } from 'react-router-dom'
import { Button, Menu, Grid, Image, Container, Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { darkGrey } from 'app/protected/roles/util/colors'
import logo from 'assets/images/logo-white.svg'
import HeaderPersonalMenu from 'app/root/header/personal-menu/HeaderPersonalMenu'
import { homePaths } from 'app/protected/common/constants/routes'
import i18next from 'i18next'

function Header(props) {
  const { isAuthorized, isAtHome, toggleMenu, history, disabled } = props

  const goToHome = () => {
    isAuthorized ?
      history.push(homePaths.ME())
      : history.push('/')
  }

  return (
    <Menu
      borderless
      fixed={'top'}
      style={{ backgroundColor: darkGrey, zIndex: 1 }}
    >
      <Grid style={{ width: '100%', margin: 0 }}>
        <Grid.Column
          style={{
            display: 'inline-flex',
            padding: '0',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flex: '1 1 0',
          }}
        >
          {(isAuthorized && !disabled) && (
            <Button
              icon="bars"
              circular
              size="large"
              style={{ backgroundColor: darkGrey, color: 'white', margin: 0 }}
              onClick={() => toggleMenu()}
            />
          )}
        </Grid.Column>

        <Grid.Column textAlign={'center'} style={{ flex: '2 0 0' }}>
          <Container onClick={goToHome} style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
            {/* <Link to={homePaths.ME()} aria-label="Link to TrainerConnect home"> */}
            <Image
              src={logo}
              style={{ height: '22px', width: 'auto' }}
              alt="TrainerConnect logo"
            />
            {/* </Link> */}
          </Container>
        </Grid.Column>

        <Grid.Column
          only="mobile"
          style={{
            display: 'inline-flex',
            padding: '0',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flex: '1 1 0',
          }}
        >
          {(isAuthorized && !disabled) && <HeaderPersonalMenu />}
        </Grid.Column>
        <Grid.Column
          only="computer tablet"
          style={{
            display: 'inline-flex',
            padding: '0',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flex: '1 1 0',
          }}
        >
          {(isAuthorized && !disabled) && <>
            {/* {isAtHome && <Button
              href="https://advagymsolutions.com/resources/"
              target="_blank"
              size='mini'
              compact
              positive
            >
              {i18next.t('resources')}
              <Icon
                name="arrow right"
              />
            </Button>} */}
            <HeaderPersonalMenu />
          </>}
        </Grid.Column>
      </Grid>
    </Menu>
  )

}

Header.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  isAtHome: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

const mapStateToProps = (state, props) => {
  const { status, data } = state.userReducer
  const acceptedTerms = data.webToSAccepted ? data.webToSAccepted : false
  const { location } = props
  const isAtHome = matchPath(location.pathname, {
    path: homePaths.HOME(),
    exact: true,
    strict: false,
  }) ? true : false
  return {
    isAuthorized: status == 'authorized' && acceptedTerms,
    isAtHome,
  }
}

const mapDispatchToProps = (dispatch) => ({
  toggleMenu: () => {
    dispatch(menuAction.toggle())
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
