import React from 'react'
import PropTypes from 'prop-types'

export default function SupText(props) {
  const { text, color } = props

  return (
    <React.Fragment>
      <sup style={{ color: color }}>{text}</sup>
    </React.Fragment>
  )
}

PropTypes.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string
}