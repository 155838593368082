import {signOut as types} from '../actions/actiontypes'

const initState = {
  ongoing: false,
  status: undefined,
  message: '',
  result: 'unknown',
}

export function signOutReducer(state = initState, action) {
  switch (action.type) {
    case "STORE_RESET":
      return Object.assign({}, initState)
    case types.REQUESTED:
      return Object.assign({}, state, {
        ongoing: true,
      })
    case types.SUCCESS:
      return Object.assign({}, state, {
        ongoing: false,
        result: 'success'
      })
    case types.FAILED:
      return Object.assign({}, state, {
        ongoing: false,
        status: action.status,
        message: action.message,
        result: 'failed'
      })
    default:
      return state
  }
}
