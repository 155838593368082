export function generateL10n(name) {
  return name.replace(/[^a-zA-Z0-9]/g, '').replace(/[^\w\s]/gi, '').toLowerCase()
  // return name.replace(/[ #–&-1234567890]/g, '').replace(/[^\w\s]/gi, '').toLowerCase()
}

function addL10n(obj) {
  if (obj && obj.name) {
    obj.l10n = generateL10n(obj.name)
  }
  return obj
}

function removeL10n(obj) {
  if (obj && obj.l10n) {
    obj.l10n = ""
  }
  return obj
}

export function addL10nGym(gym) {
  if (gym) {
    addL10n(gym)
  } else {
    removeL10n(gym)
  }
  return gym
}

export function addL10nMachine(machine) {
  if (
    machine &&
    machine.enabled &&
    machine.exercises &&
    machine.exercises.length > 0
  ) {
    addL10n(machine)
    machine.exercises.forEach(e => addL10n(e))
  } else {
    removeL10n(machine)
  }
  return machine
}

export function addL10nProgram(program) {
  if (program && (program.scope == 'curated' || program.scope == 'import') && !program.site) {
    addL10n(program)
  } else {
    removeL10n(program)
  }
  return program
}

export function addL10nRoutine(routine) {
  if (routine && (routine.scope == 'curated' || routine.scope == 'import') && !routine.site) {
    addL10n(routine)
    routine.exercises.forEach(e => addL10n(e))
  } else {
    removeL10n(routine)
  }
  return routine
}

export function addL10nExercise(exercise) {
  if (
    exercise &&
    (exercise.scope == 'curated' || exercise.scope == 'public') &&
    !exercise.site
  ) {
    addL10n(exercise)
  } else {
    removeL10n(exercise)
  }
  return exercise
}
