import { myGymProgramCollection as types } from '../actions/actiontypes'

const initState = {
  ongoing: [],
  programCollections: {},
  delete: {},
  errors: {},
  mode: 'view',
  showDuplicateDialog: null,
  routines: [],
  currentCollection: {},
  currentSite: '',
  workProgress: '',
  workResult: [],
}

function addToOngoing(state, action) {
  return [
    ...state,
    {
      id: action.id,
      what: action.what,
    },
  ]
}

function removeFromOngoing(state, action) {
  return state.filter(o => o.id != action.id)
}

function addToDeleted(state, action) {
  return Object.assign({}, state, {
    [action.id]: 'deleted',
  })
}

function addToGymProgramCollection(state, action) {
  return Object.assign({}, state, {
    [action.id]: action.programCollections,
  })
}

function removeFromProgramCollection(state, action) {
  let { [action.id]: omit, ...res } = state
  return res
}

function addToError(state, action) {
  return Object.assign({}, state, {
    [action.id]: action.error,
  })
}

function addToWorkCollection(state, action) {
  return Object.assign({}, state, action.exercise)
}

function updatePercentage(state, action) {
  return Object.assign({}, state, { percentage: action.percentage })
}

export function myGymProgramCollectionReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return {
        ...initState,
      }
    case types.GET:
    case types.SAVE:
    case types.DELETE:
    case types.DUPLICATE:
      return {
        ...state,
        errors: {},
        ongoing: addToOngoing(state.ongoing, action),
      }
    case types.RESPONSE:
    case types.SAVE_RESPONSE:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        programCollections: addToGymProgramCollection(
          state.programCollections,
          action
        ),
        mode: 'view',
      }
    case types.DELETE_RESPONSE:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        programCollections: removeFromProgramCollection(
          state.programCollections,
          action
        ),
        delete: addToDeleted(state.delete, action),
      }
    case types.DUPLICATE_RESPONSE:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        showDuplicateDialog: {
          _id: action.programCollections._id,
          name: action.programCollections.name,
        },
      }
    case types.GET_FAIL:
    case types.SAVE_FAIL:
    case types.DELETE_FAIL:
    case types.DUPLICATE_FAIL:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        errors: addToError(state.error, action),
      }
    case types.DISMISS_DUPLICATE_DIALOG:
      return {
        ...state,
        showDuplicateDialog: null,
        mode: 'view',
      }
    case types.SET_MODE:
      return {
        ...state,
        mode: action.mode,
      }

    case types.WORK:
      return {
        ...state,
        ongoing: addToOngoing(state.ongoing, action),
        currentCollection: addToWorkCollection(state.currentCollection, action),
        workProgress: updatePercentage(state.updatePercentage, action),
        currentSite: action.site,
      }
    case types.WORK_RESPONSE:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        currentCollection: {},
        workProgress: updatePercentage(state.updatePercentage, action),
        workResult: [...state.workResult, action.result],
        currentSite: '',
      }
    case types.CLEAR_WORK:
      return {
        ...state,
        ongoing: [],
        currentCollection: {},
        workProgress: '',
        workResult: [],
        currentSite: '',
      }

    default:
      return state
  }
}
