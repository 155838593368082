import React from 'react'
import { Dropdown, Message } from 'semantic-ui-react'
import i18next from 'i18next'
import { supportedLanguages } from 'i18n/i18n'
import { mediumGrey } from 'app/protected/roles/util/colors'
import { setLanguageAndTimeFormat } from 'util/util'
import thunks from 'redux/actions/thunks/ProfileSettings'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { homePaths } from 'app/protected/common/constants/routes'

export const getLanguage = settings => {
  const s = settings.filter(s => s.name === 'language')

  if (s.length <= 0) {
    return null
  }

  let item = s[0]

  item.key = item.value
  switch (item.value) {
    case 'de':
      item.text = i18next.t('language_de')
      break
    case 'en':
      item.text = i18next.t('language_en')
      break
    case 'es':
      item.text = i18next.t('language_es')
      break
    case 'fr':
      item.text = i18next.t('language_fr')
      break
    case 'it':
      item.text = i18next.t('language_it')
      break
    case 'nl':
      item.text = i18next.t('language_nl')
      break
    case 'pt':
      item.text = i18next.t('language_pt')
      break
    case 'sv':
      item.text = i18next.t('language_sv')
      break
    case 'ja':
      item.text = i18next.t('language_ja')
      break

    default:
      break
  }

  return item
}

function LanguageChooser(props) {
  const { saveLanguage, setContentLang, settings, savedLanguage, ongoing, history } = props

  const onLanguageChange = (event, target) => {
    let _settings = settings.slice()

    let languageSettingCount = 0
    for (let i = 0; i < _settings.length; i++) {
      const s = _settings[i]

      if (s.name === 'language') {
        if (s.value === target.value) return

        s.value = target.value
        s.key = target.value
        s.name = 'language'
        s.text = target.value
        languageSettingCount = languageSettingCount + 1
      }
    }

    if (!languageSettingCount) {
      _settings.push({
        value: target.value,
        key: target.value,
        name: 'language',
        text: target.value,
      })
    }

    saveLanguage({ settings: _settings })
    setLanguageAndTimeFormat(target.value)
    setContentLang(target.value)

    // Superhack for getting all the language variables to be reloaded ALL components
    history.push(homePaths.PROFILE_SETTINGS())
  }

  const languageOptions = supportedLanguages.map(language => {
    return {
      key: language.code,
      value: language.code,
      flag: language.flag,
      text: language.language,
      name: 'language',
    }
  })

  return (
    <>
      {!savedLanguage.value && (
        <Message warning compact>
          <span>{i18next.t('you_have_no_language_selected')}</span>
        </Message>
      )}

      <div style={{ maxWidth: '220px' }}>
        <div
          style={{
            color: mediumGrey,
            fontSize: '12px',
            marginBottom: '3px',
          }}
        >
          {i18next.t('language')}:
        </div>

        <Dropdown
          placeholder={i18next.t('select_language')}
          fluid
          search
          selection
          options={languageOptions}
          value={savedLanguage.value}
          onChange={onLanguageChange}
          loading={ongoing}
          disabled={ongoing}
        />
      </div>
    </>
  )
}

const mapStateToProps = state => {
  const { userReducer, profileSettingsReducer } = state
  const { data } = userReducer
  const { unitOngoing, webSettingsOngoing, languageOngoing } = profileSettingsReducer
  const { settings } = data
  const _language = getLanguage(settings)
  const savedLanguage = _language ? _language : {}

  return {
    settings,
    ongoing: unitOngoing || webSettingsOngoing || languageOngoing,
    savedLanguage,
  }
}

const mapDispatchToProps = dispatch => ({
  saveLanguage: settings => dispatch(thunks.saveLanguage(settings)),
  setContentLang: lang => dispatch(thunks.setContentLang(lang))
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LanguageChooser)
)
