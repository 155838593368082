import { gymStatus as types } from '../actions/actiontypes'
import _omit from 'lodash/omit'
import { act } from 'react-test-renderer'

const initState = {
  ongoings: {},
  ongoing: false,
  gyms: {},
  gymList: [],
  errors: {},
}

function addToOngoing(state, action) {
  return {
    ...state,
    [action.id]: {
      id: action.id,
      what: action.what,
    },
  }
}

function removeFromOngoing(state, action) {
  return _omit(state, action.id)
}

function addToGyms(state, action) {
  const next = Object.assign({}, state, {
    [action.id]: action.gym,
  })
  return next
}

function addToErrors(state, action) {
  return Object.assign({}, state, {
    [action.id]: action.error,
  })
}

export function gymStatus(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return {
        ...initState,
      }
    case types.GET:
      return {
        ...state,
        ongoings: addToOngoing(state.ongoings, action),
        ongoing: true,
      }
    case types.GET_RESPONSE:
      return {
        ...state,
        ongoings: removeFromOngoing(state.ongoings, action),
        gyms: addToGyms(state.gyms, action),
        gymList: [...state.gymList, action.gym],
        ongoing: false,
      }
    case types.GET_FAIL:
      return {
        ...state,
        ongoings: removeFromOngoing(state.ongoings, action),
        errors: addToErrors(state.error, action),
        ongoing: false,
      }
    case types.CLEAR:
      return {
        ...state,
        gymList: [],
        ongoing: false,
      }
    default:
      return state
  }
}
