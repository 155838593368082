import {connect} from 'react-redux'
import Unauthorized from '../component/Unauthorized'
import {storeReset} from 'redux/actions/actions'

const mapStateToProps = (state) => {
  const {ongoing, data, status} = state.userReducer
  return {status}
}

const mapDispatchToProps = (dispatch) => {
  return {
    reset: () => {
      dispatch(storeReset())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Unauthorized)
