import { myGymPrograms as types } from '../actions/actiontypes'

const getProgramsOptions = (programs) =>
  programs.map((program) => ({
    _id: program._id,
    key: program._id,
    text: program.name,
    value: program._id,
    type: getType(program),
  }))

const getType = (item) => {
  if (item.media) {
    if (item.media.length > 0) {
      if (item.media.find((m) => m.name === 'video_class')) {
        return 'class'
      }
    }
  }
  return 'program'
}

const updateVisibility = (programs, visibilityList) => {
  let updatedList = []
  programs.map((p) => {
    const hasVisibleUpdate = visibilityList.find((v) => v._id === p._id)
    if (hasVisibleUpdate) {
      const updatedProgram = {
        ...p,
        visible: visibilityList.find((v) => v._id === p._id).visible,
      }
      updatedList.push(updatedProgram)
    } else {
      updatedList.push(p)
    }
  })
  return updatedList
}

const initState = {
  ongoing: 'nothing',
  programs: [],
  error: undefined,
  programsOptions: [],
  program: null,
  importOngoing: 'nothing',
}

export function myGymProgramsReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return {
        ...initState,
      }
    case types.GET:
      return {
        ...state,
        ongoing: 'fetching',
      }
    case types.RESPONSE:
      return {
        ...state,
        ongoing: 'nothing',
        programs: action.programs,
        programsOptions: getProgramsOptions(action.programs),
      }
    case types.GET_FAIL:
    case types.CREATE_FAIL:
      return {
        ...state,
        ongoing: 'nothing',
        error: action.error,
        program: null,
      }
    case types.UPDATE_VISIBILITY:
      return {
        ...state,
        programs: updateVisibility(state.programs, action.updatedList),
      }
    case types.CREATE:
      return {
        ...state,
        ongoing: 'creating',
        program: null,
      }
    case types.CREATE_RESPONSE:
      return {
        ...state,
        ongoing: 'nothing',
        program: action.data,
        error: undefined,
      }
    case types.CLEAR_CREATE:
      return {
        ...state,
        ongoing: 'nothing',
        program: null,
        error: undefined,
      }
    case types.COPY:
      return {
        ...state,
        ongoing: 'copying',
        error: null,
      }
    case types.COPY_RESPONSE:
      return {
        ...state,
        ongoing: 'nothing',
        error: null,
      }
    case types.COPY_FAILED:
      return {
        ...state,
        ongoing: 'nothing',
        error: action.error,
      }
    case types.FILTER_OUT_WORKOUT:
      return {
        ...state,
        programs: state.programs.filter((p) => p._id !== action.program),
        programsOptions: state.programsOptions.filter(
          (p) => p._id !== action.program
        ),
      }
    case types.IMPORT:
      return {
        ...state,
        importOngoing: 'importing',
        error: null,
      }
    case types.IMPORT_RESPONSE:
      return {
        ...state,
        importOngoing: 'imported',
        error: null,
      }
    case types.IMPORT_FAILED:
      return {
        ...state,
        importOngoing: 'nothing',
        error: action.error,
      }
    default:
      return state
  }
}
