import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  Modal,
  Loader,
  Button
} from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { feedbackReport } from 'redux/actions/thunks'
import i18next from 'i18next'
import ModalActions from 'app/protected/roles/common/ModalActions'
import EditMessage from 'app/protected/roles/common/edit-program/component/EditMessage'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

function FeedbackMessage(props) {
  const { open, report, userEmail, name, _id, onCancel, ongoing } = props
  const messageRef = useRef()

  const initialState = { view: "initial", }
  const [{ view }, setState] = useState(initialState)

  useEffect(() => {
    if (view === "initial" && ongoing) {
      setState(prevState => ({ ...prevState, view: "loading" }))
    } else if (view === "loading" && !ongoing) {
      setState(prevState => ({ ...prevState, view: "sent" }))
    }
  }, [ongoing])

  const onDone = () => {
    setState(prevState => ({ ...prevState, view: "initial" }))
    onCancel()
  }

  const sendFeedback = () => {
    if (!messageRef.current) return
    const data = messageRef.current.getData()
    const text = data.message

    if (text.length > 0) {
      const device = navigator.userAgent
      const lang = navigator.language
      const date = moment().format('MMMM Do YYYY, h:mm:ss a')
      report(_id, name, userEmail, lang, device, date, text, data.newMedia)
    }
  }



  return (
    <Modal open={open} onClose={onCancel}>
      {view === "initial" && <>
        <Modal.Header>
          {i18next.t('feedback')}
        </Modal.Header>
        <Modal.Content>
          <EditMessage
            ref={messageRef}
            title={i18next.t('feedback_message_to_advagym')}
            placeholder={i18next.t('describe_your_feedback_in_detail') + '...'}
            limit={1000}
            allowMedia
          />
        </Modal.Content>
        <ModalActions
          action={() => sendFeedback()}
          actionText={i18next.t('send')}
          actionDisabled={false}
          onCancel={onCancel}
        />
      </>}
      {view === "loading" && <>
        <Loader size="massive">
          {i18next.t('sending') + '...'}
        </Loader>

      </>}
      {view === "sent" && <>
        <Modal.Header>
          {i18next.t('feedback')}
        </Modal.Header>
        <Modal.Content>
          {i18next.t('thank_you_very_much_your_feedback_has_been_sent')}
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            icon="checkmark"
            labelPosition="right"
            content={i18next.t('ok')}
            onClick={() => onDone()}
          />
        </Modal.Actions>
      </>}
    </Modal>
  )
}

FeedbackMessage.propTypes = {
  open: PropTypes.bool.isRequired,
  report: PropTypes.func.isRequired,
  userEmail: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  _id: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  ongoing: PropTypes.bool.isRequired,
}

const mapStateToProps = state => {
  const { feedbackOngoing } = state.profileSettingsReducer
  const { data } = state.userReducer

  return {
    userEmail: data.email,
    name: data.name,
    _id: data._id,
    ongoing: feedbackOngoing,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const { crash } = props
  return {
    report: (_id, name, userEmail, lang, device, date, text, newMedia) => { dispatch(feedbackReport.post(_id, name, userEmail, lang, device, date, text, newMedia, crash)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FeedbackMessage))