import { collections as types } from '../actions/actiontypes'

const initState = {
  ongoing: false,
  collections: [],
  error: undefined
}

export function globalCollectionsReducer(state = initState, action) {
  switch (action.type) {
    case types.GET:
      return {
        ...state,
        ongoing: state.collections.length > 0 ? false : true,
      }
    case types.RESPONSE:
      return {
        ...state,
        ongoing: false,
        collections: action.collections
      }
    case types.GET_FAIL:
      return {
        ...state,
        ongoing: false, error: action,
      }
    default:
      return state
  }
}
