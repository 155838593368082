import { profileSettings as types } from '../actions/actiontypes'

const initState = {
  unitOngoing: false,
  languageOngoing: false,
  profileOngoing: false,
  termsOngoing: false,
  webSettingsOngoing: false,
  feedbackOngoing: false,
  profileInfoOngoing: false,
}

export const profileSettingsReducer = (state = initState, action) => {
  switch (action.type) {
    case types.PROFILE_UNIT_SETTINGS_SAVE:
      return { ...state, unitOngoing: true }

    case types.PROFILE_UNIT_SETTINGS_RESPONE:
      return { ...state, unitOngoing: false }

    case types.PROFILE_UNIT_SETTINGS_ERROR:
      return { ...state, unitOngoing: false, error: action.error }

    case types.PROFILE_LANGUAGE_SETTINGS_SAVE:
      return { ...state, languageOngoing: true }

    case types.PROFILE_LANGUAGE_SETTINGS_RESPONE:
      return { ...state, languageOngoing: false }

    case types.PROFILE_LANGUAGE_SETTINGS_ERROR:
      return { ...state, languageOngoing: false, error: action.error }

    case types.PROFILE_PICTURE_SAVE:
      return { ...state, profileOngoing: true }

    case types.PROFILE_PICTURE_RESPONE:
      return { ...state, profileOngoing: false }

    case types.PROFILE_PICTURE_ERROR:
      return { ...state, profileOngoing: false, error: action.error }

    case types.PROFILE_INFO_SAVE:
      return { ...state, profileInfoOngoing: true }

    case types.PROFILE_INFO_RESPONE:
      return { ...state, profileInfoOngoing: false }
    
    case types.PROFILE_INFO_ERROR:
      return { ...state, profileInfoOngoing: false, error: action.error}

    case types.PROFILE_TERMS_SAVE:
      return { ...state, termsOngoing: true }

    case types.PROFILE_TERMS_RESPONE:
      return { ...state, termsOngoing: false }

    case types.PROFILE_TERMS_ERROR:
      return { ...state, termsOngoing: false, error: action.error }

    case types.PROFILE_PT_NOTIFICATION_SAVE:
      return { ...state, webSettingsOngoing: true }

    case types.PROFILE_PT_NOTIFICATION_RESPONE:
      return { ...state, webSettingsOngoing: false }

    case types.PROFILE_PT_NOTIFICATION_ERROR:
      return { ...state, webSettingsOngoing: false, error: action.error }

    case types.PROFILE_GET_COPY_TO_SITES:
      return { ...state, profileOngoing: true }

    case types.PROFILE_GET_COPY_TO_SITES_RESPONE:
      return { ...state, profileOngoing: false }

    case types.PROFILE_GET_COPY_TO_SITES_FAILURE:
      return { ...state, profileOngoing: false, error: action.error }

    case types.PROFILE_SEND_FEEDBACK:
      return { ...state, feedbackOngoing: true }

    case types.PROFILE_SEND_FEEDBACK_RESPONE:
      return { ...state, feedbackOngoing: false }

    case types.PROFILE_SEND_FEEDBACK_ERROR:
      return { ...state, feedbackOngoing: false, error: action.error }

    default:
      return state
  }
}
