import { advagymStatistics as types } from '../actions/actiontypes'
import { getToday, getSevenDaysAgo, isValidDate } from 'util/util'
import moment from 'moment'

const setDate = ({ state, action }) => {
  const oldFrom = state.from
  const oldTo = state.to
  const newFrom = action.from
  const newTo = action.to

  if (!isValidDate(newFrom) && !isValidDate(newTo)) {
    if (isValidDate(oldFrom) && isValidDate(oldTo)) {
      return {
        ...state,
        from: oldFrom,
        to: oldTo,
      }
    } else {
      return {
        ...state,
        from: moment().subtract(14, 'd'),
        to: getToday(),
      }
    }
  }

  return {
    ...state,
    from: newFrom,
    to: newTo,
  }
}

const initialState = {
  ongoing: true,
  ongoingNew: true,
  users: [],
  newUsers: [],
  from: moment()
    .subtract(3, 'months')
    .set({ hours: '00', minutes: '00' })
    .toDate(),
  to: getToday().set({ hours: '23', minutes: '59' }).toDate(),
}

export function advagymStatisticsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET:
      return {
        ...state,
        ongoing: true,
      }
    case types.RESPONSE:
      return {
        ...state,
        ongoing: false,
        users: action.data,
      }
    case types.FAILURE:
      return {
        ...state,
        ongoing: false,
      }

    case types.GET_NEW:
      return {
        ...state,
        ongoingNew: true,
      }
    case types.RESPONSE_NEW:
      return {
        ...state,
        ongoingNew: false,
        newUsers: action.data,
      }
    case types.FAILURE_NEW:
      return {
        ...state,
        ongoingNew: false,
      }

    case types.SET_DATE:
      return setDate({ state, action })

    default:
      return state
  }
}
