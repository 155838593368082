import { translationTools as types } from '../actions/actiontypes'

const initialState = {
  exercises: [],
}

export function translationToolsReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_EXERCISE:
      return {
        ...state,
        exercises: [...state.exercises, action.exercise],
      }
    case types.REMOVE_EXERCISE:
      return {
        ...state,
        exercises: state.exercises.filter(e => e.l10n !== action.exercise.l10n),
      }
    case types.CLEAR_EXERCISES:
      return {
        ...state,
        exercises: [],
      }

    default:
      return state
  }
}
