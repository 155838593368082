import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { menu as menuAction } from 'redux/actions/actions.js'
import { actionFooter as footer } from 'redux/actions/actions'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Sidebar as SidebarComponent, Image, Icon, Menu } from 'semantic-ui-react'
import logoSvg from 'assets/images/logo-black.svg'
import SidebarMenu from 'app/root/sidebar/SidebarMenu'
import SidebarGymMenu from 'app/root/sidebar/SidebarGymMenu'
import { homePaths } from 'app/protected/common/constants/routes'
import i18next from 'i18next'

function Sidebar(props) {
  const { closeMenu, isOpen, history } = props
  useEffect(() => {
    document.addEventListener('keydown', escapeFunction, false)

    return () => {
      document.removeEventListener('keydown', escapeFunction, false)
    }
  })

  const escapeFunction = event => {
    if (event.key == 'Escape') {
      closeMenu()
    }
  }

  const goToHome = () => {
    history.push(homePaths.ME())
  }

  return (
    <SidebarComponent
      animation={'overlay'}
      direction={'left'}
      visible={isOpen}
      style={{
        width: '230px',
        backgroundColor: '#fff',
      }}
    >
      <div
        style={{
          display: 'flex',
          textAlign: 'left',
        }}
      >
        <div
          onClick={goToHome}
          style={{ padding: '13px 13px 13px 21px', cursor: 'pointer' }}
        >
          <Image
            src={logoSvg}
            style={{
              height: '22px',
              width: 'auto',
              display: 'inline-block',
            }}
            alt="TrainerConnect logo"
          />
        </div>
      </div>

      <SidebarMenu />

      <SidebarGymMenu />

      <Menu secondary vertical style={{ width: '100%', margin: '14px 0 0' }}>
        <Menu.Item
          style={{ paddingLeft: '22px', color: '#000' }}
          onClick={() => history.push(homePaths.SUPPORT())}
        >
          <div>
            <Icon name="help" style={{ fontSize: '18px' }} />
            <span style={{ paddingLeft: '7px' }}>{i18next.t('support')}</span>
          </div>
        </Menu.Item>
      </Menu>

    </SidebarComponent>
  )
}

Sidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
  clearActionFooter: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return {
    isOpen: state.menuReducer.isOpen,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeMenu: () => {
      dispatch(menuAction.close())
    },
    clearActionFooter: () => {
      dispatch(footer.clear())
    }
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar))
