import { myGymCategories as types } from '../actions/actiontypes'

const initState = {
  ongoing: "nothing",
  categories: [],
  error: null,
}

const updateProgram = (state, action) => {
  let updatedCategories = []

  state.categories.map(cat => {
    const shouldHaveProgram = action.categories.find(c => c === cat._id) ? true : false
    const hasProgram = cat.items.find(c => c[action.unit] === action.program._id) ? true : false

    if (shouldHaveProgram) {
      if (!hasProgram) {
        let lastPrio = 0
        if (cat.items.length > 0) {
          cat.items.map(p => {
            p.prio > lastPrio ? lastPrio = p.prio : null
          })
          lastPrio === 0 ? lastPrio = 1 : null
        }
        cat.items.push({ prio: lastPrio, [action.unit]: action.program._id, name: action.program.name })
      }
      updatedCategories.push(cat)
    } else if (hasProgram) {
      if (!shouldHaveProgram) {
        const updatedItems = cat.items.filter(c => c[action.unit] !== action.program._id)
        const updatedCategory = { ...cat, items: updatedItems }
        updatedCategories.push(updatedCategory)
      }
    } else {
      updatedCategories.push(cat)
    }
  })
  return updatedCategories
}

export function myGymCategoriesReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return {
        ...initState,
      }
    case types.GET:
      return {
        ...state,
        ongoing: "fetching",
      }
    case types.RESPONSE:
      return {
        ...state,
        ongoing: "nothing",
        categories: action.categories,
      }
    case types.GET_FAIL:
    case types.SAVE_FAIL:
      return {
        ...state,
        ongoing: "error",
      }
    case types.SAVE:
      return {
        ...state,
        ongoing: "saving",
      }
    case types.REMOVE_ITEM:
      return {
        ...state,
        categories: state.categories.map(c => {
          return {
            ...c, items:
              c.items.filter(it => !(it[action.content] && it[action.content] === action._id))
          }
        }),
      }

    case types.UPDATE_WORKOUT:
      return {
        ...state,
        categories: updateProgram(state, action),
      }

    default:
      return state
  }
}
