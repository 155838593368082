import { myProgram as types } from '../actions/actiontypes'

const initState = {
  ongoing: [],
  programs: {},
  delete: {},
  errors: {},
  mode: 'view',
  showSharingDialog: null,
  showDuplicateDialog: null,

  createOngoing: 'initial',
  program: null,
  error: null,
}

function addToOngoing(state, action) {
  return [
    ...state,
    {
      id: action.id,
      what: action.what,
    },
  ]
}

function removeFromOngoing(state, action) {
  return state.filter((o) => o.id != action.id)
}

function addToDeleted(state, action) {
  return Object.assign({}, state, {
    [action.id]: 'deleted',
  })
}

function addToProgram(state, action) {
  return Object.assign({}, state, {
    [action.id]: action.programs,
  })
}

function removeFromProgram(state, action) {
  let { [action.id]: omit, ...res } = state
  return res
}

function addToError(state, action) {
  return Object.assign({}, state, {
    [action.id]: action.error,
  })
}

export function myProgramReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return {
        ...initState,
      }

    case types.CLEAR:
      return {
        ...state,
        createOngoing: 'initial',
        error: null,
        program: null,
      }
    case types.CREATE:
      return {
        ...state,
        createOngoing: 'saving',
      }
    case types.CREATE_RESPONSE:
      return {
        ...state,
        createOngoing: 'saved',
        program: action.program,
      }
    case types.CREATE_FAIL:
      return {
        ...state,
        createOngoing: 'error',
        error: action.error,
      }

    case types.GET:
      return {
        ...state,
        programs: {},
        ongoing: addToOngoing(state.ongoing, action),
      }
    case types.SAVE:
    case types.DELETE:
    case types.SHARE:
    case types.DUPLICATE:
      return {
        ...state,
        errors: {},
        ongoing: addToOngoing(state.ongoing, action),
      }
    case types.RESPONSE:
    case types.SAVE_RESPONSE:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        programs: addToProgram(state.programs, action),
        mode: 'view',
      }
    case types.DELETE_RESPONSE:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        programs: removeFromProgram(state.programs, action),
        delete: addToDeleted(state.delete, action),
      }
    case types.SHARE_RESPONSE:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        showSharingDialog: {
          email: action.share.email,
        },
      }
    case types.COPY_RESPONSE:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
      }
    case types.DUPLICATE_RESPONSE:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        showDuplicateDialog: {
          _id: action.programs._id,
          name: action.programs.name,
        },
      }
    case types.GET_FAIL:
    case types.SAVE_FAIL:
    case types.DELETE_FAIL:
    case types.DUPLICATE_FAIL:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        errors: addToError(state.error, action),
      }
    case types.SHARE_FAIL:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        mode: 'view',
      }
    case types.DISMISS_SHARING_DIALOG:
      return {
        ...state,
        showSharingDialog: null,
        mode: 'view',
      }
    case types.DISMISS_DUPLICATE_DIALOG:
      return {
        ...state,
        showDuplicateDialog: null,
        mode: 'view',
      }
    case types.SET_MODE:
      return {
        ...state,
        mode: action.mode,
      }
    default:
      return state
  }
}
