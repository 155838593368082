import _uniqBy from 'lodash/uniqBy'

export function userHasValidRoles(user, allowedRoles) {
  return allowedRoles.some(
    role => user && user.roles && user.roles.includes(role)
  )
}

export function userIsSuperUser(userPermissions) {
  if (userPermissions) {
    return userPermissions.some(permission => permission.role == 'su')
  }
  return false
}

export function getUniqueSites(userPermissions) {
  if (userPermissions) {
    const sites = userPermissions
      .map(p => p.site)
      .filter(site => {
        return site && site.name
      })
    return _uniqBy(sites, site => site.name)
  }
  return []
}

export function collectRoles(userPermissions) {
  if (userPermissions) {
    const roles = userPermissions.map(p => p.role).filter(role => role)
    return _uniqBy(roles)
  }
  return []
}

export function findSiteInPermission(permissions, siteId) {
  return permissions.map(p => p.site).find(site => site && site._id == siteId)
}

export function collectSiteRoles(permissions, siteId) {
  return _uniqBy(
    permissions.filter(p => p.site && p.site._id == siteId).map(p => p.role)
  )
}

export function getSitePermission(permissions, gymId, role) {
  return permissions.find(p => p.site && p.site._id == gymId && p.role == role)
}

export function userIsPartner(user, partnerID) {
  if (user && user.permissions && user.partnerPermissions) {
    if (user.permissions.some(p => p.role == 'partner')) {
      return user.partnerPermissions.some(pp => pp.partner._id == partnerID)
    }
  }
  return false
}

export function userIsPartnerForGym(user, partnerID, gymId) {
  if (userIsPartner(user, partnerID)) {
    const partnerPermission = user.partnerPermissions.find(pp => {
      return pp.partner._id == partnerID
    })
    if (partnerPermission) {
      return partnerPermission.partner.sites.includes(gymId)
    }
  }
  return false
}

export function getPartners(state) {
  const { data: user } = state.userReducer
  const { partnerPermissions } = user
  return partnerPermissions ? partnerPermissions.map(pp => pp.partner).filter(p => !p.type || p.type === "default") : []
}

export function getChains(state) {
  const { data: user } = state.userReducer
  const { partnerPermissions } = user
  return partnerPermissions ? partnerPermissions.map(pp => pp.partner).filter(p => p.type === "chain") : []
}

export function getPartner(state, props) {
  const { match } = props
  const { partnerID, chainID } = match.params
  const getID = chainID ? chainID : partnerID
  const { data: user } = state.userReducer
  const { partnerPermissions } = user
  const partnerPermission = partnerPermissions.find(
    pp => pp.partner._id == getID
  )
  return partnerPermission ? partnerPermission.partner : undefined
}

export function getFullPartnerState(state, props) {
  const { partnerID, chainID } = props.match.params
  const getID = chainID ? chainID : partnerID
  const { partners, ongoings, errors } = state.partnerReducer

  const partner = partners[getID]

  const ongoing =
    ongoings.filter(entry => entry.getID == getID).length > 0

  const error = errors[getID]

  return {
    partner,
    error,
    ongoing,
  }
}

export function getPartnerSite(state, props) {
  const { match } = props
  const { partnerID, gymId } = match.params
  const { partners } = state.partnerGymReducer
  return partners[partnerID] && partners[partnerID][gymId]
}

export function getPartnerSiteState(state, props) {
  const { match } = props
  const { partnerID, gymId } = match.params
  const { partners, ongoings, error } = state.partnerGymReducer
  const ongoing = ongoings.find(
    e => e.partnerID == partnerID && e.gymId == gymId
  )
    ? true
    : false
  const site = partners[partnerID] && partners[partnerID][gymId]
  return {
    site,
    ongoing,
    error,
  }
}

export function getSite(state, props) {
  const { match } = props
  const { partnerID, gymId } = match.params
  const { data: user } = state.userReducer
  const isPartner = userIsPartner(user, partnerID)
  if (isPartner) {
    return getPartnerSite(state, props)
  } else {
    return findSiteInPermission(user.permissions, gymId)
  }
}

export function getRoles(state, props) {
  const { match } = props
  const { partnerID, gymId } = match.params
  const { data: user } = state.userReducer
  const isPartner = userIsPartnerForGym(user, partnerID, gymId)
  if (isPartner) {
    return ['coach', 'admin']
  } else {
    return collectSiteRoles(user.permissions, gymId)
  }
}

export function getPermission(state, props, permissionType) {
  const { match } = props
  const { partnerID, gymId } = match.params
  const { data: user } = state.userReducer
  const isPartner = userIsPartner(user, partnerID)
  if (isPartner) {
    const site = getPartnerSite(state, props)
    if (site) {
      return {
        role: permissionType,
        site,
      }
    }
  } else {
    return getSitePermission(user.permissions, gymId, permissionType)
  }
}
