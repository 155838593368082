import React from 'react'
import { Button, Form, Message, Input } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { advagymGreen } from 'app/protected/roles/util/colors'
import i18next from 'i18next'

// Fields and Field status is handled through state. Rest through props.
const initialFields = {
  email: '',
}

const initialFieldStatus = {
  emailError: false,
}

const initialState = Object.assign({}, initialFields, initialFieldStatus)

export default class PasswordForm extends React.PureComponent {
  state = initialState

  inputChange = event => {
    const { onInput } = this.props
    const target = event.target
    const name = target.name
    const value = target.type === 'checkbox' ? target.checked : target.value
    this.setState(Object.assign({ [name]: value }, initialFieldStatus))
    onInput()
  }
  handleSubmit = () => {
    const { email } = this.state
    const { showFormStatus, hideFormStatus, post } = this.props

    if (email.length == 0) {
      this.setState({
        emailError: true,
      })
      showFormStatus('Email missing.', 'error')
      return
    }
    hideFormStatus()
    post({
      data: {
        email: this.state.email,
      },
    })
  }
  componentWillUnmount() {
    const { hideFormStatus } = this.props
    hideFormStatus()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.clearForm) {
      return { ...initialState }
    }
    return null
  }

  render() {
    const { email, emailError } = this.state

    const { emailPH, buttonText1 } = this.props
    const {
      ongoing,
      formMessage,
      formMessageType,
      isFormMessageVisible,
    } = this.props

    const showError = isFormMessageVisible && formMessageType == 'error'
    const showSuccess = isFormMessageVisible && formMessageType == 'success'

    return (
      <Form
        onSubmit={this.handleSubmit}
        error={showError}
        success={showSuccess}
      >
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{i18next.t('email')}</label>
            <Input
              icon="mail"
              iconPosition="left"
              name="email"
              type="email"
              value={email}
              placeholder={emailPH}
              onChange={this.inputChange}
              error={emailError}
              ref={c => {
                this.focusReceiver = c
              }}
            />
          </Form.Field>
        </Form.Group>
        <Message error content={formMessage} />
        <Message success content={formMessage} />
        <Button
          type="submit"
          {...this.props.buttonProps}
          loading={ongoing}
          disabled={ongoing}
          style={{ marginTop: '1rem', backgroundColor: advagymGreen }}
        >
          {buttonText1}
        </Button>
      </Form>
    )
  }
}

PasswordForm.defaultProps = {
  emailPH: i18next.t('your_email'),
  buttonText1: i18next.t('send'),
}

PasswordForm.propTypes = {
  buttonProps: PropTypes.object,
  post: PropTypes.func.isRequired,
  ongoing: PropTypes.bool.isRequired,
  clearForm: PropTypes.bool.isRequired,
  formMessage: PropTypes.string.isRequired,
  showFormStatus: PropTypes.func.isRequired,
  hideFormStatus: PropTypes.func.isRequired,
  formMessageType: PropTypes.string.isRequired,
  isFormMessageVisible: PropTypes.bool.isRequired,
  onInput: PropTypes.func.isRequired,
}
