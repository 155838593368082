import React from 'react'
import { Route } from 'react-router-dom'
import { Spring, config } from 'react-spring/renderprops.cjs.js'

export default ({ component: Component, ...routeProps }) => (
  <Route
    {...routeProps}
    render={renderProps => (
      <Spring
        key={renderProps.location.pathname}
        config={config.gentle}
        from={{ opacity: 0.5 }}
        to={{ opacity: 1 }}
      >
        {springprops => (
          <div
            style={{
              ...springprops,
              willChange: 'opacity',
              position: 'relative',
            }}
          >
            <Component {...renderProps} />
          </div>
        )}
      </Spring>
    )}
  />
)
