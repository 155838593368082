import {stackCopy as types} from '../actions/actiontypes'

const initState = {
  stacks: {
  }
}

function stacksReducer(stack, action) {
  switch (action.type) {
    case types.SET:
      return {
        ...stack,
        [action.id]: action.stack
      }
    case types.REMOVE:
      {
        const {
          [action.id]: omit,
          ...res
        } = stack
        return res
      }
    default:
      return stack
  }
}

export function stackCopyReducer(state = initState, action) {
  switch (action.type) {
    case "STORE_RESET":
      return {
        ...initState
      }
    case types.SET:
    case types.REMOVE:
      return {
        ...state,
        stacks: stacksReducer(state.stacks, action)
      }
    default:
      return state
  }
}
