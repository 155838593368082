import React from 'react'
import LoadingMessage from 'app/common/loading-message/component/LoadingMessage'
import PropTypes from 'prop-types'
import makeCancelable from 'app/common/hoc/make-cancelable/makeCancelable'
import i18next from 'i18next'

export default class AsyncBundle extends React.PureComponent {
  state = {
    mod: null,
  }

  constructor(props) {
    super(props)
    this.load(props)
  }

  componentWillUnmount() {
    if (this.request) {
      this.request.cancel()
    }
  }

  load = async props => {
    try {
      this.request = makeCancelable(props.load())
      const mod = await this.request.promise

      this.setState({
        mod: mod.default ? mod.default : mod,
      })
    } catch (error) {
      console.error('Error occured while loading module', error)
    }
  }

  render() {
    return this.state.mod ? (
      this.props.children(this.state.mod)
    ) : (
      <LoadingMessage text={i18next.t('fetching')} />
    )
  }

  static defaultProps = {}

  static propTypes = {
    load: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
  }
}
