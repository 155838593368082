import { manageSuPermissions as types } from '../actions/actiontypes'

const initReducerState = {
  getUsersOngoing: false,
  addPermissionOngoing: false,
  removePermissionOngoing: false,
  users: [],
  getUserError: undefined,
  addPermissionError: undefined,
  removePermissionError: undefined
}

export function manageSuPermissionsReducer(state = initReducerState, action) {
  switch (action.type) {
    case types.GET_USERS:
      return {
        ...state,
        getUsersOngoing: true
      }
    case types.ADD_PERMISSION:
      return {
        ...state,
        addPermissionOngoing: true
      }
    case types.REMOVE_PERMISSION:
      return {
        ...state,
        removePermissionOngoing: true
      }
    case types.GET_USERS_RESPONSE:
      return {
        ...state,
        getUsersOngoing: false,
        users: action.users
      }
    case types.ADD_PERMISSION_RESPONSE:
      return {
        ...state,
        addPermissionOngoing: false
      }
    case types.REMOVE_PERMISSION_RESPONSE:
      return {
        ...state,
        removePermissionOngoing: false
      }
    case types.GET_USERS_FAILED:
      return {
        ...state,
        getUsersOngoing: false,
        getUserError: action.error
      }
    case types.ADD_PERMISSION_FAILED:
      return {
        ...state,
        addPermissionOngoing: false,
        addPermissionError: action.error
      }
    case types.REMOVE_PERMISSION_FAILED:
      return {
        ...state,
        removePermissionOngoing: false,
        removePermissionError: action.error
      }
    case types.CLEAR_ERRORS:
      return {
        ...state,
        getUserError: undefined,
        addPermissionError: undefined,
        removePermissionError: undefined
      }
    default:
      return state
  }
}
