import React from 'react'
import { withRouter, matchPath } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import i18next from 'i18next'
import {
  clientPaths,
  homePaths,
  gymPaths,
  partnerGymPaths,
  programPaths,
  exercisePaths,
  partnerPaths,
  partnerChainPaths,
  suPaths
} from 'app/protected/common/constants/routes'
import { actionFooter as action } from 'redux/actions/actions'
import { useDispatch } from 'react-redux'
import { firstLetterToUppercase } from 'util/util'

const Breadcrumb = (props) => {
  const { location, history } = props
  const dispatch = useDispatch()

  // check if current url matches any of the declared paths.
  const checkIfUrlMatchPath = (url, path) => {
    return !!matchPath(url, {
      path: path,
      exact: true,
      strict: false,
    })
  }

  // extracts the id/ids from the current url and send it to
  // the path method.
  const getPath = (url, path) => {
    let _url = url.split('/')
    let validUrls = []

    if (_url) {
      for (let i = 0; i < _url.length; i++) {
        let u = _url[i]
        const _u = u.match(/^[a-f-A-F0-9]*$/)

        if (_u && _u[0]) {
          validUrls.push(_u[0])
        }
      }

      if (validUrls.length > 0) {
        return path(...validUrls)
      }
    }

    return path()
  }

  const getBreadcrumb = (url) => {
    switch (true) {
      // HOME PATHS //
      case checkIfUrlMatchPath(url, homePaths.PASSWORD_CHANGE()):
      case checkIfUrlMatchPath(url, homePaths.HOME()):
      case checkIfUrlMatchPath(url, homePaths.WORKOUTS()):
      case checkIfUrlMatchPath(url, homePaths.GYMS()):
      case checkIfUrlMatchPath(url, homePaths.PLANS()):
      case checkIfUrlMatchPath(url, homePaths.PARTNERS()):
      case checkIfUrlMatchPath(url, homePaths.CLIENTS()):
      case checkIfUrlMatchPath(url, homePaths.EXERCISES()):
      case checkIfUrlMatchPath(url, homePaths.PROFILE_SETTINGS()):
      case checkIfUrlMatchPath(url, homePaths.SUPPORT()):
        return {
          translatedLabel: i18next.t('home'),
          historyBackPath: getPath(url, homePaths.ME),
        }
      // ---------- //

      // GYM PATHS //
      case checkIfUrlMatchPath(url, gymPaths.EXERCISES()):
      case checkIfUrlMatchPath(url, gymPaths.INFORMATION_EMAIL()):
      case checkIfUrlMatchPath(url, gymPaths.MACHINES()):
      case checkIfUrlMatchPath(url, gymPaths.MANAGE_MEMBERS()):
      case checkIfUrlMatchPath(url, gymPaths.STAFF()):
      case checkIfUrlMatchPath(url, gymPaths.WORKOUTS()):
      case checkIfUrlMatchPath(url, gymPaths.STATUS()):
      case checkIfUrlMatchPath(url, gymPaths.STATISTICS()):
      case checkIfUrlMatchPath(url, gymPaths.ZONES()):
      case checkIfUrlMatchPath(url, gymPaths.MESSAGES()):
        return {
          translatedLabel: i18next.t('gym'),
          historyBackPath: getPath(url, homePaths.GYMS),
        }

      case checkIfUrlMatchPath(url, gymPaths.ZONE()):
      case checkIfUrlMatchPath(url, gymPaths.ADD_ZONE()):
        return {
          translatedLabel: i18next.t('zones'),
          historyBackPath: getPath(url, gymPaths.ZONES),
        }

      case checkIfUrlMatchPath(url, gymPaths.MACHINE()):
      case checkIfUrlMatchPath(url, gymPaths.CARDIO_MACHINE()):
      case checkIfUrlMatchPath(url, gymPaths.ADD_MACHINE()):
      case checkIfUrlMatchPath(url, gymPaths.ADD_CARDIO()):
        return {
          translatedLabel: i18next.t('machines'),
          historyBackPath: getPath(url, gymPaths.MACHINES),
        }

      case checkIfUrlMatchPath(url, gymPaths.WORKOUT()):
        return {
          translatedLabel: i18next.t('workouts'),
          historyBackPath: getPath(url, gymPaths.WORKOUTS),
        }

      case checkIfUrlMatchPath(url, gymPaths.EXERCISE()):
      case checkIfUrlMatchPath(url, gymPaths.CREATE_EXERCISE()):
        return {
          translatedLabel: i18next.t('exercises'),
          historyBackPath: getPath(url, gymPaths.EXERCISES),
        }

      case checkIfUrlMatchPath(url, gymPaths.CREATE_COLLECTION()):
      case checkIfUrlMatchPath(url, gymPaths.WORKOUT_COLLECTION()):
        return {
          translatedLabel: i18next.t('workouts'),
          historyBackPath: getPath(url, gymPaths.WORKOUTS),
        }

      case checkIfUrlMatchPath(url, gymPaths.CREATE_WORKOUT()):
        return {
          translatedLabel: i18next.t('workouts'),
          historyBackPath: getPath(url, gymPaths.WORKOUTS),
        }
      case checkIfUrlMatchPath(url, gymPaths.CLASS()):
      case checkIfUrlMatchPath(url, gymPaths.CREATE_CLASS()):
        return {
          translatedLabel: i18next.t('workouts'),
          historyBackPath: getPath(url, gymPaths.WORKOUTS),
        }
      case checkIfUrlMatchPath(url, gymPaths.MANAGE_MEMBERS_MEMBER()):
        return {
          translatedLabel: i18next.t('members'),
          historyBackPath: getPath(url, gymPaths.MANAGE_MEMBERS),
        }
      case checkIfUrlMatchPath(url, gymPaths.MEMBER_WORKOUT()):
      case checkIfUrlMatchPath(url, gymPaths.ASSIGN_MEMBER_WORKOUT()):
      case checkIfUrlMatchPath(url, gymPaths.EDIT_MEMBER_WORKOUT()):
        return {
          translatedLabel: i18next.t('member'),
          historyBackPath: getPath(url, gymPaths.MANAGE_MEMBERS_MEMBER),
        }
      case checkIfUrlMatchPath(url, gymPaths.HANDLE_STAFF()):
        return {
          translatedLabel: i18next.t('staff_management'),
          historyBackPath: getPath(url, gymPaths.STAFF),
        }

      // ---------- //

      // CLIENT PATHS //
      case checkIfUrlMatchPath(url, clientPaths.CLIENT()):
      case checkIfUrlMatchPath(url, clientPaths.GROUP()):
        return {
          translatedLabel: i18next.t('my_clients'),
          historyBackPath: getPath(url, homePaths.CLIENTS),
        }
      case checkIfUrlMatchPath(url, clientPaths.WORKOUT()):
        return {
          translatedLabel: i18next.t('my_client'),
          historyBackPath: getPath(url, clientPaths.CLIENT),
        }
      case checkIfUrlMatchPath(url, clientPaths.CLIENT_WORKOUT()):
      case checkIfUrlMatchPath(url, clientPaths.ASSIGN_CLIENT_WORKOUT()):
      case checkIfUrlMatchPath(url, clientPaths.EDIT_CLIENT_WORKOUT()):
        return {
          translatedLabel: i18next.t('client'),
          historyBackPath: getPath(url, clientPaths.CLIENT),
        }

      case checkIfUrlMatchPath(url, clientPaths.CLIENT_GROUP_WORKOUT()):
      case checkIfUrlMatchPath(url, clientPaths.ASSIGN_CLIENT_GROUP_WORKOUT()):
      case checkIfUrlMatchPath(url, clientPaths.EDIT_CLIENT_GROUP_WORKOUT()):
        return {
          translatedLabel: firstLetterToUppercase(i18next.t('group')),
          historyBackPath: getPath(url, clientPaths.GROUP),
        }
      // ---------- //

      // WORKOUT PATHS //
      case checkIfUrlMatchPath(url, programPaths.WORKOUT()):
      case checkIfUrlMatchPath(url, programPaths.CREATE_WORKOUT_FROM_WORKOUT()):
      case checkIfUrlMatchPath(url, programPaths.CREATE_WORKOUT()):
        return {
          translatedLabel: i18next.t('my_programs'),
          historyBackPath: getPath(url, homePaths.WORKOUTS),
        }
      // ---------- //

      // EXERCISE PATHS //
      case checkIfUrlMatchPath(url, exercisePaths.EXERCISE()):
      case checkIfUrlMatchPath(url, exercisePaths.CREATE_EXERCISE()):
        return {
          translatedLabel: i18next.t('my_exercises'),
          historyBackPath: getPath(url, homePaths.EXERCISES),
        }
      // ---------- //

      // PARTNER PATHS //
      case checkIfUrlMatchPath(url, partnerGymPaths.CREATE_GYM()):
        return {
          translatedLabel: i18next.t('gyms'),
          historyBackPath: getPath(url, partnerPaths.GYMS),
        }
      case checkIfUrlMatchPath(url, partnerGymPaths.CREATE_CHAIN()):
        return {
          translatedLabel: i18next.t('chains'),
          historyBackPath: getPath(url, partnerPaths.CHAINS),
        }

      case checkIfUrlMatchPath(url, partnerPaths.GYMS()):
      case checkIfUrlMatchPath(url, partnerPaths.CHAINS()):
      case checkIfUrlMatchPath(url, partnerPaths.WORKOUTS()):
      case checkIfUrlMatchPath(url, partnerPaths.EXERCISES()):
      case checkIfUrlMatchPath(url, partnerPaths.USERS()):
        return {
          translatedLabel: i18next.t('back'),
          historyBackPath: getPath(url, partnerPaths.PARTNER),
        }

      case checkIfUrlMatchPath(url, partnerPaths.CREATE_COLLECTION()):
      case checkIfUrlMatchPath(url, partnerPaths.CREATE_WORKOUT()):
      case checkIfUrlMatchPath(url, partnerPaths.CREATE_CLASS()):
      case checkIfUrlMatchPath(url, partnerPaths.WORKOUT_COLLECTION()):
      case checkIfUrlMatchPath(url, partnerPaths.WORKOUT()):
      case checkIfUrlMatchPath(url, partnerPaths.CLASS()):
        return {
          translatedLabel: i18next.t('workouts'),
          historyBackPath: getPath(url, partnerPaths.WORKOUTS),
        }

      case checkIfUrlMatchPath(url, partnerPaths.CREATE_EXERCISE()):
        return {
          translatedLabel: i18next.t('exercises'),
          historyBackPath: getPath(url, partnerPaths.EXERCISES),
        }
      case checkIfUrlMatchPath(url, partnerPaths.EXERCISE()):
        return {
          translatedLabel: i18next.t('exercises'),
          historyBackPath: getPath(url, partnerPaths.EXERCISES),
        }
      // ---------- //

      // PARTNER GYM PATHS //
      case checkIfUrlMatchPath(url, partnerGymPaths.EXERCISES()):
      case checkIfUrlMatchPath(url, partnerGymPaths.INFORMATION_EMAIL()):
      case checkIfUrlMatchPath(url, partnerGymPaths.MACHINES()):
      case checkIfUrlMatchPath(url, partnerGymPaths.MANAGE_MEMBERS()):
      case checkIfUrlMatchPath(url, partnerGymPaths.STAFF()):
      case checkIfUrlMatchPath(url, partnerGymPaths.WORKOUT_COLLECTION()):
      case checkIfUrlMatchPath(url, partnerGymPaths.WORKOUTS()):
      case checkIfUrlMatchPath(url, partnerGymPaths.STATUS()):
      case checkIfUrlMatchPath(url, partnerGymPaths.STATISTICS()):
      case checkIfUrlMatchPath(url, partnerGymPaths.ZONES()):
        return {
          translatedLabel: i18next.t('gym'),
          historyBackPath: getPath(url, partnerGymPaths.GYM),
        }

      case checkIfUrlMatchPath(url, partnerGymPaths.ZONE()):
      case checkIfUrlMatchPath(url, partnerGymPaths.ADD_ZONE()):
        return {
          translatedLabel: i18next.t('zones'),
          historyBackPath: getPath(url, partnerGymPaths.ZONES),
        }

      case checkIfUrlMatchPath(url, partnerGymPaths.MACHINE()):
      case checkIfUrlMatchPath(url, partnerGymPaths.CARDIO_MACHINE()):
      case checkIfUrlMatchPath(url, partnerGymPaths.ADD_MACHINE()):
      case checkIfUrlMatchPath(url, partnerGymPaths.ADD_CARDIO()):
        return {
          translatedLabel: i18next.t('machines'),
          historyBackPath: getPath(url, partnerGymPaths.MACHINES),
        }

      case checkIfUrlMatchPath(url, partnerGymPaths.GYM()):
        return {
          translatedLabel: i18next.t('gyms'),
          historyBackPath: getPath(url, partnerPaths.GYMS),
        }

      case checkIfUrlMatchPath(url, partnerGymPaths.CREATE_COLLECTION()):
        return {
          translatedLabel: i18next.t('workouts'),
          historyBackPath: getPath(url, partnerGymPaths.WORKOUTS),
        }

      case checkIfUrlMatchPath(url, partnerGymPaths.WORKOUT()):
        return {
          translatedLabel: i18next.t('workouts'),
          historyBackPath: getPath(url, partnerGymPaths.WORKOUTS),
        }

      case checkIfUrlMatchPath(url, partnerGymPaths.CREATE_WORKOUT()):
        return {
          translatedLabel: i18next.t('program'),
          historyBackPath: getPath(url, partnerGymPaths.WORKOUTS),
        }
      case checkIfUrlMatchPath(url, partnerGymPaths.CLASS()):
      case checkIfUrlMatchPath(url, partnerGymPaths.CREATE_CLASS()):
        return {
          translatedLabel: i18next.t('workouts'),
          historyBackPath: getPath(url, partnerGymPaths.WORKOUTS),
        }
      case checkIfUrlMatchPath(url, partnerGymPaths.EXERCISE()):
      case checkIfUrlMatchPath(url, partnerGymPaths.CREATE_EXERCISE()):
        return {
          translatedLabel: i18next.t('exercises'),
          historyBackPath: getPath(url, partnerGymPaths.EXERCISES),
        }
      case checkIfUrlMatchPath(url, partnerGymPaths.MANAGE_MEMBERS_MEMBER()):
        return {
          translatedLabel: i18next.t('members'),
          historyBackPath: getPath(url, partnerGymPaths.MANAGE_MEMBERS),
        }
      case checkIfUrlMatchPath(url, partnerGymPaths.HANDLE_STAFF()):
        return {
          translatedLabel: i18next.t('staff_management'),
          historyBackPath: getPath(url, partnerGymPaths.STAFF),
        }
      // PARTNER CHAIN PATHS //
      case checkIfUrlMatchPath(url, partnerChainPaths.CHAIN()):
        return {
          translatedLabel: i18next.t('partner') + " / " + i18next.t('chains'),
          historyBackPath: getPath(url, partnerPaths.CHAINS),
        }
      case checkIfUrlMatchPath(url, partnerChainPaths.USERS()):
      case checkIfUrlMatchPath(url, partnerChainPaths.GYMS()):
        return {
          translatedLabel: i18next.t('partner') + " / " + i18next.t('chain'),
          historyBackPath: getPath(url, partnerChainPaths.CHAIN),
        }
      case checkIfUrlMatchPath(url, partnerChainPaths.CREATE_GYM()):
        return {
          translatedLabel: i18next.t('partner') + " / " + i18next.t('gyms'),
          historyBackPath: getPath(url, partnerChainPaths.GYMS),
        }

      // ---------- //

      // SU PATHS //
      case checkIfUrlMatchPath(url, suPaths.ZONES()):
        return {
          translatedLabel: i18next.t('home'),
          historyBackPath: getPath(url, homePaths.ME),
        }
      case checkIfUrlMatchPath(url, suPaths.ADD_ZONE()):
      case checkIfUrlMatchPath(url, suPaths.ZONE()):
        return {
          translatedLabel: i18next.t('zones'),
          historyBackPath: getPath(url, suPaths.ZONES),
        }
      // ---------- //

      default:
        return {
          translatedLabel: i18next.t('back'),
          historyBackPath: getPath(url, homePaths.ME),
          isBack: true,
        }
    }
  }

  const breadcrumb = getBreadcrumb(location.pathname)
  const { translatedLabel, historyBackPath, isBack } = breadcrumb
  const onBreadCrumbClick = () => {
    isBack ? history.goBack() : history.push(historyBackPath)
  }

  return (
    <div style={{ position: 'relative', zIndex: 1 }}>
      <Icon name="chevron left" size="small" />

      <span
        style={{
          color: 'green',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={onBreadCrumbClick}
      >
        {translatedLabel}
      </span>
    </div>
  )

}

export default withRouter(Breadcrumb)