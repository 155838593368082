import { sites as types } from '../actions/actiontypes'

const initState = {
  ongoing: false,
  count: 0,
  sites: [],
  searchResult: [],
  searchOngoing: "nothing",
  error: undefined
}

export function sitesReducer(state = initState, action) {
  switch (action.type) {
    case "STORE_RESET":
      return Object.assign({}, initState)
    case types.GET:
      return {
        ...state,
        ongoing: true,
      }
    case types.RESPONSE:
      return {
        ...state,
        ongoing: false,
        sites: action.sites,
        count: action.count
      }
    case types.GET_FAIL:
      return {
        ...state,
        ongoing: false,
        error: action
      }
    case types.SEARCH:
      return {
        ...state,
        searchOngoing: "searching",
      }
    case types.SEARCH_RESPONSE:
      return {
        ...state,
        searchOngoing: "done",
        searchResult: action.sites
      }
    case types.SEARCH_FAILED:
      return {
        ...state,
        searchOngoing: "nothing",
        searchResult: [],
        error: action
      }
    case types.SEARCH_CLEAR:
      return {
        ...state,
        searchOngoing: "nothing",
        searchResult: [],
        error: undefined
      }
    default:
      return state
  }
}
