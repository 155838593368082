import React from 'react'
import sheet from './style.scss'
import PropTypes from 'prop-types'
import PasswordChangeForm from '../container/PasswordChangeForm'
import { Header, Divider } from 'semantic-ui-react'
import i18next from 'i18next'
import { mediumGrey } from 'app/protected/roles/util/colors'

export default function PasswordChange(props) {
  const { hideSuccess, history, showSuccess, match } = props

  return (
    <>
      {showSuccess ? (
        <div className={sheet.passwordChange}>
          <Header as="h1" icon textAlign="center" htmlFor="email">
            {i18next.t('password_has_been_changed')}!
          </Header>
          <Divider hidden />

          <div
            style={{
              alignItems: 'center',
              color: mediumGrey,
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <p>{i18next.t('you_can_now_sign_in_with_your_new_password')}.</p>
          </div>
        </div>
      ) : (
        <div className={sheet.passwordChange}>
          <Header as="h1" icon textAlign="center" htmlFor="email">
            {i18next.t('change_password')}
          </Header>

          <Divider hidden />

          <Header as="h5" icon htmlFor="email">
            {i18next.t('please_select_new_password')}
          </Header>

          <div className={sheet.form}>
            <PasswordChangeForm
              match={match}
              buttonProps={{
                fluid: true,
                primary: true,
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}

PasswordChange.propTypes = {
  showSuccess: PropTypes.bool.isRequired,
  hideSuccess: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}
