import { partnerGym as types } from '../actions/actiontypes'

// require {partnerID, gymId, what} = action
function addToOngoing(state, action) {
  return [
    ...state,
    {
      partnerID: action.partnerID,
      gymId: action.gymId,
      what: action.what,
    },
  ]
}

// require {gymId, what} = action
function removeFromOngoing(state, action) {
  return state.filter(
    entry =>
      entry.what != action.what ||
      entry.gymId != action.gymId ||
      entry.partnerID != action.partnerID
  )
}

// require {gymId, gym} = action
function addToGyms(state, action) {
  return {
    ...state,
    [action.gymId]: action.gym,
  }
}

// require {partnerID, gymId} = action
function addToPartners(state, action) {
  const partner = state[action.partnerID] || {}
  return {
    ...state,
    [action.partnerID]: addToGyms(partner, action),
  }
}

function addSavedGymToPartners(state, action) {
  // const partner = state[action.partnerID] || {}
  // return {
  //   ...state,
  //   [action.partnerID]: addToGyms(partner, action),
  // }
  return { ...state }
}

// {gymId, error} = action
function addToErrors(state, action) {
  return {
    ...state,
    error: action.error,
  }
}
function ongoingReducer(state, action) {
  switch (action.type) {
    case types.DELETE:
    case types.SAVE:
    case types.GET:
      return [
        ...state,
        {
          id: action.id,
          what: action.what,
        },
      ]
    case types.RESPONSE:
    case types.SAVE_RESPONSE:
    case types.DELETE_RESPONSE:
    case types.GET_FAIL:
    case types.SAVE_FAIL:
      return state.filter(o => o.id != action.id)
    default:
      return state
  }
}

function deleteReducer(state, action) {
  switch (action.type) {
    case types.DELETE_RESPONSE:
      return Object.assign({}, state, {
        [action.id]: 'deleted',
      })
    default:
      return state
  }
}
function gymsReducer(state, action) {
  switch (action.type) {
    case types.RESPONSE:
    case types.SAVE_RESPONSE:
      return Object.assign({}, state, {
        [action.id]: action.gyms,
      })
    case types.GET_FAIL:
    case types.SAVE_FAIL:
    case types.DELETE_RESPONSE: {
      let { [action.id]: omit, ...res } = state
      return res
    }
    default:
      return state
  }
}

function getErrorMesssage(error){
  let message
  const status = error?.response?.status

  if (status === 409) {
      message = "Tag or beacon already exists."
  } else if (status === 401) {
      message = "Unauthorized."
  } else if (status === 400) {
      message = "The data provided seems to be incorrect. Please review it."
  } else {
      message = "Something unexpected happened."
  }
  return message
}

function errorReducer(state, action) {
  switch (action.type) {
    case types.GET_FAIL:
    case types.SAVE_FAIL:
    case types.CREATE_FAIL:
      return getErrorMesssage(action.error)
    case types.DELETE_FAIL:
      return Object.assign({}, state, {
        [action.id]: action.error
      })
    default:
      return state
  }
}
const initState = {
  ongoings: [],
  partners: {},
  error: null,
  status: 'initial',
  redirectTo: null,
  ongoing: false,
}

export function partnerGymReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return {
        ...initState,
      }
    case types.GET:
    case types.SAVE:
      return {
        ...state,
        ongoings: addToOngoing(state.ongoings, action),
      }
    case types.GET_RESPONSE:
    case types.SAVE_RESPONSE:
      return {
        ...state,
        ongoings: removeFromOngoing(state.ongoings, action),
        partners: addToPartners(state.partners, action),
      }
    case types.SAVED_RESPONSE:
      return {
        ...state,
        ongoings: removeFromOngoing(state.ongoings, action),
        partners: addToPartners(state.partners, action),
        status: "saved",
        error: null
      }
    case types.GET_FAIL:
    case types.SAVE_FAIL:
      return {
        ...state,
        ongoings: removeFromOngoing(state.ongoings, action),
        error: addToErrors(state.error, action),
      }
    case types.SAVE_FAILED:
      return {
        ...state,
        ongoings: removeFromOngoing(state.ongoings, action),
        error: addToErrors(state.error, action).error.response.data.error,
        status: 'failed'
      }
    case types.CREATE:
      return {
        ...state,
        ongoing: true,
        //ongoings: ongoingReducer(state.ongoing, action),
        status: 'initial',
      }
    case types.CREATE_RESPONSE:
      return {
        ...state,
        ongoing: false,
        //ongoings: ongoingReducer(state.ongoing, action),
        gyms: gymsReducer(state.gyms, action),
        delete: deleteReducer(state.delete, action),
        status: 'saved',
        redirectTo: action.redirectTo,
      }
    case types.CREATE_FAIL:
      return {
        ...state,
        ongoing: false,
        //ongoings: ongoingReducer(state.ongoing, action),
        gyms: gymsReducer(state.gyms, action),
        error: errorReducer(state.error, action),
        status: 'failed',
      }
    case types.CLEAR:
      return {
        ...state,
        status: 'initial',
        error: null,
      }
    default:
      return state
  }
}
