import { myGymExercise as types } from '../actions/actiontypes'

const initState = {
  ongoing: [],
  exercises: {},
  delete: {},
  errors: {},
  mode: 'view',
  currentExercise: {},
  currentSite: '',
  workProgress: '',
  workResult: [],

  createOngoing: 'initial',
  exercise: null,
  error: null,
}

function addToOngoing(state, action) {
  return [
    ...state,
    {
      id: action.id,
      what: action.what,
    },
  ]
}

function removeFromOngoing(state, action) {
  return state.filter((o) => o.id != action.id)
}

function addToDeleted(state, action) {
  return Object.assign({}, state, {
    [action.id]: 'deleted',
  })
}

function addToGymExercise(state, action) {
  return Object.assign({}, state, {
    [action.id]: action.exercises,
  })
}

function removeFromGymExercise(state, action) {
  let { [action.id]: omit, ...res } = state
  return res
}

function addToError(state, action) {
  if (action.id) {
    return Object.assign({}, state, {
      [action.id]: action.error,
    })
  } else {
    return Object.assign({}, state, {
      create: action.error,
    })
  }
}

function addToRestoringExercise(state, action) {
  return Object.assign({}, state, action.exercise)
}

function updatePercentage(state, action) {
  return Object.assign({}, state, { percentage: action.percentage })
}

export function myGymExerciseReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return {
        ...initState,
      }
    case types.CLEAR:
      return {
        ...state,
        createOngoing: 'initial',
        error: null,
        exercise: null,
      }
    case types.CLEAR_ERROR:
      return {
        ...state,
        error: null,
        errors: {},
      }
    case types.CREATE:
      return {
        ...state,
        createOngoing: 'saving',
      }
    case types.CREATE_RESPONSE:
      return {
        ...state,
        createOngoing: 'saved',
        exercise: action.exercise,
      }
    case types.CREATE_FAIL:
      return {
        ...state,
        createOngoing: 'error',
        error: action.error,
        errors: addToError(state.error, action),
      }
    case types.GET:
    case types.SAVE:
    case types.DELETE:
    case types.COPY:
      return {
        ...state,
        ongoing: addToOngoing(state.ongoing, action),
      }
    case types.RESPONSE:
    case types.SAVE_RESPONSE:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        exercises: addToGymExercise(state.exercises, action),
        mode: 'view',
      }
    case types.DELETE_RESPONSE:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        exercises: removeFromGymExercise(state.exercises, action),
        delete: addToDeleted(state.delete, action),
      }
    case types.GET_FAIL:
    case types.SAVE_FAIL:
    case types.DELETE_FAIL:
      return {
        ...state,
        createOngoing: 'initial',
        ongoing: removeFromOngoing(state.ongoing, action),
        errors: addToError(state.error, action),
      }
    case types.COPY_RESPONSE:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
      }
    case types.COPY_FAILED:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        error: action.error,
      }
    case types.SET_MODE:
      return {
        ...state,
        mode: action.mode,
      }
    case types.WORK:
      return {
        ...state,
        ongoing: addToOngoing(state.ongoing, action),
        currentExercise: addToRestoringExercise(state.currentExercise, action),
        workProgress: updatePercentage(state.updatePercentage, action),
        currentSite: action.site,
      }
    case types.WORK_RESPONSE:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        currentExercise: {},
        workProgress: updatePercentage(state.updatePercentage, action),
        workResult: [...state.workResult, action.result],
        currentSite: '',
      }
    case types.CLEAR_WORK:
      return {
        ...state,
        ongoing: [],
        currentExercise: {},
        workProgress: '',
        workResult: [],
        currentSite: '',
      }
    default:
      return state
  }
}
