import { actionFooter as types } from '../actions/actiontypes'


const initState = {
  leftText: null,
  leftCalled: false,

  rightText: null,
  rightIcon: null,
  rightButtonColor: null,
  rightCalled: false,
  rightDisabled: false,

  extraRightText: null,
  extraRightIcon: null,
  extraRightButtonColor: null,
  extraRightCalled: false,
  extraRightDisabled: false,

  show: false,
}

export function actionFooterReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return { ...initState }

    case types.SET:
      return {
        ...state,
        leftText: action.leftText,

        rightText: action.rightText,
        rightIcon: action.rightIcon,
        rightButtonColor: action.rightButtonColor,
        rightDisabled: action.rightDisabled,

        extraRightText: action.extraRightText,
        extraRightIcon: action.extraRightIcon,
        extraRightButtonColor: action.extraRightButtonColor,
        extraRightDisabled: action.extraRightDisabled,

        show: action.show,
      }
    case types.CALL_LEFT:
      return {
        ...state,
        leftCalled: true,
      }
    case types.REACT_LEFT:
      return {
        ...state,
        leftCalled: false,
      }

    case types.CALL_RIGHT:
      return {
        ...state,
        rightCalled: true,
      }
    case types.REACT_RIGHT:
      return {
        ...state,
        rightCalled: false,
      }

    case types.CALL_EXTRA_RIGHT:
      return {
        ...state,
        extraRightCalled: true,
      }
    case types.REACT_EXTRA_RIGHT:
      return {
        ...state,
        extraRightCalled: false,
      }

    case types.DISABLE_RIGHT:
      return {
        ...state,
        rightDisabled: true,
      }
    case types.ENABLE_RIGHT:
      return {
        ...state,
        rightDisabled: false,
      }
    case types.DISABLE_EXTRA_RIGHT:
      return {
        ...state,
        extraRightDisabled: true,
      }
    case types.ENABLE_EXTRA_RIGHT:
      return {
        ...state,
        extraRightDisabled: false,
      }

    case types.CLEAR:
      return {
        ...state,
        show: false,
      }
    case types.UNCLEAR:
      return {
        ...state,
        show: true,
      }

    default:
      return state
  }
}
