import i18next from 'i18next'

export const countryList = [
  { key: 'af', value: 'Afghanistan', flag: 'af', text: i18next.t('Afghanistan'), lang: '' },
  { key: 'ax', value: 'Aland Islands', flag: 'ax', text: i18next.t('Aland Islands'), lang: '' },
  { key: 'al', value: 'Albania', flag: 'al', text: i18next.t('Albania'), lang: '' },
  { key: 'dz', value: 'Algeria', flag: 'dz', text: i18next.t('Algeria'), lang: '' },
  { key: 'as', value: 'American Samoa', flag: 'as', text: i18next.t('American Samoa'), lang: '' },
  { key: 'ad', value: 'Andorra', flag: 'ad', text: i18next.t('Andorra'), lang: '' },
  { key: 'ao', value: 'Angola', flag: 'ao', text: i18next.t('Angola'), lang: '' },
  { key: 'ai', value: 'Anguilla', flag: 'ai', text: i18next.t('Anguilla'), lang: '' },
  { key: 'ag', value: 'Antigua', flag: 'ag', text: i18next.t('Antigua'), lang: '' },
  { key: 'ar', value: 'Argentina', flag: 'ar', text: i18next.t('Argentina'), lang: '' },
  { key: 'am', value: 'Armenia', flag: 'am', text: i18next.t('Armenia'), lang: '' },
  { key: 'aw', value: 'Aruba', flag: 'aw', text: i18next.t('Aruba'), lang: '' },
  { key: 'au', value: 'Australia', flag: 'au', text: i18next.t('Australia'), lang: '' },
  { key: 'at', value: 'Austria', flag: 'at', text: i18next.t('Austria'), lang: '' },
  { key: 'az', value: 'Azerbaijan', flag: 'az', text: i18next.t('Azerbaijan'), lang: '' },
  { key: 'bs', value: 'Bahamas', flag: 'bs', text: i18next.t('Bahamas'), lang: '' },
  { key: 'bh', value: 'Bahrain', flag: 'bh', text: i18next.t('Bahrain'), lang: '' },
  { key: 'bd', value: 'Bangladesh', flag: 'bd', text: i18next.t('Bangladesh'), lang: '' },
  { key: 'bb', value: 'Barbados', flag: 'bb', text: i18next.t('Barbados'), lang: '' },
  { key: 'by', value: 'Belarus', flag: 'by', text: i18next.t('Belarus'), lang: '' },
  { key: 'be', value: 'Belgium', flag: 'be', text: i18next.t('Belgium'), lang: '' },
  { key: 'bz', value: 'Belize', flag: 'bz', text: i18next.t('Belize'), lang: '' },
  { key: 'bj', value: 'Benin', flag: 'bj', text: i18next.t('Benin'), lang: '' },
  { key: 'bm', value: 'Bermuda', flag: 'bm', text: i18next.t('Bermuda'), lang: '' },
  { key: 'bt', value: 'Bhutan', flag: 'bt', text: i18next.t('Bhutan'), lang: '' },
  { key: 'bo', value: 'Bolivia', flag: 'bo', text: i18next.t('Bolivia'), lang: '' },
  { key: 'ba', value: 'Bosnia', flag: 'ba', text: i18next.t('Bosnia'), lang: '' },
  { key: 'bw', value: 'Botswana', flag: 'bw', text: i18next.t('Botswana'), lang: '' },
  { key: 'bv', value: 'Bouvet Island', flag: 'bv', text: i18next.t('Bouvet Island'), lang: '' },
  { key: 'br', value: 'Brazil', flag: 'br', text: i18next.t('Brazil'), lang: '' },
  { key: 'vg', value: 'British Virgin Islands', flag: 'vg', text: i18next.t('British Virgin Islands'), lang: '' },
  { key: 'bn', value: 'Brunei', flag: 'bn', text: i18next.t('Brunei'), lang: '' },
  { key: 'bg', value: 'Bulgaria', flag: 'bg', text: i18next.t('Bulgaria'), lang: '' },
  { key: 'bf', value: 'Burkina Faso', flag: 'bf', text: i18next.t('Burkina Faso'), lang: '' },
  { key: 'bi', value: 'Burundi', flag: 'bi', text: i18next.t('Burundi'), lang: '' },
  { key: 'tc', value: 'Caicos Islands', flag: 'tc', text: i18next.t('Caicos Islands'), lang: '' },
  { key: 'kh', value: 'Cambodia', flag: 'kh', text: i18next.t('Cambodia'), lang: '' },
  { key: 'cm', value: 'Cameroon', flag: 'cm', text: i18next.t('Cameroon'), lang: '' },
  { key: 'ca', value: 'Canada', flag: 'ca', text: i18next.t('Canada'), lang: '' },
  { key: 'cv', value: 'Cape Verde', flag: 'cv', text: i18next.t('Cape Verde'), lang: '' },
  { key: 'ky', value: 'Cayman Islands', flag: 'ky', text: i18next.t('Cayman Islands'), lang: '' },
  { key: 'cf', value: 'Central African Republic', flag: 'cf', text: i18next.t('Central African Republic'), lang: '' },
  { key: 'td', value: 'Chad', flag: 'td', text: i18next.t('Chad'), lang: '' },
  { key: 'cl', value: 'Chile', flag: 'cl', text: i18next.t('Chile'), lang: '' },
  { key: 'cn', value: 'China', flag: 'cn', text: i18next.t('China'), lang: '' },
  { key: 'cx', value: 'Christmas Island', flag: 'cx', text: i18next.t('Christmas Island'), lang: '' },
  { key: 'cc', value: 'Cocos Islands', flag: 'cc', text: i18next.t('Cocos Islands'), lang: '' },
  { key: 'co', value: 'Colombia', flag: 'co', text: i18next.t('Colombia'), lang: '' },
  { key: 'km', value: 'Comoros', flag: 'km', text: i18next.t('Comoros'), lang: '' },
  { key: 'cd', value: 'Congo', flag: 'cd', text: i18next.t('Congo'), lang: '' },
  { key: 'cg', value: 'Congo Brazzaville', flag: 'cg', text: i18next.t('Congo Brazzaville'), lang: '' },
  { key: 'ck', value: 'Cook Islands', flag: 'ck', text: i18next.t('Cook Islands'), lang: '' },
  { key: 'cr', value: 'Costa Rica', flag: 'cr', text: i18next.t('Costa Rica'), lang: '' },
  { key: 'ci', value: 'Cote Divoire', flag: 'ci', text: i18next.t('Cote Divoire'), lang: '' },
  { key: 'hr', value: 'Croatia', flag: 'hr', text: i18next.t('Croatia'), lang: '' },
  { key: 'cu', value: 'Cuba', flag: 'cu', text: i18next.t('Cuba'), lang: '' },
  { key: 'cy', value: 'Cyprus', flag: 'cy', text: i18next.t('Cyprus'), lang: '' },
  { key: 'cz', value: 'Czech Republic', flag: 'cz', text: i18next.t('Czech Republic'), lang: '' },
  { key: 'dk', value: 'Denmark', flag: 'dk', text: i18next.t('Denmark'), lang: '' },
  { key: 'dj', value: 'Djibouti', flag: 'dj', text: i18next.t('Djibouti'), lang: '' },
  { key: 'dm', value: 'Dominica', flag: 'dm', text: i18next.t('Dominica'), lang: '' },
  { key: 'do', value: 'Dominican Republic', flag: 'do', text: i18next.t('Dominican Republic'), lang: '' },
  { key: 'ec', value: 'Ecuador', flag: 'ec', text: i18next.t('Ecuador'), lang: '' },
  { key: 'eg', value: 'Egypt', flag: 'eg', text: i18next.t('Egypt'), lang: '' },
  { key: 'sv', value: 'El Salvador', flag: 'sv', text: i18next.t('El Salvador'), lang: '' },
  { key: 'gq', value: 'Equatorial Guinea', flag: 'gq', text: i18next.t('Equatorial Guinea'), lang: '' },
  { key: 'er', value: 'Eritrea', flag: 'er', text: i18next.t('Eritrea'), lang: '' },
  { key: 'ee', value: 'Estonia', flag: 'ee', text: i18next.t('Estonia'), lang: '' },
  { key: 'et', value: 'Ethiopia', flag: 'et', text: i18next.t('Ethiopia'), lang: '' },
  { key: 'fk', value: 'Falkland Islands', flag: 'fk', text: i18next.t('Falkland Islands'), lang: '' },
  { key: 'fo', value: 'Faroe Islands', flag: 'fo', text: i18next.t('Faroe Islands'), lang: '' },
  { key: 'fj', value: 'Fiji', flag: 'fj', text: i18next.t('Fiji'), lang: '' },
  { key: 'fi', value: 'Finland', flag: 'fi', text: i18next.t('Finland'), lang: '' },
  { key: 'fr', value: 'France', flag: 'fr', text: i18next.t('France'), lang: '' },
  { key: 'gf', value: 'French Guiana', flag: 'gf', text: i18next.t('French Guiana'), lang: '' },
  { key: 'pf', value: 'French Polynesia', flag: 'pf', text: i18next.t('French Polynesia'), lang: '' },
  { key: 'ga', value: 'Gabon', flag: 'ga', text: i18next.t('Gabon'), lang: '' },
  { key: 'gm', value: 'Gambia', flag: 'gm', text: i18next.t('Gambia'), lang: '' },
  { key: 'ge', value: 'Georgia', flag: 'ge', text: i18next.t('Georgia'), lang: '' },
  { key: 'de', value: 'Germany', flag: 'de', text: i18next.t('Germany'), lang: '' },
  { key: 'gh', value: 'Ghana', flag: 'gh', text: i18next.t('Ghana'), lang: '' },
  { key: 'gi', value: 'Gibraltar', flag: 'gi', text: i18next.t('Gibraltar'), lang: '' },
  { key: 'gr', value: 'Greece', flag: 'gr', text: i18next.t('Greece'), lang: '' },
  { key: 'gl', value: 'Greenland', flag: 'gl', text: i18next.t('Greenland'), lang: '' },
  { key: 'gd', value: 'Grenada', flag: 'gd', text: i18next.t('Grenada'), lang: '' },
  { key: 'gp', value: 'Guadeloupe', flag: 'gp', text: i18next.t('Guadeloupe'), lang: '' },
  { key: 'gu', value: 'Guam', flag: 'gu', text: i18next.t('Guam'), lang: '' },
  { key: 'gt', value: 'Guatemala', flag: 'gt', text: i18next.t('Guatemala'), lang: '' },
  { key: 'gn', value: 'Guinea', flag: 'gn', text: i18next.t('Guinea'), lang: '' },
  { key: 'gw', value: 'Guinea-Bissau', flag: 'gw', text: i18next.t('Guinea-Bissau'), lang: '' },
  { key: 'gy', value: 'Guyana', flag: 'gy', text: i18next.t('Guyana'), lang: '' },
  { key: 'ht', value: 'Haiti', flag: 'ht', text: i18next.t('Haiti'), lang: '' },
  { key: 'hm', value: 'Heard Island', flag: 'hm', text: i18next.t('Heard Island'), lang: '' },
  { key: 'hn', value: 'Honduras', flag: 'hn', text: i18next.t('Honduras'), lang: '' },
  { key: 'hk', value: 'Hong Kong', flag: 'hk', text: i18next.t('Hong Kong'), lang: '' },
  { key: 'hu', value: 'Hungary', flag: 'hu', text: i18next.t('Hungary'), lang: '' },
  { key: 'is', value: 'Iceland', flag: 'is', text: i18next.t('Iceland'), lang: '' },
  { key: 'in', value: 'India', flag: 'in', text: i18next.t('India'), lang: '' },
  { key: 'io', value: 'Indian Ocean Territory', flag: 'io', text: i18next.t('Indian Ocean Territory'), lang: '' },
  { key: 'id', value: 'Indonesia', flag: 'id', text: i18next.t('Indonesia'), lang: '' },
  { key: 'ir', value: 'Iran', flag: 'ir', text: i18next.t('Iran'), lang: '' },
  { key: 'iq', value: 'Iraq', flag: 'iq', text: i18next.t('Iraq'), lang: '' },
  { key: 'ie', value: 'Ireland', flag: 'ie', text: i18next.t('Ireland'), lang: '' },
  { key: 'il', value: 'Israel', flag: 'il', text: i18next.t('Israel'), lang: '' },
  { key: 'it', value: 'Italy', flag: 'it', text: i18next.t('Italy'), lang: '' },
  { key: 'jm', value: 'Jamaica', flag: 'jm', text: i18next.t('Jamaica'), lang: '' },
  { key: 'sj', value: 'Jan Mayen', flag: 'sj', text: i18next.t('Jan Mayen'), lang: '' },
  { key: 'jp', value: 'Japan', flag: 'jp', text: i18next.t('Japan'), lang: '' },
  { key: 'jo', value: 'Jordan', flag: 'jo', text: i18next.t('Jordan'), lang: '' },
  { key: 'kz', value: 'Kazakhstan', flag: 'kz', text: i18next.t('Kazakhstan'), lang: '' },
  { key: 'ke', value: 'Kenya', flag: 'ke', text: i18next.t('Kenya'), lang: '' },
  { key: 'ki', value: 'Kiribati', flag: 'ki', text: i18next.t('Kiribati'), lang: '' },
  { key: 'kw', value: 'Kuwait', flag: 'kw', text: i18next.t('Kuwait'), lang: '' },
  { key: 'kg', value: 'Kyrgyzstan', flag: 'kg', text: i18next.t('Kyrgyzstan'), lang: '' },
  { key: 'la', value: 'Laos', flag: 'la', text: i18next.t('Laos'), lang: '' },
  { key: 'lv', value: 'Latvia', flag: 'lv', text: i18next.t('Latvia'), lang: '' },
  { key: 'lb', value: 'Lebanon', flag: 'lb', text: i18next.t('Lebanon'), lang: '' },
  { key: 'ls', value: 'Lesotho', flag: 'ls', text: i18next.t('Lesotho'), lang: '' },
  { key: 'lr', value: 'Liberia', flag: 'lr', text: i18next.t('Liberia'), lang: '' },
  { key: 'ly', value: 'Libya', flag: 'ly', text: i18next.t('Libya'), lang: '' },
  { key: 'li', value: 'Liechtenstein', flag: 'li', text: i18next.t('Liechtenstein'), lang: '' },
  { key: 'lt', value: 'Lithuania', flag: 'lt', text: i18next.t('Lithuania'), lang: '' },
  { key: 'lu', value: 'Luxembourg', flag: 'lu', text: i18next.t('Luxembourg'), lang: '' },
  { key: 'mo', value: 'Macau', flag: 'mo', text: i18next.t('Macau'), lang: '' },
  { key: 'mk', value: 'Macedonia', flag: 'mk', text: i18next.t('Macedonia'), lang: '' },
  { key: 'mg', value: 'Madagascar', flag: 'mg', text: i18next.t('Madagascar'), lang: '' },
  { key: 'mw', value: 'Malawi', flag: 'mw', text: i18next.t('Malawi'), lang: '' },
  { key: 'my', value: 'Malaysia', flag: 'my', text: i18next.t('Malaysia'), lang: '' },
  { key: 'mv', value: 'Maldives', flag: 'mv', text: i18next.t('Maldives'), lang: '' },
  { key: 'ml', value: 'Mali', flag: 'ml', text: i18next.t('Mali'), lang: '' },
  { key: 'mt', value: 'Malta', flag: 'mt', text: i18next.t('Malta'), lang: '' },
  { key: 'mh', value: 'Marshall Islands', flag: 'mh', text: i18next.t('Marshall Islands'), lang: '' },
  { key: 'mq', value: 'Martinique', flag: 'mq', text: i18next.t('Martinique'), lang: '' },
  { key: 'mr', value: 'Mauritania', flag: 'mr', text: i18next.t('Mauritania'), lang: '' },
  { key: 'mu', value: 'Mauritius', flag: 'mu', text: i18next.t('Mauritius'), lang: '' },
  { key: 'mm', value: 'Myanmar', flag: 'mm', text: i18next.t('Myanmar'), lang: '' },
  { key: 'yt', value: 'Mayotte', flag: 'yt', text: i18next.t('Mayotte'), lang: '' },
  { key: 'mx', value: 'Mexico', flag: 'mx', text: i18next.t('Mexico'), lang: '' },
  { key: 'fm', value: 'Micronesia', flag: 'fm', text: i18next.t('Micronesia'), lang: '' },
  { key: 'md', value: 'Moldova', flag: 'md', text: i18next.t('Moldova'), lang: '' },
  { key: 'mc', value: 'Monaco', flag: 'mc', text: i18next.t('Monaco'), lang: '' },
  { key: 'mn', value: 'Mongolia', flag: 'mn', text: i18next.t('Mongolia'), lang: '' },
  { key: 'me', value: 'Montenegro', flag: 'me', text: i18next.t('Montenegro'), lang: '' },
  { key: 'ms', value: 'Montserrat', flag: 'ms', text: i18next.t('Montserrat'), lang: '' },
  { key: 'ma', value: 'Morocco', flag: 'ma', text: i18next.t('Morocco'), lang: '' },
  { key: 'mz', value: 'Mozambique', flag: 'mz', text: i18next.t('Mozambique'), lang: '' },
  { key: 'na', value: 'Namibia', flag: 'na', text: i18next.t('Namibia'), lang: '' },
  { key: 'nr', value: 'Nauru', flag: 'nr', text: i18next.t('Nauru'), lang: '' },
  { key: 'np', value: 'Nepal', flag: 'np', text: i18next.t('Nepal'), lang: '' },
  { key: 'nl', value: 'Netherlands', flag: 'nl', text: i18next.t('Netherlands'), lang: '' },
  { key: 'an', value: 'Netherlandsantilles', flag: 'an', text: i18next.t('Netherlandsantilles'), lang: '' },
  { key: 'nc', value: 'New Caledonia', flag: 'nc', text: i18next.t('New Caledonia'), lang: '' },
  { key: 'pg', value: 'New Guinea', flag: 'pg', text: i18next.t('New Guinea'), lang: '' },
  { key: 'nz', value: 'New Zealand', flag: 'nz', text: i18next.t('New Zealand'), lang: '' },
  { key: 'ni', value: 'Nicaragua', flag: 'ni', text: i18next.t('Nicaragua'), lang: '' },
  { key: 'ne', value: 'Niger', flag: 'ne', text: i18next.t('Niger'), lang: '' },
  { key: 'ng', value: 'Nigeria', flag: 'ng', text: i18next.t('Nigeria'), lang: '' },
  { key: 'nu', value: 'Niue', flag: 'nu', text: i18next.t('Niue'), lang: '' },
  { key: 'nf', value: 'Norfolk Island', flag: 'nf', text: i18next.t('Norfolk Island'), lang: '' },
  { key: 'kp', value: 'North Korea', flag: 'kp', text: i18next.t('North Korea'), lang: '' },
  { key: 'mp', value: 'Northern Mariana Islands', flag: 'mp', text: i18next.t('Northern Mariana Islands'), lang: '' },
  { key: 'no', value: 'Norway', flag: 'no', text: i18next.t('Norway'), lang: '' },
  { key: 'om', value: 'Oman', flag: 'om', text: i18next.t('Oman'), lang: '' },
  { key: 'pk', value: 'Pakistan', flag: 'pk', text: i18next.t('Pakistan'), lang: '' },
  { key: 'pw', value: 'Palau', flag: 'pw', text: i18next.t('Palau'), lang: '' },
  { key: 'ps', value: 'Palestine', flag: 'ps', text: i18next.t('Palestine'), lang: '' },
  { key: 'pa', value: 'Panama', flag: 'pa', text: i18next.t('Panama'), lang: '' },
  { key: 'py', value: 'Paraguay', flag: 'py', text: i18next.t('Paraguay'), lang: '' },
  { key: 'pe', value: 'Peru', flag: 'pe', text: i18next.t('Peru'), lang: '' },
  { key: 'ph', value: 'Philippines', flag: 'ph', text: i18next.t('Philippines'), lang: '' },
  { key: 'pn', value: 'Pitcairn Islands', flag: 'pn', text: i18next.t('Pitcairn Islands'), lang: '' },
  { key: 'pl', value: 'Poland', flag: 'pl', text: i18next.t('Poland'), lang: '' },
  { key: 'pt', value: 'Portugal', flag: 'pt', text: i18next.t('Portugal'), lang: '' },
  { key: 'pr', value: 'Puerto Rico', flag: 'pr', text: i18next.t('Puerto Rico'), lang: '' },
  { key: 'qa', value: 'Qatar', flag: 'qa', text: i18next.t('Qatar'), lang: '' },
  { key: 're', value: 'Reunion', flag: 're', text: i18next.t('Reunion'), lang: '' },
  { key: 'ro', value: 'Romania', flag: 'ro', text: i18next.t('Romania'), lang: '' },
  { key: 'ru', value: 'Russia', flag: 'ru', text: i18next.t('Russia'), lang: '' },
  { key: 'rw', value: 'Rwanda', flag: 'rw', text: i18next.t('Rwanda'), lang: '' },
  { key: 'sh', value: 'Saint Helena', flag: 'sh', text: i18next.t('Saint Helena'), lang: '' },
  { key: 'kn', value: 'Saint Kitts and Nevis', flag: 'kn', text: i18next.t('Saint Kitts and Nevis'), lang: '' },
  { key: 'lc', value: 'Saint Lucia', flag: 'lc', text: i18next.t('Saint Lucia'), lang: '' },
  { key: 'pm', value: 'Saint Pierre', flag: 'pm', text: i18next.t('Saint Pierre'), lang: '' },
  { key: 'vc', value: 'Saint Vincent', flag: 'vc', text: i18next.t('Saint Vincent'), lang: '' },
  { key: 'ws', value: 'Samoa', flag: 'ws', text: i18next.t('Samoa'), lang: '' },
  { key: 'sm', value: 'San Marino', flag: 'sm', text: i18next.t('San Marino'), lang: '' },
  { key: 'gs', value: 'Sandwich Islands', flag: 'gs', text: i18next.t('Sandwich Islands'), lang: '' },
  { key: 'st', value: 'Sao Tome', flag: 'st', text: i18next.t('Sao Tome'), lang: '' },
  { key: 'sa', value: 'Saudi Arabia', flag: 'sa', text: i18next.t('Saudi Arabia'), lang: '' },
  { key: 'sn', value: 'Sénégal', flag: 'sn', text: i18next.t('Senegal'), lang: '' },
  { key: 'cs', value: 'Serbia', flag: 'cs', text: i18next.t('Serbia'), lang: '' },
  { key: 'rs', value: 'Serbia', flag: 'rs', text: i18next.t('Serbia'), lang: '' },
  { key: 'sc', value: 'Seychelles', flag: 'sc', text: i18next.t('Seychelles'), lang: '' },
  { key: 'sl', value: 'Sierra Leone', flag: 'sl', text: i18next.t('Sierra Leone'), lang: '' },
  { key: 'sg', value: 'Singapore', flag: 'sg', text: i18next.t('Singapore'), lang: '' },
  { key: 'sk', value: 'Slovakia', flag: 'sk', text: i18next.t('Slovakia'), lang: '' },
  { key: 'si', value: 'Slovenia', flag: 'si', text: i18next.t('Slovenia'), lang: '' },
  { key: 'sb', value: 'Solomon Islands', flag: 'sb', text: i18next.t('Solomon Islands'), lang: '' },
  { key: 'so', value: 'Somalia', flag: 'so', text: i18next.t('Somalia'), lang: '' },
  { key: 'za', value: 'South Africa', flag: 'za', text: i18next.t('South Africa'), lang: '' },
  { key: 'kr', value: 'South Korea', flag: 'kr', text: i18next.t('South Korea'), lang: '' },
  { key: 'es', value: 'Spain', flag: 'es', text: i18next.t('Spain'), lang: '' },
  { key: 'lk', value: 'Sri Lanka', flag: 'lk', text: i18next.t('Sri Lanka'), lang: '' },
  { key: 'sd', value: 'Sudan', flag: 'sd', text: i18next.t('Sudan'), lang: '' },
  { key: 'sr', value: 'Suriname', flag: 'sr', text: i18next.t('Suriname'), lang: '' },
  { key: 'sz', value: 'Swaziland', flag: 'sz', text: i18next.t('Swaziland'), lang: '' },
  { key: 'se', value: 'Sweden', flag: 'se', text: i18next.t('Sweden'), lang: '' },
  { key: 'ch', value: 'Switzerland', flag: 'ch', text: i18next.t('Switzerland'), lang: '' },
  { key: 'sy', value: 'Syria', flag: 'sy', text: i18next.t('Syria'), lang: '' },
  { key: 'tw', value: 'Taiwan', flag: 'tw', text: i18next.t('Taiwan'), lang: '' },
  { key: 'tj', value: 'Tajikistan', flag: 'tj', text: i18next.t('Tajikistan'), lang: '' },
  { key: 'tz', value: 'Tanzania', flag: 'tz', text: i18next.t('Tanzania'), lang: '' },
  { key: 'th', value: 'Thailand', flag: 'th', text: i18next.t('Thailand'), lang: '' },
  { key: 'tl', value: 'Timorleste', flag: 'tl', text: i18next.t('Timorleste'), lang: '' },
  { key: 'tg', value: 'Togo', flag: 'tg', text: i18next.t('Togo'), lang: '' },
  { key: 'tk', value: 'Tokelau', flag: 'tk', text: i18next.t('Tokelau'), lang: '' },
  { key: 'to', value: 'Tonga', flag: 'to', text: i18next.t('Tonga'), lang: '' },
  { key: 'tt', value: 'Trinidad', flag: 'tt', text: i18next.t('Trinidad'), lang: '' },
  { key: 'tn', value: 'Tunisia', flag: 'tn', text: i18next.t('Tunisia'), lang: '' },
  { key: 'tr', value: 'Turkey', flag: 'tr', text: i18next.t('Turkey'), lang: '' },
  { key: 'tm', value: 'Turkmenistan', flag: 'tm', text: i18next.t('Turkmenistan'), lang: '' },
  { key: 'tv', value: 'Tuvalu', flag: 'tv', text: i18next.t('Tuvalu'), lang: '' },
  { key: 'ae', value: 'United Arab Emirates', flag: 'ae', text: i18next.t('United Arab Emirates'), lang: '' },
  { key: 'ug', value: 'Uganda', flag: 'ug', text: i18next.t('Uganda'), lang: '' },
  { key: 'ua', value: 'Ukraine', flag: 'ua', text: i18next.t('Ukraine'), lang: '' },
  { key: 'gb', value: 'United Kingdom', flag: 'gb', text: i18next.t('United Kingdom'), lang: '' },
  { key: 'us', value: 'United States', flag: 'us', text: i18next.t('United States'), lang: '' },
  { key: 'uy', value: 'Uruguay', flag: 'uy', text: i18next.t('Uruguay'), lang: '' },
  { key: 'um', value: 'US Minor Islands', flag: 'um', text: i18next.t('US Minor Islands'), lang: '' },
  { key: 'vi', value: 'US Virgin Islands', flag: 'vi', text: i18next.t('US Virgin Islands'), lang: '' },
  { key: 'uz', value: 'Uzbekistan', flag: 'uz', text: i18next.t('Uzbekistan'), lang: '' },
  { key: 'vu', value: 'Vanuatu', flag: 'vu', text: i18next.t('Vanuatu'), lang: '' },
  { key: 'va', value: 'Vatican City', flag: 'va', text: i18next.t('Vatican City'), lang: '' },
  { key: 've', value: 'Venezuela', flag: 've', text: i18next.t('Venezuela'), lang: '' },
  { key: 'vn', value: 'Vietnam', flag: 'vn', text: i18next.t('Vietnam'), lang: '' },
  { key: 'wf', value: 'Wallis and Futuna', flag: 'wf', text: i18next.t('Wallis and Futuna'), lang: '' },
  { key: 'eh', value: 'Western Sahara', flag: 'eh', text: i18next.t('Western Sahara'), lang: '' },
  { key: 'ye', value: 'Yemen', flag: 'ye', text: i18next.t('Yemen'), lang: '' },
  { key: 'zm', value: 'Zambia', flag: 'zm', text: i18next.t('Zambia'), lang: '' },
  { key: 'zw', value: 'Zimbabwe', flag: 'zw', text: i18next.t('Zimbabwe'), lang: '' },
]

export const countryToLangCode = {
  "Andorra": "ca",
  "United Arab Emirates": "ar",
  "Afghanistan": "fa",
  "Antigua": "en",
  "Antigua and Barbuda": "en",
  "Anguilla": "en",
  "Albania": "sq",
  "Armenia": "hy",
  "Angola": "pt",
  "Antarctica": "en",
  "Argentina": "es",
  "American Samoa": "en",
  "Austria": "de",
  "Australia": "en",
  "Aruba": "nl",
  "Aland Islands": "sv",
  "Azerbaijan": "az",
  "Bosnia": "bs",
  "Bosnia and Herzegovina": "bs",
  "Barbados": "en",
  "Bangladesh": "bn",
  "Belgium": "nl",
  "Burkina Faso": "fr",
  "Bulgaria": "bg",
  "Bahrain": "ar",
  "Burundi": "fr",
  "Benin": "fr",
  "Saint-Barthélemy": "fr",
  "Bermuda": "en",
  "Brunei": "ms",
  "Brunei Darussalam": "ms",
  "Bolivia": "es",
  "Netherlandsantilles": "nl",
  "Caribbean Netherlands": "nl",
  "Brazil": "pt",
  "Bahamas": "en",
  "Bhutan": "dz",
  "Bouvet Island": "no",
  "Botswana": "en",
  "Belarus": "be",
  "Belize": "en",
  "Canada": "en",
  "Cocos Islands": "en",
  "Cocos (Keeling) Islands": "en",
  "Congo": "fr",
  "Democratic Republic of the Congo (Congo-Kinshasa, former Zaire)": "fr",
  "Central African Republic": "fr",
  "Centrafrican Republic": "fr",
  "Congo Brazzaville": "fr",
  "Republic of the Congo (Congo-Brazzaville)": "fr",
  "Switzerland": "de",
  "Cote Divoire": "fr",
  "Côte d'Ivoire": "fr",
  "Cook Islands": "en",
  "Chile": "es",
  "Cameroon": "fr",
  "China": "zh-hans",
  "Colombia": "es",
  "Costa Rica": "es",
  "Cuba": "es",
  "Cape Verde": "pt",
  "Cabo Verde": "pt",
  "Curaçao": "nl",
  "Christmas Island": "en",
  "Cyprus": "el",
  "Czech Republic": "cs",
  "Germany": "de",
  "Djibouti": "fr",
  "Denmark": "da",
  "Dominica": "en",
  "Dominican Republic": "es",
  "Algeria": "ar",
  "Ecuador": "es",
  "Estonia": "et",
  "Egypt": "ar",
  "Western Sahara": "ar",
  "Eritrea": "ti",
  "Basque Country": "eu",
  "Spain": "es",
  "Ethiopia": "am",
  "Finland": "fi",
  "Fiji": "en",
  "Falkland Islands": "en",
  "Micronesia": "en",
  "Micronesia (Federated States of)": "en",
  "Faroe Islands": "fo",
  "France": "fr",
  "Gabon": "fr",
  "United Kingdom": "en",
  "Grenada": "en",
  "Georgia": "ka",
  "French Guiana": "fr",
  "Guernsey": "en",
  "Ghana": "en",
  "Gibraltar": "en",
  "Greenland": "kl",
  "Gambia": "en",
  "The Gambia": "en",
  "Guinea": "fr",
  "Guadeloupe": "fr",
  "Equatorial Guinea": "es",
  "Greece": "el",
  "Sandwich Islands": "en",
  "South Georgia and the South Sandwich Islands": "en",
  "Guatemala": "es",
  "Guam": "en",
  "Guinea-Bissau": "pt",
  "Guyana": "en",
  "Hong Kong": "zh-hant",
  "Heard Island": "en",
  "Heard Island and McDonald Islands": "en",
  "Honduras": "es",
  "Croatia": "hr",
  "Haiti": "fr",
  "Hungary": "hu",
  "Indonesia": "id",
  "Ireland": "en",
  "Israel": "he",
  "Isle of Man": "en",
  "India": "hi",
  "Indian Ocean Territory": "en",
  "British Indian Ocean Territory": "en",
  "Iraq": "ar",
  "Iran": "fa",
  "Iceland": "is",
  "Italy": "it",
  "Jersey": "en",
  "Jamaica": "en",
  "Jordan": "ar",
  "Japan": "ja",
  "Kenya": "sw",
  "Kyrgyzstan": "ky",
  "Cambodia": "km",
  "Kiribati": "en",
  "Comoros": "ar",
  "Comores": "ar",
  "Saint Kitts and Nevis": "en",
  "North Korea": "ko",
  "South Korea": "ko",
  "Kuwait": "ar",
  "Cayman Islands": "en",
  "Kazakhstan": "kk",
  "Laos": "lo",
  "Lebanon": "ar",
  "Saint Lucia": "en",
  "Liechtenstein": "de",
  "Sri Lanka": "si",
  "Liberia": "en",
  "Lesotho": "en",
  "Lithuania": "lt",
  "Luxembourg": "lb",
  "Latvia": "lv",
  "Libya": "ar",
  "Morocco": "ar",
  "Monaco": "fr",
  "Moldova": "ro",
  "Montenegro": "srp",
  "Saint Martin (French part)": "fr",
  "Madagascar": "mg",
  "Marshall Islands": "en",
  "Macedonia": "mk",
  "North Macedonia": "mk",
  "Mali": "fr",
  "Myanmar": "my",
  "Mongolia": "mn",
  "Macau": "zh-hant",
  "Macao": "zh-hant",
  "Northern Mariana Islands": "en",
  "Martinique": "fr",
  "Mauritania": "ar",
  "Montserrat": "en",
  "Malta": "mt",
  "Mauritius": "mfe",
  "Maldives": "dv",
  "Malawi": "en",
  "Mexico": "es",
  "Malaysia": "ms",
  "Mozambique": "pt",
  "Namibia": "en",
  "New Caledonia": "fr",
  "Niger": "fr",
  "Norfolk Island": "en",
  "Nigeria": "en",
  "Nicaragua": "es",
  "Netherlands": "nl",
  "Norway": "nb",
  "Nepal": "ne",
  "Nauru": "na",
  "Niue": "niu",
  "New Zealand": "mi",
  "Oman": "ar",
  "Panama": "es",
  "Peru": "es",
  "French Polynesia": "fr",
  "New Guinea": "en",
  "Papua New Guinea": "en",
  "Philippines": "en",
  "Pakistan": "en",
  "Poland": "pl",
  "Saint Pierre": "fr",
  "Saint Pierre and Miquelon": "fr",
  "Pitcairn Islands": "en",
  "Pitcairn": "en",
  "Puerto Rico": "es",
  "Palestine": "ar",
  "Palestinian Territory": "ar",
  "Portugal": "pt",
  "Palau": "en",
  "Paraguay": "es",
  "Qatar": "ar",
  "Reunion": "fr",
  "Romania": "ro",
  "Serbia": "sr",
  "Russia": "ru",
  "Rwanda": "rw",
  "Saudi Arabia": "ar",
  "Solomon Islands": "en",
  "Seychelles": "fr",
  "Sudan": "ar",
  "Sweden": "sv",
  "Singapore": "zh-hans",
  "Saint Helena": "en",
  "Slovenia": "sl",
  "Jan Mayen": "no",
  "Svalbard and Jan Mayen": "no",
  "Slovakia": "sk",
  "Sierra Leone": "en",
  "San Marino": "it",
  "Sénégal": "fr",
  "Somalia": "so",
  "Suriname": "nl",
  "Sao Tome": "pt",
  "São Tomé and Príncipe": "pt",
  "South Sudan": "en",
  "El Salvador": "es",
  "Saint Martin (Dutch part)": "nl",
  "Syria": "ar",
  "Swaziland": "en",
  "eSwatini": "en",
  "Caicos Islands": "en",
  "Turks and Caicos Islands": "en",
  "Chad": "fr",
  "French Southern and Antarctic Lands": "fr",
  "Togo": "fr",
  "Thailand": "th",
  "Tajikistan": "tg",
  "Tokelau": "tkl",
  "Timorleste": "pt",
  "Timor-Leste": "pt",
  "Turkmenistan": "tk",
  "Tunisia": "ar",
  "Tonga": "en",
  "Turkey": "tr",
  "Trinidad": "en",
  "Trinidad and Tobago": "en",
  "Tuvalu": "en",
  "Taiwan": "zh-hant",
  "Tanzania": "sw",
  "Ukraine": "uk",
  "Uganda": "en",
  "US Minor Islands": "en",
  "United States Minor Outlying Islands": "en",
  "United States of America": "en",
  "United States": "en",
  "Uruguay": "es",
  "Uzbekistan": "uz",
  "Vatican City": "it",
  "City of the Vatican": "it",
  "Saint Vincent": "en",
  "Saint Vincent and the Grenadines": "en",
  "Venezuela": "es",
  "British Virgin Islands": "en",
  "US Virgin Islands": "en",
  "United States Virgin Islands": "en",
  "Vietnam": "vi",
  "Vanuatu": "bi",
  "Wallis and Futuna": "fr",
  "Samoa": "sm",
  "Yemen": "ar",
  "Mayotte": "fr",
  "South Africa": "en",
  "Zambia": "en",
  "Zimbabwe": "en"
}

export const countryLangList =
  [
    {
      "countryCode": "ad",
      "countryName": "Andorra",
      "countryLocalName": "Andorra",
      "countryLang": "ca"
    },
    {
      "countryCode": "ae",
      "countryName": "United Arab Emirates",
      "countryLocalName": "دولة الإمارات العربيّة المتّحدة",
      "countryLang": "ar"
    },
    {
      "countryCode": "af",
      "countryName": "Afghanistan",
      "countryLocalName": "د افغانستان اسلامي دولتدولت اسلامی افغانستان, جمهوری اسلامی افغانستان",
      "countryLang": "fa"
    },
    {
      "countryCode": "ag",
      "countryName": "Antigua",
      "countryLocalName": "Antigua",
      "countryLang": "en"
    },
    {
      "countryCode": "ag",
      "countryName": "Antigua and Barbuda",
      "countryLocalName": "Antigua and Barbuda",
      "countryLang": "en"
    },
    {
      "countryCode": "ai",
      "countryName": "Anguilla",
      "countryLocalName": "Anguilla",
      "countryLang": "en"
    },
    {
      "countryCode": "al",
      "countryName": "Albania",
      "countryLocalName": "Shqipëria",
      "countryLang": "sq"
    },
    {
      "countryCode": "am",
      "countryName": "Armenia",
      "countryLocalName": "Հայաստան",
      "countryLang": "hy"
    },
    {
      "countryCode": "ao",
      "countryName": "Angola",
      "countryLocalName": "Angola",
      "countryLang": "pt"
    },
    {
      "countryCode": "aq",
      "countryName": "Antarctica",
      "countryLocalName": "Antarctica, Antártico, Antarctique, Антарктике",
      "countryLang": "en"
    },
    {
      "countryCode": "ar",
      "countryName": "Argentina",
      "countryLocalName": "Argentina",
      "countryLang": "es"
    },
    {
      "countryCode": "as",
      "countryName": "American Samoa",
      "countryLocalName": "American Samoa",
      "countryLang": "en"
    },
    {
      "countryCode": "at",
      "countryName": "Austria",
      "countryLocalName": "Österreich",
      "countryLang": "de"
    },
    {
      "countryCode": "au",
      "countryName": "Australia",
      "countryLocalName": "Australia",
      "countryLang": "en"
    },
    {
      "countryCode": "aw",
      "countryName": "Aruba",
      "countryLocalName": "Aruba",
      "countryLang": "nl"
    },
    {
      "countryCode": "ax",
      "countryName": "Aland Islands",
      "countryLocalName": "Åland",
      "countryLang": "sv"
    },
    {
      "countryCode": "az",
      "countryName": "Azerbaijan",
      "countryLocalName": "Azərbaycan",
      "countryLang": "az"
    },
    {
      "countryCode": "ba",
      "countryName": "Bosnia",
      "countryLocalName": "Bosna",
      "countryLang": "bs"
    },
    {
      "countryCode": "ba",
      "countryName": "Bosnia and Herzegovina",
      "countryLocalName": "Bosna i Hercegovina",
      "countryLang": "bs"
    },
    {
      "countryCode": "bb",
      "countryName": "Barbados",
      "countryLocalName": "Barbados",
      "countryLang": "en"
    },
    {
      "countryCode": "bd",
      "countryName": "Bangladesh",
      "countryLocalName": "গণপ্রজাতন্ত্রী বাংলাদেশ",
      "countryLang": "bn"
    },
    {
      "countryCode": "be",
      "countryName": "Belgium",
      "countryLocalName": "België, Belgique, Belgien",
      "countryLang": "nl"
    },
    {
      "countryCode": "bf",
      "countryName": "Burkina Faso",
      "countryLocalName": "Burkina Faso",
      "countryLang": "fr"
    },
    {
      "countryCode": "bg",
      "countryName": "Bulgaria",
      "countryLocalName": "България",
      "countryLang": "bg"
    },
    {
      "countryCode": "bh",
      "countryName": "Bahrain",
      "countryLocalName": "البحرين",
      "countryLang": "ar"
    },
    {
      "countryCode": "bi",
      "countryName": "Burundi",
      "countryLocalName": "Burundi",
      "countryLang": "fr"
    },
    {
      "countryCode": "bj",
      "countryName": "Benin",
      "countryLocalName": "Bénin",
      "countryLang": "fr"
    },
    {
      "countryCode": "bl",
      "countryName": "Saint-Barthélemy",
      "countryLocalName": "Saint-Barthélemy",
      "countryLang": "fr"
    },
    {
      "countryCode": "bm",
      "countryName": "Bermuda",
      "countryLocalName": "Bermuda",
      "countryLang": "en"
    },
    {
      "countryCode": "bn",
      "countryName": "Brunei",
      "countryLocalName": "Brunei",
      "countryLang": "ms"
    },
    {
      "countryCode": "bn",
      "countryName": "Brunei Darussalam",
      "countryLocalName": "Brunei Darussalam",
      "countryLang": "ms"
    },
    {
      "countryCode": "bo",
      "countryName": "Bolivia",
      "countryLocalName": "Bolivia, Bulibiya, Volívia, Wuliwya",
      "countryLang": "es"
    },
    {
      "countryCode": "bq",
      "countryName": "Netherlandsantilles",
      "countryLocalName": "Caribisch Nederland",
      "countryLang": "nl"
    },
    {
      "countryCode": "bq",
      "countryName": "Caribbean Netherlands",
      "countryLocalName": "Caribisch Nederland",
      "countryLang": "nl"
    },
    {
      "countryCode": "br",
      "countryName": "Brazil",
      "countryLocalName": "Brasil",
      "countryLang": "pt"
    },
    {
      "countryCode": "bs",
      "countryName": "Bahamas",
      "countryLocalName": "Bahamas",
      "countryLang": "en"
    },
    {
      "countryCode": "bt",
      "countryName": "Bhutan",
      "countryLocalName": "འབྲུག་ཡུལ",
      "countryLang": "dz"
    },
    {
      "countryCode": "bv",
      "countryName": "Bouvet Island",
      "countryLocalName": "Bouvetøya",
      "countryLang": "no"
    },
    {
      "countryCode": "bw",
      "countryName": "Botswana",
      "countryLocalName": "Botswana",
      "countryLang": "en"
    },
    {
      "countryCode": "by",
      "countryName": "Belarus",
      "countryLocalName": "Беларусь",
      "countryLang": "be"
    },
    {
      "countryCode": "bz",
      "countryName": "Belize",
      "countryLocalName": "Belize",
      "countryLang": "en"
    },
    {
      "countryCode": "ca",
      "countryName": "Canada",
      "countryLocalName": "Canada",
      "countryLang": "en"
    },
    {
      "countryCode": "cc",
      "countryName": "Cocos Islands",
      "countryLocalName": "Cocos Islands",
      "countryLang": "en"
    },
    {
      "countryCode": "cc",
      "countryName": "Cocos (Keeling) Islands",
      "countryLocalName": "Cocos (Keeling) Islands",
      "countryLang": "en"
    },
    {
      "countryCode": "cd",
      "countryName": "Congo",
      "countryLocalName": "République Démocratique du Congo",
      "countryLang": "fr"
    },
    {
      "countryCode": "cd",
      "countryName": "Democratic Republic of the Congo (Congo-Kinshasa, former Zaire)",
      "countryLocalName": "République Démocratique du Congo",
      "countryLang": "fr"
    },
    {
      "countryCode": "cf",
      "countryName": "Central African Republic",
      "countryLocalName": "République centrafricaine, Ködörösêse tî Bêafrîka",
      "countryLang": "fr"
    },
    {
      "countryCode": "cf",
      "countryName": "Centrafrican Republic",
      "countryLocalName": "République centrafricaine, Ködörösêse tî Bêafrîka",
      "countryLang": "fr"
    },
    {
      "countryCode": "cg",
      "countryName": "Congo Brazzaville",
      "countryLocalName": "République du Congo",
      "countryLang": "fr"
    },
    {
      "countryCode": "cg",
      "countryName": "Republic of the Congo (Congo-Brazzaville)",
      "countryLocalName": "République du Congo",
      "countryLang": "fr"
    },
    {
      "countryCode": "ch",
      "countryName": "Switzerland",
      "countryLocalName": "Schweiz, Suisse, Svizzera, Svizra",
      "countryLang": "de"
    },
    {
      "countryCode": "ci",
      "countryName": "Cote Divoire",
      "countryLocalName": "Côte d'Ivoire",
      "countryLang": "fr"
    },
    {
      "countryCode": "ci",
      "countryName": "Côte d'Ivoire",
      "countryLocalName": "Côte d'Ivoire",
      "countryLang": "fr"
    },
    {
      "countryCode": "ck",
      "countryName": "Cook Islands",
      "countryLocalName": "Cook Islands, Kūki ʻĀirani",
      "countryLang": "en"
    },
    {
      "countryCode": "cl",
      "countryName": "Chile",
      "countryLocalName": "Chile",
      "countryLang": "es"
    },
    {
      "countryCode": "cm",
      "countryName": "Cameroon",
      "countryLocalName": "Cameroun, Cameroon",
      "countryLang": "fr"
    },
    {
      "countryCode": "cn",
      "countryName": "China",
      "countryLocalName": "中国",
      "countryLang": "zh-hans"
    },
    {
      "countryCode": "co",
      "countryName": "Colombia",
      "countryLocalName": "Colombia",
      "countryLang": "es"
    },
    {
      "countryCode": "cr",
      "countryName": "Costa Rica",
      "countryLocalName": "Costa Rica",
      "countryLang": "es"
    },
    {
      "countryCode": "cu",
      "countryName": "Cuba",
      "countryLocalName": "Cuba",
      "countryLang": "es"
    },
    {
      "countryCode": "cv",
      "countryName": "Cape Verde",
      "countryLocalName": "Cape Verde",
      "countryLang": "pt"
    },
    {
      "countryCode": "cv",
      "countryName": "Cabo Verde",
      "countryLocalName": "Cabo Verde",
      "countryLang": "pt"
    },
    {
      "countryCode": "cw",
      "countryName": "Curaçao",
      "countryLocalName": "Curaçao",
      "countryLang": "nl"
    },
    {
      "countryCode": "cx",
      "countryName": "Christmas Island",
      "countryLocalName": "Christmas Island",
      "countryLang": "en"
    },
    {
      "countryCode": "cy",
      "countryName": "Cyprus",
      "countryLocalName": "Κύπρος, Kibris",
      "countryLang": "el"
    },
    {
      "countryCode": "cz",
      "countryName": "Czech Republic",
      "countryLocalName": "Česká republika",
      "countryLang": "cs"
    },
    {
      "countryCode": "de",
      "countryName": "Germany",
      "countryLocalName": "Deutschland",
      "countryLang": "de"
    },
    {
      "countryCode": "dj",
      "countryName": "Djibouti",
      "countryLocalName": "Djibouti, جيبوتي, Jabuuti, Gabuutih",
      "countryLang": "fr"
    },
    {
      "countryCode": "dk",
      "countryName": "Denmark",
      "countryLocalName": "Danmark",
      "countryLang": "da"
    },
    {
      "countryCode": "dm",
      "countryName": "Dominica",
      "countryLocalName": "Dominica",
      "countryLang": "en"
    },
    {
      "countryCode": "do",
      "countryName": "Dominican Republic",
      "countryLocalName": "República Dominicana",
      "countryLang": "es"
    },
    {
      "countryCode": "dz",
      "countryName": "Algeria",
      "countryLocalName": "الجزائر",
      "countryLang": "ar"
    },
    {
      "countryCode": "ec",
      "countryName": "Ecuador",
      "countryLocalName": "Ecuador",
      "countryLang": "es"
    },
    {
      "countryCode": "ee",
      "countryName": "Estonia",
      "countryLocalName": "Eesti",
      "countryLang": "et"
    },
    {
      "countryCode": "eg",
      "countryName": "Egypt",
      "countryLocalName": "مصر",
      "countryLang": "ar"
    },
    {
      "countryCode": "eh",
      "countryName": "Western Sahara",
      "countryLocalName": "Sahara Occidental",
      "countryLang": "ar"
    },
    {
      "countryCode": "er",
      "countryName": "Eritrea",
      "countryLocalName": "ኤርትራ, إرتريا, Eritrea",
      "countryLang": "ti"
    },
    {
      "countryCode": "es-pv",
      "countryName": "Basque Country",
      "countryLocalName": "Euskal Herria",
      "countryLang": "eu"
    },
    {
      "countryCode": "es",
      "countryName": "Spain",
      "countryLocalName": "España",
      "countryLang": "es"
    },
    {
      "countryCode": "et",
      "countryName": "Ethiopia",
      "countryLocalName": "ኢትዮጵያ, Itoophiyaa",
      "countryLang": "am"
    },
    {
      "countryCode": "fi",
      "countryName": "Finland",
      "countryLocalName": "Suomi",
      "countryLang": "fi"
    },
    {
      "countryCode": "fj",
      "countryName": "Fiji",
      "countryLocalName": "Fiji",
      "countryLang": "en"
    },
    {
      "countryCode": "fk",
      "countryName": "Falkland Islands",
      "countryLocalName": "Falkland Islands",
      "countryLang": "en"
    },
    {
      "countryCode": "fm",
      "countryName": "Micronesia",
      "countryLocalName": "Micronesia",
      "countryLang": "en"
    },
    {
      "countryCode": "fm",
      "countryName": "Micronesia (Federated States of)",
      "countryLocalName": "Micronesia",
      "countryLang": "en"
    },
    {
      "countryCode": "fo",
      "countryName": "Faroe Islands",
      "countryLocalName": "Føroyar, Færøerne",
      "countryLang": "fo"
    },
    {
      "countryCode": "fr",
      "countryName": "France",
      "countryLocalName": "France",
      "countryLang": "fr"
    },
    {
      "countryCode": "ga",
      "countryName": "Gabon",
      "countryLocalName": "Gabon",
      "countryLang": "fr"
    },
    {
      "countryCode": "gb",
      "countryName": "United Kingdom",
      "countryLocalName": "United Kingdom",
      "countryLang": "en"
    },
    {
      "countryCode": "gd",
      "countryName": "Grenada",
      "countryLocalName": "Grenada",
      "countryLang": "en"
    },
    {
      "countryCode": "ge",
      "countryName": "Georgia",
      "countryLocalName": "საქართველო",
      "countryLang": "ka"
    },
    {
      "countryCode": "gf",
      "countryName": "French Guiana",
      "countryLocalName": "Guyane française",
      "countryLang": "fr"
    },
    {
      "countryCode": "gg",
      "countryName": "Guernsey",
      "countryLocalName": "Guernsey",
      "countryLang": "en"
    },
    {
      "countryCode": "gh",
      "countryName": "Ghana",
      "countryLocalName": "Ghana",
      "countryLang": "en"
    },
    {
      "countryCode": "gi",
      "countryName": "Gibraltar",
      "countryLocalName": "Gibraltar",
      "countryLang": "en"
    },
    {
      "countryCode": "gl",
      "countryName": "Greenland",
      "countryLocalName": "Kalaallit Nunaat, Grønland",
      "countryLang": "kl"
    },
    {
      "countryCode": "gm",
      "countryName": "Gambia",
      "countryLocalName": "The Gambia",
      "countryLang": "en"
    },
    {
      "countryCode": "gm",
      "countryName": "The Gambia",
      "countryLocalName": "The Gambia",
      "countryLang": "en"
    },
    {
      "countryCode": "gn",
      "countryName": "Guinea",
      "countryLocalName": "Guinée",
      "countryLang": "fr"
    },
    {
      "countryCode": "gp",
      "countryName": "Guadeloupe",
      "countryLocalName": "Guadeloupe",
      "countryLang": "fr"
    },
    {
      "countryCode": "gq",
      "countryName": "Equatorial Guinea",
      "countryLocalName": "Guiena ecuatorial, Guinée équatoriale, Guiné Equatorial",
      "countryLang": "es"
    },
    {
      "countryCode": "gr",
      "countryName": "Greece",
      "countryLocalName": "Ελλάδα",
      "countryLang": "el"
    },
    {
      "countryCode": "gs",
      "countryName": "Sandwich Islands",
      "countryLocalName": "Sandwich Islands",
      "countryLang": "en"
    },
    {
      "countryCode": "gs",
      "countryName": "South Georgia and the South Sandwich Islands",
      "countryLocalName": "South Georgia and the South Sandwich Islands",
      "countryLang": "en"
    },
    {
      "countryCode": "gt",
      "countryName": "Guatemala",
      "countryLocalName": "Guatemala",
      "countryLang": "es"
    },
    {
      "countryCode": "gu",
      "countryName": "Guam",
      "countryLocalName": "Guam, Guåhån",
      "countryLang": "en"
    },
    {
      "countryCode": "gw",
      "countryName": "Guinea-Bissau",
      "countryLocalName": "Guiné-Bissau",
      "countryLang": "pt"
    },
    {
      "countryCode": "gy",
      "countryName": "Guyana",
      "countryLocalName": "Guyana",
      "countryLang": "en"
    },
    {
      "countryCode": "hk",
      "countryName": "Hong Kong",
      "countryLocalName": "香港, Hong Kong",
      "countryLang": "zh-hant"
    },
    {
      "countryCode": "hm",
      "countryName": "Heard Island",
      "countryLocalName": "Heard Island",
      "countryLang": "en"
    },
    {
      "countryCode": "hm",
      "countryName": "Heard Island and McDonald Islands",
      "countryLocalName": "Heard Island and McDonald Islands",
      "countryLang": "en"
    },
    {
      "countryCode": "hn",
      "countryName": "Honduras",
      "countryLocalName": "Honduras",
      "countryLang": "es"
    },
    {
      "countryCode": "hr",
      "countryName": "Croatia",
      "countryLocalName": "Hrvatska",
      "countryLang": "hr"
    },
    {
      "countryCode": "ht",
      "countryName": "Haiti",
      "countryLocalName": "Haïti, Ayiti",
      "countryLang": "fr"
    },
    {
      "countryCode": "hu",
      "countryName": "Hungary",
      "countryLocalName": "Magyarország",
      "countryLang": "hu"
    },
    {
      "countryCode": "id",
      "countryName": "Indonesia",
      "countryLocalName": "Indonesia",
      "countryLang": "id"
    },
    {
      "countryCode": "ie",
      "countryName": "Ireland",
      "countryLocalName": "Ireland, Éire",
      "countryLang": "en"
    },
    {
      "countryCode": "il",
      "countryName": "Israel",
      "countryLocalName": "ישראל",
      "countryLang": "he"
    },
    {
      "countryCode": "im",
      "countryName": "Isle of Man",
      "countryLocalName": "Isle of Man",
      "countryLang": "en"
    },
    {
      "countryCode": "in",
      "countryName": "India",
      "countryLocalName": "भारत, India",
      "countryLang": "hi"
    },
    {
      "countryCode": "io",
      "countryName": "Indian Ocean Territory",
      "countryLocalName": "Indian Ocean Territory",
      "countryLang": "en"
    },
    {
      "countryCode": "io",
      "countryName": "British Indian Ocean Territory",
      "countryLocalName": "British Indian Ocean Territory",
      "countryLang": "en"
    },
    {
      "countryCode": "iq",
      "countryName": "Iraq",
      "countryLocalName": "العراق, Iraq",
      "countryLang": "ar"
    },
    {
      "countryCode": "ir",
      "countryName": "Iran",
      "countryLocalName": "ایران",
      "countryLang": "fa"
    },
    {
      "countryCode": "is",
      "countryName": "Iceland",
      "countryLocalName": "Ísland",
      "countryLang": "is"
    },
    {
      "countryCode": "it",
      "countryName": "Italy",
      "countryLocalName": "Italia",
      "countryLang": "it"
    },
    {
      "countryCode": "je",
      "countryName": "Jersey",
      "countryLocalName": "Jersey",
      "countryLang": "en"
    },
    {
      "countryCode": "jm",
      "countryName": "Jamaica",
      "countryLocalName": "Jamaica",
      "countryLang": "en"
    },
    {
      "countryCode": "jo",
      "countryName": "Jordan",
      "countryLocalName": "الأُرْدُن",
      "countryLang": "ar"
    },
    {
      "countryCode": "jp",
      "countryName": "Japan",
      "countryLocalName": "日本",
      "countryLang": "ja"
    },
    {
      "countryCode": "ke",
      "countryName": "Kenya",
      "countryLocalName": "Kenya",
      "countryLang": "sw"
    },
    {
      "countryCode": "kg",
      "countryName": "Kyrgyzstan",
      "countryLocalName": "Кыргызстан, Киргизия",
      "countryLang": "ky"
    },
    {
      "countryCode": "kh",
      "countryName": "Cambodia",
      "countryLocalName": "កម្ពុជា",
      "countryLang": "km"
    },
    {
      "countryCode": "ki",
      "countryName": "Kiribati",
      "countryLocalName": "Kiribati",
      "countryLang": "en"
    },
    {
      "countryCode": "km",
      "countryName": "Comoros",
      "countryLocalName": "ﺍﻟﻘﻤﺮي, Comoros, Komori",
      "countryLang": "ar"
    },
    {
      "countryCode": "km",
      "countryName": "Comores",
      "countryLocalName": "ﺍﻟﻘﻤﺮي, Comores, Komori",
      "countryLang": "ar"
    },
    {
      "countryCode": "kn",
      "countryName": "Saint Kitts and Nevis",
      "countryLocalName": "Saint Kitts and Nevis",
      "countryLang": "en"
    },
    {
      "countryCode": "kp",
      "countryName": "North Korea",
      "countryLocalName": "북조선",
      "countryLang": "ko"
    },
    {
      "countryCode": "kr",
      "countryName": "South Korea",
      "countryLocalName": "대한민국",
      "countryLang": "ko"
    },
    {
      "countryCode": "kw",
      "countryName": "Kuwait",
      "countryLocalName": "الكويت",
      "countryLang": "ar"
    },
    {
      "countryCode": "ky",
      "countryName": "Cayman Islands",
      "countryLocalName": "Cayman Islands",
      "countryLang": "en"
    },
    {
      "countryCode": "kz",
      "countryName": "Kazakhstan",
      "countryLocalName": "Қазақстан, Казахстан",
      "countryLang": "kk"
    },
    {
      "countryCode": "la",
      "countryName": "Laos",
      "countryLocalName": "ປະຊາຊົນລາວ",
      "countryLang": "lo"
    },
    {
      "countryCode": "lb",
      "countryName": "Lebanon",
      "countryLocalName": "لبنان, Liban",
      "countryLang": "ar"
    },
    {
      "countryCode": "lc",
      "countryName": "Saint Lucia",
      "countryLocalName": "Saint Lucia",
      "countryLang": "en"
    },
    {
      "countryCode": "li",
      "countryName": "Liechtenstein",
      "countryLocalName": "Liechtenstein",
      "countryLang": "de"
    },
    {
      "countryCode": "lk",
      "countryName": "Sri Lanka",
      "countryLocalName": "ශ්‍රී ලංකා, இலங்கை",
      "countryLang": "si"
    },
    {
      "countryCode": "lr",
      "countryName": "Liberia",
      "countryLocalName": "Liberia",
      "countryLang": "en"
    },
    {
      "countryCode": "ls",
      "countryName": "Lesotho",
      "countryLocalName": "Lesotho",
      "countryLang": "en"
    },
    {
      "countryCode": "lt",
      "countryName": "Lithuania",
      "countryLocalName": "Lietuva",
      "countryLang": "lt"
    },
    {
      "countryCode": "lu",
      "countryName": "Luxembourg",
      "countryLocalName": "Lëtzebuerg, Luxembourg, Luxemburg",
      "countryLang": "lb"
    },
    {
      "countryCode": "lv",
      "countryName": "Latvia",
      "countryLocalName": "Latvija",
      "countryLang": "lv"
    },
    {
      "countryCode": "ly",
      "countryName": "Libya",
      "countryLocalName": "ليبيا",
      "countryLang": "ar"
    },
    {
      "countryCode": "ma",
      "countryName": "Morocco",
      "countryLocalName": "المغرب, Maroc, ⵍⵎⵖⵔⵉⴱ",
      "countryLang": "ar"
    },
    {
      "countryCode": "mc",
      "countryName": "Monaco",
      "countryLocalName": "Monaco",
      "countryLang": "fr"
    },
    {
      "countryCode": "md",
      "countryName": "Moldova",
      "countryLocalName": "Moldova, Молдавия",
      "countryLang": "ro"
    },
    {
      "countryCode": "me",
      "countryName": "Montenegro",
      "countryLocalName": "Crna Gora, Црна Гора",
      "countryLang": "srp"
    },
    {
      "countryCode": "mf",
      "countryName": "Saint Martin (French part)",
      "countryLocalName": "Saint-Martin",
      "countryLang": "fr"
    },
    {
      "countryCode": "mg",
      "countryName": "Madagascar",
      "countryLocalName": "Madagasikara, Madagascar",
      "countryLang": "mg"
    },
    {
      "countryCode": "mh",
      "countryName": "Marshall Islands",
      "countryLocalName": "Marshall Islands",
      "countryLang": "en"
    },
    {
      "countryCode": "mk",
      "countryName": "Macedonia",
      "countryLocalName": "Северна Македонија",
      "countryLang": "mk"
    },
    {
      "countryCode": "mk",
      "countryName": "North Macedonia",
      "countryLocalName": "Северна Македонија",
      "countryLang": "mk"
    },
    {
      "countryCode": "ml",
      "countryName": "Mali",
      "countryLocalName": "Mali",
      "countryLang": "fr"
    },
    {
      "countryCode": "mm",
      "countryName": "Myanmar",
      "countryLocalName": "မြန်မာ",
      "countryLang": "my"
    },
    {
      "countryCode": "mn",
      "countryName": "Mongolia",
      "countryLocalName": "Монгол Улс",
      "countryLang": "mn"
    },
    {
      "countryCode": "mo",
      "countryName": "Macau",
      "countryLocalName": "澳門, Macau",
      "countryLang": "zh-hant"
    },
    {
      "countryCode": "mo",
      "countryName": "Macao",
      "countryLocalName": "澳門, Macau",
      "countryLang": "zh-hant"
    },
    {
      "countryCode": "mp",
      "countryName": "Northern Mariana Islands",
      "countryLocalName": "Northern Mariana Islands",
      "countryLang": "en"
    },
    {
      "countryCode": "mq",
      "countryName": "Martinique",
      "countryLocalName": "Martinique",
      "countryLang": "fr"
    },
    {
      "countryCode": "mr",
      "countryName": "Mauritania",
      "countryLocalName": "موريتانيا, Mauritanie",
      "countryLang": "ar"
    },
    {
      "countryCode": "ms",
      "countryName": "Montserrat",
      "countryLocalName": "Montserrat",
      "countryLang": "en"
    },
    {
      "countryCode": "mt",
      "countryName": "Malta",
      "countryLocalName": "Malta",
      "countryLang": "mt"
    },
    {
      "countryCode": "mu",
      "countryName": "Mauritius",
      "countryLocalName": "Maurice, Mauritius",
      "countryLang": "mfe"
    },
    {
      "countryCode": "mv",
      "countryName": "Maldives",
      "countryLocalName": "",
      "countryLang": "dv"
    },
    {
      "countryCode": "mw",
      "countryName": "Malawi",
      "countryLocalName": "Malawi",
      "countryLang": "en"
    },
    {
      "countryCode": "mx",
      "countryName": "Mexico",
      "countryLocalName": "México",
      "countryLang": "es"
    },
    {
      "countryCode": "my",
      "countryName": "Malaysia",
      "countryLocalName": "",
      "countryLang": "ms"
    },
    {
      "countryCode": "mz",
      "countryName": "Mozambique",
      "countryLocalName": "Mozambique",
      "countryLang": "pt"
    },
    {
      "countryCode": "na",
      "countryName": "Namibia",
      "countryLocalName": "Namibia",
      "countryLang": "en"
    },
    {
      "countryCode": "nc",
      "countryName": "New Caledonia",
      "countryLocalName": "Nouvelle-Calédonie",
      "countryLang": "fr"
    },
    {
      "countryCode": "ne",
      "countryName": "Niger",
      "countryLocalName": "Niger",
      "countryLang": "fr"
    },
    {
      "countryCode": "nf",
      "countryName": "Norfolk Island",
      "countryLocalName": "Norfolk Island",
      "countryLang": "en"
    },
    {
      "countryCode": "ng",
      "countryName": "Nigeria",
      "countryLocalName": "Nigeria",
      "countryLang": "en"
    },
    {
      "countryCode": "ni",
      "countryName": "Nicaragua",
      "countryLocalName": "Nicaragua",
      "countryLang": "es"
    },
    {
      "countryCode": "nl",
      "countryName": "Netherlands",
      "countryLocalName": "Nederland",
      "countryLang": "nl"
    },
    {
      "countryCode": "no",
      "countryName": "Norway",
      "countryLocalName": "Norge, Noreg",
      "countryLang": "nb"
    },
    {
      "countryCode": "np",
      "countryName": "Nepal",
      "countryLocalName": "",
      "countryLang": "ne"
    },
    {
      "countryCode": "nr",
      "countryName": "Nauru",
      "countryLocalName": "Nauru",
      "countryLang": "na"
    },
    {
      "countryCode": "nu",
      "countryName": "Niue",
      "countryLocalName": "Niue",
      "countryLang": "niu"
    },
    {
      "countryCode": "nz",
      "countryName": "New Zealand",
      "countryLocalName": "New Zealand",
      "countryLang": "mi"
    },
    {
      "countryCode": "om",
      "countryName": "Oman",
      "countryLocalName": "سلطنة عُمان",
      "countryLang": "ar"
    },
    {
      "countryCode": "pa",
      "countryName": "Panama",
      "countryLocalName": "Panama",
      "countryLang": "es"
    },
    {
      "countryCode": "pe",
      "countryName": "Peru",
      "countryLocalName": "Perú",
      "countryLang": "es"
    },
    {
      "countryCode": "pf",
      "countryName": "French Polynesia",
      "countryLocalName": "Polynésie française",
      "countryLang": "fr"
    },
    {
      "countryCode": "pg",
      "countryName": "New Guinea",
      "countryLocalName": "Papua New Guinea",
      "countryLang": "en"
    },
    {
      "countryCode": "pg",
      "countryName": "Papua New Guinea",
      "countryLocalName": "Papua New Guinea",
      "countryLang": "en"
    },
    {
      "countryCode": "ph",
      "countryName": "Philippines",
      "countryLocalName": "Philippines",
      "countryLang": "en"
    },
    {
      "countryCode": "pk",
      "countryName": "Pakistan",
      "countryLocalName": "پاکستان",
      "countryLang": "en"
    },
    {
      "countryCode": "pl",
      "countryName": "Poland",
      "countryLocalName": "Polska",
      "countryLang": "pl"
    },
    {
      "countryCode": "pm",
      "countryName": "Saint Pierre",
      "countryLocalName": "Saint-Pierre",
      "countryLang": "fr"
    },
    {
      "countryCode": "pm",
      "countryName": "Saint Pierre and Miquelon",
      "countryLocalName": "Saint-Pierre-et-Miquelon",
      "countryLang": "fr"
    },
    {
      "countryCode": "pn",
      "countryName": "Pitcairn Islands",
      "countryLocalName": "Pitcairn Islands",
      "countryLang": "en"
    },
    {
      "countryCode": "pn",
      "countryName": "Pitcairn",
      "countryLocalName": "Pitcairn",
      "countryLang": "en"
    },
    {
      "countryCode": "pr",
      "countryName": "Puerto Rico",
      "countryLocalName": "Puerto Rico",
      "countryLang": "es"
    },
    {
      "countryCode": "ps",
      "countryName": "Palestine",
      "countryLocalName": "Palestinian",
      "countryLang": "ar"
    },
    {
      "countryCode": "ps",
      "countryName": "Palestinian Territory",
      "countryLocalName": "Palestinian Territory",
      "countryLang": "ar"
    },
    {
      "countryCode": "pt",
      "countryName": "Portugal",
      "countryLocalName": "Portugal",
      "countryLang": "pt"
    },
    {
      "countryCode": "pw",
      "countryName": "Palau",
      "countryLocalName": "Palau",
      "countryLang": "en"
    },
    {
      "countryCode": "py",
      "countryName": "Paraguay",
      "countryLocalName": "Paraguay",
      "countryLang": "es"
    },
    {
      "countryCode": "qa",
      "countryName": "Qatar",
      "countryLocalName": "قطر",
      "countryLang": "ar"
    },
    {
      "countryCode": "re",
      "countryName": "Reunion",
      "countryLocalName": "La Réunion",
      "countryLang": "fr"
    },
    {
      "countryCode": "ro",
      "countryName": "Romania",
      "countryLocalName": "România",
      "countryLang": "ro"
    },
    {
      "countryCode": "rs",
      "countryName": "Serbia",
      "countryLocalName": "Србија",
      "countryLang": "sr"
    },
    {
      "countryCode": "ru",
      "countryName": "Russia",
      "countryLocalName": "Россия",
      "countryLang": "ru"
    },
    {
      "countryCode": "rw",
      "countryName": "Rwanda",
      "countryLocalName": "Rwanda",
      "countryLang": "rw"
    },
    {
      "countryCode": "sa",
      "countryName": "Saudi Arabia",
      "countryLocalName": "السعودية",
      "countryLang": "ar"
    },
    {
      "countryCode": "sb",
      "countryName": "Solomon Islands",
      "countryLocalName": "Solomon Islands",
      "countryLang": "en"
    },
    {
      "countryCode": "sc",
      "countryName": "Seychelles",
      "countryLocalName": "Seychelles",
      "countryLang": "fr"
    },
    {
      "countryCode": "sd",
      "countryName": "Sudan",
      "countryLocalName": "السودان",
      "countryLang": "ar"
    },
    {
      "countryCode": "se",
      "countryName": "Sweden",
      "countryLocalName": "Sverige",
      "countryLang": "sv"
    },
    {
      "countryCode": "sg",
      "countryName": "Singapore",
      "countryLocalName": "Singapore",
      "countryLang": "zh-hans"
    },
    {
      "countryCode": "sh",
      "countryName": "Saint Helena",
      "countryLocalName": "Saint Helena",
      "countryLang": "en"
    },
    {
      "countryCode": "si",
      "countryName": "Slovenia",
      "countryLocalName": "Slovenija",
      "countryLang": "sl"
    },
    {
      "countryCode": "sj",
      "countryName": "Jan Mayen",
      "countryLocalName": "Jan Mayen",
      "countryLang": "no"
    },
    {
      "countryCode": "sj",
      "countryName": "Svalbard and Jan Mayen",
      "countryLocalName": "Svalbard and Jan Mayen",
      "countryLang": "no"
    },
    {
      "countryCode": "sk",
      "countryName": "Slovakia",
      "countryLocalName": "Slovensko",
      "countryLang": "sk"
    },
    {
      "countryCode": "sl",
      "countryName": "Sierra Leone",
      "countryLocalName": "Sierra Leone",
      "countryLang": "en"
    },
    {
      "countryCode": "sm",
      "countryName": "San Marino",
      "countryLocalName": "San Marino",
      "countryLang": "it"
    },
    {
      "countryCode": "sn",
      "countryName": "Sénégal",
      "countryLocalName": "Sénégal",
      "countryLang": "fr"
    },
    {
      "countryCode": "so",
      "countryName": "Somalia",
      "countryLocalName": "Somalia, الصومال",
      "countryLang": "so"
    },
    {
      "countryCode": "sr",
      "countryName": "Suriname",
      "countryLocalName": "Suriname",
      "countryLang": "nl"
    },
    {
      "countryCode": "st",
      "countryName": "Sao Tome",
      "countryLocalName": "São Tomé",
      "countryLang": "pt"
    },
    {
      "countryCode": "st",
      "countryName": "São Tomé and Príncipe",
      "countryLocalName": "São Tomé e Príncipe",
      "countryLang": "pt"
    },
    {
      "countryCode": "ss",
      "countryName": "South Sudan",
      "countryLocalName": "South Sudan",
      "countryLang": "en"
    },
    {
      "countryCode": "sv",
      "countryName": "El Salvador",
      "countryLocalName": "El Salvador",
      "countryLang": "es"
    },
    {
      "countryCode": "sx",
      "countryName": "Saint Martin (Dutch part)",
      "countryLocalName": "Sint Maarten",
      "countryLang": "nl"
    },
    {
      "countryCode": "sy",
      "countryName": "Syria",
      "countryLocalName": "سوريا, Sūriyya",
      "countryLang": "ar"
    },
    {
      "countryCode": "sz",
      "countryName": "eSwatini",
      "countryLocalName": "eSwatini",
      "countryLang": "en"
    },
    {
      "countryCode": "tc",
      "countryName": "Caicos Islands",
      "countryLocalName": "Caicos Islands",
      "countryLang": "en"
    },
    {
      "countryCode": "tc",
      "countryName": "Turks and Caicos Islands",
      "countryLocalName": "Turks and Caicos Islands",
      "countryLang": "en"
    },
    {
      "countryCode": "td",
      "countryName": "Chad",
      "countryLocalName": "Tchad, تشاد",
      "countryLang": "fr"
    },
    {
      "countryCode": "tf",
      "countryName": "French Southern and Antarctic Lands",
      "countryLocalName": "Terres australes et antarctiques françaises",
      "countryLang": "fr"
    },
    {
      "countryCode": "tg",
      "countryName": "Togo",
      "countryLocalName": "Togo",
      "countryLang": "fr"
    },
    {
      "countryCode": "th",
      "countryName": "Thailand",
      "countryLocalName": "ประเทศไทย",
      "countryLang": "th"
    },
    {
      "countryCode": "tj",
      "countryName": "Tajikistan",
      "countryLocalName": ",",
      "countryLang": "tg"
    },
    {
      "countryCode": "tk",
      "countryName": "Tokelau",
      "countryLocalName": "Tokelau",
      "countryLang": "tkl"
    },
    {
      "countryCode": "tl",
      "countryName": "Timorleste",
      "countryLocalName": "Timorleste, Timor Lorosa'e",
      "countryLang": "pt"
    },
    {
      "countryCode": "tl",
      "countryName": "Timor-Leste",
      "countryLocalName": "Timor-Leste, Timor Lorosa'e",
      "countryLang": "pt"
    },
    {
      "countryCode": "tm",
      "countryName": "Turkmenistan",
      "countryLocalName": "Türkmenistan",
      "countryLang": "tk"
    },
    {
      "countryCode": "tn",
      "countryName": "Tunisia",
      "countryLocalName": "تونس, Tunisie",
      "countryLang": "ar"
    },
    {
      "countryCode": "to",
      "countryName": "Tonga",
      "countryLocalName": "Tonga",
      "countryLang": "en"
    },
    {
      "countryCode": "tr",
      "countryName": "Turkey",
      "countryLocalName": "Türkiye",
      "countryLang": "tr"
    },
    {
      "countryCode": "tt",
      "countryName": "Trinidad",
      "countryLocalName": "Trinidad",
      "countryLang": "en"
    },
    {
      "countryCode": "tt",
      "countryName": "Trinidad and Tobago",
      "countryLocalName": "Trinidad and Tobago",
      "countryLang": "en"
    },
    {
      "countryCode": "tv",
      "countryName": "Tuvalu",
      "countryLocalName": "Tuvalu",
      "countryLang": "en"
    },
    {
      "countryCode": "tw",
      "countryName": "Taiwan",
      "countryLocalName": "Taiwan",
      "countryLang": "zh-hant"
    },
    {
      "countryCode": "tz",
      "countryName": "Tanzania",
      "countryLocalName": "Tanzania",
      "countryLang": "sw"
    },
    {
      "countryCode": "ua",
      "countryName": "Ukraine",
      "countryLocalName": "Україна",
      "countryLang": "uk"
    },
    {
      "countryCode": "ug",
      "countryName": "Uganda",
      "countryLocalName": "Uganda",
      "countryLang": "en"
    },
    {
      "countryCode": "um",
      "countryName": "US Minor Islands",
      "countryLocalName": "US Minor Islands",
      "countryLang": "en"
    },
    {
      "countryCode": "um",
      "countryName": "United States Minor Outlying Islands",
      "countryLocalName": "United States Minor Outlying Islands",
      "countryLang": "en"
    },
    {
      "countryCode": "us",
      "countryName": "United States of America",
      "countryLocalName": "United States of America",
      "countryLang": "en"
    },
    {
      "countryCode": "us",
      "countryName": "United States",
      "countryLocalName": "United States",
      "countryLang": "en"
    },
    {
      "countryCode": "uy",
      "countryName": "Uruguay",
      "countryLocalName": "Uruguay",
      "countryLang": "es"
    },
    {
      "countryCode": "uz",
      "countryName": "Uzbekistan",
      "countryLocalName": "",
      "countryLang": "uz"
    },
    {
      "countryCode": "va",
      "countryName": "Vatican City",
      "countryLocalName": "Città del Vaticano",
      "countryLang": "it"
    },
    {
      "countryCode": "va",
      "countryName": "City of the Vatican",
      "countryLocalName": "Città del Vaticano",
      "countryLang": "it"
    },
    {
      "countryCode": "vc",
      "countryName": "Saint Vincent",
      "countryLocalName": "Saint Vincent",
      "countryLang": "en"
    },
    {
      "countryCode": "vc",
      "countryName": "Saint Vincent and the Grenadines",
      "countryLocalName": "Saint Vincent and the Grenadines",
      "countryLang": "en"
    },
    {
      "countryCode": "ve",
      "countryName": "Venezuela",
      "countryLocalName": "Venezuela",
      "countryLang": "es"
    },
    {
      "countryCode": "vg",
      "countryName": "British Virgin Islands",
      "countryLocalName": "British Virgin Islands",
      "countryLang": "en"
    },
    {
      "countryCode": "vi",
      "countryName": "United States Virgin Islands",
      "countryLocalName": "United States Virgin Islands",
      "countryLang": "en"
    },
    {
      "countryCode": "vn",
      "countryName": "Vietnam",
      "countryLocalName": "Việt Nam",
      "countryLang": "vi"
    },
    {
      "countryCode": "vu",
      "countryName": "Vanuatu",
      "countryLocalName": "Vanuatu",
      "countryLang": "bi"
    },
    {
      "countryCode": "wf",
      "countryName": "Wallis and Futuna",
      "countryLocalName": "Wallis-et-Futuna",
      "countryLang": "fr"
    },
    {
      "countryCode": "ws",
      "countryName": "Samoa",
      "countryLocalName": "Samoa",
      "countryLang": "sm"
    },
    {
      "countryCode": "ye",
      "countryName": "Yemen",
      "countryLocalName": "اليَمَن",
      "countryLang": "ar"
    },
    {
      "countryCode": "yt",
      "countryName": "Mayotte",
      "countryLocalName": "Mayotte",
      "countryLang": "fr"
    },
    {
      "countryCode": "za",
      "countryName": "South Africa",
      "countryLocalName": "South Africa",
      "countryLang": "en"
    },
    {
      "countryCode": "zm",
      "countryName": "Zambia",
      "countryLocalName": "Zambia",
      "countryLang": "en"
    },
    {
      "countryCode": "zw",
      "countryName": "Zimbabwe",
      "countryLocalName": "Zimbabwe",
      "countryLang": "en"
    }
  ]