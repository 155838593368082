import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'semantic-ui-react'
import checkBuild from './checkBuild'
import i18next from 'i18next'
import { isAndroidWebApp, isIOSWebApp } from 'util/util'

const periodMinutes = 1
export default function ReloadCheck(props) {
  const { commitHash } = props
  const initialState = {
    showModal: false,
    currentHash: commitHash ? commitHash : null,
  }
  const [{ showModal, currentHash }, setState] = useState(initialState)

  useEffect(() => {
    if (currentHash && commitHash) {
      if (currentHash !== commitHash) {
        setState(prevState => ({ ...prevState, showModal: true }))
      }
    }
  }, [currentHash])

  const doCheckBuild = async () => {
    try {
      const responseHash = await checkBuild()
      setState(prevState => ({
        ...prevState,
        currentHash: responseHash,
      }))
    } catch (error) {
      console.error("Couldn't check build info.")
    }
  }

  const closeModal = () => {
    setState(prevState => ({
      ...prevState,
      showModal: false,
    }))
  }

  const reload = () => {
    closeModal()
    if (isIOSWebApp) {
      window.webkit.messageHandlers.bridge.postMessage({ type: "reload" })
    } else if (isAndroidWebApp) {
      window.Android.reload()
    }
    window.location.reload()
  }

  useEffect(() => {
    doCheckBuild()
    const interval = setInterval(() => {
      doCheckBuild()
    }, periodMinutes * 60 * 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <React.Fragment>
      <Modal size="mini" open={showModal} onClose={closeModal}>
        <Modal.Header>{i18next.t('new_version_available')}</Modal.Header>
        <Modal.Content>
          <p>
            {i18next.t(
              'a_new_version_is_available_do_you_want_to_reload_page'
            )}
            ?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={closeModal}>
            {i18next.t('not_now')}
          </Button>
          <Button positive onClick={reload}>
            {i18next.t('yes_reload')}
          </Button>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  )
}

ReloadCheck.propTypes = {
  commitHash: PropTypes.string.isRequired,
  periodMinutes: PropTypes.number,
}