import React from 'react'
import PropTypes from 'prop-types'
import PasswordForgottenForm from '../container/PasswordForgottenForm'
import Success from 'app/public/Success'
import { Header, Divider } from 'semantic-ui-react'
import i18next from 'i18next'
import PageTop from 'app/protected/roles/common/page-top/PageTop'

export default function Password(props) {
  const { showSuccess, hideSuccess, history } = props

  const onSuccessClose = () => {
    hideSuccess()
    history.push('/')
  }

  return (
    <>
      <PageTop />
      <div style={{ padding: "1rem" }}>
        <Header as="h1" icon textAlign="center" htmlFor="email">
          {i18next.t('password_forgotten')}
        </Header>

        <Divider hidden />

        <Header as="h5" icon htmlFor="email">
          {i18next.t('please_enter_your_email_to_reset_password')}
        </Header>

        <div>
          <PasswordForgottenForm
            buttonProps={{
              fluid: true,
              primary: true,
            }}
          />
        </div>
        <Success
          onClose={onSuccessClose}
          show={showSuccess}
          title={i18next.t('email_has_been_sent')}
          content={<>
            <Header>{i18next.t('please_follow_the_link_in_the_email')}.</Header>
            <p>{i18next.t('see_you_soon')}!</p>
          </>}
          actionText={i18next.t('take_me_to_login')}
        />
      </div>
    </>
  )
}

Password.propTypes = {
  showSuccess: PropTypes.bool.isRequired,
  hideSuccess: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}
