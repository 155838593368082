import React, { useState, useRef, useEffect } from 'react'
import { Button, Form, Message, Input, Grid, Divider } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import SupText from 'app/protected/common/SupText'
import i18next from 'i18next'
import PasswordRequirements from 'app/public/PasswordRequirements'

export default function PasswordChangeForm(props) {
  const { onInput, showFormStatus, hideFormStatus, post, match, ongoing,
    formMessage,
    formMessageType,
    clearForm,
    isFormMessageVisible } = props
  const initialState = {
    password1: '',
    password2: '',
    password1Error: false,
    password2Error: false,
    customFormMessage: false,
  }
  const [{
    password1,
    password2,
    password1Error,
    password2Error,
    customFormMessage,
  }, setState] = useState(initialState)
  const [modalOpen, setModalOpen] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)
  const toggleShowPassword = () => {
    event.preventDefault()
    setShowPassword(!showPassword)
  }
  const toggleShowPassword2 = () => {
    event.preventDefault()
    setShowPassword2(!showPassword2)
  }

  const inputChange = (event) => {
    const target = event.target
    const name = target.name
    const value = target.type === 'checkbox' ? target.checked : target.value
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }))
    onInput()
  }

  const validatePassword = (password) => {
    // At least one alphabetical character
    if (!/[a-zA-Z]/.test(password)) {
      return false
    }
    // At least one number
    if (!/[0-9]/.test(password)) {
      return false
    }
    // At least one special character
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      return false
    }
    return true
  }

  const handleSubmit = () => {
    if (password1.length < 8) {
      setState(prevState => ({
        ...prevState,
        password1Error: true,
      }))
      showFormStatus(
        i18next.t('too_short_password_at_least_8_characters_is_required'),
        'error'
      )
      return
    }
    else if (password2.length < 8) {
      setState(prevState => ({
        ...prevState,
        password2Error: true,
      }))
      showFormStatus(
        i18next.t('too_short_password_at_least_8_characters_is_required'),
        'error'
      )
      return
    }
    else if (password1 != password2) {
      setState(prevState => ({
        ...prevState,
        password1Error: true,
        password2Error: true,
        customFormMessage: false,
      }))
      showFormStatus(i18next.t('passwords_are_not_matching'), 'error')
      return
    }
    else if (!validatePassword(password1)) {
      setState(prevState => ({
        ...prevState,
        password1Error: true,
        password2Error: true,
        customFormMessage: true
      }))
      showFormStatus(i18next.t('bad_password'), 'error')
      return
    }

    hideFormStatus()

    const url = `/api/reset/${match.params.id}`
    post({
      url,
      data: {
        password: password1,
      },
    })
  }

  useEffect(() => {
    if (clearForm) {
      setState(initialState)
    }
  }, [clearForm])

  useEffect(() => {
    if (inputRef) {
      inputRef.current.focus()
    }
  }, [inputRef])

  const lengthLimit = (minLength, length, showText) => {
    return (
      <Grid>
        <Grid.Row
          style={{ paddingTop: '0px' }}
        >
          <Grid.Column width="12" verticalAlign="middle" textAlign="left">
            {length < minLength ? (
              <div style={{ position: 'relative', top: '10px' }}>
                {showText && <span
                  style={{
                    fontSize: 'x-small',
                    paddingLeft: '2px',
                    color: 'red',
                  }}
                >
                  *{i18next.t('password_requires_at_least_8_characters')}
                </span>}
              </div>
            ) : null}
          </Grid.Column>
          <Grid.Column width="4" verticalAlign="middle" textAlign="right">
            <span
              style={{
                fontSize: 'x-small',
                color: length < minLength ? 'red' : 'green',
                position: 'relative',
                top: '10px',
              }}
            >{`${length}/${minLength}`}</span>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  const getErrorContent = (errorMessage) => {
    console.log(errorMessage)
    if (errorMessage.startsWith("User already exist")) {
      return i18next.t('user_already_exists')
    } else if (errorMessage === "bad_password") {
      return <>
        <Message.Header>{i18next.t('password_is_too_weak')}</Message.Header>
        <p>{i18next.t('your_password_is_too_easy_to_guess_try_a_new_one')}</p>
        <div>
          {i18next.t('according_to') + ": "}
          <a href="https://haveibeenpwned.com/" target="_blank">{"https://haveibeenpwned.com/"}</a>
        </div>
      </>
    } else if (errorMessage === i18next.t('passwords_are_not_matching')) {
      return i18next.t('passwords_are_not_matching')
    }
    return i18next.t('oh_no_something_went_wrong_try_again')//errorMessage
  }

  const inputRef = useRef()
  const showError = isFormMessageVisible && formMessageType == 'error'
  const showSuccess = isFormMessageVisible && formMessageType == 'success'
  const isDisabled = ongoing || password1.length < 8 || password2.length < 8

  return (
    <Form
      onSubmit={handleSubmit}
      error={showError}
      success={showSuccess}
    >
      <Form.Group widths="equal">
        <Form.Field>
          <label>
            {i18next.t('enter_password')}
            {' '}
            <SupText
              text={'*'}
              color={password1.length > 8 ? 'green' : 'red'}
            />
            <span style={{ float: 'right', fontWeight: 300, color: 'gray' }}>*Password needs specific criteria. <a href="#" onClick={() => setModalOpen(true)}>Learn more</a></span>
          </label>
          <Input
            icon="lock"
            iconPosition="left"
            name="password1"
            value={password1}
            type={showPassword ? 'text' : 'password'}
            placeholder={i18next.t('enter_password')}
            onChange={inputChange}
            error={password1Error}
            ref={inputRef}
            action={{
              icon: showPassword ? 'eye slash' : 'eye',
              onClick: toggleShowPassword
            }}
          />
          {lengthLimit(8, password1.length, true)}
        </Form.Field>
        <Form.Field>
          <label>
            {i18next.t('verify_password')}
            {' '}
            <SupText
              text={'*'}
              color={password2.length > 8 ? 'green' : 'red'}
            />
          </label>
          <Input
            icon="lock"
            iconPosition="left"
            name="password2"
            value={password2}
            type={showPassword2 ? 'text' : 'password'}
            placeholder={i18next.t('verify_password')}
            onChange={inputChange}
            error={password2Error}
            action={{
              icon: showPassword2 ? 'eye slash' : 'eye',
              onClick: toggleShowPassword2
            }}
          />
          {lengthLimit(8, password2.length, true)}
        </Form.Field>
      </Form.Group>
      <Message
        error
        content={getErrorContent(formMessage)}
      />
      <PasswordRequirements
        onClose={() => { setModalOpen(false) }}
        show={modalOpen}
      />
      <Message success content={formMessage} />
      <Divider hidden />
      <Button
        type="submit"
        fluid
        primary
        loading={ongoing}
        disabled={isDisabled}
        positive
      >
        {i18next.t('change_password')}
      </Button>
    </Form>
  )

}

PasswordChangeForm.propTypes = {
  post: PropTypes.func.isRequired,
  ongoing: PropTypes.bool.isRequired,
  clearForm: PropTypes.bool.isRequired,
  formMessage: PropTypes.string.isRequired,
  showFormStatus: PropTypes.func.isRequired,
  hideFormStatus: PropTypes.func.isRequired,
  formMessageType: PropTypes.string.isRequired,
  isFormMessageVisible: PropTypes.bool.isRequired,
  onInput: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
}
