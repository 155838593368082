import React from 'react'
import { connect } from 'react-redux'
import { signIn as action } from 'redux/actions/actions.js'
import sheet from './style.scss'
import PropTypes from 'prop-types'
import SignInForm from 'app/public/sign-in/SignInForm'
import { Link } from 'react-router-dom'
import { Divider, Button, Header } from 'semantic-ui-react'
import { homePaths } from 'app/protected/common/constants/routes'
import { resendVerification as join_thunk } from 'redux/actions/thunks.js'
import i18next from 'i18next'
import { Redirect } from 'react-router-dom'
import i18n from 'i18n/i18n'
import LoadingMessage from 'app/common/loading-message/component/LoadingMessage'

class SignIn extends React.PureComponent {
  componentDidUpdate() {
    const { showSuccess: redirectToHome } = this.props
    if (redirectToHome) {
      const { history, location } = this.props
      if (location.state && location.state.from) {
        history.push(location.state.from.pathname)
      } else {
        history.replace(homePaths.PROTECTED())
      }
    }
  }

  render() {
    const { history, resendVerification, isAuthorized, ongoing } = this.props

    if (isAuthorized) {
      const { location } = this.props

      const hasState = location.state ?
        location.state.from ?
          location.state.from.pathname ?
            location.state.from.pathname : false
          : false
        : false
      if (hasState) {
        return (
          <React.Fragment>
            <Redirect to={{ pathname: hasState }} />
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            <Redirect to={homePaths.ME()} />
          </React.Fragment>
        )
      }
    }

    if (ongoing) {
      return (<LoadingMessage text={i18next.t('fetching') + "..."} />)
    }

    return (
      <div className={sheet.signIn}>
        <Header as="h1" icon textAlign="center" htmlFor="email">
          {i18next.t('sign_in')}
        </Header>

        <div className={sheet.form}>
          <SignInForm
            resendVerification={resendVerification}
            goToJoin={() => history.push(homePaths.JOIN())}
            buttonProps={{
              fluid: true,
              primary: true,
            }}
          />
        </div>

        <div>
          <Divider horizontal>{i18next.t('or')}</Divider>

          <Button
            secondary
            fluid
            color="black"
            inverted
            onClick={() => history.push(homePaths.JOIN())}
          >
            {i18next.t('create_account')}
          </Button>

          <Divider />

          <div style={{ textAlign: 'center' }}>
            <Link to={homePaths.PASSWORD_FORGOTTEN()}>
              <span>{i18next.t('forgot_password')}?</span>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

SignIn.defaultProps = {}

SignIn.propTypes = {
  showSuccess: PropTypes.bool.isRequired,
  name: PropTypes.string,
  hideSuccess: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}

//Hack to set language on this site only
const getLangCode = (lang) => {
  if (lang.length >= 2) {
    const firstCode = lang.substring(0, 2)
    return firstCode
  }
  return 'en'
}

const mapStateToProps = (state) => {
  const { showWelcomeScreen } = state.signInReducer
  const { status, data, ongoing } = state.userReducer
  const { name } = data

  if (status === 'unauthorized') {
    //Hack to set language on this site only
    const userLang = navigator.language || navigator.userLanguage
    const userLangCode = getLangCode(userLang)
    i18n.changeLanguage(userLangCode)
  } else if (status === "authorized") {
    const { settings } = data
    const s = settings.find(s => s.name === 'language')
    if (s) {
      if (s.value) {
        i18n.changeLanguage(s.value)
      }
    }
  }
  return {
    name,
    showSuccess: showWelcomeScreen,
    isAuthorized: status == 'authorized',
    ongoing: status === "unauthorized" ? false : ongoing,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    hideSuccess: () => dispatch(action.hideWelcomeScreen()),
    resendVerification: (data) => {
      dispatch(join_thunk.post(data))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
