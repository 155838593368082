import React from "react"
import i18next from 'i18next'
import { Modal, Button } from "semantic-ui-react"

const CustomPrompt = ({ message, cleanUp }) => {
  const cancel = () => cleanUp(false)
  const ok = () => cleanUp(true)
  const { header, content } = JSON.parse(message)
  return (
    <Modal open size="mini" onClose={cancel}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        {content}
      </Modal.Content>
      <Modal.Actions>
        <div
          style={{
            display: 'flex',
            flex: '1',
            alignItems: 'strech',
            paddingBottom: '10px',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'inline-flex',
              cursor: 'pointer',
            }}
            onClick={cancel}
          >
            {i18next.t('cancel')}
          </div>
          <div
            style={{
              display: 'flex',
              flex: '1',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              positive
              content={i18next.t('leave')}
              onClick={ok}
            />
          </div>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

export default CustomPrompt