import React from 'react'
import { Dropdown, Menu, Icon, Image } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import moreOptions from 'assets/images/more_vert.svg'

const PageActions = props => {
  const { showActions, actionsConfig, moreVert } = props

  const optionIcon = moreVert ?
    <Image
      src={moreOptions}
      style={{ width: '24px', }}
    />
    : <Icon name="cog" size={'large'} />

  return (
    <>
      <Dropdown
        simple
        basic
        closeOnBlur
        direction="left"
        trigger={optionIcon}
        className="PageAction"
      >
        <Menu>
          {actionsConfig.map((c, key) => (
            <Menu.Item key={key} onClick={c.onClick} disabled={c.disabled ? c.disabled : false}>
              <Icon name={c.name} />
              <span>{c.toolTip}</span>
            </Menu.Item>
          ))}
        </Menu>
      </Dropdown>
    </>
  )
}

PageActions.propTypes = {
  actionsConfig: PropTypes.array.isRequired,
  showActions: PropTypes.bool,
  moreVert: PropTypes.bool,
}

export default PageActions
