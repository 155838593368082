import { connect } from 'react-redux'
import PrivateRoute from '../component/PrivateRoute'
import { user } from 'redux/actions/thunks.js'

const mapStateToProps = (state) => {
  const { status, lastAuthorization } = state.userReducer
  return { status, loggedIn: lastAuthorization ? true : false }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: () => {
      dispatch(user.get())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
