import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'
import FadeRoute from 'app/root/fade-route/component/FadeRoute'
import { Header, Loader } from 'semantic-ui-react'
import LoadingMessage from 'app/common/loading-message/component/LoadingMessage'
import { homePaths } from 'app/protected/common/constants/routes'
import i18next from 'i18next'

export default function PrivateRoute(props) {

  const { wrapped: Component, getUser, status, loggedIn, ...rest } = props

  useEffect(() => {
    if (status === "unauthorized") {
      const interval = setInterval(() => {
        getUser()
      }, 3000)
      return () => clearInterval(interval)
    }
  }, [status])

  const unknownStatus = () => {
    return <h1>unknown error</h1>
  }

  const unauthorized = () => {
    return (
      <Redirect
        to={{
          pathname: homePaths.SIGNIN(),
          state: {
            from: props.location,
          },
        }}
      />
    )
  }

  const deauthorized = () => {
    return (
      <Redirect
        to={{
          pathname: homePaths.SIGNIN(),
        }}
      />
    )
  }

  const select = (Component) => {
    switch (status) {
      case 'unknown':
        return <LoadingMessage text="Verifying user" />
      case 'deauthorized':
        return deauthorized()
      case 'unauthorized':
        if (loggedIn) {
          return <>
            <Header textAlign="center" as="h2">{i18next.t('reconnecting') + '...'}</Header>
            <Loader size="massive" className='workaround' inline="centered" active />
          </>
        } else {
          return unauthorized()
        }
      case 'authorized':
        return <FadeRoute {...props} component={Component} />
      default:
        return unknownStatus()
    }
  }


  return <Route {...rest} render={() => select(Component)} />

}

PrivateRoute.defaultProps = {}

PrivateRoute.propTypes = {
  wrapped: PropTypes.any.isRequired,
  getUser: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
}
