import { connect } from 'react-redux'
import { menu as menuAction } from 'redux/actions/actions.js'
import { actionFooter as footer } from 'redux/actions/actions'
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Sidebar, Menu, Image, Icon, Divider } from 'semantic-ui-react'
import dumbellSvg from 'assets/images/dumbell.svg'
import excercisesSvg from 'assets/images/excercises.svg'
import { advagymGreen } from 'app/protected/roles/util/colors'
import i18next from 'i18next'
import { homePaths } from 'app/protected/common/constants/routes'
import { useTranslation } from 'react-i18next'

function SidebarMenu(props) {
  const { t } = useTranslation()

  const { location, history, closeMenu } = props

  const isExactPath = (item) => {
    const { pathname } = location
    return (
      pathname &&
      item.path &&
      pathname.replace(/\//g, '') == item.path.replace(/\//g, '')
    )
  }

  const goToPath = path => {
    history.push(path)
    closeMenu()
  }

  const menuItems = [
    {
      label: t('home'),
      path: homePaths.ME(),
      icon: <Icon name="home" style={{ fontSize: '20px' }} />,
    },
    {
      label: 'Divider',
    },
    {
      label: t('my_clients'),
      path: homePaths.CLIENTS(),
      icon: <Icon name="users" style={{ fontSize: '18px' }} />,
    },
    {
      label: t('my_programs'),
      path: homePaths.WORKOUTS(),
      icon: (
        <Image
          src={dumbellSvg}
          style={{ display: 'inline-block', width: '20px' }}
        />
      ),
    },
    {
      label: t('my_exercises'),
      path: homePaths.EXERCISES(),
      icon: (
        <Image
          src={excercisesSvg}
          style={{ display: 'inline-block', width: '20px' }}
        />
      ),
    },
  ]

  const menuItemStyles = item => {
    let styles
    if (isExactPath(item)) {
      styles = {
        color: advagymGreen,
        fontWeight: 'bold',
      }
    } else {
      styles = {
        color: '#000',
      }
    }

    return {
      paddingLeft: '22px',
      ...styles,
    }
  }

  return (
    <Menu secondary vertical style={{ width: '100%', margin: '14px 0 0' }}>
      {menuItems.map((item, key) => (
        <React.Fragment key={key}>
          {item.label != 'Divider' ? (
            <Menu.Item
              style={menuItemStyles(item)}
              onClick={() => goToPath(item.path)}
            >
              <div>
                {item.icon}
                <span style={{ paddingLeft: '7px' }}>{item.label}</span>
              </div>
            </Menu.Item>
          ) : (
            <div style={{ padding: '0 14px' }}>
              <Divider />
            </div>
          )}
        </React.Fragment>
      ))}
    </Menu>
  )

}

SidebarMenu.propTypes = {
  location: PropTypes.object.isRequired,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      address: PropTypes.string,
      city: PropTypes.string,
    })
  ).isRequired,
  closeMenu: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  const { uniqueSites } = state.userReducer

  return {
    sites: uniqueSites,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeMenu: () => {
      dispatch(menuAction.close())
    },
    clearActionFooter: () => {
      dispatch(footer.clear())
    }
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SidebarMenu)
)
