import _isPlainObject from 'lodash/isPlainObject'
import _isArray from 'lodash/isArray'
import _set from 'lodash/set'
import _has from 'lodash/has'
import _get from 'lodash/get'

// One level of stripping ['machines', 'observers', 'owner']
// NOT ['permissions.site']
// Mutates data.
function strip(key, data) {
  if (_isPlainObject(data[key])) {
    if (data[key]._id) {
      data[key] = data[key]._id
    }
  } else if (_isArray(data[key])) {
    data[key] = data[key].map(item => {
      return (_isPlainObject(item) && item._id) ? item._id : item
    })
  }
}

export function stripToIdOnly(keys, data) {
  keys.forEach(key => strip(key, data))
}
