import { myClientMessages as types } from '../actions/actiontypes'

const addIfNewMessages = (currentMessages, newMessages) => {
  const isNewMessages = newMessages.filter(m => !currentMessages.find(c => c._id === m._id))
  return isNewMessages.concat(currentMessages)
}

const hasNewMessages = (currentMessages, newMessages) => {
  const isNewMessages = newMessages.filter(m => !currentMessages.find(c => c._id === m._id))
  return isNewMessages.length > 0
}

const addNewMessage = (message, state) => {
  const messageAlreadyExist = state.messages.find(m => m._id === message._id)
  if (!messageAlreadyExist) {
    return [...state.messages, message]
  } else {
    return state.messages
  }
}

const initState = {
  ongoing: 'nothing',
  getMoreOngoing: false,
  messages: [],
  moreMessagesToGet: true,
  newMessage: null,
  error: null,
}

export function clientMessagesReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return { ...initState }

    case types.GET_MESSAGES:
      return {
        ...state,
        ongoing: 'fetching',
      }
    case types.GET_MESSAGES_RESPONSE:
      return {
        ...state,
        moreMessagesToGet: action.messages.length >= action.limit,
        messages: action.messages,
        ongoing: 'nothing',
      }
    case types.GET_MESSAGES_FAIL:
      return {
        ...state,
        ongoing: 'error',
        error: action,
      }

    case types.GET_MORE_MESSAGES:
      return {
        ...state,
        getMoreOngoing: true,
      }
    case types.GET_MORE_MESSAGES_RESPONSE:
      return {
        ...state,
        moreMessagesToGet: action.messages.length >= 25,
        messages: state.messages.concat(action.messages),
        getMoreOngoing: false,
      }
    case types.GET_MORE_MESSAGES_FAIL:
      return {
        ...state,
        moreMessagesToGet: false,
        getMoreOngoing: false,
        error: action,
      }

    case types.SEND_MESSAGE:
      return {
        ...state,
        ongoing: 'sending',
        newMessage: action.message,
        error: null,
      }
    case types.SEND_MESSAGE_RESPONSE:
      return {
        ...state,
        newMessage: null,
        messages: addNewMessage(action.message, state),
        ongoing: 'nothing',
      }
    case types.SEND_MESSAGE_FAIL:
      return {
        ...state,
        ongoing: 'error',
        error: action,
      }

    case types.REMOVE_MESSAGE:
      return {
        ...state,
        ongoing: 'removing',
      }
    case types.REMOVE_MESSAGE_RESPONSE:
      return {
        ...state,
        messages: state.messages.filter((m) => m._id !== action.message._id),
        ongoing: 'nothing',
      }
    case types.REMOVE_MESSAGE_FAIL:
      return {
        ...state,
        ongoing: 'error',
        error: action,
      }

    case types.CLEAR:
      return {
        ...state,
        ongoing: 'nothing',
      }

    case types.GET_LIVE_MESSAGES_RESPONSE:
      if (hasNewMessages(state.messages, action.messages)) {
        return {
          ...state,
          messages: addIfNewMessages(state.messages, action.messages),
        }
      } else {
        return {
          ...state,
        }
      }

    default:
      return state
  }
}
