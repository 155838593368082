import React from 'react'
import PropTypes from 'prop-types'
import JoinForm from '../container/JoinForm'
import Success from 'app/public/Success'
import { Header } from 'semantic-ui-react'
import { homePaths } from 'app/protected/common/constants/routes'
import i18next from 'i18next'
import LoadingMessage from 'app/common/loading-message/component/LoadingMessage'
import { Redirect } from 'react-router-dom'
import PageTop from 'app/protected/roles/common/page-top/PageTop'

export default function Join(props) {
  const { showSuccess, hideSuccess, history, isAuthorized, ongoing } = props

  const onCloseSuccess = () => {
    hideSuccess()
    history.push(homePaths.SIGNIN())
  }

  const successProps = {
    onClose: onCloseSuccess,
    show: showSuccess,
  }

  if (isAuthorized) {
    return (
      <React.Fragment>
        <Redirect to={homePaths.ME()} />
      </React.Fragment>
    )
  }

  if (ongoing) {
    return (<LoadingMessage text={i18next.t('fetching') + "..."} />)
  }

  return (
    <>
      <PageTop />
      <div style={{ padding: "1rem" }}>
        <Header as="h1" icon textAlign="center" htmlFor="email">
          {i18next.t('create_account')}
        </Header>

        <div style={{ marginTop: "3rem" }}>
          <JoinForm />
        </div>

        <Success
          {...successProps}
          title={i18next.t('welcome_to_advagym') + "!"}
          content={<>
            <Header>{i18next.t('verify_email_header')}!</Header>
            <p>{i18next.t('verify_email_text')}</p>
            <p>{i18next.t('verify_email_info')}</p>
          </>}
          actionText={i18next.t('take_me_to_login')}
        />
      </div>
    </>
  )

}

Join.propTypes = {
  showSuccess: PropTypes.bool.isRequired,
  hideSuccess: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}