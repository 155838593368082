export function feedback(name, userEmail, lang, device, date, text,) {
  return {
    "blocks": [
      {
        "type": "divider"
      },
      {
        "type": "header",
        "text": {
          "type": "plain_text",
          "text": `:speech_balloon: Feedback from ${name} :bulb:`,
          "emoji": true
        }
      },
      {
        "type": "section",
        "text": {
          "type": "mrkdwn",
          "text": `${text}`
        }
      },
      {
        "type": "context",
        "elements": [
          {
            "type": "plain_text",
            "text": `Date: ${date}`,
            "emoji": true
          }
        ]
      },
      {
        "type": "section",
        "fields": [
          {
            "type": "mrkdwn",
            "text": "*User:*\n`" + `${userEmail ? userEmail : 'none'}` + "`"
          },
          {
            "type": "mrkdwn",
            "text": `*Language:*\n${lang}`
          },
        ]
      },
      {
        "type": "divider"
      },
      {
        "type": "context",
        "elements": [
          {
            "type": "plain_text",
            "text": `Device:`,
            "emoji": true
          },
          {
            "type": "plain_text",
            "text": `${device}`,
            "emoji": true
          }
        ]
      },
    ]
  }
}

export function feedbackWithPic(name, userEmail, lang, device, date, text, picUrl) {
  return {
    "blocks": [
      {
        "type": "divider"
      },
      {
        "type": "header",
        "text": {
          "type": "plain_text",
          "text": `:speech_balloon: Feedback from ${name} :bulb:`,
          "emoji": true
        }
      },
      {
        "type": "section",
        "text": {
          "type": "mrkdwn",
          "text": `${text}`
        }
      },
      {
        "type": "image",
        "title": {
          "type": "plain_text",
          "text": "Attatched pic",
          "emoji": true
        },
        "image_url": `${picUrl}`,
        "alt_text": "marg"
      },
      {
        "type": "context",
        "elements": [
          {
            "type": "plain_text",
            "text": `Date: ${date}`,
            "emoji": true
          }
        ]
      },
      {
        "type": "section",
        "fields": [
          {
            "type": "mrkdwn",
            "text": "*User:*\n`" + `${userEmail ? userEmail : 'none'}` + "`"
          },
          {
            "type": "mrkdwn",
            "text": `*Language:*\n${lang}`
          },
        ]
      },
      {
        "type": "divider"
      },
      {
        "type": "context",
        "elements": [
          {
            "type": "plain_text",
            "text": `Device:`,
            "emoji": true
          },
          {
            "type": "plain_text",
            "text": `${device}`,
            "emoji": true
          }
        ]
      },
    ]
  }
}

export function crashFeedbackWithPic(name, userEmail, lang, device, date, text, picUrl) {
  return {
    "blocks": [
      {
        "type": "divider"
      },
      {
        "type": "header",
        "text": {
          "type": "plain_text",
          "text": `:speech_balloon: Crash feedback from ${name} :boom:`,
          "emoji": true
        }
      },
      {
        "type": "section",
        "text": {
          "type": "mrkdwn",
          "text": `${text}`
        }
      },
      {
        "type": "image",
        "title": {
          "type": "plain_text",
          "text": "Attatched pic",
          "emoji": true
        },
        "image_url": `${picUrl}`,
        "alt_text": "marg"
      },
      {
        "type": "context",
        "elements": [
          {
            "type": "plain_text",
            "text": `Date: ${date}`,
            "emoji": true
          }
        ]
      },
      {
        "type": "section",
        "fields": [
          {
            "type": "mrkdwn",
            "text": "*User:*\n`" + `${userEmail ? userEmail : 'none'}` + "`"
          },
          {
            "type": "mrkdwn",
            "text": `*Language:*\n${lang}`
          },
        ]
      },
      {
        "type": "divider"
      },
      {
        "type": "context",
        "elements": [
          {
            "type": "plain_text",
            "text": `Device:`,
            "emoji": true
          },
          {
            "type": "plain_text",
            "text": `${device}`,
            "emoji": true
          }
        ]
      },
    ]
  }
}

export function crashFeedback(name, userEmail, lang, device, date, text) {
  return {
    "blocks": [
      {
        "type": "divider"
      },
      {
        "type": "header",
        "text": {
          "type": "plain_text",
          "text": `:speech_balloon: Crash feedback from ${name} :boom:`,
          "emoji": true
        }
      },
      {
        "type": "section",
        "text": {
          "type": "mrkdwn",
          "text": `${text}`
        }
      },
      {
        "type": "context",
        "elements": [
          {
            "type": "plain_text",
            "text": `Date: ${date}`,
            "emoji": true
          }
        ]
      },
      {
        "type": "section",
        "fields": [
          {
            "type": "mrkdwn",
            "text": "*User:*\n`" + `${userEmail ? userEmail : 'none'}` + "`"
          },
          {
            "type": "mrkdwn",
            "text": `*Language:*\n${lang}`
          },
        ]
      },
      {
        "type": "divider"
      },
      {
        "type": "context",
        "elements": [
          {
            "type": "plain_text",
            "text": `Device:`,
            "emoji": true
          },
          {
            "type": "plain_text",
            "text": `${device}`,
            "emoji": true
          }
        ]
      },
    ]
  }
}

export function crash(date, server, location, user, lang, error, errorAt, device) {
  return {
    "blocks": [
      {
        "type": "divider"
      },
      {
        "type": "header",
        "text": {
          "type": "plain_text",
          "text": ":boom: A new crash has occurred :boom:",
          "emoji": true
        }
      },
      {
        "type": "context",
        "elements": [
          {
            "type": "plain_text",
            "text": `Date: ${date}`,
            "emoji": true
          }
        ]
      },
      {
        "type": "section",
        "fields": [
          {
            "type": "mrkdwn",
            "text": `*Server:*\n${server}`
          },
          {
            "type": "mrkdwn",
            "text": "*Location:*\n`" + `${location}` + "`"
          },
          {
            "type": "mrkdwn",
            "text": "*User:*\n`" + `${user}` + "`"
          },
          {
            "type": "mrkdwn",
            "text": `*Language:*\n${lang}`
          },
          {
            "type": "mrkdwn",
            "text": `*Message:*\n${error.message}`
          },
          {
            "type": "mrkdwn",
            "text": `*Error at:*\n${errorAt}`
          }
        ]
      },
      {
        "type": "divider"
      },
      {
        "type": "context",
        "elements": [
          {
            "type": "plain_text",
            "text": `Device:`,
            "emoji": true
          },
          {
            "type": "plain_text",
            "text": `${device}`,
            "emoji": true
          }
        ]
      },
      {
        "type": "section",
        "text": {
          "type": "mrkdwn",
          "text": "*React with :eyes: for read, :white_check_mark: for fixed*"
        }
      }
    ]
  }
}