import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import { Form, TextArea, Grid, Button, Icon } from 'semantic-ui-react'
import CancelButton from 'app/protected/common/CancelButton'
import { lineLightGrey } from 'app/protected/roles/util/colors'
import { useDropzone } from 'react-dropzone'

const acceptedTypes = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/pdf',
  'application/pdf',
]

const EditMessage = forwardRef((props, ref) => {
  const { initialMessage, placeholder, title, limit, allowMedia, rows, buttonType } = props
  const messageInput = useRef()

  useEffect(() => {
    if (messageInput.current) {
      messageInput.current.focus()
      //messageInput.current.click()
    }
  }, [])

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      message: initialMessage ? initialMessage : "",
    }))
    setFiles([])
  }, [initialMessage])

  const initialState = { message: "" }
  const [{ message }, setState] = useState(initialState)

  useImperativeHandle(
    ref,
    () => ({
      getData() {
        if (files.length > 0) {
          const newMedia = files[0]
          return { message, newMedia: [newMedia] }
        } else {
          return { message }
        }
      }
    }),
  )

  const onInputChange = (event, data) => {
    const name = data.name
    let value = data.type === 'checkbox' ? data.checked : data.value

    setState(prevState => ({
      ...prevState,
      [name]: limit ? String(value).substring(0, limit + 1) : value,
    }))
  }

  const [files, setFiles] = useState([])

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    style: { borderStyle: 'none', cursor: 'pointer' },
    inputProps: { 'aria-label': 'File input' },
    onDrop: onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
      'image/pdf': ['.pdf'],
    },
    multiple: false,
    open,
  })

  const fileInputRef = React.createRef()

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length === 1) {
      if (files)
        files.forEach((file) => window.URL.revokeObjectURL(file.preview))
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
    }
  }

  const fileChange = (e) => {
    const newFile = e.target.files[0] ? e.target.files[0] : null
    if (newFile) {
      console.log(newFile.typew)
      if (acceptedTypes.some(a => a === newFile.type)) {
        Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        })
        setFiles([newFile])
      }
    }
  }

  const removeAttachment = () => {
    setFiles([])
  }



  return (<>
    <Form>
      <Form.Group widths="equal">
        <Form.Field>
          <label style={buttonType === "square" ? { paddingBottom: '10px' } : null}>
            {title ? title : i18next.t('message')}
          </label>
          <TextArea
            ref={messageInput}
            aria-label="Description"
            name="message"
            value={message}
            placeholder={placeholder ? placeholder : i18next.t('type_a_message') + '...'}
            onChange={onInputChange}
            rows={rows ? rows : 3}
          />
        </Form.Field>
      </Form.Group>
    </Form>
    {files.length > 0 && <div style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '14px' }}>
      <div
        style={{
          position: 'relative',
          left: '138px',
          top: '2px',
          zIndex: '2',
          height: '0',
          display: 'flex',
        }}
      >
        <CancelButton onClick={removeAttachment} />
      </div>
      <div
        style={{
          display: 'flex',
          border: `1px solid ${lineLightGrey}`,
          //backgroundImage: `url(${previewFile.preview})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          borderRadius: '15px',
          height: '35px',
          width: '140px',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <div style={{ width: '100px', overflow: 'hidden', textOverflow: 'ellipsis', position: 'absolute', left: '6px' }}>
          {files[0].name}
        </div>
      </div>
    </div>}
    {(allowMedia && limit) && <Grid>
      <Grid.Row>
        <Grid.Column
          textAlign="left"
          verticalAlign="middle"
          computer={6}
          tablet={6}
          mobile={6}
        >
          <span
            style={{
              color: `${message.length < limit + 1 ? 'green' : 'red'
                }`,
            }}
          >{`${message.length}/${limit}`}</span>
        </Grid.Column>

        <Grid.Column
          textAlign="right"
          verticalAlign="middle"
          computer={10}
          tablet={10}
          mobile={10}
        >
          <input
            {...getInputProps()}
            ref={fileInputRef}
            type="file"
            hidden
            onChange={(e) => fileChange(e)}
          />
          {buttonType === "square" ?
            <Button style={{ borderRadius: "4px" }} icon onClick={() => fileInputRef.current.click()}>
              <Icon name="attach" />
              {i18next.t('attach')}
            </Button>
            : <Button size="small" compact icon onClick={() => fileInputRef.current.click()}>
              <Icon name="attach" />
              {i18next.t('attach')}
            </Button>
          }

        </Grid.Column>
      </Grid.Row>
    </Grid>}
  </>)
})

export default EditMessage

EditMessage.propTypes = {
  initialMessage: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  limit: PropTypes.number,
  rows: PropTypes.number,
  buttonType: PropTypes.oneOf(['square']),
}