import { combineReducers } from 'redux'
import * as actionFooterReducer from './actionFooterReducer'
import * as advagymStatisticsReducer from './advagymStatisticsReducer'
import * as categoryReducer from './categoryReducer'
import * as clientGroupMessagesReducer from './clientGroupMessagesReducer'
import * as clientMessagesReducer from './clientMessagesReducer'
import * as clientReducer from './clientReducer'
import * as clientsReducer from './clientsReducer'
import * as clientWorkoutHistoryReducer from './clientWorkoutHistoryReducer'
import * as collectionReducer from './collectionReducer'
import * as exerciseReducer from './exerciseReducer'
import * as galleryReducer from './galleryReducer'
import * as globalCategoriesReducer from './globalCategoriesReducer'
import * as globalCollectionsReducer from './globalCollectionsReducer'
import * as globalExercisesReducer from './globalExercisesReducer'
import * as globalProgramsReducer from './globalProgramsReducer'
import * as gymBannerStatusReducer from './gymBannerStatusReducer'
import * as gymContentStatisticsReducer from './gymContentStatisticsReducer'
import * as gymHourActivityReducer from './gymHourActivityReducer'
import * as gymInvitationReducer from './gymInvitationReducer'
import * as gymMemberActivityReducer from './gymMemberActivityReducer'
import * as gymMemberStatisticsReducer from './gymMemberStatisticsReducer'
import * as gymMessagesReducer from './gymMessagesReducer'
import * as gymReducer from './gymReducer'
import * as gymStatusReducer from './gymStatusReducer'
import * as gymUtilizationReducer from './gymUtilizationReducer'
import * as importCSVReducer from './importCSVReducer'
import * as joinReducer from './joinReducer'
import * as machineReducer from './machineReducer'
import * as manageGymPermissionsReducer from './manageGymPermissionsReducer'
import * as manageMemberProgramsReducer from './manageMemberProgramsReducer'
import * as manageMemberReducer from './manageMemberReducer'
import * as manageMembersReducer from './manageMembersReducer'
import * as managePermissionsReducer from './managePermissionsReducer'
import * as manageSuPermissionsReducer from './manageSuPermissionsReducer'
import * as memberWorkoutHistoryReducer from './memberWorkoutHistoryReducer'
import * as menuReducer from './menuReducer'
import * as myClientGroupReducer from './clientGroupReducer'
import * as myClientGroupsReducer from './clientGroupsReducer'
import * as myExercisesReducer from './myExercisesReducer'
import * as myGymCategoriesReducer from './myGymCategoriesReducer'
import * as myGymExerciseReducer from './myGymExerciseReducer'
import * as myGymExercisesReducer from './myGymExercisesReducer'
import * as myStackReducer from './myStackReducer'
import * as myStacksReducer from './myStacksReducer'
import * as myGymInfoReducer from './myGymInfoReducer'
import * as myGymMachineReducer from './myGymMachineReducer'
import * as myGymMachinesReducer from './myGymMachinesReducer'
import * as myGymProgramCollectionReducer from './myGymProgramCollectionReducer'
import * as myGymProgramCollectionsReducer from './myGymProgramCollectionsReducer'
import * as myGymProgramReducer from './myGymProgramReducer'
import * as myGymProgramsReducer from './myGymProgramsReducer'
import * as gymStaffReducer from './gymStaffReducer'
import * as myGymZoneReducer from './myGymZoneReducer'
import * as myGymZonesReducer from './myGymZonesReducer'
import * as myPrivateExerciseReducer from './myPrivateExerciseReducer'
import * as myPrivateExercisesReducer from './myPrivateExercisesReducer'
import * as myProgramReducer from './myProgramReducer'
import * as myProgramShareReducer from './myProgramShareReducer'
import * as myProgramsReducer from './myProgramsReducer'
import * as myWorkoutReducer from './myWorkoutReducer'
import * as observerReducer from './observerReducer'
import * as partnerGymReducer from './partnerGymReducer'
import * as partnerReducer from './partnerReducer'
import * as partnersReducer from './partnersReducer'
import * as passwordChangeReducer from './passwordChangeReducer'
import * as passwordForgottenReducer from './passwordForgottenReducer'
import * as profileSettingsReducer from './profileSettingsReducer'
import * as programReducer from './programReducer'
import * as signInReducer from './signInReducer'
import * as signOutReducer from './signOutReducer'
import * as sitesReducer from './sitesReducer'
import * as stackCopyReducer from './stackCopyReducer'
import * as tabsReducer from './tabsReducer'
import * as translationToolsReducer from './translationToolsReducer'
import * as userDashboardStatisticsReducer from './userDashboardStatisticsReducer'
import * as userReducer from './userReducer'
import * as usersReducer from './usersReducer'

export default combineReducers(
  Object.assign(
    {},
    actionFooterReducer,
    advagymStatisticsReducer,
    categoryReducer,
    clientGroupMessagesReducer,
    clientMessagesReducer,
    clientReducer,
    clientsReducer,
    clientWorkoutHistoryReducer,
    collectionReducer,
    exerciseReducer,
    galleryReducer,
    globalCategoriesReducer,
    globalCollectionsReducer,
    globalExercisesReducer,
    globalProgramsReducer,
    gymBannerStatusReducer,
    gymContentStatisticsReducer,
    gymHourActivityReducer,
    gymInvitationReducer,
    gymMemberActivityReducer,
    gymMemberStatisticsReducer,
    gymMessagesReducer,
    gymReducer,
    gymStatusReducer,
    gymUtilizationReducer,
    importCSVReducer,
    joinReducer,
    machineReducer,
    manageGymPermissionsReducer,
    manageMemberProgramsReducer,
    manageMemberReducer,
    manageMembersReducer,
    managePermissionsReducer,
    manageSuPermissionsReducer,
    memberWorkoutHistoryReducer,
    menuReducer,
    myClientGroupReducer,
    myClientGroupsReducer,
    myExercisesReducer,
    myGymCategoriesReducer,
    myGymExerciseReducer,
    myGymExercisesReducer,
    myStackReducer,
    myStacksReducer,
    myGymInfoReducer,
    myGymMachineReducer,
    myGymMachinesReducer,
    myGymProgramCollectionReducer,
    myGymProgramCollectionsReducer,
    myGymProgramReducer,
    myGymProgramsReducer,
    gymStaffReducer,
    myGymZoneReducer,
    myGymZonesReducer,
    myPrivateExerciseReducer,
    myPrivateExercisesReducer,
    myProgramReducer,
    myProgramShareReducer,
    myProgramsReducer,
    myWorkoutReducer,
    observerReducer,
    partnerGymReducer,
    partnerReducer,
    partnersReducer,
    passwordChangeReducer,
    passwordForgottenReducer,
    profileSettingsReducer,
    programReducer,
    signInReducer,
    signOutReducer,
    sitesReducer,
    stackCopyReducer,
    tabsReducer,
    translationToolsReducer,
    userDashboardStatisticsReducer,
    userReducer,
    usersReducer
  )
)