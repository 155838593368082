/*global process*/
import '@babel/polyfill'
import React, { useCallback, useEffect } from 'react'
import ReactDOM from 'react-dom'
import 'style.scss'

// match, location, history is passed through props to all Route
import { Provider } from 'react-redux'
import store from 'redux/store/store'
import {
  BrowserRouter as Router, Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import Index from 'app/root/container/Root'

import tolgee from 'i18n/i18n'
import { I18nextProvider } from 'react-i18next'
import { TolgeeProvider } from "@tolgee/react"

const baseName = process.env.BASENAME
import CustomPrompt from 'app/root/custom-prompt/CustomPrompt'
// Remove import below when returning web tool functionalities
import ClosedBanner from 'app/ClosedBanner'
import ReloadCheck from 'app/common/reload-check/ReloadCheck'
const commitHash = process.env.COMMIT_ID.trim()

document.getElementById('loadingsplash').style.display = 'none'

// Remove this function and clean up imports when returning web tool functionalities
const CleanupRedirect = () => {
  useEffect(() => {
    const path = window.location.pathname
    if (path !== '/') {
      window.history.replaceState({}, document.title, '/')
    }
  }, [])
  return <Redirect to="/" />
}

function App() {
  const userConfirmation = useCallback((message, callback) => {
    const node = document.getElementById('custom-prompt')

    const cleanUp = (answer) => {
      ReactDOM.unmountComponentAtNode(node)
      callback(answer)
    }

    ReactDOM.render(<CustomPrompt message={message} cleanUp={cleanUp} />, node)
  }, [])

  return (
    <Provider store={store}>
      <Router basename={baseName} getUserConfirmation={userConfirmation}>
        {/* <I18nextProvider i18n={i18n}> */}
        <TolgeeProvider
          tolgee={tolgee}
          fallback="Loading..." // loading fallback
        >
          {/* Remove section below to return the web tool funcitonaliteis */}
          {/* <Switch>
            <Route exact path="/">
              <ClosedBanner />
              <ReloadCheck commitHash={commitHash} />
            </Route>
            <Route path="/*" component={CleanupRedirect} />
          </Switch> */}
          {/* And uncomment this section as well to return the web tool funcitonaliteis */}
          <Route component={Index} />
          {/* </I18nextProvider> */}
        </TolgeeProvider>
      </Router>
    </Provider >
  )
}

export default App
