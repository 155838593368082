import { myPrograms as types } from '../actions/actiontypes'

const getProgramsOptions = programs =>
  programs.map(program => ({
    _id: program._id,
    key: program._id,
    text: program.name,
    value: program._id,
  }))

const initState = {
  ongoing: false,
  programs: [],
  programsOptions: [],
  error: undefined,
}

export function myProgramsReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return {
        ...state,
        initState
      }

    case types.GET:
      return {
        ...state,
        ongoing: true
      }
    case types.RESPONSE:
      return {
        ...state,
        ongoing: false,
        programs: action.programs,
        programsOptions: getProgramsOptions(action.programs),
      }
    case types.GET_FAIL:
      return {
        ...state,
        ongoing: false,
        error: action,
      }
    case types.FILTER_OUT_WORKOUT:
      return {
        ...state,
        programs: state.programs.filter(p => p._id !== action.program),
        programsOptions: state.programsOptions.filter(p => p._id !== action.program),
      }

    default:
      return state
  }
}
