import { managePermissions as types } from '../actions/actiontypes'

const initReducerState = {}

const initGymState = {}

const initUsersState = {
  getUsersOngoing: false,
  addPermissionOngoing: false,
  removePermissionOngoing: false,
  users: [],
  getUserError: false,
  addPermissionError: false,
  removePermissionError: false,
}

// // Example
// const state = {
//   gym1: {
//     coach: {
//       getUsersOngoing: false,
//       addPermissionOngoing: true,
//       removePermissionOngoing: false,
//       users: [],
//       getUserError: undefined,
//       addPermissionError: undefined,
//       removePermissionError: undefined,
//     },
//     admin: {
//       getUsersOngoing: false,
//       addPermissionOngoing: false,
//       removePermissionOngoing: false,
//       users: [],
//       getUserError: undefined,
//       addPermissionError: "failed to add permission",
//       removePermissionError: undefined,
//     }
//   },
//   gym2: {
//     admin: {
//       getUsersOngoing: true,
//       addPermissionOngoing: false,
//       removePermissionOngoing: true,
//       users: [],
//       getUserError: undefined,
//       addPermissionError: undefined,
//       removePermissionError: undefined,
//     }
//   }
// }

const manageUsers = (state = initUsersState, action) => {
  switch (action.type) {
    case types.GET_USERS:
      return {
        ...state,
        getUsersOngoing: true,
      }
    case types.ADD_PERMISSION:
      return {
        ...state,
        addPermissionOngoing: true,
      }
    case types.REMOVE_PERMISSION:
      return {
        ...state,
        removePermissionOngoing: true,
      }
    case types.GET_USERS_RESPONSE:
      return {
        ...state,
        getUsersOngoing: false,
        users: action.users,
      }
    case types.ADD_PERMISSION_RESPONSE:
      return {
        ...state,
        addPermissionOngoing: false,
      }
    case types.REMOVE_PERMISSION_RESPONSE:
      return {
        ...state,
        removePermissionOngoing: false,
      }
    case types.GET_USERS_FAILED:
      return {
        ...state,
        getUsersOngoing: false,
        getUserError: true,
      }
    case types.ADD_PERMISSION_FAILED:
      return {
        ...state,
        addPermissionOngoing: false,
        addPermissionError: true,
      }
    case types.REMOVE_PERMISSION_FAILED:
      return {
        ...state,
        removePermissionOngoing: false,
        removePermissionError: true,
      }
    case types.CLEAR_ERRORS:
      return {
        ...state,
        getUserError: false,
        addPermissionError: false,
        removePermissionError: false,
      }
    default:
      return state
  }
}

const manageGymPermission = (state = initGymState, action) => {
  switch (action.type) {
    case types.GET_USERS:
    case types.ADD_PERMISSION:
    case types.REMOVE_PERMISSION:
    case types.GET_USERS_RESPONSE:
    case types.REMOVE_PERMISSION_RESPONSE:
    case types.ADD_PERMISSION_RESPONSE:
    case types.GET_USERS_FAILED:
    case types.ADD_PERMISSION_FAILED:
    case types.REMOVE_PERMISSION_FAILED:
    case types.CLEAR_ERRORS:
      return {
        ...state,
        [action.permission.role]: manageUsers(
          state[action.permission.role],
          action
        ),
      }
    default:
      return state
  }
}

export function managePermissionsReducer(state = initReducerState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return {}
    case types.GET_USERS:
    case types.ADD_PERMISSION:
    case types.REMOVE_PERMISSION:
    case types.GET_USERS_RESPONSE:
    case types.REMOVE_PERMISSION_RESPONSE:
    case types.ADD_PERMISSION_RESPONSE:
    case types.GET_USERS_FAILED:
    case types.ADD_PERMISSION_FAILED:
    case types.REMOVE_PERMISSION_FAILED:
    case types.CLEAR_ERRORS:
      return {
        ...state,
        [action.permission.site]: manageGymPermission(
          state[action.permission.site],
          action
        ),
      }
    default:
      return state
  }
}
