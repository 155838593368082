import { manageGymPermissions as types } from '../actions/actiontypes'

const refineUser = (user, permission) => {
  return {
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    name: user.name,
    media: user.media,
    site: permission.site,
    clients: 0,
    members: 0,
    status: user.status,
    updatedAt: user.updatedAt,
    _id: user._id,
    permission: permission,
  }
}

const initState = {
  users: [],
  ongoing: 'nothing',
  currentUser: null,
  error: null,
}

export function manageGymPermissionsReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return { ...initState }

    case types.GET:
      return {
        ...state,
        ongoing: 'fetching',
        error: null,
      }
    case types.GET_RESPONSE:
      return {
        ...state,
        users: action.users,
        ongoing: 'nothing',
      }
    case types.GET_FAIL:
      return {
        ...state,
        ongoing: 'nothing',
        error: action,
      }

    case types.REMOVE:
      return {
        ...state,
        ongoing: 'removing',
        currentUser: action.email,
      }
    case types.REMOVE_RESPONSE:
      return {
        ...state,
        users: state.users.filter((u) => u.email !== action.email),
        currentUser: null,
        ongoing: 'nothing',
      }
    case types.REMOVE_FAIL:
      return {
        ...state,
        currentUser: null,
        ongoing: 'nothing',
        error: action,
      }

    case types.ADD:
      return {
        ...state,
        ongoing: 'adding',
      }
    case types.ADD_RESPONSE:
      return {
        ...state,
        users: [...state.users, refineUser(action.user, action.permission)],
        ongoing: 'nothing',
      }
    case types.ADD_FAIL:
      return {
        ...state,
        ongoing: 'nothing',
        error: action,
      }

    case types.UPDATE:
      return {
        ...state,
        ongoing: 'changing',
        currentUser: action.email,
      }
    case types.UPDATE_RESPONSE:
      return {
        ...state,
        users: state.users.map((u) =>
          u._id === action.user._id ? action.user : u
        ),
        ongoing: 'nothing',
        currentUser: null,
      }
    case types.UPDATE_FAIL:
      return {
        ...state,
        ongoing: 'nothing',
        error: action,
        currentUser: null,
      }

    case types.CLEAR:
      return {
        ...state,
        ongoing: 'nothing',
        error: null,
      }

    default:
      return state
  }
}
