import {connect} from 'react-redux'
import PasswordForgottenForm from '../component/PasswordForgottenForm'
import {passwordForgotten as action} from 'redux/actions/actions.js'
import {passwordForgotten as thunk} from 'redux/actions/thunks.js'
import withReduxForm from 'app/common/hoc/redux-form/ReduxForm'

const mapStateToProps = (state) => {
  return {
    ...state.passwordForgottenReducer
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default withReduxForm(mapStateToProps, mapDispatchToProps, action, thunk)(PasswordForgottenForm)
