import { myGymInfo as types } from '../actions/actiontypes'

const initState = {
  ongoing: 'nothing',
  gym: {},
  error: null,
  beaconMessage: null,
}

export function myGymInfoReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return { ...initState }

    case types.GET:
      return {
        ...state,
        ongoing: 'fetching',
      }
    case types.GET_RESPONSE:
      return {
        ...state,
        gym: action.gym,
        ongoing: 'nothing',
      }
    case types.GET_FAIL:
      return {
        ...state,
        ongoing: 'error',
        error: action,
      }

    case types.SAVE:
      return {
        ...state,
        ongoing: 'saving',
      }
    case types.SAVE_RESPONSE:
      return {
        ...state,
        gym: state.gym.beaconMessage
          ? action.isBeacon ?
            action.gym
            : { ...action.gym, beaconMessage: state.gym.beaconMessage }
          : action.gym,
        ongoing: 'saved',
      }
    case types.SAVE_FAIL:
      return {
        ...state,
        ongoing: 'error',
        error: action,
      }

    case types.CLEAR:
      return {
        ...state,
        ongoing: 'nothing',
      }
    case types.GYM_LOGO_ERROR:
      return {
        ...state,
        error: action,
      }

    case types.SAVE_BEACON_MESSAGE:
      return {
        ...state,
        beaconMessage: action.message,
        ongoing: 'saving',
      }
    case types.SAVE_BEACON_MESSAGE_RESPONSE:
      return {
        ...state,
        gym: { ...state.gym, beaconMessage: action.message },
        beaconMessage: null,
        ongoing: 'nothing',
      }
    case types.SAVE_BEACON_MESSAGE_FAILED:
      return {
        ...state,
        beaconMessage: null,
        ongoing: 'nothing',
      }

    default:
      return state
  }
}
