import React from 'react'
import { Spring, config } from 'react-spring/renderprops.cjs.js'

export const PopInAnimation = props => (
  <React.Fragment>
    <Spring
      config={config.gentle}
      from={{ opacity: 0, x: 0 }}
      to={{ opacity: 1, x: 16 }}
    >
      {springprops => (
        <div
          style={{
            opacity: springprops.opacity,
            marginBottom: `${springprops.x}px`,
            willChange: 'margin-bottom, opacity',
          }}
        >
          {props.children}
        </div>
      )}
    </Spring>
  </React.Fragment>
)
export const PopInAnimationBTT = props => (
  <React.Fragment>
    <Spring
      config={config.gentle}
      from={{ opacity: 0, x: 0 }}
      to={{ opacity: 1, x: props.length }}
    >
      {springprops => (
        <div
          style={{
            opacity: springprops.opacity,
            marginTop: `${springprops.x}px`,
            willChange: 'margin-top, opacity',
          }}
        >
          {props.children}
        </div>
      )}
    </Spring>
  </React.Fragment>
)

export const PopInAnimationLTR = props => (
  <React.Fragment>
    <Spring
      config={config.gentle}
      from={{ opacity: 0, x: 0 }}
      to={{ opacity: 1, x: props.length }}
    >
      {springprops => (
        <div
          style={{
            opacity: springprops.opacity,
            marginLeft: `${springprops.x}px`,
            willChange: 'margin-bottom, opacity',
          }}
        >
          {props.children}
        </div>
      )}
    </Spring>
  </React.Fragment>
)

export const PopInAnimationRTL = props => (
  <React.Fragment>
    <Spring
      config={config.gentle}
      from={{ opacity: 0, x: 0 }}
      to={{ opacity: 1, x: props.length }}
    >
      {springprops => (
        <div
          style={{
            opacity: springprops.opacity,
            marginRight: `${springprops.x}px`,
            willChange: 'margin-bottom, opacity',
          }}
        >
          {props.children}
        </div>
      )}
    </Spring>
  </React.Fragment>
)
