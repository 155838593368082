import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const VideoWrapper = styled.div`
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;
    `
const IFrame = styled.iframe`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    `

export default function ViewYoutubeMedia(props) {
  const { youtubeTag } = props

  return (
    <React.Fragment>
      <VideoWrapper>
        <IFrame
          src={`https://www.youtube.com/embed/${youtubeTag}`}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
        />
      </VideoWrapper>
    </React.Fragment>
  )
}

ViewYoutubeMedia.propTypes = {
  youtubeTag: PropTypes.string.isRequired,
}