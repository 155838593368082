import { connect } from 'react-redux'
import Join from '../component/Join'
import { join as action } from 'redux/actions/actions.js'

const mapStateToProps = (state) => {
  const { showWelcomeScreen } = state.joinReducer
  const { status, ongoing } = state.userReducer
  return {
    showSuccess: showWelcomeScreen,
    isAuthorized: status == 'authorized',
    ongoing: status === "unauthorized" ? false : ongoing,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    hideSuccess: () => dispatch(action.hideWelcomeScreen())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Join)
