import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Message } from 'semantic-ui-react'
import { PopInAnimation } from 'app/common/animations/pop-in/PopInAnimation'


function TemporaryBanner(props) {
  const { header, text, icon } = props
  const initialState = {
    show: true,
  }
  const [{ show }, setState] = useState(initialState)

  const closeShow = () => {
    setState(prevState => ({ ...prevState, show: false }))
  }

  if (show) {
    return <PopInAnimation>
      <Message
        icon={icon}
        header={header}
        content={text}
        info
        onDismiss={() => closeShow()}
      />
    </PopInAnimation>

  } else {
    return (<></>)
  }

}


TemporaryBanner.propTypes = {
  header: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.string,
}

export default TemporaryBanner
