import { myWorkout as types } from '../actions/actiontypes'

const initState = {
  ongoing: 'nothing',
  workout: null,
  error: undefined,
}

export function myWorkoutReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return { ...initState }
    case types.GET:
      return {
        ...state,
        ongoing: 'fetching',
        workout: null,
        error: undefined,
      }
    case types.RESPONSE:
      return {
        ...state,
        ongoing: 'nothing',
        workout: action.workout,
      }
    case types.GET_FAIL:
      return { ...state, ongoing: 'nothing', error: action.error }
    case types.CLEAR:
      return {
        ...state,
        ongoing: 'nothing',
        workout: null,
        error: undefined,
      }
    default:
      return state
  }
}
