import { user as types } from '../actions/actiontypes'
import { collectRoles, getUniqueSites } from 'util/authorized'

const initState = {
  ongoing: false,
  data: {
    firstname: '',
    email: '',
    roles: [],
    sites: [],
    permissions: [],
    partnerPermissions: [],
    settings: [],
    webToSAccepted: false,
  },
  collectedRoles: [],
  uniqueSites: [],
  status: 'unknown',
  copySites: [],
  copyOngoingStatus: false,
  copySitesOngoing: [],
  lastAuthorization: null,
  contentLang: null,
  unreadSiteMessages: [],
}

const setContentlang = (settings, prevContentLang) => {
  if (prevContentLang) {
    return prevContentLang
  } else {
    const _language = settings.find(s => s.name === 'language')
    const userLanguage = _language ? _language.value : "en"
    return userLanguage
  }
}

const getSites = (permissions) =>
  getUniqueSites(
    permissions.filter((p) => p.role != 'member' && p.role != 'trial' && p.role != 'installer')
  )

const getSitesWithAdmin = (permissions) =>
  getUniqueSites(permissions.filter((p) => p.role == 'admin'))

const getRoles = (permissions) => collectRoles(permissions)

export const userReducer = (state = initState, action) => {
  switch (action.type) {
    case types.POSTED:
      return { ...state, ongoing: true }

    case types.AUTHORIZED:
      return {
        ...state,
        ongoing: false,
        data: action.data,
        collectedRoles: getRoles(action.data.permissions),
        uniqueSites: getSites(action.data.permissions),
        adminSites: getSitesWithAdmin(action.data.permissions),
        status: 'authorized',
        lastAuthorization: new Date(),
        contentLang: setContentlang(action.data.settings, state.contentLang)
      }

    case types.UNAUTHORIZED:
      return {
        ...state,
        status: 'unauthorized',
        ongoing: true,
      }

    case types.DEAUTHORIZE:
      return {
        ...state,
        status: 'deauthorized',
        lastAuthorization: null,
      }

    case types.SETTINGS_UNIT_SAVE:
    case types.SETTINGS_LANGUAGE_SAVE:
    case types.TERMS_SAVE:
    case types.WEB_SETTINGS_SAVE:
      return {
        ...state,
      }

    case types.SETTINGS_UNIT_RESPONSE:
    case types.SETTINGS_LANGUAGE_RESPONSE:
      return {
        ...state,
        data: { ...state.data, settings: action.settings },
      }
    case types.TERMS_RESPONSE:
      return {
        ...state,
        data: { ...state.data, webToSAccepted: action.accepted },
      }
    case types.WEB_SETTINGS_RESPONSE:
      return {
        ...state,
        data: { ...state.data, webSettings: action.settings },
      }
    case types.SETTINGS_UNIT_FAILURE:
    case types.SETTINGS_LANGUAGE_FAILURE:
    case types.TERMS_FAILURE:
    case types.WEB_SETTINGS_FAILURE:
      return {
        ...state,
      }
    case types.GET_COPY_TO_SITES_RESPONSE:
      return {
        ...state,
        copySitesOngoing: action.ongoing,
        copySites: action.sites,
      }

    case types.SET_CONTENT_LANG:
      return {
        ...state,
        contentLang: action.lang,
      }

    case types.RESET_CONTENT_LANG:
      return {
        ...state,
        contentLang: setContentlang(state.data.settings, null),
      }

    case types.GET_COPY_STATUS:
      return {
        ...state,
        copyOngoingStatus: action.status,
        copySitesOngoing: action.sites ? action.sites : [],
      }

    case types.GET_UNREAD_SITE_MESSAGES:
      return {
        ...state,
        unreadSiteMessages: action.unreadSiteMessages
      }

    default:
      return state
  }
}
