import {connect} from 'react-redux'
import PasswordChange from '../component/PasswordChange'
import {passwordChange as action} from 'redux/actions/actions.js'

const mapStateToProps = (state) => {
  const {showWelcomeScreen} = state.passwordChangeReducer
  return {
    showSuccess: showWelcomeScreen
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    hideSuccess: () => dispatch(action.hideWelcomeScreen())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChange)
