import { menu as types } from '../actions/actiontypes'

const initState = {
  isOpen: false,
}

export function menuReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return Object.assign({}, initState)
    case types.OPEN:
      return Object.assign({}, state, { isOpen: true })
    case types.CLOSE:
      return Object.assign({}, state, {
        isOpen: false,
      })
    case types.TOGGLE:
      return Object.assign({}, state, {
        isOpen: !state.isOpen,
      })

    default:
      return state
  }
}
