/*global process*/
import '@babel/polyfill'
require('./util/switch-react-tools.js')
import React from 'react'
import ReactDOM from 'react-dom'

document.getElementById('loadingsplash').style.display = 'none'

import App from './app/App'

ReactDOM.render(
  <App />,
  document.getElementById('root')
)
