import {programs as types} from '../actions/actiontypes'

const initState = {
  ongoing: false,
  programs: [],
  error: undefined
}

export function globalProgramsReducer(state = initState, action) {
  switch (action.type) {
    case "STORE_RESET":
      return Object.assign({}, initState)
    case types.GET:
      return Object.assign({}, state, {ongoing: state.programs.length > 0 ? false : true})
    case types.RESPONSE:
      return Object.assign({}, state, {
        ongoing: false,
        programs: action.programs
      })
    case types.GET_FAIL:
      return Object.assign({}, state, {ongoing: false, error: action})
    default:
      return state
  }
}
