import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import LoadingMessage from 'app/common/loading-message/component/LoadingMessage'
import { homePaths } from 'app/protected/common/constants/routes'

export default class Main extends React.PureComponent {
  render() {
    const { userIsSignedIn, loading } = this.props
    if (loading) {
      return (
        <React.Fragment>
          <LoadingMessage text="Authorizing..." />
        </React.Fragment>
      )
    }

    if (userIsSignedIn) {
      return (
        <React.Fragment>
          <Redirect to={homePaths.ME()} />
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Redirect to={homePaths.SIGNIN()} />
      </React.Fragment>
    )
  }
}

Main.defaultProps = {}

Main.propTypes = {
  userIsSignedIn: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
}
