import { gymMemberStatistics as types } from '../actions/actiontypes'
import { getToday, isValidDate } from 'util/util'
import moment from 'moment'

const whileEngine = (type, to, currentYear, currentInterval) => {
  switch (type) {
    case "year":
      return moment().set("year", currentInterval).year() <= moment(to).year()
    case "month":
      return moment(moment().set("year", currentYear).set("month", currentInterval - 1))
        .isSameOrBefore(moment(to), "month")
    case "week":
      return moment(moment().set("year", currentYear).set("week", currentInterval))
        .isSameOrBefore(moment(to), "week")
    case "day":
      return moment(moment().dayOfYear(currentInterval).set("year", currentYear))
        .isSameOrBefore(moment(to), "day")
  }
}

const refineData = (data, type, pickerType, from, to, increment) => {
  if (data.length == 0) return []

  let refinedData = []
  let currentInterval = 1
  let intervalLimit = 1
  let firstValuePassed = false

  switch (type) {
    case "year":
      if (moment(from).isSame(to, "year")) {
        currentInterval = moment(to).year()
        intervalLimit = moment(to).year()
      } else {
        currentInterval = moment(from).year()
        intervalLimit = moment(to).year()
      }
      break
    case "month":
      currentInterval = moment(from).month() + 1
      intervalLimit = 11
      break
    case "week":
      if (pickerType === "month" && moment(from).month() === 0) {
        currentInterval = 1
      } else {
        currentInterval = moment(from).week()
      }
      intervalLimit = moment(from).endOf("year").week() === 1 ?
        moment(from).endOf("year").subtract(1, "week").week() - 1
        : moment(from).endOf("year").week() - 1
      break
    case "day":
      if (pickerType === "month" && moment(from).month() === 0) {
        currentInterval = 1
      } else {
        currentInterval = moment(from).dayOfYear()
      }
      intervalLimit = 364
      break
  }

  let currentYear = moment(from).year()
  // if (type === "day" && currentInterval === 1 && pickerType !== "month") {
  //   currentYear = currentYear + 1
  //   // HANDLES CASE IF WEEKS AND ONLY ONE WEEK IS CHOSEN IN THE CHANGE OF YEARS
  // } else 
  if (type === "week" && moment(from).isSame(to, "week") && moment(from).week() === 1) {
    currentYear = moment(to).year()
  }

  while (whileEngine(type, to, currentYear, currentInterval)) {
    let currentValue = data.find(d => type === "year" ?
      (d.year === currentInterval)
      : (d.year === currentYear && d[type] === currentInterval)
    )

    currentValue ? firstValuePassed = true : null
    if (currentValue) {
      refinedData.push(currentValue)
    } else if (refinedData.length > 0 && firstValuePassed) {
      if (increment) {
        refinedData.push({
          ...refinedData[refinedData.length - 1],
          new: 0,
          retained: refinedData[refinedData.length - 1].total,
          total: refinedData[refinedData.length - 1].total,
          year: currentYear,
          [type]: currentInterval
        })
      } else {
        refinedData.push({
          year: currentYear,
          [type]: currentInterval,
          new: 0,
          retained: 0,
          total: 0
        })
      }
    } else {
      if (increment) {
        refinedData.push({
          ...data[0],
          new: 0,
          retained: data[0].new >= data[0].total ? 0 : data[0].retained,
          total: data[0].new >= data[0].total ? 0 : data[0].retained,
          year: currentYear,
          [type]: currentInterval
        })
      } else {
        refinedData.push({
          year: currentYear,
          [type]: currentInterval,
          new: 0,
          retained: 0,
          total: 0
        })
      }
    }

    if (type === "year") {
      currentInterval += 1
      currentYear += 1
    } else {
      if (currentInterval > intervalLimit) {
        currentInterval = 1
        currentYear += 1
        if (type === "week") {
          intervalLimit = moment().set("year", currentYear).endOf("year").week()
          if (intervalLimit == 1) {
            intervalLimit = moment().set("year", currentYear).endOf("year").subtract(1, "week").week()
          }
        }
      } else {
        currentInterval += 1
      }
    }
  }

  return refinedData
}

const setDate = ({ state, action }) => {
  const oldFrom = state.from
  const oldTo = state.to
  const newFrom = action.from
  const newTo = action.to

  if (!isValidDate(newFrom) && !isValidDate(newTo)) {
    if (isValidDate(oldFrom) && isValidDate(oldTo)) {
      return {
        ...state,
        from: oldFrom,
        to: oldTo,
      }
    } else {
      return {
        ...state,
        from: moment().subtract(14, 'd'),
        to: getToday(),
      }
    }
  }

  return {
    ...state,
    from: newFrom,
    to: newTo,
  }
}

const initialState = {
  from: moment().subtract(16, 'weeks').startOf('week').toDate(),
  to: moment().subtract(1, 'week').endOf('week').toDate(),
  sortOn: 'week',
  sortPickerOn: 'week',
  ongoingRetention: true,
  members: [],
  ongoingAllRetention: true,
  allMembers: [],
  ongoingIncrement: true,
  memberIncrement: [],
  requestedSite: null,
  lastRequestDate: null,
  requestedSiteAll: null,
  lastRequestDateAll: null,
  lastRequestDateIncrement: null,
  siteLangFormat: null,
}

export function gymMemberStatisticsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_RETENTION:
      return {
        ...state,
        ongoingRetention: true,
        requestedSite: action.siteId,
        lastRequestDate: action.lastRequestDate,
      }
    case types.RESPONSE_RETENTION:
      if (action.siteId === state.requestedSite && moment(state.lastRequestDate).isSame(action.lastRequestDate)) {
        return {
          ...state,
          ongoingRetention: false,
          members: refineData(action.data, state.sortOn, state.sortPickerOn, state.from, state.to),
        }
      } else {
        return { ...state }
      }
    case types.FAILURE_RETENTION:
      return {
        ...state,
        ongoingRetention: false,
      }

    case types.GET_ALL_RETENTION:
      return {
        ...state,
        ongoingAllRetention: true,
        requestedSiteAll: action.siteId,
        lastRequestDateAll: action.lastRequestDate,
      }
    case types.RESPONSE_ALL_RETENTION:
      if (action.siteId === state.requestedSiteAll && moment(state.lastRequestDateAll).isSame(action.lastRequestDate)) {
        return {
          ...state,
          ongoingAllRetention: false,
          allMembers: refineData(action.data, state.sortOn, state.sortPickerOn, state.from, state.to),
        }
      } else {
        return { ...state }
      }
    case types.FAILURE_ALL_RETENTION:
      return {
        ...state,
        ongoingAllRetention: false,
      }
    case types.GET_INCREMENT:
      return {
        ...state,
        ongoingIncrement: true,
        requestedSiteAll: action.siteId,
        lastRequestDateIncrement: action.lastRequestDate,
      }
    case types.RESPONSE_INCREMENT:
      if (action.siteId === state.requestedSiteAll && moment(state.lastRequestDateIncrement).isSame(action.lastRequestDate)) {
        return {
          ...state,
          ongoingIncrement: false,
          memberIncrement: refineData(action.data, state.sortOn, state.sortPickerOn, state.from, state.to, true),
          // memberIncrement: refineData(action.data.map(d => ({ ...d, members: d.total - d.new })), state.sortOn, state.from, state.to, true),
        }
      } else {
        return { ...state }
      }
    case types.FAILURE_INCREMENT:
      return {
        ...state,
        ongoingIncrement: false,
      }

    case types.SET_DATE:
      return setDate({ state, action })
    case types.SET_SORT:
      return {
        ...state,
        sortOn: action.sortOn
      }
    case types.SET_PICKER_SORT:
      return {
        ...state,
        sortPickerOn: action.sortPickerOn
      }
    case types.SET_LANG_FORMAT:
      return {
        ...state,
        siteLangFormat: action.lang
      }

    default:
      return state
  }
}