import React from 'react'
import PropTypes from 'prop-types'
import * as authorized from 'util/authorized'
import { Route, Redirect } from 'react-router-dom'
import { homePaths } from 'app/protected/common/constants/routes'

export default class SuRoute extends React.PureComponent {
  render() {
    const { userPermissions, ...rest } = this.props
    const isAllowed = authorized.userIsSuperUser(userPermissions)

    return isAllowed ? (
      <Route {...rest} />
    ) : (
      <Route
        render={props => {
          return (
            <Redirect
              to={{
                pathname: homePaths.HOME(),
                state: { from: props.location },
              }}
            />
          )
        }}
      />
    )
  }

  static propTypes = {
    userPermissions: PropTypes.arrayOf(
      PropTypes.shape({
        role: PropTypes.string.isRequired,
      })
    ),
  }
}
