import { clients as types } from '../actions/actiontypes'
import { sortAlphabetically } from 'util/util'

const initState = {
  ongoing: 'nothing',
  cancel: null,
  clients: [],
  error: null,
  client: null,
  getMoreClientsOngoing: false,
  nextClientsResult: [],
  searchResult: [],
  searchOngoing: 'nothing',

  unreadMessages: 0,
  clientCount: 0,
  getClientCountOngoing: false,
}

export function clientsReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return { ...initState }

    case types.GET:
      return {
        ...state,
        ongoing: 'fetching',
      }

    case types.CANCEL_SET:
      return {
        ...state,
        cancel: action.email,
      }

    case types.CANCEL_CLEAR:
      return {
        ...state,
        cancel: null,
      }

    case types.RESPONSE:
      return {
        ...state,
        ongoing: 'nothing',
        clients: action.clients === '' ? [] : action.clients, //If the response is empty, it can deliver an empty string, temporary fix.
        nextClientsResult: action.clients === '' ? [] : action.clients, //If the response is empty, it can deliver an empty string, temporary fix.
      }

    case types.GET_FAIL:
      return {
        ...state,
        ongoing: 'nothing',
        error: action,
      }

    case types.GET_MORE_CLIENTS:
      return {
        ...state,
        getMoreClientsOngoing: true,
        nextClientsResult: [],
        addMemberOngoing: 'nothing',
      }
    case types.GET_MORE_CLIENTS_RESPONSE:
      return {
        ...state,
        getMoreClientsOngoing: false,
        nextClientsResult: action.clients,
        clients: state.clients.concat(action.clients),
      }
    case types.GET_MORE_CLIENTS_FAILED:
      return {
        ...state,
        getMoreClientsOngoing: false,
        nextClientsResult: [],
        error: action.error,
      }

    case types.SEARCH_FOR_CLIENTS:
      return {
        ...state,
        searchOngoing: 'searching',
      }
    case types.SEARCH_FOR_CLIENTS_RESPONSE:
      return {
        ...state,
        searchOngoing: 'done',
        nextClientsResult: [],
        searchResult: action.clients,
      }
    case types.SEARCH_FOR_CLIENTS_FAILED:
      return {
        ...state,
        searchOngoing: 'failed',
        searchResult: [],
        nextClientsResult: [],
        error: action.error,
      }
    case types.SEARCH_FOR_CLIENTS_CLEAR:
      return {
        ...state,
        searchOngoing: 'nothing',
        nextClientsResult: [],
        searchResult: [],
      }

    case types.GET_ALL_UNREAD_MESSAGES_RESPONSE:
      return {
        ...state,
        unreadMessages: action.unreadMessages,
      }

    case types.GET_CLIENT_COUNT:
      return {
        ...state,
        clientCount: 0,
        getClientCountOngoing: true,
      }
    case types.GET_CLIENT_COUNT_RESPONSE:
      return {
        ...state,
        clientCount: action.clients,
        getClientCountOngoing: false,
      }
    case types.GET_CLIENT_COUNT_FAILED:
      return {
        ...state,
        clientCount: 0,
        getClientCountOngoing: false,
      }
    case types.ADD_CLIENT:
      return {
        ...state,
        ongoing: "adding",
      }
    case types.ADD_CLIENT_RESPONSE:
      return {
        ...state,
        ongoing: "nothing",
        client: action.client,
        clients: sortAlphabetically([...state.clients, action.client], "name"),
        clientCount: state.clientCount + 1,
      }
    case types.ADD_CLIENT_FAILED:
      return {
        ...state,
        ongoing: "nothing",
        error: action.errorType,
      }
    case types.ADD_CLIENT_CLEAR:
      return {
        ...state,
        ongoing: "nothing",
        error: null,
        client: null,
      }

    default:
      return state
  }
}
