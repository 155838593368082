import { machine as types } from '../actions/actiontypes'

const initState = {
  mode: 'view',
  ongoing: [],
  machines: {},
  delete: {},
  errors: {}
}

function ongoingReducer(state, action) {
  switch (action.type) {
    case types.DELETE:
    case types.SAVE:
    case types.GET:
      return [
        ...state,
        {
          id: action.id,
          what: action.what
        }
      ]
    case types.RESPONSE:
    case types.SAVE_RESPONSE:
    case types.DELETE_RESPONSE:
    case types.GET_FAIL:
    case types.SAVE_FAIL:
      return state.filter(o => o.id != action.id)
    default:
      return state
  }
}

function deleteReducer(state, action) {
  switch (action.type) {
    case types.DELETE_RESPONSE:
      return Object.assign({}, state, {
        [action.id]: "deleted"
      })
    default:
      return state
  }
}
function machinesReducer(state, action) {
  switch (action.type) {
    case types.RESPONSE:
    case types.SAVE_RESPONSE:
      return Object.assign({}, state, {
        [action.id]: action.machines
      })
    case types.GET_FAIL:
    case types.SAVE_FAIL:
    case types.DELETE_RESPONSE:
      {
        let {
          [action.id]: omit,
          ...res
        } = state
        return res
      }
    default:
      return state
  }
}

function errorReducer(state, action) {
  switch (action.type) {
    case types.GET_FAIL:
    case types.SAVE_FAIL:
    case types.DELETE_FAIL:
      return Object.assign({}, state, {
        [action.id]: action.error
      })
    default:
      return state
  }
}

export function machineReducer(state = initState, action) {
  switch (action.type) {
    case "STORE_RESET":
      return Object.assign({}, initState)
    case types.GET:
    case types.SAVE:
    case types.DELETE:
      return Object.assign({}, state, {
        ongoing: ongoingReducer(state.ongoing, action)
      })
    case types.RESPONSE:
    case types.SAVE_RESPONSE:
    case types.DELETE_RESPONSE:
      return Object.assign({}, state, {
        ongoing: ongoingReducer(state.ongoing, action),
        machines: machinesReducer(state.machines, action),
        delete: deleteReducer(state.delete, action)
      })
    case types.GET_FAIL:
    case types.SAVE_FAIL:
    case types.DELETE_FAIL:
      return Object.assign({}, state, {
        ongoing: ongoingReducer(state.ongoing, action),
        machines: machinesReducer(state.machines, action),
        errors: errorReducer(state.error, action)
      })
    case types.SET_MODE:
      return {
        ...state,
        mode: action.mode,
      }
    default:
      return state
  }
}
