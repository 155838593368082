import { myExercises as types } from '../actions/actiontypes'

const initState = {
  ongoing: false,
  exercises: [],
  error: undefined,
  count: 0,
  searchOngoing: "initial",
  searchResult: [],
}

export function myExercisesReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return {
        ...state,
        initState
      }
    case types.GET:
      return {
        ...state,
        ongoing: state.exercises.length > 0 ? false : true,
      }
    case types.RESPONSE:
      return {
        ...state,
        ongoing: false,
        exercises: action.exercises,
      }
    case types.GET_FAIL:
      return {
        ...state,
        ongoing: false,
        error: action,
      }
    case types.ADD_NEW:
      return {
        ...state,
        exercises: [...state.exercises, action.exercise]
      }

    case types.GET_EXERCISES:
      return {
        ...state,
        ongoing: true,
      }
    case types.GET_EXERCISES_RESPONSE:
      return {
        ...state,
        ongoing: false,
        exercises: action.exercises,
        count: action.count,
      }
    case types.GET_EXERCISES_FAILED:
      return {
        ...state,
        ongoing: false,
        error: action.error,
      }

    case types.SEARCH_FOR_EXERCISES:
      return {
        ...state,
        searchOngoing: 'searching',
      }
    case types.SEARCH_FOR_EXERCISES_RESPONSE:
      return {
        ...state,
        searchOngoing: 'done',
        searchResult: action.exercises,
      }
    case types.SEARCH_FOR_EXERCISES_FAILED:
      return {
        ...state,
        searchOngoing: false,
        searchResult: [],
        error: action.error,
      }
    case types.SEARCH_FOR_EXERCISES_CLEAR:
      return {
        ...state,
        searchOngoing: "initial",
        searchResult: [],
      }
    case types.FILTER_OUT_EXERCISE:
      return {
        ...state,
        exercises: state.exercises.filter(item => item._id !== action.exercise),
      }
    default:
      return state
  }
}
