import { myGymProgramCollections as types } from '../actions/actiontypes'

const initState = {
  ongoing: false,
  programCollections: [],
  copyOngoing: false,
  error: undefined,
}

const updateVisibility = (programs, visibilityList) => {
  let updatedList = []
  programs.map((p) => {
    const hasVisibleUpdate = visibilityList.find((v) => v._id === p._id)
    if (hasVisibleUpdate) {
      const updatedProgram = {
        ...p,
        visible: visibilityList.find((v) => v._id === p._id).visible,
      }
      updatedList.push(updatedProgram)
    } else {
      updatedList.push(p)
    }
  })
  return updatedList
}

export function myGymProgramCollectionsReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return {
        ...state,
        ...initState,
      }
    case types.GET:
      return {
        ...state,
        ongoing: true,
      }
    case types.RESPONSE:
      return {
        ...state,
        ongoing: false,
        programCollections: action.programCollections,
      }
    case types.GET_FAIL:
      return {
        ...state,
        ongoing: false,
        error: action,
      }
    case types.UPDATE_VISIBILITY:
      return {
        ...state,
        programCollections: updateVisibility(
          state.programCollections,
          action.updatedList
        ),
      }
    case types.FILTER_OUT_COLLECTION:
      return {
        ...state,
        programCollections: state.programCollections.filter(
          (p) => p._id !== action.collection
        ),
      }
    case types.COPY:
      return {
        ...state,
        copyOngoing: true,
        error: null,
      }
    case types.COPY_RESPONSE:
      return {
        ...state,
        copyOngoing: false,
        error: null,
      }
    case types.COPY_FAILED:
      return {
        ...state,
        copyOngoing: false,
        error: action.error,
      }
    default:
      return state
  }
}
