import { gymZone as types } from '../actions/actiontypes'

const initState = {
  ongoing: [],
  zones: {},
  delete: {},
  errors: {},
  mode: 'view',
  currentCopyZone: '',
  currentCopySite: '',
  copyResult: [],
  copyProgress: '',
  exercises: [],
}

function addToOngoing(state, action) {
  return [
    ...state,
    {
      id: action.id,
      what: action.what,
    },
  ]
}

function removeFromOngoing(state, action) {
  return state.filter(o => o.id != action.id)
}

function addToDeleted(state, action) {
  return Object.assign({}, state, {
    [action.id]: 'deleted',
  })
}

function addToZone(state, action) {
  return Object.assign({}, state, {
    [action.id]: action.zones,
  })
}

function removeFromZones(state, action) {
  let { [action.id]: omit, ...res } = state
  return res
}

function addToError(state, action) {
  if (action.id) {
    return Object.assign({}, state, {
      [action.id]: action.error,
    })
  } else {
    return Object.assign({}, state, {
      create: action.error,
    })
  }
}

export function myGymZoneReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return {
        ...initState,
      }
    case types.GET:
    case types.SAVE:
    case types.DELETE:
      return {
        ...state,
        ongoing: addToOngoing(state.ongoing, action),
      }
    case types.RESPONSE:
    case types.SAVE_RESPONSE:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        zones: addToZone(state.zones, action),
        exercises: action.exercises ? action.exercises : state.exercises,
        mode: 'view',
      }
    case types.DELETE_RESPONSE:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        zones: removeFromZones(state.zones, action),
        delete: addToDeleted(state.delete, action),
      }
    case types.COPY_RESPONSE:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
      }
    case types.GET_FAIL:
    case types.SAVE_FAIL:
    case types.DELETE_FAIL:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        errors: addToError(state.errors, action),
      }
    case types.SET_MODE:
      return {
        ...state,
        mode: action.mode,
      }

    case types.SENDCOPY:
      return {
        ...state,
        ongoing: addToOngoing(state.ongoing, action),
        currentCopyZone: action.zone,
        currentCopySite: action.site,
        copyProgress: action.percentage,
      }
    case types.SENDCOPY_RESPONSE:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        currentCopyZone: '',
        currentCopySite: '',
        copyResult: [...state.copyResult, action.result],
        copyProgress: action.percentage,
      }
    case types.SENDCOPY_COMPLETE:
      return {
        ...state,
        ongoing: [],
        currentCopyZone: '',
        currentCopySite: '',
        copyResult: [],
        copyProgress: '',
      }

    case types.ADD_EXERCISES:
      return {
        ...state,
        exercises: state.exercises.concat(action.exercises)
      }
    case types.REMOVE_EXERCISE:
      return {
        ...state,
        exercises: state.exercises.filter(e => e._id !== action.exercise)
      }
    case types.CLEAR_EXERCISES:
      return {
        ...state,
        exercises: []
      }

    default:
      return state
  }
}