import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import gym_invitation_thunk from 'redux/actions/thunks/gymInvitationThunk'
import GymInviteLink from 'app/public/gym-invite-link/GymInviteLink'
import i18n from 'i18n/i18n'

//Hack to set language on this site only
const getLangCode = (lang) => {
  if (lang.length >= 2) {
    const firstCode = lang.substring(0, 2)
    return firstCode
  }
  return 'en'
}

const mapStateToProps = (state, props) => {
  const { match } = props
  const { status } = state.userReducer
  const gymLinkCode = match.params.id ? match.params.id : ''
  const redirectToAppStore = match.url ? match.url.includes("appStore") : false
  const { ongoing, site } = state.gymInvitationReducer

  if (status === 'unauthorized') {
    //Hack to set language on this site only
    const userLang = navigator.language || navigator.userLanguage
    const userLangCode = getLangCode(userLang)
    i18n.changeLanguage(userLangCode)
  }

  const siteName = site ? (site.name ? site.name : 'noname') : 'noname'
  return {
    ongoing,
    site,
    siteName,
    gymLinkCode,
    redirectToAppStore,
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  getSite: (code) => {
    dispatch(gym_invitation_thunk.getSite(code))
  },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GymInviteLink)
)