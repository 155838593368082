import { connect } from 'react-redux'
import Protected from '../component/Protected'
import { user } from 'redux/actions/thunks.js'
import clientsThunk from 'redux/actions/thunks/clients'

const mapStateToProps = (state) => {
  const { data, status, unreadSiteMessages } = state.userReducer
  const { unreadMessages } = state.clientsReducer
  const { roles } = data
  const isSU = roles.includes('su')
  const unread = unreadSiteMessages.reduce((accumulator, object) => {
    return accumulator + object.unreadMessages
  }, 0) + unreadMessages

  return {
    data,
    status,
    isSU,
    unread
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: () => {
      dispatch(user.get())
    },
    getUnreadMessages: () => {
      dispatch(clientsThunk.getUnreadMessages())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Protected)
