import { manageMembers as types } from '../actions/actiontypes'
import moment from 'moment'

const initReducerState = {
  getNumberOfMembersOngoing: false,
  memberCount: 0,
  activeMemberCount: 0,
  guestCount: 0,
  invitedCount: 0,
  getNumberOfMembersError: undefined,
  getMembersOngoing: false,
  nextMembersResult: [],
  manageMembersReducer: false,
  removeMemberOngoing: false,
  addMemberOngoing: 'nothing',
  members: [],
  getMembersError: undefined,
  removeMemberError: undefined,
  addMemberError: undefined,
  searchResult: [],
  searchOngoing: 'nothing',

  memberFilter: [],
  requestDate: null,

  workOngoing: [],
  currentMember: {},
  workProgress: '',
  workResult: [],
}

function addToOngoing(state, action) {
  return [
    ...state,
    {
      id: action.id,
      what: action.what,
    },
  ]
}

function removeFromOngoing(state, action) {
  return state.filter((o) => o.id != action.id)
}

export function manageMembersReducer(state = initReducerState, action) {
  switch (action.type) {
    case types.GET_NUMBER_OF_MEMBERS:
      return {
        ...state,
        getNumberOfMembersOngoing: true,
        memberCount: 0,
        activeMemberCount: 0,
        guestCount: 0,
        invitedCount: 0,
      }
    case types.GET_NUMBER_OF_MEMBERS_RESPONSE:
      return {
        ...state,
        getNumberOfMembersOngoing: false,
        memberCount: action.members.members ? action.members.members : 0,
        activeMemberCount: action.members.active ? action.members.active : 0,
        guestCount: action.members.guests ? action.members.guests : 0,
        invitedCount: action.members.invited ? action.members.invited : 0,
      }
    case types.GET_NUMBER_OF_MEMBERS_FAILED:
      return {
        ...state,
        getNumberOfMembersOngoing: false,
        getNumberOfMembersError: action.error,
        memberCount: 0,
        guestCount: 0,
        invitedCount: 0,
      }

    case types.GET_MEMBERS:
      return {
        ...state,
        getMembersOngoing: true,
        addMemberOngoing: 'nothing',
        updateMembersOngoing: 'nothing',
        requestDate: action.date,
      }
    case types.GET_MEMBERS_RESPONSE:
      if (moment(state.requestDate).isSame(action.date)) {
        return {
          ...state,
          getMembersOngoing: false,
          nextMembersResult: action.members,
          members: action.members,
        }
      } else {
        return { ...state }
      }
    case types.GET_MEMBERS_FAILED:
      return {
        ...state,
        getMembersOngoing: false,
        getMembersError: action.error,
      }

    case types.SEARCH_FOR_MEMBERS:
      return {
        ...state,
        searchOngoing: 'searching',
        requestDate: action.date,
      }
    case types.SEARCH_FOR_MEMBERS_RESPONSE:
      if (moment(state.requestDate).isSame(action.date)) {
        return {
          ...state,
          searchOngoing: 'done',
          //nextMembersResult: [],
          searchResult: action.members,
        }
      } else {
        return { ...state }
      }
    case types.SEARCH_FOR_MEMBERS_FAILED:
      return {
        ...state,
        searchOngoing: 'failed',
        searchResult: [],
        //nextMembersResult: [],
        getMembersError: action.error,
      }
    case types.SEARCH_FOR_MEMBERS_CLEAR:
      return {
        ...state,
        searchOngoing: 'nothing',
        //nextMembersResult: [],
        searchResult: [],
      }

    case types.SAVE_MEMBER_RESPONSE:
      return {
        ...state,
        members: state.members.map((m) =>
          m._id === action.member._id ? action.member : m
        ),
        searchResult: state.searchResult.map((m) =>
          m._id === action.member._id ? action.member : m
        ),
      }
    case types.REMOVE_MEMBER_RESPONSE:
      return {
        ...state,
        members: state.members.filter((m) => m._id !== action.member),
        searchResult: state.searchResult.filter((m) => m._id !== action.member),
      }

    case types.ADD_MEMBERS:
      return {
        ...state,
        addMemberOngoing: 'adding',
      }
    case types.ADD_MEMBERS_RESPONSE:
      return {
        ...state,
        addMemberOngoing: 'added',
      }
    case types.ADD_MEMBERS_FAILED:
      return {
        ...state,
        addMemberOngoing: 'failed',
        addMemberError: action.error,
      }

    case types.UPDATE_MEMBERS:
      return {
        ...state,
        workOngoing: addToOngoing(state.workOngoing, { id: 'status', what: 'updating' }),
      }
    case types.UPDATE_MEMBERS_RESPONSE:
      return {
        ...state,
        workOngoing: removeFromOngoing(state.workOngoing, { id: 'status', what: 'updating' }),
      }
    case types.UPDATE_MEMBERS_FAILED:
      return {
        ...state,
        getMembersError: action.error,
        workOngoing: removeFromOngoing(state.workOngoing, { id: 'status', what: 'updating' }),
      }

    case types.WORK:
      return {
        ...state,
        workOngoing: addToOngoing(state.workOngoing, action),
        currentMember: action.member,
        workProgress: action.percentage,
      }
    case types.WORK_RESPONSE:
      return {
        ...state,
        workOngoing: removeFromOngoing(state.workOngoing, action),
        currentMember: {},
        workProgress: action.percentage,
        workResult: [...state.workResult, action.result],
      }
    case types.CLEAR_WORK:
      return {
        ...state,
        workOngoing: [],
        currentMember: {},
        workProgress: '',
        workResult: [],
      }

    case types.CLEAR_ERRORS:
      return {
        ...state,
        getMembersError: undefined,
        removeMemberError: undefined,
      }

    case types.SET_FILTER:
      return {
        ...state,
        memberFilter: action.filter,
      }
    default:
      return state
  }
}
