import { manageMember as types } from '../actions/actiontypes'

const initReducerState = {
  ongoing: 'nothing',
  progress: 'initial',
  programs: [],
  error: null,
}

const updatePrograms = (programs, program) => {
  let list = []
  programs && programs.map(n => {
    if (n._id === program._id) {
      list.push(program)
    } else {
      list.push(n)
    }
  })
  return list
}

export function manageMemberProgramsReducer(state = initReducerState, action) {
  switch (action.type) {
    case types.GET_WORKOUTS:
      return {
        ...state,
        ongoing: 'fetching',
      }
    case types.GET_WORKOUTS_RESPONSE:
      return {
        ...state,
        ongoing: 'nothing',
        programs: action.programs,
      }
    case types.GET_WORKOUTS_FAILED:
      return {
        ...state,
        ongoing: 'nothing',
        error: action.error,
      }

    case types.CREATE_WORKOUT:
      return {
        ...state,
        ongoing: 'creating',
        progress: 'initial',
        error: null,
      }
    case types.CREATE_WORKOUT_RESPONSE:
      return {
        ...state,
        ongoing: 'nothing',
        programs: [...state.programs, action.program],
        progress: action.clear ? 'initial' : 'created',
      }
    case types.CREATE_WORKOUT_FAILED:
      return {
        ...state,
        ongoing: 'nothing',
        error: action.error,
        progress: 'initial',
      }
    case types.PROGRESS_CLEAR:
      return {
        ...state,
        progress: 'initial',
      }
    case types.REMOVE_WORKOUT:
      return {
        ...state,
        ongoing: 'removing',
      }
    case types.REMOVE_WORKOUT_RESPONSE:
      return {
        ...state,
        programs: state.programs.filter((m) => m._id !== action.program),
        ongoing: 'nothing',
      }
    case types.REMOVE_WORKOUT_FAILED:
      return {
        ...state,
        ongoing: 'error',
        error: action.error,
      }
    case types.SAVE_WORKOUT:
      return {
        ...state,
        ongoing: 'saving',
        error: null,
      }
    case types.SAVE_WORKOUT_RESPONSE:
      return {
        ...state,
        programs: updatePrograms(state.programs, action.program),
        ongoing: 'nothing',
        progress: 'saved',
      }
    case types.SAVE_WORKOUT_FAILED:
      return {
        ...state,
        ongoing: 'error',
        error: action.error,
      }

    default:
      return state
  }
}
