import { gymMachines as types } from '../actions/actiontypes'

const initState = {
  ongoing: false,
  machine: null,
  machines: [],
  error: undefined,

  workOngoing: [],
  workResult: [],
  currentMachine: {},
  currentSite: '',
  percentage: '',
}

function addToOngoing(state, action) {
  return [
    ...state,
    {
      id: action.id,
      what: action.what,
    },
  ]
}

function removeFromOngoing(state, action) {
  return state.filter((o) => o.id != action.id)
}

export function myGymMachinesReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return { ...state, initState }
    case types.GET:
      return {
        ...state,
        ongoing: true,
        error: undefined,
      }
    case types.RESPONSE:
      return {
        ...state,
        ongoing: false,
        machines: action.machines,
      }
    case types.GET_FAIL:
      return { ...state, ongoing: false, error: action }

    case types.CREATE:
      return {
        ...state,
        ongoing: true,
        error: undefined,
        machine: action.machine,
      }
    case types.CREATE_RESPONSE:
      return {
        ...state,
        ongoing: false,
        machine: action.machine,
      }
    case types.CREATE_FAILED:
      return {
        ...state,
        ongoing: false,
        machine: null,
        error: action.error.response ? action.error.response.statusText : 'Error: Network Error',
      }
    case types.CLEAR:
      return {
        ...state,
        ongoing: false,
        machine: null,
        machines: [],
        error: undefined,
      }

    case types.WORK:
      return {
        ...state,
        workOngoing: addToOngoing(state.workOngoing, action),
        currentMachine: action.machine,
        percentage: action.percentage,
        currentSite: action.site,
      }
    case types.WORK_RESPONSE:
      return {
        ...state,
        workOngoing: removeFromOngoing(state.workOngoing, action),
        currentMachine: {},
        percentage: action.percentage,
        workResult: [...state.workResult, action.result],
        currentSite: '',
      }
    case types.CLEAR_WORK:
      return {
        ...state,
        workOngoing: [],
        currentMachine: {},
        percentage: '',
        workResult: [],
        currentSite: '',
      }
    case types.FILTER_OUT_MACHINE:
      return {
        ...state,
        machines: state.machines.filter(item => item._id !== action.machine),
      }
    default:
      return state
  }
}