import { gymBannerStatus as types } from '../actions/actiontypes'

const initState = {
  ongoing: false,
  sites: [],
  error: null,
}

export function gymBannerStatusReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return { ...initState }

    case types.GET:
      return {
        ...state,
        ongoing: true,
      }

    case types.GET_RESPONSE:
      return {
        ...state,
        ongoing: false,
        sites: action.sites,
      }

    case types.GET_FAIL:
      return {
        ...state,
        ongoing: false,
        error: action,
      }

    case types.CLEAR:
      return {
        ...state,
        ongoing: false,
        sites: [],
        error: null,
      }

    default:
      return state
  }
}
