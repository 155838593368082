import { myProgramShare as types } from "../actions/actiontypes"

const initState = {
  ongoing: [],
  shares: {},
  delete: {},
  errors: {},
  mode: "view"
}

function addToOngoing(state, action) {
  return [
    ...state,
    {
      id: action.id,
      what: action.what
    }
  ]
}

function removeFromOngoing(state, action) {
  return state.filter(o => o.id != action.id)
}

function addToDeleted(state, action) {
  return Object.assign({}, state, {
    [action.id]: "deleted"
  })
}

function addToShare(state, action) {
  return Object.assign({}, state, {
    [action.id]: action.share
  })
}

function removeFromShare(state, action) {
  let { [action.id]: omit, ...res } = state
  return res
}

function addToError(state, action) {
  return Object.assign({}, state, {
    [action.id]: action.error
  })
}

export function myProgramShareReducer(state = initState, action) {
  switch (action.type) {
    case "STORE_RESET":
      return {
        ...initState
      }
    case types.GET:
    case types.DELETE:
      return {
        ...state,
        ongoing: addToOngoing(state.ongoing, action)
      }
    case types.RESPONSE:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        shares: addToShare(state.shares, action),
        mode: "view"
      }
    case types.DELETE_RESPONSE:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        shares: removeFromShare(state.shares, action),
        delete: addToDeleted(state.delete, action)
      }
    case types.GET_FAIL:
    case types.DELETE_FAIL:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        errors: addToError(state.error, action)
      }
    case types.SET_MODE:
      return {
        ...state,
        mode: action.mode
      }
    case types.UPDATE_MESSAGE:
      return {
        ...state,
        ongoing: addToOngoing(state.ongoing, action)
      }
    case types.UPDATE_MESSAGE_RESPONSE:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        shares: addToShare(state.shares, action)
      }
    case types.UPDATE_MESSAGE_FAIL:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        errors: addToError(state.error, action)
      }
    default:
      return state
  }
}
