import React, { useEffect, useState } from 'react'
import {
  Header,
  Button,
  Popup,
  Label,
  Menu,
  Divider,
  Icon,
} from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { darkGrey } from 'app/protected/roles/util/colors'
import { connect } from 'react-redux'
import { mediumGrey } from 'app/protected/roles/util/colors'
import { signOut } from 'redux/actions/thunks'
import { getPermissionLabel } from 'util/util'
import i18next from 'i18next'
import { homePaths } from 'app/protected/common/constants/routes'
import { withRouter } from 'react-router-dom'
import FeedbackMessage from 'app/root/header/personal-menu/FeedbackMessage'
import { isAndroidWebApp, isIOSWebApp } from 'util/util'

const HeaderPersonalMenu = props => {
  let node = React.createRef()
  const { firstname, email, signOut, permissions, partnerPermissions, history } = props

  const initialState = { showModal: false }
  const [{ showModal }, setState] = useState(initialState)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    document.addEventListener('mousedown', event => handleClick(event), false)

    return () => {
      document.removeEventListener(
        'mousedown',
        event => handleClick(event),
        false
      )
    }
  })

  const handleClick = event => {
    if (typeof node.current != 'undefined' && node.current != null) {
      if (!node.current.contains(event.target)) {
        if (node.current.contains(event.target)) return
      }
    }
    return
  }

  const goToProfileSettings = () => {
    setOpen(false)
    history.push(homePaths.PROFILE_SETTINGS())
  }

  const doSignOut = () => {
    if (isIOSWebApp) {
      window.webkit.messageHandlers.bridge.postMessage({ type: "logout" })
    } else if (isAndroidWebApp) {
      window.Android.logout()
    }
    setOpen(false)
    signOut()
  }

  const openFeedback = () => {
    setState(prevState => ({ ...prevState, showModal: true }))
    setOpen(false)
  }

  const closeFeedback = () => {
    setState(prevState => ({ ...prevState, showModal: false }))
  }

  return (
    <>
      <FeedbackMessage open={showModal} onCancel={closeFeedback} />
      <Popup
        basic
        on="click"
        style={{ padding: 0 }}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        trigger={
          <Button
            icon="user circle"
            circular
            size="big"
            style={{ backgroundColor: darkGrey, color: 'white', margin: 0 }}
          />
        }
      >
        <div ref={node}>
          <div style={{ padding: '8px 12px 0' }}>
            <Header as="h3" style={{ margin: 0 }}>
              {firstname}
            </Header>

            <span style={{ color: mediumGrey }}>{email}</span>

            <div style={{ margin: '12px 0' }}>
              <div
                style={{
                  color: mediumGrey,
                  fontSize: '12px',
                  marginBottom: '3px',
                }}
              >
                {i18next.t('permissions')}:
              </div>

              {getPermissionLabel(permissions, partnerPermissions).map((p, i) => (
                <Label key={i} content={p} style={{ margin: '0 3px 3px 0' }} />
              ))}
            </div>
          </div>

          <Divider style={{ marginBottom: '0' }} />

          <Menu secondary vertical style={{ margin: '0' }}>
            <Menu.Item
              onClick={() => goToProfileSettings()}
              style={{ margin: 0, lineHeight: '20px' }}
            >
              <div>
                <Icon style={{ color: mediumGrey }} name="cog" />
                <span style={{ color: mediumGrey }}>
                  {i18next.t('profile_settings')}
                </span>
              </div>
            </Menu.Item>
          </Menu>

          <Divider style={{ margin: '0' }} />

          <Menu secondary vertical style={{ margin: '0' }}>
            <Menu.Item
              onClick={() => openFeedback()}
              style={{ margin: 0, lineHeight: '20px' }}
            >
              <div>
                <Icon style={{ color: mediumGrey }} name="chat" />
                <span style={{ color: mediumGrey }}>{i18next.t('feedback')}</span>
              </div>
            </Menu.Item>
          </Menu>

          <Divider style={{ margin: '0' }} />

          <Menu secondary vertical style={{ margin: '0' }}>
            <Menu.Item
              onClick={() => doSignOut()}
              style={{ margin: 0, lineHeight: '20px' }}
            >
              <div>
                <Icon style={{ color: mediumGrey }} name="sign out" />
                <span style={{ color: mediumGrey }}>{i18next.t('logout')}</span>
              </div>
            </Menu.Item>
          </Menu>
        </div>
      </Popup>
    </>
  )
}

HeaderPersonalMenu.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  firstname: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  permissions: PropTypes.array.isRequired,
}

const mapStateToProps = state => {
  const { status, data } = state.userReducer
  const { firstname, email, permissions, partnerPermissions } = data

  return {
    isAuthorized: status == 'authorized',
    firstname,
    email,
    permissions,
    partnerPermissions,
  }
}

const mapDispatchToProps = dispatch => ({
  signOut: () => {
    dispatch(signOut.request())
  },
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HeaderPersonalMenu)
)