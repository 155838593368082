import React from 'react'
import sheet from './style.scss'
import logo from 'assets/images/logo-white.svg'
import { advagymGreen } from 'app/protected/roles/util/colors'
import { Image, Container } from 'semantic-ui-react'
import i18next from 'i18next'

function Footer(props) {
  const year = new Date().getFullYear()

  return (
    <footer className={sheet.footer}>
      <Container>
        <a to="/" aria-label="Link to TrainerConnect home" className={sheet.logo}>
          <Image
            src={logo}
            style={{ height: '22px', width: 'auto' }}
            alt="TrainerConnect logo"
          />
        </a>

        <section className={sheet.links}>
          <a
            target="_blank"
            href="https://www.halo.fitness/web/halo.html#/termsOfUse?developerMode=false&selectedTncTab=privacy"
            style={{ color: advagymGreen }}
          >
            {i18next.t('legal_page')}
          </a>
          {/* <a
              target="_blank"
              href="https://www.halo.fitness/web/halo.html#/termsOfUse?developerMode=false&selectedTncTab=privacy"
              style={{ color: advagymGreen }}
            >
              {i18next.t('software_security')}
            </a> */}
          <a
            target="_blank"
            href="https://www.halo.fitness/web/halo.html#/termsOfUse?developerMode=false&selectedTncTab=cookiePolicy"
            style={{ color: advagymGreen }}
          >
            {i18next.t('cookies')}
          </a>
        </section>

        <section className={sheet.name}>
          <p>Copyright &copy; {year} Life Fitness. All Rights Reserved.</p>
        </section>
      </Container>
    </footer>
  )
}

export default Footer
