import React from 'react'
import PropTypes from 'prop-types'
import { Button, Image, Modal } from 'semantic-ui-react'
import header from 'assets/images/header.jpg'

export default function Success(props) {
  const { title, content, actionText, show, onClose } = props
  return (
    <Modal open={show} onClose={onClose}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content image>
        <Image
          wrapped
          size="medium"
          src={header}
        />
        <Modal.Description>
          {content}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          positive
          color="green"
          icon="checkmark"
          labelPosition="right"
          content={actionText}
          onClick={onClose}
        />
      </Modal.Actions>
    </Modal>
  )
}

Success.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  actionText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}
