import { categories as types } from '../actions/actiontypes'

const initState = {
  ongoing: false,
  categories: [],
  error: undefined
}

export function globalCategoriesReducer(state = initState, action) {
  switch (action.type) {
    case types.GET:
      return {
        ...state,
        ongoing: state.categories.length > 0 ? false : true,
      }
    case types.RESPONSE:
      return {
        ...state,
        ongoing: false,
        categories: action.categories
      }
    case types.GET_FAIL:
      return {
        ...state,
        ongoing: false, error: action,
      }
    default:
      return state
  }
}
