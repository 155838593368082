import React from 'react'
import { Loader, Image } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import grayCloseIcon from 'assets/images/close_icon_gray.svg'
import whiteCloseIcon from 'assets/images/close_icon_white.svg'
import redCloseIcon from 'assets/images/close_icon_red.svg'
import styled from 'styled-components'

const Cancel = styled.div`
      background-color: rgba(0, 0, 0, 0);
      display: inline-block;
      cursor: pointer;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      transition: 0.2s ease-in-out background-color;
      will-transform: background-color;
      position: relative;
      :active {
        background-color: rgba(255, 255, 255, 0.4);
      }
      :hover {
        background-color: rgba(0, 0, 0, 0.8);
      }
    `
const CancelWhite = styled.div`
      background-color: rgba(0, 0, 0, 0.2);
      display: inline-block;
      cursor: pointer;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      transition: 0.2s ease-in-out background-color;
      will-transform: background-color;
      position: relative;
      :active {
        background-color: rgba(255, 255, 255, 0.4);
      }
      :hover {
        background-color: rgba(0, 0, 0, 0.8);
      }
    `

function CancelButton(props) {
  const { loading, onClick, color } = props

  const colorFormat = () => {
    switch (color) {
      case 'white':
        return { icon: whiteCloseIcon }
      case 'gray':
        return { icon: grayCloseIcon }
      case 'red':
        return { icon: redCloseIcon }
      default:
        return { icon: grayCloseIcon }
    }
  }

  return (
    <React.Fragment>
      {loading ? (
        <div style={{ width: '30px', height: '30px' }}>
          <Loader active />
        </div>
      ) : color == 'white' ? (
        <CancelWhite onClick={onClick}>
          <Image
            src={colorFormat().icon}
            style={{
              width: '24px',
              display: 'inline-block',
              position: 'absolute',
              left: '3px',
              top: '3px',
            }}
          />
        </CancelWhite>
      ) : (
        <Cancel onClick={onClick}>
          <Image
            src={colorFormat().icon}
            style={{
              width: '24px',
              display: 'inline-block',
              position: 'absolute',
              left: '3px',
              top: '3px',
            }}
          />
        </Cancel>
      )}
    </React.Fragment>
  )
}


CancelButton.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  color: PropTypes.oneOf(['gray', 'white', "red"]),
}

export default CancelButton
