import React from 'react'
import {
  Button,
  Modal,
} from 'semantic-ui-react'
import PropTypes from 'prop-types'
import i18next from 'i18next'

function ModalActions(props) {
  const { actionDisabled, action, actionText, actionColor, onCancel, cancelText, leftColor, leftText, leftAction } = props
  return (
    <Modal.Actions>
      <div
        style={{
          display: 'flex',
          flex: '1',
          alignItems: 'strech',
          paddingBottom: '10px',
        }}
      >
        {onCancel && <div
          style={{
            alignItems: 'center',
            display: 'inline-flex',
            cursor: 'pointer',
          }}
          onClick={onCancel}
        >
          {cancelText ? cancelText : i18next.t('cancel')}
        </div>}
        {leftAction &&
          <Button
            color={leftColor ? leftColor : "green"}
            disabled={actionDisabled}
            content={leftText ? leftText : i18next.t('cancel')}
            onClick={leftAction}
          />
        }
        <div
          style={{
            display: 'flex',
            flex: '1',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            color={actionColor ? actionColor : "green"}
            disabled={actionDisabled}
            content={actionText ? actionText : i18next.t('ok')}
            onClick={action}
          />
        </div>
      </div>
    </Modal.Actions>
  )
}


ModalActions.propTypes = {
  actionDisabled: PropTypes.bool,
  action: PropTypes.func.isRequired,
  actionText: PropTypes.string,
  actionColor: PropTypes.string,
  onCancel: PropTypes.func,
  cancelText: PropTypes.string,
  leftColor: PropTypes.string,
  leftText: PropTypes.string,
  leftAction: PropTypes.func,
}

export default ModalActions