import React from 'react'
import PropTypes from 'prop-types'
import {Segment, Header, Button, Icon} from 'semantic-ui-react'
import {Redirect} from 'react-router-dom'

export default class Unauthorized extends React.PureComponent {
  componentDidMount() {
    const {reset, status} = this.props
    if (status != 'authorized') {
      reset()
    }
  }

  render() {
    const {status} = this.props

    if (status != 'authorized') {
      return (
        <React.Fragment>
          <Segment inverted color='red' textAlign='center'>
            <Header as='h1'>Session has ended.</Header>
          </Segment>
          <Segment color='red' raised size='small'>
            <p>You have been logged out. Most likely due to that you have signed in somewhere else, maybe another browser or the TrainerConnect app.</p>
          </Segment>
          <Segment textAlign='center' raised>
            <a href='/#/signin'>
              <Button positive>
                Sign in <Icon name='right arrow'/>
              </Button>
            </a>
          </Segment>
        </React.Fragment>
      )
    }
    return <Redirect to={{pathname: '/', state: {from: this.props.location}}}/>
  }

  static propTypes = {
    reset: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired,
  }
}
