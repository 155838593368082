import { gymHourActivity as types } from '../actions/actiontypes'
import { getToday, isValidDate } from 'util/util'
import moment from 'moment'

const getMaxValue = days => {
  let maxValue = 0
  days[1] && days[1].map(hour => Number(hour.avg) > maxValue ? maxValue = Number(hour.avg) : null)
  days[2] && days[2].map(hour => Number(hour.avg) > maxValue ? maxValue = Number(hour.avg) : null)
  days[3] && days[3].map(hour => Number(hour.avg) > maxValue ? maxValue = Number(hour.avg) : null)
  days[4] && days[4].map(hour => Number(hour.avg) > maxValue ? maxValue = Number(hour.avg) : null)
  days[5] && days[5].map(hour => Number(hour.avg) > maxValue ? maxValue = Number(hour.avg) : null)
  days[6] && days[6].map(hour => Number(hour.avg) > maxValue ? maxValue = Number(hour.avg) : null)
  days[7] && days[7].map(hour => Number(hour.avg) > maxValue ? maxValue = Number(hour.avg) : null)
  return maxValue
}

const setDate = ({ state, action }) => {
  const oldFrom = state.from
  const newFrom = action.from

  if (!isValidDate(newFrom)) {
    if (isValidDate(oldFrom)) {
      return {
        ...state,
        from: oldFrom,
      }
    } else {
      return {
        ...state,
        from: moment().subtract(14, 'd'),
      }
    }
  }

  return {
    ...state,
    from: newFrom,
  }
}

const initialState = {
  ongoing: false,
  calculating: false,
  days: null,
  maxValue: null,
  from: getToday().startOf('month').set({ hours: '0', minutes: '0' }).toDate(),
  // from: getToday().startOf('month').subtract(1, 'month').set({ hours: '0', minutes: '0' }).toDate(),
  requestedSite: null,
}

export function gymHourActivityReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET:
      return {
        ...state,
        ongoing: true,
        requestedSite: action.siteId,
      }
    case types.CALCULATING:
      return {
        ...state,
        calculating: true,
      }
    case types.RESPONSE:
      if (action.siteId === state.requestedSite) {
        return {
          ...state,
          ongoing: false,
          days: action.data,
          maxValue: getMaxValue(action.data),
          calculating: false,
        }
      } else {
        return { ...state }
      }
    case types.FAILURE:
      return {
        ...state,
        ongoing: false,
        days: null,
        maxValue: null,
      }

    case types.SET_DATE:
      return setDate({ state, action })

    default:
      return state
  }
}
