import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'semantic-ui-react'

export default function PasswordRequirements(props) {
  const { show, onClose } = props
  return (
    <Modal open={show} onClose={onClose}>
      <Modal.Header>{"Password Requirements"}</Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          <ul style={{listStyleType: 'disc'}}>
            <li>Minimum of 8 characters</li>
            <li>At least one special character (!@#$%^&*)</li>
            <li>At least one number</li>
            <li>At least one uppercase or lowercase letter</li>
            <li>New password cannot match previous password</li>
          </ul>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          positive
          color="green"
          icon="checkmark"
          content="Got It"
          onClick={onClose}
        />
      </Modal.Actions>
    </Modal>
  )
}

PasswordRequirements.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}
