/*global process*/

import thunkMiddleware from 'redux-thunk'
import { applyMiddleware, createStore, compose } from 'redux'
import combinedReducer from '../reducers'

const isLocal = process.env.IS_LOCAL ? true : false

const composeEnhancers = isLocal && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  })
  : compose

const store = createStore(combinedReducer, composeEnhancers(applyMiddleware(thunkMiddleware)))

export default store
