import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import AsyncBundle from 'app/common/async-bundle/component/AsyncBundle'
import SuRoute from 'app/root/role-route/component/SuRoute.js'
import { homePaths } from 'app/protected/common/constants/routes'
import sheet from './style.scss'
import { isAndroidWebApp, isIOSWebApp } from 'util/util'

const NoMatch = ({ location }) => (
  <div>
    <h3>
      <code>{location.pathname} not found (protected)</code>
    </h3>
  </div>
)

const AsyncTools = (props) => (
  <AsyncBundle
    load={() =>
      import(
        /* webpackChunkName: "tools" */
        'app/protected/tools/container/Tools'
      )
    }
  >
    {(Tools) => <Tools {...props} />}
  </AsyncBundle>
)

const AsyncGyms = (props) => (
  <AsyncBundle
    load={() =>
      import(
        /* webpackChunkName: "gyms" */
        'app/protected/gyms/container/Gyms'
      )
    }
  >
    {(Gyms) => <Gyms {...props} />}
  </AsyncBundle>
)

const AsyncPartners = (props) => (
  <AsyncBundle
    load={() =>
      import(
        /* webpackChunkName: "partners" */
        'app/protected/su-partners/container/Partners'
      )
    }
  >
    {(Partners) => <Partners {...props} />}
  </AsyncBundle>
)

const AsyncTag = (props) => (
  <AsyncBundle
    load={() =>
      import(
        /* webpackChunkName: "identify_puck" */
        'app/protected/identifyPuck/container/IdentifyPuck'
      )
    }
  >
    {(IdentifyPuck) => <IdentifyPuck {...props} />}
  </AsyncBundle>
)

const AsyncOtherUser = (props) => (
  <AsyncBundle
    load={() =>
      import(
        /* webpackChunkName: "users" */
        'app/protected/users/user/container/HandleUser'
      )
    }
  >
    {(HandleUser) => <HandleUser {...props} />}
  </AsyncBundle>
)

const AsyncOtherUsers = (props) => (
  <AsyncBundle
    load={() =>
      import(
        /* webpackChunkName: "users" */
        'app/protected/users/container/HandleUsers'
      )
    }
  >
    {(HandleUsers) => <HandleUsers {...props} />}
  </AsyncBundle>
)

const AsyncForceClient = (props) => (
  <AsyncBundle
    load={() =>
      import(
        /* webpackChunkName: "users" */
        'app/protected/users/container/ForceClient'
      )
    }
  >
    {(ForceClient) => <ForceClient {...props} />}
  </AsyncBundle>
)

const AsyncStatistics = (props) => (
  <AsyncBundle
    load={() =>
      import(
        /* webpackChunkName: "statistics" */
        'app/protected/statistics/container/Statistics'
      )
    }
  >
    {(System) => <System {...props} />}
  </AsyncBundle>
)

const AsyncTranslationTools = (props) => (
  <AsyncBundle
    load={() =>
      import(
        /* webpackChunkName: "statistics" */
        'app/protected/translationTools/container/TranslationTools'
      )
    }
  >
    {(System) => <System {...props} />}
  </AsyncBundle>
)

const AsyncUserHome = (props) => (
  <AsyncBundle
    load={() =>
      import(
        /* webpackChunkName: "user_home" */
        'app/protected/roles/container/UserHome'
      )
    }
  >
    {(Content) => <Content />}
  </AsyncBundle>
)

const AsyncManageSuperUser = (props) => (
  <AsyncBundle
    load={() =>
      import(
        /* webpackChunkName: "superuser" */
        'app/protected/superusers/container/ManageSuperUser'
      )
    }
  >
    {(ManageSuperUser) => <ManageSuperUser {...props} />}
  </AsyncBundle>
)

export default function Protected(props) {
  const { getUser, getUnreadMessages, data, status, unread } = props
  const { permissions: userPermissions } = data

  useEffect(() => {
    getUser()
    if (status === "authorized") {
      getUnreadMessages()
    }
  }, [])

  useEffect(() => {
    if (isIOSWebApp) {
      window.webkit.messageHandlers.bridge.postMessage({ type: "badge", badge: unread })
    }
    // THIS DOES NOT WORK WELL FOR ANDROID
    // else if (isAndroidWebApp) {
    //   window.Android.updateBadge(unread)
    // }
  }, [unread])

  useEffect(() => {
    if (status === "authorized") {
      const interval = setInterval(() => {
        getUnreadMessages()
      }, 60000)
      return () => clearInterval(interval)
    }
  }, [])

  return (
    <div className={sheet.protected}>
      <Switch>
        <Route
          exact
          path={homePaths.PROTECTED()}
          render={(props) => <Redirect to={homePaths.ME()} />}
        />

        <Route path={homePaths.ME()} component={AsyncUserHome} />

        <SuRoute
          exact
          path="/protected/users"
          component={AsyncOtherUsers}
          userPermissions={userPermissions}
        />
        <SuRoute
          exact
          path="/protected/users/:userId"
          component={AsyncOtherUser}
          userPermissions={userPermissions}
        />
        <SuRoute
          exact
          path="/protected/force-client"
          component={AsyncForceClient}
          userPermissions={userPermissions}
        />

        <SuRoute
          exact
          path="/protected/identifyPuck"
          component={AsyncTag}
          userPermissions={userPermissions}
        />
        <SuRoute
          exact
          path="/protected/identifyPuck/:tagId"
          component={AsyncTag}
          userPermissions={userPermissions}
        />

        <SuRoute
          exact
          path="/protected/superusers"
          component={AsyncManageSuperUser}
          userPermissions={userPermissions}
        />

        <SuRoute
          path="/protected/tools"
          component={AsyncTools}
          userPermissions={userPermissions}
        />

        <SuRoute
          path="/protected/gyms"
          component={AsyncGyms}
          userPermissions={userPermissions}
        />

        <SuRoute
          path="/protected/partners"
          component={AsyncPartners}
          userPermissions={userPermissions}
        />

        <SuRoute
          exact
          path="/protected/statistics"
          component={AsyncStatistics}
          userPermissions={userPermissions}
        />

        <SuRoute
          exact
          path="/protected/translationTools"
          component={AsyncTranslationTools}
          userPermissions={userPermissions}
        />

        <Route component={NoMatch} />
      </Switch>
    </div>
  )

}


Protected.propTypes = {
  data: PropTypes.object.isRequired,
  getUser: PropTypes.func.isRequired,
}
