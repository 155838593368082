import { partners as types } from "../actions/actiontypes"

const initState = {
  ongoing: false,
  partners: [],
  error: undefined
}

export function partnersReducer(state = initState, action) {
  switch (action.type) {
    case "STORE_RESET":
      return Object.assign({}, initState)
    case types.GET:
      return Object.assign({}, state, { ongoing: true })
    case types.RESPONSE:
      return Object.assign({}, state, {
        ongoing: false,
        partners: action.partners
      })
    case types.GET_FAIL:
      return Object.assign({}, state, { ongoing: false, error: action })
    default:
      return state
  }
}
