import { myPrivateExercise as types } from '../actions/actiontypes'

const initState = {
  ongoing: [],
  exercises: {},
  delete: {},
  errors: {},
  mode: 'view',

  createOngoing: 'initial',
  exercise: null,
  error: null,
}

function addToOngoing(state, action) {
  return [
    ...state,
    {
      id: action.id,
      what: action.what,
    },
  ]
}

function removeFromOngoing(state, action) {
  return state.filter(o => o.id != action.id)
}

function addToDeleted(state, action) {
  return Object.assign({}, state, {
    [action.id]: 'deleted',
  })
}

function addToPrivateExercise(state, action) {
  return Object.assign({}, state, {
    [action.id]: action.exercises,
  })
}

function removeFromPrivateExercise(state, action) {
  let { [action.id]: omit, ...res } = state
  return res
}

function addToError(state, action) {
  if (action.id) {
    return Object.assign({}, state, {
      [action.id]: action.error,
    })
  } else {
    return Object.assign({}, state, {
      create: action.error,
    })
  }
}

export function myPrivateExerciseReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return {
        ...initState,
      }
    case types.CLEAR:
      return {
        ...state,
        createOngoing: 'initial',
        error: null,
        exercise: null,
      }
    case types.CLEAR_ERROR:
      return {
        ...state,
        errors: {},
        createOngoing: 'initial',
      }
    case types.CREATE:
      return {
        ...state,
        createOngoing: 'saving',
      }
    case types.CREATE_RESPONSE:
      return {
        ...state,
        createOngoing: 'saved',
        exercise: action.exercise,
      }
    case types.CREATE_FAIL:
      return {
        ...state,
        createOngoing: 'error',
        error: action.error,
        errors: addToError(state.error, action),
      }
    case types.GET:
    case types.SAVE:
    case types.DELETE:
      return {
        ...state,
        ongoing: addToOngoing(state.ongoing, action),
      }
    case types.RESPONSE:
    case types.SAVE_RESPONSE:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        exercises: addToPrivateExercise(state.exercises, action),
        mode: 'view',
      }
    case types.DELETE_RESPONSE:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        exercises: removeFromPrivateExercise(state.exercises, action),
        delete: addToDeleted(state.delete, action),
      }
    case types.GET_FAIL:
    case types.SAVE_FAIL:
    case types.DELETE_FAIL:
      return {
        ...state,
        ongoing: removeFromOngoing(state.ongoing, action),
        errors: addToError(state.error, action),
        createOngoing: 'initial',
      }
    case types.SET_MODE:
      return {
        ...state,
        mode: action.mode,
      }
    default:
      return state
  }
}
