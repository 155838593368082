import {connect} from 'react-redux'
import PasswordForgotten from '../component/PasswordForgotten'
import {passwordForgotten as action} from 'redux/actions/actions.js'

const mapStateToProps = (state) => {
  const {showWelcomeScreen} = state.passwordForgottenReducer
  return {
    showSuccess: showWelcomeScreen
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    hideSuccess: () => dispatch(action.hideWelcomeScreen())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForgotten)
