import { tabs as types } from '../actions/actiontypes'

const initState = {
  tabs: [],
}

const updateTab = (update, state) => {
  const updatedTabs = {
    ...state.tabs,
    [update.place]: update.currentIndex
  }
  return updatedTabs
}

export function tabsReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return {
        ...initState,
      }
    case types.UPDATE:
      return {
        ...state,
        tabs: updateTab(action.tab, state),
      }
    default:
      return state
  }
}
