import { userDashboardStatistics as types } from '../actions/actiontypes'
import moment from 'moment'

const getDataPoints = (array1, array2) => {
  let array = []
  array1 && array1.map(a => a.items ? array = array.concat(a.items) : null)
  array2 && array2.map(a => a.items ? array = array.concat(a.items) : null)
  array = array.sort(function (a, b) {
    return new Date(a.endedAt) - new Date(b.endedAt)
  })
  array = array.map(a => moment(a.endedAt).format('YYYY-MM-DD'))

  return [...new Set(array)].map(s => ({ endedAt: s }))
}

const initState = {
  generalOngoing: true,
  general: null,
  generalDate: moment().set({ hours: '23', minutes: '59' }).toDate(),
  generalInterval: 'days',
  generalNumber: '30',

  allProgramsResultOngoing: false,
  programsResultOngoing: [],
  programsResult: [],
  programsDate: moment().set({ hours: '23', minutes: '59' }).toDate(),
  programsInterval: 'months',
  programsNumber: '3',
  programsDataPoints: [],
  programsGetId: null,

  performedExercises: [],
  performedExercisesOngoing: false,
  allExercisesResultOngoing: false,
  exercisesResultOngoing: [],
  exercisesResult: [],
  exercisesDate: moment().set({ hours: '23', minutes: '59' }).toDate(),
  exercisesInterval: 'months',
  exercisesNumber: '3',
  exercisesDataPoints: [],
  exercisesGetId: null,

  performedCardioExercises: [],
  performedCardioExercisesOngoing: false,
  allCardioExercisesResultOngoing: false,
  cardioExercisesResultOngoing: [],
  cardioExercisesResult: [],
  cardioExercisesDate: moment().set({ hours: '23', minutes: '59' }).toDate(),
  cardioExercisesInterval: 'months',
  cardioExercisesNumber: '3',
  cardioExercisesDataPoints: [],
  cardioExercisesGetId: null,

  errors: null,
}

export function userDashboardStatisticsReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return {
        ...initState,
      }
    case types.GET_GENERAL:
      return {
        ...state,
        generalOngoing: true,
        general: null,
      }
    case types.GET_GENERAL_RESPONSE:
      return {
        ...state,
        generalOngoing: false,
        general: action.data,
      }
    case types.GET_GENERAL_FAILED:
      return {
        ...state,
        generalOngoing: false,
        errors: action,
      }

    //WORKOUTS
    case types.GET_PERFORMED_WORKOUTS_RESPONSE:
      return {
        ...state,
        programsGetId: action.id,
      }
    case types.GET_WORKOUTS:
      return {
        ...state,
        allProgramsResultOngoing: true,
        programsResult: [],
        programsResultOngoing: [],
      }
    case types.GET_WORKOUTS_RESPONSE:
      return {
        ...state,
        allProgramsResultOngoing: false,
        programsResult: action.result.map(r => ({
          ...r, items: r.items.map(i =>
            ({ ...i, endedAt: moment(i.endedAt).format("YYYY-MM-DD") }))
            .sort(function (a, b) {
              return new Date(a.endedAt) - new Date(b.endedAt)
            })
        })),
        programsDataPoints: getDataPoints(action.result),
      }
    case types.GET_WORKOUTS_FAILED:
      return {
        ...state,
        allProgramsResultOngoing: false,
        programsResult: [],
        errors: action,
      }
    case types.GET_WORKOUTS_SELECTION:
      return {
        ...state,
        programsResultOngoing: state.programsResultOngoing.concat(action.programs),
      }
    case types.GET_WORKOUTS_SELECTION_RESPONSE:
      return {
        ...state,
        programsResultOngoing: state.programsResultOngoing.filter(p => !action.programs.includes(p)),
        programsResult: state.programsResult.concat(action.result.map(r => ({
          ...r, items: r.items.map(i => ({ ...i, endedAt: moment(i.endedAt).format("YYYY-MM-DD") }))
            .sort(function (a, b) {
              return new Date(a.endedAt) - new Date(b.endedAt)
            })
        }))),
        programsDataPoints: getDataPoints(action.result, state.programsResult),
      }
    case types.GET_WORKOUTS_SELECTION_FAILED:
      return {
        ...state,
        programsResultOngoing: state.programsResultOngoing.filter(p => !action.programs.includes(p)),
        errors: action,
      }

    //EXERCISES

    case types.GET_PERFORMED_EXERCISES:
      return {
        ...state,
        performedExercisesOngoing: true,
        performedExercises: [],
      }
    case types.GET_PERFORMED_EXERCISES_RESPONSE:
      return {
        ...state,
        performedExercisesOngoing: false,
        performedExercises: action.result,
        exercisesGetId: action.id,
      }
    case types.GET_PERFORMED_EXERCISES_FAILED:
      return {
        ...state,
        performedExercisesOngoing: false,
        errors: action,
      }
    case types.GET_EXERCISES:
      return {
        ...state,
        allExercisesResultOngoing: true,
        exercisesResult: [],
        exercisesResultOngoing: [],
      }
    case types.GET_EXERCISES_RESPONSE:
      return {
        ...state,
        allExercisesResultOngoing: false,
        exercisesResult: action.result.map(r => ({
          ...r, items: r.items.map(i =>
            ({ ...i, endedAt: moment(i.endedAt).format("YYYY-MM-DD") }))
            .sort(function (a, b) {
              return new Date(a.endedAt) - new Date(b.endedAt)
            })
        })),
        exercisesDataPoints: getDataPoints(action.result),
      }
    case types.GET_EXERCISES_FAILED:
      return {
        ...state,
        allExercisesResultOngoing: false,
        exercisesResult: false,
        errors: action,
      }
    case types.GET_EXERCISES_SELECTION:
      return {
        ...state,
        exercisesResultOngoing: state.exercisesResultOngoing.concat(action.exercises),
      }
    case types.GET_EXERCISES_SELECTION_RESPONSE:
      return {
        ...state,
        exercisesResultOngoing: state.exercisesResultOngoing.filter(p => !action.exercises.includes(p)),
        exercisesResult: state.exercisesResult.concat(action.result.map(r => ({
          ...r, items: r.items.map(i => ({ ...i, endedAt: moment(i.endedAt).format("YYYY-MM-DD") }))
            .sort(function (a, b) {
              return new Date(a.endedAt) - new Date(b.endedAt)
            })
        }))),
        exercisesDataPoints: getDataPoints(action.result, state.exercisesResult),
      }
    case types.GET_EXERCISES_SELECTION_FAILED:
      return {
        ...state,
        exercisesResultOngoing: state.exercisesResultOngoing.filter(p => !action.exercises.includes(p)),
        errors: action,
      }

    //CARDIO EXERCISES

    case types.GET_PERFORMED_CARDIO_EXERCISES:
      return {
        ...state,
        performedCardioExercisesOngoing: true,
        performedCardioExercises: [],
      }
    case types.GET_PERFORMED_CARDIO_EXERCISES_RESPONSE:
      return {
        ...state,
        performedCardioExercisesOngoing: false,
        performedCardioExercises: action.result,
        cardioExercisesGetId: action.id,
      }
    case types.GET_PERFORMED_CARDIO_EXERCISES_FAILED:
      return {
        ...state,
        performedCardioExercisesOngoing: false,
        errors: action,
      }
    case types.GET_CARDIO_EXERCISES:
      return {
        ...state,
        allCardioExercisesResultOngoing: true,
        cardioExercisesResult: [],
        cardioExercisesResultOngoing: [],
      }
    case types.GET_CARDIO_EXERCISES_RESPONSE:
      return {
        ...state,
        allCardioExercisesResultOngoing: false,
        cardioExercisesResult: action.result.map(r => ({
          ...r, items: r.items.map(i =>
          ({
            ...i,
            endedAt: moment(i.endedAt).format("YYYY-MM-DD")
          }))
            .sort(function (a, b) {
              return new Date(a.endedAt) - new Date(b.endedAt)
            })
        })),
        cardioExercisesDataPoints: getDataPoints(action.result),
      }
    case types.GET_CARDIO_EXERCISES_FAILED:
      return {
        ...state,
        allCardioExercisesResultOngoing: false,
        cardioExercisesResult: false,
        errors: action,
      }
    case types.GET_CARDIO_EXERCISES_SELECTION:
      return {
        ...state,
        cardioExercisesResultOngoing: state.cardioExercisesResultOngoing.concat(action.exercises),
      }
    case types.GET_CARDIO_EXERCISES_SELECTION_RESPONSE:
      return {
        ...state,
        cardioExercisesResultOngoing: state.cardioExercisesResultOngoing.filter(p => !action.exercises.includes(p)),
        cardioExercisesResult: state.cardioExercisesResult.concat(action.result.map(r => ({
          ...r, items: r.items.map(i => ({ ...i, endedAt: moment(i.endedAt).format("YYYY-MM-DD") }))
            .sort(function (a, b) {
              return new Date(a.endedAt) - new Date(b.endedAt)
            })
        }))),
        cardioExercisesDataPoints: getDataPoints(action.result, state.cardioExercisesResult),
      }
    case types.GET_CARDIO_EXERCISES_SELECTION_FAILED:
      return {
        ...state,
        cardioExercisesResultOngoing: state.cardioExercisesResultOngoing.filter(p => !action.exercises.includes(p)),
        errors: action,
      }

    //SET INTERVALS

    case types.SET_INTEVAL_SORT:
      return {
        ...state,
        generalInterval: action.interval,
        generalNumber: action.number,
      }
    case types.SET_WORKOUTS_SORT:
      return {
        ...state,
        programsDate: action.end,
        programsInterval: action.interval,
        programsNumber: action.number,
      }
    case types.SET_EXERCISES_SORT:
      return {
        ...state,
        exercisesDate: action.end,
        exercisesInterval: action.interval,
        exercisesNumber: action.number,
      }
    case types.SET_CARDIO_EXERCISES_SORT:
      return {
        ...state,
        cardioExercisesDate: action.end,
        cardioExercisesInterval: action.interval,
        cardioExercisesNumber: action.number,
      }

    default:
      return state
  }
}
