import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import i18n from 'i18n/i18n'
import PropTypes from 'prop-types'
import { Modal, Button, List } from 'semantic-ui-react'
import styled from 'styled-components'
import i18next from 'i18next'
import { firstLetterToLowercase } from 'util/util'

const Link = styled.a`
  text-decoration: underline;
`

const Container = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  @media (min-width: 768px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`

const ModalLink = styled.span`
  text-decoration: underline;
  color: #1e70bf;
  cursor: pointer;
  :hover {
    text-decoration: none;
  }
`

function Terms(props) {
  const { userLangCode } = props
  const initialState = { showPersonalData: false }
  const [{ showPersonalData }, setState] = useState(initialState)

  const personalDataModal = (open) => {
    setState((prevState) => ({ ...prevState, showPersonalData: open }))
  }

  return (
    <Container>
      <span>
        {i18next.t(
          'by_creating_an_account_you_agree_that_life_fitness_mobile_will_collect_your'
        ) + ' '}
      </span>
      <ModalLink onClick={() => personalDataModal(true)}>
        {firstLetterToLowercase(i18next.t('personal_data'))}
      </ModalLink>
      <span>
        {' ' +
          i18next.t(
            'to_monitor_your_workout_results_life_fitness_will_handle_your_data_per_our'
          ) +
          ' '}
      </span>
      <Link
        href="https://www.halo.fitness/web/halo.html#/termsOfUse?developerMode=false&selectedTncTab=privacy"
        target="_blank"
      >
        {i18next.t('privacy_policy') + '. '}
      </Link>
      <span> </span>
      <Link
        href="https://www.halo.fitness/web/halo.html#/termsOfUse?developerMode=false&selectedTncTab=tnc"
        target="_blank"
      >
        {i18next.t('terms_of_use')}
      </Link>
      <span> {i18next.t('apply')}.</span>
      <Modal size="small" open={showPersonalData}>
        <Modal.Header>{i18next.t('personal_data')}</Modal.Header>
        <Modal.Content>
          <List>
            <List.Item>
              <List.Icon name="user" />
              <List.Content>{i18next.t('gym_profile')}</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="map marker alternate" />
              <List.Content>{i18next.t('gym_location')}</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="area graph" />
              <List.Content>{i18next.t('workout_data')}</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="mail" />
              <List.Content>{i18next.t('email_address')}</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="weight" />
              <List.Content>{i18next.t('weight')}</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="heart" />
              <List.Content>{i18next.t('heart_rate')}</List.Content>
            </List.Item>
          </List>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            positive
            onClick={() => personalDataModal(false)}
          >
            {i18next.t('ok')}
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  )
}

//Hack to set language on this site only
const getLangCode = (lang) => {
  if (lang.length >= 2) {
    const firstCode = lang.substring(0, 2)
    return firstCode
  }
  return 'en'
}

const mapStateToProps = (state) => {
  //Hack to set language on this site only
  const userLang = navigator.language || navigator.userLanguage
  const userLangCode = getLangCode(userLang)
  i18n.changeLanguage(userLangCode)
  return {
    userLangCode,
  }
}

const mapDispatchToProps = (dispatch) => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Terms))
