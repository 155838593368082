import {connect} from 'react-redux'
import JoinForm from '../component/JoinForm'
import {join as action} from 'redux/actions/actions.js'
import {join as thunk} from 'redux/actions/thunks.js'
import withReduxForm from 'app/common/hoc/redux-form/ReduxForm'

const mapStateToProps = (state) => {
  return {
    ...state.joinReducer
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default withReduxForm(mapStateToProps, mapDispatchToProps, action, thunk)(JoinForm)
