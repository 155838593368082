import { partner as types } from "../actions/actiontypes"

// require {partnerID, what} = action
function addToOngoing(state, action) {
  return [
    ...state,
    {
      partnerID: action.partnerID,
      what: action.what
    }
  ]
}

// require {partnerID, what} = action
function removeFromOngoing(state, action) {
  return state.filter(
    entry => entry.what != action.what || entry.partnerID != action.partnerID
  )
}

// require {partnerID} = action
function addToPartners(state, action) {
  return {
    ...state,
    [action.partnerID]: action.partner
  }
}

// {partnerID, error} = action
function addToErrors(state, action) {
  return {
    ...state,
    [action.partnerID]: action.error
  }
}

// require {partnerID} = action
function addToDeleted(state, action) {
  return Object.assign({}, state, {
    [action.partnerID]: "deleted"
  })
}

const initState = {
  ongoings: [],
  partners: {},
  errors: {},
  deleted: {},
  users: [],
  workOngoing: "nothing",
  das: null,
  dasOngoing: false,
  dasError: null,
}

export function partnerReducer(state = initState, action) {
  switch (action.type) {
    case "STORE_RESET":
      return {
        ...initState
      }
    case types.GET:
    case types.SAVE:
    case types.DELETE:
      return {
        ...state,
        ongoings: addToOngoing(state.ongoings, action)
      }
    case types.GET_RESPONSE:
    case types.SAVE_RESPONSE:
      return {
        ...state,
        ongoings: removeFromOngoing(state.ongoings, action),
        partners: addToPartners(state.partners, action)
      }
    case types.GET_FAIL:
    case types.SAVE_FAIL:
    case types.DELETE_FAIL:
      return {
        ...state,
        ongoings: removeFromOngoing(state.ongoings, action),
        errors: addToErrors(state.errors, action)
      }
    case types.DELETE_RESPONSE:
      return {
        ...state,
        deleted: addToDeleted(state.deleted, action)
      }
    case types.GET_USERS:
      return {
        ...state,
        users: [],
        ongoings: addToOngoing(state.ongoings, action)
      }
    case types.GET_USERS_RESPONSE:
      return {
        ...state,
        users: action.users,
        ongoings: removeFromOngoing(state.ongoings, action),
      }
    case types.GET_USERS_FAIL:
      return {
        ...state,
        users: [],
        ongoings: removeFromOngoing(state.ongoings, action),
        errors: addToErrors(state.errors, action)
      }
    case types.ADD_USER:
      return {
        ...state,
        workOngoing: "adding",
      }
    case types.ADD_USER_RESPONSE:
      return {
        ...state,
        workOngoing: "nothing",
        users: [...state.users, action.user],
      }
    case types.ADD_USER_FAIL:
      return {
        ...state,
        workOngoing: "nothing",
        errors: addToErrors(state.errors, action)
      }
    case types.REMOVE_USER:
      return {
        ...state,
        workOngoing: "removing",
      }
    case types.REMOVE_USER_RESPONSE:
      return {
        ...state,
        workOngoing: "nothing",
        users: state.users.filter(u => u.email !== action.user),
      }
    case types.REMOVE_USER_FAIL:
      return {
        ...state,
        workOngoing: "nothing",
        errors: addToErrors(state.errors, action)
      }
    case types.CLEAR:
      return {
        ...state,
        workOngoing: "nothing",
        ongoings: [],
        errors: {}
      }
    case types.GET_DAS:
      return {
        ...state,
        das: null,
        dasOngoing: true,
        dasError: null,
      }
    case types.DAS_RESPONSE:
      return {
        ...state,
        das: action.das,
        dasOngoing: false,
      }
    case types.DAS_FAIL:
      return {
        ...state,
        das: null,
        dasError: action.error,
        dasOngoing: false,
      }
    case types.CLEAR_DAS:
      return {
        ...state,
        das: null,
        dasError: null,
        dasOngoing: false,
      }
    default:
      return state
  }
}
