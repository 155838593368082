import { gymUtilization as types } from '../actions/actiontypes'
import { getToday, getSevenDaysAgo, isValidDate } from 'util/util'
import moment from 'moment'

const setDate = ({ state, action }) => {
  const oldFrom = state.from
  const oldTo = state.to
  const newFrom = action.from
  const newTo = action.to

  if (!isValidDate(newFrom) && !isValidDate(newTo)) {
    if (isValidDate(oldFrom) && isValidDate(oldTo)) {
      return {
        ...state,
        from: oldFrom,
        to: oldTo,
      }
    } else {
      return {
        ...state,
        from: moment().subtract(14, 'd'),
        to: getToday(),
      }
    }
  }

  return {
    ...state,
    from: newFrom,
    to: newTo,
  }
}

const initialState = {
  ongoing: true,
  calculating: false,
  machines: [],
  observerWarnings: [],
  daysObserverDown: 0,
  site: {},
  from: moment().subtract(1, 'month').startOf('month').set({ hours: '00', minutes: '00' }).toDate(),
  to: moment().subtract(1, 'month').endOf('month').set({ hours: '23', minutes: '59' }).toDate(),
  sortOn: 'month',
  ongoingGetTaps: true,
  calculatingTaps: false,
  pucks: [],
  requestedSite: null,
  lastRequestDateMachine: null,
  lastRequestDatePuck: null,
}

const getToggledMachine = (machines, id) => {
  const _machines = machines.slice()
  let m = machines.find((m) => m._id === id)
  m.selected = !m.selected

  return _machines
}

const getAllToggledMachines = (machines, toggledState) => {
  const _machines = machines.slice()
  _machines.map((m) => (m.selected = !toggledState))

  return _machines
}

const daysDown = (observerWarnings) => {
  let days = 0
  observerWarnings.map(o => {
    days += moment(o.resolvedAt ? o.resolvedAt : new Date()).diff(o.issuedAt, 'days')
  })

  return days
}

export function gymUtilization(state = initialState, action) {
  switch (action.type) {
    case types.GET:
      return {
        ...state,
        ongoing: true,
        requestedSite: action.siteId,
        lastRequestDateMachine: action.lastRequestDateMachine,
      }
    case types.CALCULATING:
      return {
        ...state,
        calculating: true,
      }
    case types.RESPONSE:
      if (action.data.site._id === state.requestedSite && moment(state.lastRequestDateMachine).isSame(action.lastRequestDateMachine)) {
        return {
          ...state,
          ongoing: false,
          machines: action.data.machines,
          site: action.data.site,
          observerWarnings: action.data.observerWarnings,
          daysObserverDown: daysDown(action.data.observerWarnings),
          calculating: false,
        }
      } else {
        return { ...state }
      }
    case types.FAILURE:
      return {
        ...state,
        ongoing: false,
      }

    case types.SELECT_MACHINE:
      return {
        ...state,
        machines: getToggledMachine(state.machines, action.id),
      }
    case types.TOGGLE_SELECT_ALL_MACHINES:
      return {
        ...state,
        machines: getAllToggledMachines(state.machines, action.toggledState),
      }

    case types.GET_TAPS:
      return {
        ...state,
        ongoingGetTaps: true,
        requestedSite: action.siteId,
        lastRequestDatePuck: action.lastRequestDatePuck,
      }
    case types.CALCULATING_TAPS:
      return {
        ...state,
        calculatingTaps: true,
      }
    case types.GET_TAPS_RESPONSE:
      if (action.data.site._id === state.requestedSite && moment(state.lastRequestDatePuck).isSame(action.lastRequestDatePuck)) {
        return {
          ...state,
          ongoingGetTaps: false,
          pucks: action.data.machines,
          site: action.data.site,
          calculatingTaps: false,
        }
      } else {
        return { ...state }
      }
    case types.GET_TAPS_FAIL:
      return {
        ...state,
        ongoingGetTaps: false,
      }

    case types.SELECT_PUCK:
      return {
        ...state,
        pucks: getToggledMachine(state.pucks, action.id),
      }
    case types.TOGGLE_SELECT_ALL_PUCKS:
      return {
        ...state,
        pucks: getAllToggledMachines(state.pucks, action.toggledState),
      }

    case types.SET_DATE:
      return setDate({ state, action })

    case types.SET_SORT:
      return {
        ...state,
        sortOn: action.sortOn
      }

    default:
      return state
  }
}
