export const machineType = ['reps_only', 'reps_and_weight']

export const machineTypeOptions = [
  {
    key: 'reps_only',
    text: 'Reps only',
    value: 'reps_only'
  }, {
    key: 'reps_and_weight',
    text: 'Reps and weight',
    value: 'reps_and_weight'
  }
]

export function isMachine(machine) {
  return machineType.includes(machine.type)
}
