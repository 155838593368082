import { manageMember as types } from '../actions/actiontypes'

const initReducerState = {
  ongoing: 'nothing',
  member: null,
  error: null,
  notes: [],
  ongoingNotes: 'nothing',
}

const updateNotes = (notes, note) => {
  let list = []
  notes && notes.map(n => {
    if (n._id === note._id) {
      list.push(note)
    } else {
      list.push(n)
    }
  })
  return list
}

export function manageMemberReducer(state = initReducerState, action) {
  switch (action.type) {
    case types.GET_MEMBER:
      return {
        ...state,
        ongoing: 'fetching',
        isRemoved: false,
      }
    case types.GET_MEMBER_RESPONSE:
      return {
        ...state,
        ongoing: 'nothing',
        member: action.member,
        isRemoved: false,
      }
    case types.GET_MEMBER_FAILED:
      return {
        ...state,
        ongoing: 'nothing',
        error: action.error,
      }

    case types.SAVE_MEMBER:
      return {
        ...state,
        ongoing: 'saving',
        member: action.member,
      }
    case types.SAVE_MEMBER_RESPONSE:
      return {
        ...state,
        ongoing: 'nothing',
        member: { ...state.member, permissions: action.data.permissions, allowResponse: action.data.allowResponse },
      }
    case types.SAVE_MEMBER_FAILED:
      return {
        ...state,
        ongoing: 'nothing',
        error: action.error,
      }

    case types.REMOVE_MEMBER:
      return {
        ...state,
        ongoing: 'removing',
      }
    case types.REMOVE_MEMBER_RESPONSE:
      return {
        ...state,
        ongoing: 'nothing',
        member: null,
      }
    case types.REMOVE_MEMBER_STATUS_RESPONSE:
      return {
        ...state,
        ongoing: 'removed',
        member: null,
      }
    case types.REMOVE_MEMBER_FAILED:
      return {
        ...state,
        ongoingNotes: 'nothing',
        error: action.error,
      }

    case types.GET_NOTES:
      return {
        ...state,
        ongoingNotes: 'fetching',
      }
    case types.GET_NOTES_RESPONSE:
      return {
        ...state,
        ongoingNotes: 'nothing',
        notes: action.notes,
      }
    case types.GET_NOTES_FAILED:
      return {
        ...state,
        ongoingNotes: 'nothing',
        error: action.error,
      }
    case types.CREATE_NOTE:
      return {
        ...state,
        ongoingNotes: 'creating',
      }
    case types.CREATE_NOTE_RESPONSE:
      return {
        ...state,
        ongoingNotes: 'nothing',
        notes: [...state.notes, action.note],
      }
    case types.CREATE_NOTE_FAILED:
      return {
        ...state,
        ongoingNotes: 'nothing',
        error: action.error,
      }
    case types.REMOVE_NOTE:
      return {
        ...state,
        ongoingNotes: 'removing',
      }
    case types.REMOVE_NOTE_RESPONSE:
      return {
        ...state,
        notes: state.notes.filter((m) => m._id !== action.note),
        ongoingNotes: 'nothing',
      }
    case types.REMOVE_NOTE_FAILED:
      return {
        ...state,
        ongoingNotes: 'error',
        error: action,
      }
    case types.SAVE_NOTE:
      return {
        ...state,
        ongoingNotes: 'saving',
      }
    case types.SAVE_NOTE_RESPONSE:
      return {
        ...state,
        notes: updateNotes(state.notes, action.note),
        ongoingNotes: 'nothing',
      }
    case types.SAVE_NOTE_FAILED:
      return {
        ...state,
        ongoingNotes: 'error',
        error: action,
      }

    case types.CLEAR:
      return {
        ongoing: 'nothing',
        member: null,
        error: null,
        notes: []
      }

    case types.SET_UNREAD_MESSAGES:
      if (state.member) {
        return {
          ...state,
          member: { ...state.member, unreadMessages: action.unreadMessages },
        }
      } else {
        return { ...state }
      }
    default:
      return state
  }
}
