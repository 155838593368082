import React, { useState, useEffect } from 'react'
import { Segment, Header, Loader, Icon, Grid } from 'semantic-ui-react'
import i18next from 'i18next'
import request from 'app/common/hoc/request/request'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import advagymA from 'assets/images/advagym_a_white.svg'
import Badge from 'app/protected/roles/common/Badge'
import { mediumGrey } from 'app/protected/roles/util/colors'
import excercisesSvgWhite from 'assets/images/excercises_white.svg'
import dumbellSvgWhite from 'assets/images/dumbell_white.svg'
import _get from 'lodash/get'

function ExportUserData(props) {
  const { code } = props

  const initialState = {
    user: null,
    ongoing: false,
    valid: false,
    error: null
  }

  const [{ user, ongoing, valid, error }, setState] = useState(initialState)

  useEffect(() => {
    if (code && code !== "") {
      checkValidity()
    }
  }, [])

  let currentRequest = null

  const checkValidity = async () => {
    setState(prevState => ({ ...prevState, ongoing: true }))

    try {
      currentRequest = request({ method: 'get', url: `/api/web/export/user/code?code=${code}`, })
      let response = await currentRequest.promise
      setState(prevState => ({
        ...prevState,
        ongoing: false,
        valid: response.data.valid,
        user: response.data.user,
        error: null,
      }))
    } catch (error) {
      if (!error.isCanceled) {
        if (error.response) {
          console.log('ExportUserData check validity failed - 1', error.response)
          let msg = error.response.data.error || 'unknown response error'
          setState(prevState => ({ ...prevState, ongoing: false, valid: false, error: msg }))
        } else {
          console.log('ExportUserData check validity failed - 2', error)
          setState(prevState => ({ ...prevState, ongoing: false, valid: false, error: 'unknown error' }))
        }
      }
    }
  }

  const downloadCSV = (data, user, type) => {
    let filename = user.replace(/\s/g, "").toLowerCase()
      + '_advagym_'
      + type
      + '.csv'

    var blob = new Blob(["\ufeff", data])
    if (window.navigator.msSaveOrOpenBlob)  // IE hack see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
      window.navigator.msSaveBlob(blob, data.filename)
    else {
      var a = window.document.createElement("a")
      a.href = window.URL.createObjectURL(blob, { type: "text/plain;charset=utf-8" })
      a.download = filename
      document.body.appendChild(a)
      a.click()  // IE: "Access is denied" see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
      document.body.removeChild(a)
    }
  }

  const downloadCsv = async (type) => {
    setState(prevState => ({ ...prevState, ongoing: true }))

    try {
      currentRequest = request({ method: 'get', url: `/api/web/export/user/${code}?content=${type}`, })
      let response = await currentRequest.promise
      downloadCSV(response.data, _get(user, "name", "user"), type)
      setState(prevState => ({
        ...prevState,
        ongoing: false,
      }))
    } catch (error) {
      if (!error.isCanceled) {
        if (error.response) {
          console.log('ExportUserData workouts failed - 1', error.response)
          let msg = error.response.data.error || 'unknown response error'
          setState(prevState => ({ ...prevState, ongoing: false, error: msg }))
        } else {
          console.log('ExportUserData workouts failed - 2', error)
          setState(prevState => ({ ...prevState, ongoing: false, error: 'unknown error' }))
        }
      }
    }
  }

  const headerView = () => {
    const logo = user ? user.media ? user.media.find(m => m.name === "icon") : null : null
    return (
      <Grid>
        <Grid.Row textAlign="center" style={{ paddingBottom: '0px' }}>
          <Grid.Column textAlign="center">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Badge
                type="coach"
                label={user ? user.name : 'User'}
                icon=""
                customIcon={logo === '' ? advagymA : ''}
                size="big"
                imageUrl={logo ? logo.url : ''}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row textAlign="center">
          <Grid.Column>
            <Header as="h2" icon>
              <div>
                {i18next.t('welcome') + " "}
                {user ? user.name : 'User'}
              </div>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  const item = (action, icon, title, subtitle) => {
    return (
      <Grid.Column mobile="16" tablet="8" computer="8" style={{ marginBottom: '10px' }}>
        <Segment
          onClick={() => action()}
          style={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '15px',
            borderRadius: '4px',
          }}
        >
          <span>
            <Badge type="coach" customIcon={icon} />
          </span>

          <span
            style={{
              display: 'inline-flex',
              flexDirection: 'column',
              paddingLeft: '12px',
              flex: '1 0 0',
            }}
          >
            <div>
              <Header as="h4">{title}</Header>
            </div>
            <div>
              <span style={{ color: mediumGrey }}>
                {subtitle}
              </span>
            </div>
          </span>
        </Segment>
      </Grid.Column>
    )
  }

  if (ongoing) {
    return <>
      <Header textAlign="center" as="h2">{i18next.t('fetching') + '...'}</Header>
      <Loader size="massive" className='workaround' inline="centered" active />
    </>
  }
  if (!valid || error) {
    return (<div style={{ display: 'flex', flex: '1', justifyContent: 'center' }}>
      <Header as="h2" icon>
        <Icon name="warning sign" />
        {i18next.t('link_has_expired_or_not_valid')}
        <Header.Subheader>
          {'Your link has expired. Open up the TrainerConnect app and press export again.'}
        </Header.Subheader>
      </Header>
    </div>)
  } else {
    return (<Grid>
      <Grid.Row columns={1}>
        <Grid.Column>
          {headerView()}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered>
        {item(() => downloadCsv('workouts'), excercisesSvgWhite, i18next.t('workouts'), i18next.t('export_to_csv'))}
        {item(() => downloadCsv('routines'), dumbellSvgWhite, i18next.t('workouts'), i18next.t('export_to_csv'))}
      </Grid.Row>
    </Grid>)
  }
}

const mapStateToProps = (state, props) => {
  const { match } = props
  const code = match.params.id ? match.params.id : ''

  return {
    code,
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(ExportUserData)
)
