import { connect } from "react-redux"
import Main from "../component/Main"
import { withRouter } from "react-router-dom"
import { user } from "redux/actions/thunks.js"

const mapStateToProps = state => {
  const { status } = state.userReducer
  return {
    userIsSignedIn: status == "authorized",
    loading: status == "unknown"
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUser: () => {
      dispatch(user.get())
    }
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Main)
)
