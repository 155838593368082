import { connect } from 'react-redux'
import { menu as menuAction } from 'redux/actions/actions.js'
import { actionFooter as footer } from 'redux/actions/actions'
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Menu, Divider } from 'semantic-ui-react'
import Badge from 'app/protected/roles/common/Badge'
import { advagymGreen } from 'app/protected/roles/util/colors'
import { homePaths } from 'app/protected/common/constants/routes'
import { getGymLogo } from 'util/util'

function SidebarGymMenu(props) {
  const { location, history, closeMenu, sites } = props

  const isExactPath = (_id) => {
    const { pathname } = location
    const gymPath = homePaths.GYMS(_id)
    return (
      pathname &&
      gymPath &&
      pathname.replace(/\//g, '') == gymPath.replace(/\//g, '')
    )
  }

  const escapeFunction = event => {
    if (event.key == 'Escape') {
      closeMenu()
    }
  }

  const goToPath = _id => {
    history.push(homePaths.GYMS(_id))
    closeMenu()
  }

  const _sites = sites.filter(s => !s.isRemoved)

  const menuItemStyles = item => {
    if (isExactPath(item)) {
      return {
        color: advagymGreen,
        fontWeight: 'bold',
      }
    } else {
      return {
        color: '#000',
      }
    }
  }

  return (
    <Menu secondary vertical style={{ width: '100%', margin: '14px 0 0' }}>
      <div style={{ padding: '0 14px' }}>
        <Divider />
      </div>

      {_sites.map((site, key) => (
        <React.Fragment key={key}>
          <Menu.Item
            onClick={() => goToPath(site._id)}
            style={{
              justifyContent: 'left',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <React.Fragment>
              {site.media.length > 0 ? (
                <Badge
                  gymName={site.name}
                  imageUrl={
                    getGymLogo(site.media)
                      ? getGymLogo(site.media).url
                      : ''
                  }
                />
              ) : (
                <Badge label={site.name} />
              )}
              <span
                style={{ paddingLeft: '7px', ...menuItemStyles(site._id) }}
              >
                {site.name}
              </span>
            </React.Fragment>
          </Menu.Item>
        </React.Fragment>
      ))}

      <div style={{ padding: '0 14px' }}>
        <Divider />
      </div>
    </Menu>
  )

}

SidebarGymMenu.propTypes = {
  closeMenu: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      address: PropTypes.string,
      city: PropTypes.string,
    })
  ).isRequired,
  clearActionFooter: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  const { uniqueSites: sites } = state.userReducer

  return {
    sites,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeMenu: () => {
      dispatch(menuAction.close())
    },
    clearActionFooter: () => {
      dispatch(footer.clear())
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SidebarGymMenu)
)
