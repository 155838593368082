import { gymZones as types } from '../actions/actiontypes'

const initState = {
  ongoing: false,
  machines: [],
  globalZones: [],
  error: undefined,
  zone: null,
  importOngoing: "nothing",
  routines: [],
  exercises: [],
}

export function myGymZonesReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return { ...state, initState }
    case types.GET:
      return {
        ...state,
        ongoing: true,
      }
    case types.RESPONSE:
      if (action.id === "global") {
        return {
          ...state,
          ongoing: false,
          globalZones: action.machines,
          routines: action.routines,
          exercises: action.exercises,
        }
      } else {
        return {
          ...state,
          ongoing: false,
          machines: action.machines,
        }
      }
    case types.GET_FAIL:
      return { ...state, ongoing: false, error: action }

    case types.CREATE:
      return {
        ...state,
        ongoing: true,
        zone: action.machine,
      }
    case types.CREATE_RESPONSE:
      return {
        ...state,
        ongoing: false,
        zone: action.machine,
      }
    case types.CREATE_FAILED:
      return {
        ...state,
        ongoing: false,
        zone: null,
        error: action.error.response ? action.error.response.statusText : 'Error: Network Error',
      }
    case types.CLEAR:
      return {
        ...state,
        ongoing: false,
        zone: null,
        machines: [],
        error: undefined,
      }
    case types.FILTER_OUT_ZONE:
      return {
        ...state,
        machines: state.machines.filter(m => m._id !== action.machineId),
      }

    case types.IMPORT:
      return {
        ...state,
        importOngoing: "importing",
        error: null,
      }
    case types.IMPORT_RESPONSE:
      return {
        ...state,
        importOngoing: "imported",
        error: null,
      }
    case types.IMPORT_FAILED:
      return {
        ...state,
        importOngoing: "nothing",
        error: action.error,
      }
    default:
      return state
  }
}