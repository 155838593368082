import {exercises as types} from '../actions/actiontypes'

const initState = {
  ongoing: false,
  exercises: [],
  error: undefined
}

export function globalExercisesReducer(state = initState, action) {
  switch (action.type) {
    case "STORE_RESET":
      return Object.assign({}, initState)
    case types.GET:
      return Object.assign({}, state, {ongoing: state.exercises.length > 0 ? false : true})
    case types.RESPONSE:
      return Object.assign({}, state, {
        ongoing: false,
        exercises: action.exercises
      })
    case types.GET_FAIL:
      return Object.assign({}, state, {ongoing: false, error: action})
    default:
      return state
  }
}
