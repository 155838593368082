import axios from "axios"

const noCacheAxios = axios.create({
  headers: {
    "Cache-Control": "no-cache,no-store,must-revalidate,max-age=-1,private",
    "Content-Type": "application/json"
  }
})

export default () =>
  new Promise((resolve, reject) => {
    noCacheAxios({
      url: "/build-info/build.json",
      responseType: "json"
    })
      .then(response => {
        resolve(response.data.commitHash)
      })
      .catch(error => {
        console.error("checkBuild error", error)
        reject(error)
      })
  })
