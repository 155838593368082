import { myGymStaff as types } from '../actions/actiontypes'
import moment from 'moment'

const initState = {
  user: null,
  ongoing: 'nothing',
  error: null,

  clients: [],
  clientsSearchResult: [],
  clientsOngoing: 'nothing',
  clientsSearchOngoing: 'nothing',

  members: [],
  membersSearchResult: [],
  membersOngoing: 'nothing',
  membersSearchOngoing: 'nothing',

  membersStatisticsOngoing: false,
  membersStatistics: [],
  lastRequestMembers: null,
  clientsStatisticsOngoing: false,
  clientsStatistics: [],
  lastRequestClients: null,
}

export function gymStaffReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return { ...initState }

    case types.GET:
      return {
        ...state,
        ongoing: 'fetching',
        user: null,
      }
    case types.GET_RESPONSE:
      return {
        ...state,
        ongoing: 'nothing',
        user: action.user,
      }
    case types.GET_FAIL:
      return {
        ...state,
        ongoing: 'nothing',
        user: null,
        error: action.error,
      }
    case types.UPDATE:
      return {
        ...state,
        user: state.user ? state.user.permission ? { ...state.user, permission: { ...state.user.permission, role: action.role } } : null : null,
      }
    case types.REMOVE:
      return {
        ...state,
        ongoing: 'removing'
      }
    case types.REMOVE_RESPONSE:
      return {
        ...state,
        ongoing: 'removed'
      }
    case types.REMOVE_FAIL:
      return {
        ...state,
        ongoing: 'error',
        action: action.error,
      }

    // === CLIENTS === //
    case types.GET_CLIENTS:
      return {
        ...state,
        clientsOngoing: 'fetching',
        clients: [],
      }
    case types.GET_CLIENTS_RESPONSE:
      return {
        ...state,
        clientsOngoing: 'nothing',
        clients: action.clients,
      }
    case types.GET_CLIENTS_FAIL:
      return {
        ...state,
        clientsOngoing: 'nothing',
        clients: [],
        error: action.error,
      }
    case types.SEARCH_FOR_CLIENTS:
      return {
        ...state,
        clientsSearchOngoing: 'searching',
        clientsSearchResult: [],
      }
    case types.SEARCH_FOR_CLIENTS_RESPONSE:
      return {
        ...state,
        clientsSearchOngoing: 'done',
        clientsSearchResult: action.clients,
      }
    case types.SEARCH_FOR_CLIENTS_FAILED:
      return {
        ...state,
        clientsSearchOngoing: 'nothing',
        clientsSearchResult: [],
        error: action.error,
      }
    case types.GET_CLIENTS_STATISTICS:
      return {
        ...state,
        clientsStatisticsOngoing: true,
        clientsStatistics: [],
        lastRequestClients: action.timeStamp,
      }
    case types.GET_CLIENTS_STATISTICS_RESPONSE:
      if (moment(state.lastRequestClients).isSame(action.timeStamp)) {
        return {
          ...state,
          clientsStatisticsOngoing: false,
          clientsStatistics: action.clients,
        }
      } else {
        return { ...state }
      }
    case types.GET_CLIENTS_STATISTICS_FAIL:
      return {
        ...state,
        clientsStatisticsOngoing: false,
        clientsStatistics: [],
        error: action.error,
      }

    // === MEMBERS === //
    case types.GET_MEMBERS:
      return {
        ...state,
        membersOngoing: 'fetching',
        members: [],
      }
    case types.GET_MEMBERS_RESPONSE:
      return {
        ...state,
        membersOngoing: 'nothing',
        members: action.members,
      }
    case types.GET_MEMBERS_FAIL:
      return {
        ...state,
        membersOngoing: 'nothing',
        members: [],
        error: action.error,
      }
    case types.SEARCH_FOR_MEMBERS:
      return {
        ...state,
        membersSearchOngoing: 'searching',
        membersSearchResult: [],
      }
    case types.SEARCH_FOR_MEMBERS_RESPONSE:
      return {
        ...state,
        membersSearchOngoing: 'done',
        membersSearchResult: action.members,
      }
    case types.SEARCH_FOR_MEMBERS_FAILED:
      return {
        ...state,
        clientsSearchOngoing: 'nothing',
        clientsSearchResult: [],
        error: action.error,
      }
    case types.GET_MEMBERS_STATISTICS:
      return {
        ...state,
        membersStatisticsOngoing: true,
        membersStatistics: [],
        lastRequestMembers: action.timeStamp,
      }
    case types.GET_MEMBERS_STATISTICS_RESPONSE:
      if (moment(state.lastRequestMembers).isSame(action.timeStamp)) {
        return {
          ...state,
          membersStatisticsOngoing: false,
          membersStatistics: action.members,
        }
      } else {
        return { ...state }
      }
    case types.GET_MEMBERS_STATISTICS_FAIL:
      return {
        ...state,
        membersStatisticsOngoing: false,
        membersStatistics: [],
        error: action.error,
      }

    case types.CLEAR:
      return {
        ...state,
        user: null,
        ongoing: 'nothing',
      }
    case types.SEARCH_FOR_CLEAR:
      return {
        ...state,
        clientsSearchOngoing: 'nothing',
        clientsSearchResult: [],
        membersSearchOngoing: 'nothing',
        membersSearchResult: [],
      }

    default:
      return state
  }
}
