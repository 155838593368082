import { myStacks as types } from '../actions/actiontypes'

const initState = {
  ongoing: [],
  count: {},
  all: [],
  site: [],
  chainSite: [],
  global: [],
  zone: [],
  error: [],
  searchResult: [],
  searchOngoing: [],
}

export function myStacksReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return Object.assign({}, initState)
    case types.GET_STACKS:
      return {
        ...state,
        ongoing: [...state.ongoing, action.scope]
      }
    case types.RESPONSE:
      return {
        ...state,
        ongoing: state.ongoing.filter(o => o !== action.scope),
        stacks: action.stacks,
        count: { ...state.count, [action.scope]: action.count }
      }
    case types.GET_STACKS_RESPONSE:
      return {
        ...state,
        ongoing: state.ongoing.filter(o => o !== action.scope),
        [action.scope]: action.stacks,
        count: { ...state.count, [action.scope]: action.count }
      }
    case types.GET_STACKS_FAILED:
      return {
        ...state,
        ongoing: state.ongoing.filter(o => o !== action.scope),
        error: [...state.error, action.error],
      }

    case types.SEARCH_FOR_STACKS:
      return {
        ...state,
        searchOngoing: [...state.searchOngoing, { scope: action.scope, what: 'searching' }],
      }
    case types.SEARCH_FOR_STACKS_RESPONSE:
      return {
        ...state,
        searchOngoing: [...state.searchOngoing.filter(o => o.scope !== action.scope), { scope: action.scope, what: 'done' }],
        searchResult: action.stacks,
      }
    case types.SEARCH_FOR_STACKS_FAILED:
      return {
        ...state,
        searchOngoing: state.searchOngoing.filter(o => o.scope !== action.scope),
        searchResult: [],
        error: [...state.error, action.error],
      }
    case types.SEARCH_FOR_STACKS_CLEAR:
      return {
        ...state,
        searchOngoing: [],
        searchResult: [],
      }
    case types.FILTER_OUT_STACK:
      return {
        ...state,
        all: state.all.filter(item => item._id !== action.stack),
        site: state.site.filter(item => item._id !== action.stack),
        chainSite: state.chainSite.filter(item => item._id !== action.stack),
        global: state.global.filter(item => item._id !== action.stack),
        zone: state.zone.filter(item => item._id !== action.stack),
        searchResult: state.searchResult.filter(item => item._id !== action.stack),
      }
    case types.SILENT_GET_COUNT:
      return {
        ...state,
        count: { ...state.count, [action.scope]: action.count }
      }
    default:
      return state
  }
}