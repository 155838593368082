import { connect } from 'react-redux'
import OnRouteChange from '../component/OnRouteChange'
import { menu as menuAction } from 'redux/actions/actions.js'

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    closeMenu: () => {
      dispatch(menuAction.close())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnRouteChange)
