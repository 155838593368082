import React from 'react'
import {connect} from 'react-redux'

export default function withReduxForm(stateToProps, dispatchToProps, action, thunk) {
  return (WrappedComponent) => {
    const mapDispatchToProps = (dispatch) => {
      return Object.assign({}, {
        post: (data) => {
          dispatch(thunk.post(data))
        },
        showFormStatus: (message, type) => {
          dispatch(action.showFormStatus(message, type))
        },
        hideFormStatus: () => {
          dispatch(action.hideFormStatus())
        },
        onInput: () => {
          dispatch(action.onInput())
        }
      }, dispatchToProps(dispatch))
    }
    class ReduxRouterForm extends React.PureComponent {
      render() {
        return <WrappedComponent {...this.props}/>
      }
    }
    return connect(stateToProps, mapDispatchToProps)(ReduxRouterForm)
  }
}
