import { myGymMemberActivity as types } from '../actions/actiontypes'

const initState = {
  ongoing: 'nothing',
  activity: [],
  error: null,
}

export function gymMemberActivityReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return { ...initState }

    case types.GET_ACTIVITY:
      return {
        ...state,
        ongoing: 'fetching',
      }
    case types.GET_ACTIVITY_RESPONSE:
      return {
        ...state,
        activity: action.activity,
        ongoing: 'nothing',
      }
    case types.GET_ACTIVITY_FAIL:
      return {
        ...state,
        ongoing: 'error',
        error: action,
      }

    default:
      return state
  }
}
