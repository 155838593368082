import { myClientGroup as types } from '../actions/actiontypes'

const initState = {
  ongoing: 'nothing',
  group: null,
  notes: [],
  ongoingNotes: 'nothing',
  error: null,
  progress: 'initial',
}

const updateNotes = (notes, note) => {
  let list = []
  notes && notes.map(n => {
    if (n._id === note._id) {
      list.push(note)
    } else {
      list.push(n)
    }
  })
  return list
}

export function clientGroupReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return { ...initState }

    case types.GET:
      return {
        ...state,
        ongoing: 'fetching',
      }
    case types.GET_RESPONSE:
      return {
        ...state,
        group: action.group,
        ongoing: 'nothing',
      }
    case types.GET_FAIL:
      return {
        ...state,
        ongoing: 'error',
        error: action,
      }

    case types.SAVE:
    case types.REMOVE_CLIENT:
    case types.ADD_MEMBERS:
      return {
        ...state,
        ongoing: 'saving',
      }
    case types.SAVE_RESPONSE:
      return {
        ...state,
        group: {
          ...state.group,
          name: action.group.name,
          description: action.group.description,
          media: action.group.media,
        },
        ongoing: 'saved',
      }
    case types.REMOVE_CLIENT_RESPONSE:
      return {
        ...state,
        group: {
          ...state.group,
          clients: state.group.clients.filter(c => c._id !== action.client),
        },
        ongoing: 'saved',
      }
    case types.ADD_MEMBERS_RESPONSE:
      return {
        ...state,
        group: {
          ...state.group,
          clients: state.group.clients.concat(action.clients),
        },
        ongoing: 'saved',
      }
    case types.SAVE_FAIL:
    case types.REMOVE_CLIENT_FAIL:
    case types.ADD_MEMBERS_FAIL:
      return {
        ...state,
        ongoing: 'error',
        error: action,
      }

    case types.REMOVE:
      return {
        ...state,
        ongoing: 'removing',
      }
    case types.REMOVE_RESPONSE:
      return {
        ...state,
        group: null,
        ongoing: 'removed',
      }
    case types.REMOVE_FAIL:
      return {
        ...state,
        ongoing: 'error',
        error: action,
      }
    case types.CLEAR:
      return {
        ...state,
        ongoing: 'nothing',
      }
    //==== WORKOUT ==== //
    case types.SAVE_WORKOUT:
      return {
        ...state,
        progress: 'saving',
        error: null,
      }
    case types.SAVE_WORKOUT_RESPONSE:
      return {
        ...state,
        group: {
          ...state.group,
          routines: state.group.routines.map(s => { return s._id === action.program._id ? action.program : s }),
        },
        progress: "saved",
      }
    case types.SAVE_WORKOUT_FAIL:
      return {
        ...state,
        progress: "nothing",
        error: action,
      }

    case types.MESSAGE_ADD_WORKOUT:
      if (state.group) {
        return {
          ...state,
          group: {
            ...state.group,
            routines: [...state.group.routines, action.routine],
          },
          progress: action.clear ? 'initial' : 'created',
        }
      } else {
        return {
          ...state,
          progress: action.clear ? 'initial' : 'created',
        }
      }
    case types.PROGRESS_CLEAR:
      return {
        ...state,
        progress: 'initial',
      }
    case types.REMOVE_WORKOUT:
      return {
        ...state,
        ongoing: 'removing',
      }
    case types.REMOVE_WORKOUT_RESPONSE:
      return {
        ...state,
        group: {
          ...state.group,
          routines: state.group.routines.filter(r => r._id !== action.programId)
        },
        ongoing: 'nothing',
      }
    case types.REMOVE_WORKOUT_FAIL:
      return {
        ...state,
        ongoing: 'error',
        error: action,
      }

    // ==== NOTES ==== //
    case types.GET_NOTES:
      return {
        ...state,
        ongoingNotes: 'fetching',
      }
    case types.GET_NOTES_RESPONSE:
      return {
        ...state,
        ongoingNotes: 'nothing',
        notes: action.notes,
      }
    case types.GET_NOTES_FAILED:
      return {
        ...state,
        ongoingNotes: 'nothing',
        error: action.error,
      }
    case types.CREATE_NOTE:
      return {
        ...state,
        ongoingNotes: 'creating',
      }
    case types.CREATE_NOTE_RESPONSE:
      return {
        ...state,
        ongoingNotes: 'nothing',
        notes: [...state.notes, action.note],
      }
    case types.CREATE_NOTE_FAILED:
      return {
        ...state,
        ongoingNotes: 'nothing',
        error: action.error,
      }
    case types.REMOVE_NOTE:
      return {
        ...state,
        ongoingNotes: 'removing',
      }
    case types.REMOVE_NOTE_RESPONSE:
      return {
        ...state,
        notes: state.notes.filter((m) => m._id !== action.note),
        ongoingNotes: 'nothing',
      }
    case types.REMOVE_NOTE_FAILED:
      return {
        ...state,
        ongoingNotes: 'error',
        error: action,
      }
    case types.SAVE_NOTE:
      return {
        ...state,
        ongoingNotes: 'saving',
      }
    case types.SAVE_NOTE_RESPONSE:
      return {
        ...state,
        notes: updateNotes(state.notes, action.note),
        ongoingNotes: 'nothing',
      }
    case types.SAVE_NOTE_FAILED:
      return {
        ...state,
        ongoingNotes: 'error',
        error: action,
      }


    default:
      return state
  }
}
