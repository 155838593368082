import { myGymInvitation as types } from '../actions/actiontypes'

const initReducerState = {
  ongoing: 'nothing',
  gymInvite: null,
  site: null,
  error: null,
}

export function gymInvitationReducer(state = initReducerState, action) {
  switch (action.type) {
    case types.GET:
      return {
        ...state,
        ongoing: 'fetching',
      }
    case types.GET_RESPONSE:
      return {
        ...state,
        ongoing: 'nothing',
        gymInvite: action.gymInvite,
      }
    case types.GET_FAILED:
      return {
        ...state,
        ongoing: 'nothing',
      }

    case types.GET_SITE:
      return {
        ...state,
        ongoing: 'fetching',
      }
    case types.GET_SITE_RESPONSE:
      return {
        ...state,
        ongoing: 'nothing',
        site: action.site,
      }
    case types.GET_SITE_FAILED:
      return {
        ...state,
        ongoing: 'nothing',
      }

    case types.GENERATE_NEW:
      return {
        ...state,
        ongoing: 'fetching',
      }
    case types.GENERATE_NEW_RESPONSE:
      return {
        ...state,
        ongoing: 'nothing',
        gymInvite: action.gymInvite,
      }
    case types.GENERATE_NEW_FAILED:
      return {
        ...state,
        ongoing: 'nothing',
      }

    default:
      return state
  }
}
