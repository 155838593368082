export default types => {
  const initState = {
    ongoing: false,
    responseCode: undefined,
    responseMessage: '',
    showWelcomeScreen: false,
    isFormMessageVisible: false,
    formMessageType: '',
    formMessage: '',
    clearForm: false,
    responseStatus: '',
  }

  return function(state = initState, action) {
    switch (action.type) {
      case 'STORE_RESET':
        return Object.assign({}, initState)
      case types.REQUEST:
        return Object.assign({}, state, {
          ongoing: true,
          responseStatus: '',
        })
      case types.RESPONSE:
        return Object.assign({}, state, {
          ongoing: false,
          responseCode: action.responseCode,
          responseMessage: action.responseMessage,
          responseStatus: action.status,
        })
      case types.SHOW_WELCOME_SCREEN:
        return Object.assign({}, state, {
          showWelcomeScreen: true,
          responseStatus: '',
        })
      case types.HIDE_WELCOME_SCREEN:
        return Object.assign({}, state, {
          showWelcomeScreen: false,
          responseStatus: '',
        })
      case types.SHOW_FORM_STATUS:
        return Object.assign({}, state, {
          isFormMessageVisible: true,
          formMessageType: action.formMessageType,
          formMessage: action.formMessage,
        })
      case types.HIDE_FORM_STATUS:
        return Object.assign({}, state, {
          isFormMessageVisible: false,
        })
      case types.CLEAR_FORM:
        return Object.assign({}, state, {
          clearForm: true,
          responseStatus: '',
        })
      case types.ON_INPUT:
        return Object.assign({}, state, {
          clearForm: false,
          isFormMessageVisible: false,
          responseStatus: '',
        })
      case types.RESET:
        return Object.assign({}, initState)
      default:
        return state
    }
  }
}
