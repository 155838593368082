const me = `/protected/me`

export const homePaths = {
  APP_INFO: () => {
    return '/appinfo'
  },
  QR_REDIRECT: () => {
    return '/qrredirect'
  },
  GYM_INVITE_LINK_APPSTORE: (id = ':id') => {
    return `/gym-invite-link/${id}/*`
  },
  GYM_INVITE_LINK: (id = ':id') => {
    return `/gym-invite-link/${id}`
  },
  GYM_INVITE_MISSING_LINK: () => {
    return `/gym-invite-link/`
  },
  CLIENTS: () => {
    return `${me}/clients`
  },
  EXERCISES: () => {
    return `${me}/exercises`
  },
  GYMS: (id = ':id') => {
    return `${me}/gyms/${id}`
  },
  HOME: () => {
    return `${me}`
  },
  JOIN: () => {
    return '/join'
  },
  ME: () => {
    return me
  },
  PARTNERS: (id = ':id') => {
    return `${me}/partners/${id}`
  },
  PASSWORD_CHANGE: () => {
    return '/password-change'
  },
  PASSWORD_FORGOTTEN: () => {
    return '/password-forgotten'
  },
  PLANS: () => {
    return `${me}/plans`
  },
  WORKOUTS: () => {
    return `${me}/programs`
  },
  PROTECTED: () => {
    return '/protected'
  },
  SIGNIN: () => {
    return '/signin'
  },
  UNAUTHORIZED: () => {
    return '/unauthorized'
  },
  PROFILE_SETTINGS: () => {
    return `${me}/profile_settings`
  },
  SUPPORT: () => {
    return `${me}/support`
  },
  EXPORT_USER_DATA: (id = ':id') => {
    return `/export-user-data/${id}`
  },
}

export const gymPaths = {
  GYM: (gymId = ':gymId') => {
    return `${me}/gyms/${gymId}`
  },
  CREATE_COLLECTION: (gymId = ':gymId') => {
    return `${me}/gyms/${gymId}/create_program_collection`
  },
  CREATE_EXERCISE: (gymId = ':gymId') => {
    return `${me}/gyms/${gymId}/create_exercise`
  },
  CREATE_WORKOUT: (gymId = ':gymId') => {
    return `${me}/gyms/${gymId}/create_program`
  },
  CREATE_CLASS: (gymId = ':gymId') => {
    return `${me}/gyms/${gymId}/create_class`
  },
  EXERCISE: (gymId = ':gymId', exerciseId = ':exerciseId') => {
    return `${me}/gyms/${gymId}/exercises/${exerciseId}`
  },
  EXERCISES: (gymId = ':gymId') => {
    return `${me}/gyms/${gymId}/exercises`
  },
  CLASS: (gymId = ':gymId', classId = ':classId') => {
    return `${me}/gyms/${gymId}/programs/class/${classId}`
  },
  INFORMATION_EMAIL: (gymId = ':gymId') => {
    return `${me}/gyms/${gymId}/information_email`
  },
  MACHINE: (gymId = ':gymId', machineId = ':machineId') => {
    return `${me}/gyms/${gymId}/machines/${machineId}`
  },
  CARDIO_MACHINE: (gymId = ':gymId', zoneId = ':zoneId') => {
    return `${me}/gyms/${gymId}/machines/cardio/${zoneId}`
  },
  MACHINES: (gymId = ':gymId') => {
    return `${me}/gyms/${gymId}/machines/`
  },
  ADD_MACHINE: (gymId = ':gymId') => {
    return `${me}/gyms/${gymId}/add_machine/`
  },
  ADD_CARDIO: (gymId = ':gymId') => {
    return `${me}/gyms/${gymId}/add_cardio/`
  },
  MANAGE_MEMBERS: (gymId = ':gymId') => {
    return `${me}/gyms/${gymId}/manage_members`
  },
  MEMBER_SELECTION_MODE: (gymId = ':gymId') => {
    return `${me}/gyms/${gymId}/manage_members/selection_mode`
  },
  MANAGE_MEMBERS_MEMBER: (gymId = ':gymId', memberId = ':memberId') => {
    return `${me}/gyms/${gymId}/manage_members/${memberId}`
  },
  CREATE_MEMBER_WORKOUT_FROM_WORKOUT: (gymId = ':gymId', memberId = ':memberId', workoutId = ':workoutId') => {
    return `${me}/gyms/${gymId}/manage_members/${memberId}/assign_program/workout/${workoutId}`
  },
  CREATE_MEMBER_WORKOUT: (gymId = ':gymId', memberId = ':memberId') => {
    return `${me}/gyms/${gymId}/manage_members/${memberId}/assign_program`
  },
  ASSIGN_MEMBER_WORKOUT: (gymId = ':gymId', memberId = ':memberId', programId = ':programId') => {
    return `${me}/gyms/${gymId}/manage_members/${memberId}/assign_program/${programId}`
  },
  EDIT_MEMBER_WORKOUT: (gymId = ':gymId', memberId = ':memberId', programId = ':programId') => {
    return `${me}/gyms/${gymId}/manage_members/${memberId}/edit_program/${programId}`
  },
  MEMBER_WORKOUT: (gymId = ':gymId', memberId = ':memberId', programId = ':programId') => {
    return `${me}/gyms/${gymId}/manage_members/${memberId}/program/${programId}`
  },
  STAFF: (gymId = ':gymId') => {
    return `${me}/gyms/${gymId}/staff`
  },
  HANDLE_STAFF: (gymId = ':gymId', userId = ':userId') => {
    return `${me}/gyms/${gymId}/staff/${userId}`
  },
  WORKOUT_COLLECTION: (gymId = ':gymId', collectionId = ':collectionId') => {
    return `${me}/gyms/${gymId}/programs/collection/${collectionId}`
  },
  WORKOUT: (gymId = ':gymId', programId = ':programId') => {
    return `${me}/gyms/${gymId}/programs/program/${programId}`
  },
  WORKOUTS: (gymId = ':gymId') => {
    return `${me}/gyms/${gymId}/programs`
  },
  STATUS: (gymId = ':gymId') => {
    return `${me}/gyms/${gymId}/status`
  },
  STATISTICS: (gymId = ':gymId') => {
    return `${me}/gyms/${gymId}/statistics`
  },
  ZONE: (gymId = ':gymId', zoneId = ':zoneId') => {
    return `${me}/gyms/${gymId}/zones/${zoneId}`
  },
  ZONES: (gymId = ':gymId') => {
    return `${me}/gyms/${gymId}/zones`
  },
  ADD_ZONE: (gymId = ':gymId') => {
    return `${me}/gyms/${gymId}/add_zone`
  },
  MESSAGES: (gymId = ':gymId') => {
    return `${me}/gyms/${gymId}/messages`
  },
  BEACON_MESSAGE: (gymId = ':gymId') => {
    return `${me}/gyms/${gymId}/beacon_message`
  },
}

export const partnerPaths = {
  USERS: (partnerID = ':partnerID') => {
    return `${me}/partners/${partnerID}/users`
  },
  CHAINS: (partnerID = ':partnerID') => {
    return `${me}/partners/${partnerID}/chains`
  },
  GYMS: (partnerID = ':partnerID') => {
    return `${me}/partners/${partnerID}/gyms`
  },
  PARTNER: (partnerID = ':partnerID') => {
    return `${me}/partners/${partnerID}`
  },
  PARTNERS: () => {
    return `${me}/partners`
  },
  WORKOUTS: (partnerID = ':partnerID') => {
    return `${me}/partners/${partnerID}/programs`
  },
  CREATE_WORKOUT: (partnerID = ':partnerID') => {
    return `${me}/partners/${partnerID}/create_program`
  },
  WORKOUT: (partnerID = ':partnerID', programId = ':programId') => {
    return `${me}/partners/${partnerID}/programs/program/${programId}`
  },
  CREATE_CLASS: (partnerID = ':partnerID') => {
    return `${me}/partners/${partnerID}/create_class`
  },
  CLASS: (partnerID = ':partnerID', classId = ':classId') => {
    return `${me}/partners/${partnerID}/programs/class/${classId}`
  },
  CREATE_COLLECTION: (partnerID = ':partnerID') => {
    return `${me}/partners/${partnerID}/create_program_collection`
  },
  WORKOUT_COLLECTION: (partnerID = ':partnerID', collectionId = ':collectionId') => {
    return `${me}/partners/${partnerID}/programs/program_collection/${collectionId}`
  },
  CREATE_EXERCISE: (partnerID = ':partnerID') => {
    return `${me}/partners/${partnerID}/create_exercise`
  },
  EXERCISE: (partnerID = ':partnerID', exerciseId = ':exerciseId') => {
    return `${me}/partners/${partnerID}/exercises/${exerciseId}`
  },
  EXERCISES: (partnerID = ':partnerID') => {
    return `${me}/partners/${partnerID}/exercises`
  },

}

export const partnerGymPaths = {
  GYM: (partnerID = ':partnerID', gymId = ':gymId') => {
    return `${me}/partners/${partnerID}/gyms/${gymId}`
  },
  CREATE_CHAIN: (partnerID = ':partnerID') => {
    return `${me}/partners/${partnerID}/create_chain`
  },
  CREATE_GYM: (partnerID = ':partnerID') => {
    return `${me}/partners/${partnerID}/create_gym`
  },
  EXERCISE: (
    partnerID = ':partnerID',
    gymId = ':gymId',
    exerciseId = ':exerciseId'
  ) => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/exercises/${exerciseId}`
  },
  EXERCISES: (partnerID = ':partnerID', gymId = ':gymId') => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/exercises`
  },
  CREATE_EXERCISE: (partnerID = ':partnerID', gymId = ':gymId') => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/create_exercise`
  },
  CLASS: (partnerID = ':partnerID', gymId = ':gymId', classId = ':classId') => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/programs/class/${classId}`
  },
  CREATE_CLASS: (partnerID = ':partnerID', gymId = ':gymId') => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/create_class`
  },
  INFORMATION_EMAIL: (partnerID = ':partnerID', gymId = ':gymId') => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/information_email`
  },
  MACHINES: (partnerID = ':partnerID', gymId = ':gymId') => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/machines`
  },
  ADD_MACHINE: (partnerID = ':partnerID', gymId = ':gymId') => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/add_machine`
  },
  ADD_CARDIO: (partnerID = ':partnerID', gymId = ':gymId') => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/add_cardio`
  },
  MACHINE: (
    partnerID = ':partnerID',
    gymId = ':gymId',
    machineId = ':machineId'
  ) => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/machines/${machineId}`
  },
  CARDIO_MACHINE: (
    partnerID = ':partnerID',
    gymId = ':gymId',
    zoneId = ':zoneId'
  ) => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/machines/cardio/${zoneId}`
  },
  MANAGE_MEMBERS: (partnerID = ':partnerID', gymId = ':gymId') => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/manage_members`
  },
  MEMBER_SELECTION_MODE: (partnerID = ':partnerID', gymId = ':gymId') => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/manage_members/selection_mode`
  },
  MANAGE_MEMBERS_MEMBER: (
    partnerID = ':partnerID',
    gymId = ':gymId',
    memberId = ':memberId'
  ) => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/manage_members/${memberId}`
  },
  STAFF: (partnerID = ':partnerID', gymId = ':gymId') => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/staff`
  },
  HANDLE_STAFF: (partnerID = ':partnerID', gymId = ':gymId', userId = ':userId') => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/staff/${userId}`
  },
  WORKOUT_COLLECTION: (
    partnerID = ':partnerID',
    gymId = ':gymId',
    collectionId = ':collectionId'
  ) => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/programs/collection/${collectionId}`
  },
  CREATE_COLLECTION: (partnerID = ':partnerID', gymId = ':gymId') => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/create_program_collection`
  },
  WORKOUT: (
    partnerID = ':partnerID',
    gymId = ':gymId',
    programId = ':programId'
  ) => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/programs/program/${programId}`
  },
  WORKOUTS: (partnerID = ':partnerID', gymId = ':gymId') => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/programs`
  },
  CREATE_WORKOUT: (partnerID = ':partnerID', gymId = ':gymId') => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/create_program`
  },
  STATUS: (partnerID = ':partnerID', gymId = ':gymId') => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/status`
  },
  STATISTICS: (partnerID = ':partnerID', gymId = ':gymId') => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/statistics`
  },
  ZONES: (partnerID = ':partnerID', gymId = ':gymId') => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/zones`
  },
  ADD_ZONE: (partnerID = ':partnerID', gymId = ':gymId') => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/add_zone`
  },
  ZONE: (partnerID = ':partnerID', gymId = ':gymId', zoneId = ':zoneId') => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/zones/${zoneId}`
  },
  MESSAGES: (partnerID = ':partnerID', gymId = ':gymId') => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/messages`
  },
  BEACON_MESSAGE: (partnerID = ':partnerID', gymId = ':gymId') => {
    return `${me}/partners/${partnerID}/gyms/${gymId}/beacon_message`
  },
}

export const partnerChainPaths = {
  USERS: (partnerID = ':partnerID', chainID = ':chainID') => {
    return `${me}/partners/${partnerID}/chains/${chainID}/users`
  },
  CHAIN: (partnerID = ':partnerID', chainID = ':chainID') => {
    return `${me}/partners/${partnerID}/chains/${chainID}`
  },
  CREATE_GYM: (partnerID = ':partnerID', chainID = ':chainID') => {
    return `${me}/partners/${partnerID}/chains/${chainID}/create_gym`
  },
  GYMS: (partnerID = ':partnerID', chainID = ':chainID') => {
    return `${me}/partners/${partnerID}/chains/${chainID}/gyms`
  },
}

export const clientPaths = {
  CLIENT: (clientId = ':clientId') => {
    return `${me}/clients/view/${clientId}`
  },
  WORKOUTS: (clientId = ':clientId') => {
    return `${me}/clients/view/${clientId}/programs`
  },
  WORKOUT: (clientId = ':clientId', id = ':id') => {
    return `${me}/clients/view/${clientId}/programs/${id}`
  },
  GROUP: (id = ':id') => {
    return `${me}/clients/view/group/${id}`
  },
  CREATE_CLIENT_WORKOUT_FROM_WORKOUT: (clientId = ':clientId', workoutId = ':workoutId') => {
    return `${me}/clients/view/${clientId}/assign_program/workout/${workoutId}`
  },
  CREATE_CLIENT_WORKOUT: (clientId = ':clientId') => {
    return `${me}/clients/view/${clientId}/assign_program`
  },
  ASSIGN_CLIENT_WORKOUT: (clientId = ':clientId', programId = ':programId') => {
    return `${me}/clients/view/${clientId}/assign_program/${programId}`
  },
  EDIT_CLIENT_WORKOUT: (clientId = ':clientId', programId = ':programId') => {
    return `${me}/clients/view/${clientId}/edit_program/${programId}`
  },
  CLIENT_WORKOUT: (clientId = ':clientId', programId = ':programId') => {
    return `${me}/clients/view/${clientId}/program/${programId}`
  },
  CREATE_CLIENT_GROUP_WORKOUT: (id = ':id') => {
    return `${me}/clients/view/group/${id}/assign_program`
  },
  ASSIGN_CLIENT_GROUP_WORKOUT: (id = ':id', programId = ':programId') => {
    return `${me}/clients/view/group/${id}/assign_program/${programId}`
  },
  EDIT_CLIENT_GROUP_WORKOUT: (id = ':id', programId = ':programId') => {
    return `${me}/clients/view/group/${id}/edit_program/${programId}`
  },
  CLIENT_GROUP_WORKOUT: (id = ':id', programId = ':programId') => {
    return `${me}/clients/view/group/${id}/program/${programId}`
  },
}

export const programPaths = {
  WORKOUT: (id = ':id') => {
    return `${me}/programs/${id}`
  },
  CREATE_WORKOUT: () => {
    return `${me}/create_program/`
  },
  CREATE_WORKOUT_FROM_WORKOUT: (id = ':id') => {
    return `${me}/create_program/${id}`
  },
}

export const exercisePaths = {
  EXERCISE: (id = ':id') => {
    return `${me}/exercises/${id}`
  },
  CREATE_EXERCISE: () => {
    return `${me}/create_exercise/`
  },
}

export const suPaths = {
  ZONES: () => {
    return `/protected/tools/software/zones`
  },
  ZONE: (id = ':id') => {
    return `/protected/tools/software/zones/view/${id}`
  },
  ADD_ZONE: () => {
    return `/protected/tools/software/zones/create`
  },
}

export const getGymPath = (key, params, id) => {
  const { partnerID, gymId, memberId } = params
  if (!partnerID && gymId && memberId) return gymPaths[key](gymId, memberId, id)
  if (!partnerID && gymId) return gymPaths[key](gymId, id)
  if (partnerID && gymId) return partnerGymPaths[key](partnerID, gymId, id)
}

export const getPartnerPath = (key, params, id) => {
  const { partnerID } = params
  return partnerPaths[key](partnerID, id)
}
