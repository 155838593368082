import {users as types} from '../actions/actiontypes'

const initState = {
  ongoing: false,
  users: [],
  error: undefined
}

export function usersReducer(state = initState, action) {
  switch (action.type) {
    case "STORE_RESET":
      return Object.assign({}, initState)
    case types.GET:
      return Object.assign({}, state, {ongoing: state.users.length > 0 ? false : true})
    case types.RESPONSE:
      return Object.assign({}, state, {
        ongoing: false,
        users: action.users
      })
    case types.GET_FAIL:
      return Object.assign({}, state, {ongoing: false, error: action})
    default:
      return state
  }
}
