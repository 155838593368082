import { gallery as types } from '../actions/actiontypes'

const initialState = {
  ongoing: false,
  gallery: [],
}

export function galleryReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET:
      return {
        ...state,
        ongoing: true,
        gallery: [],
      }
    case types.RESPONSE:
      return {
        ...state,
        ongoing: false,
        gallery: action.gallery,
      }
    case types.FAIL:
      return {
        ...state,
        ongoing: false,
        gallery: [],
      }

    default:
      return state
  }
}
