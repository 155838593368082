import React from 'react'
import PropTypes from 'prop-types'
import { Loader } from 'semantic-ui-react'
import sheet from './style.scss'

export default function LoadingMessage(props) {
  const { text } = props

  return (
    <div className={sheet.loadingMessage}>
      <h3>{text}</h3>
      <Loader active inline='centered' />
    </div>
  )
}

LoadingMessage.propTypes = {
  text: PropTypes.string
}
