import i18next from 'i18next'

const typeBeginner = 'beginner'
const typeIntermediate = 'intermediate'
const typeAdvanced = 'advanced'

export const categoryType = [typeBeginner, typeIntermediate, typeAdvanced]
export const defaultCategory = typeIntermediate

export const getCategoryLabel = type => {
  switch (type) {
    case typeBeginner:
      return i18next.t('beginner')
    case typeIntermediate:
      return i18next.t('intermediate')
    case typeAdvanced:
      return i18next.t('advanced')
    default:
      return type
  }
}

export const getCategory = function(category) {
  return category ? category : defaultCategory
}
