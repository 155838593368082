import { clientWorkouts as types } from '../actions/actiontypes'

const initState = {
  ongoing: 'nothing',
  getMoreOngoing: false,
  clientWorkouts: [],
  errors: {},
}

export function clientWorkoutHistoryReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return {
        ...initState,
      }
    case types.GET:
      return {
        ...state,
        ongoing: 'fetching',
        clientWorkouts: [],
      }
    case types.RESPONSE:
      return {
        ...state,
        ongoing: 'nothing',
        clientWorkouts: action.clientWorkouts,
      }
    case types.GET_FAILED:
      return {
        ...state,
        ongoing: 'failed',
        errors: action.errors,
        clientWorkouts: [],
      }

    case types.GET_MORE:
      return {
        ...state,
        getMoreOngoing: true,
      }
    case types.GET_MORE_RESPONSE:
      return {
        ...state,
        getMoreOngoing: false,
        clientWorkouts: state.clientWorkouts.concat(action.clientWorkouts),
      }
    case types.GET_MORE_FAILED:
      return {
        ...state,
        getMoreOngoing: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
