import { memberWorkouts as types } from '../actions/actiontypes'

const initState = {
  ongoing: 'nothing',
  getMoreOngoing: false,
  memberWorkouts: [],
  errors: {},
}

export function memberWorkoutHistoryReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return {
        ...initState,
      }
    case types.GET:
      return {
        ...state,
        ongoing: 'fetching',
        memberWorkouts: [],
      }
    case types.RESPONSE:
      return {
        ...state,
        ongoing: 'nothing',
        memberWorkouts: action.memberWorkouts,
      }
    case types.GET_FAILED:
      return {
        ...state,
        ongoing: 'failed',
        errors: action.errors,
        memberWorkouts: [],
      }

    case types.GET_MORE:
      return {
        ...state,
        getMoreOngoing: true,
      }
    case types.GET_MORE_RESPONSE:
      return {
        ...state,
        getMoreOngoing: false,
        memberWorkouts: state.memberWorkouts.concat(action.memberWorkouts),
      }
    case types.GET_MORE_FAILED:
      return {
        ...state,
        getMoreOngoing: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
