import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { matchPath } from 'react-router-dom'
import { Button, Icon, Grid } from 'semantic-ui-react'
import { standardBackground } from 'app/protected/roles/util/colors'
import {
  clientPaths,
  homePaths,
  gymPaths,
  partnerGymPaths,
  programPaths,
  exercisePaths,
  partnerPaths,
  suPaths,
} from 'app/protected/common/constants/routes'
import { actionFooter as action } from 'redux/actions/actions'
import { useDispatch } from 'react-redux'
import i18next from 'i18next'

const footerStyle = {
  backgroundColor: standardBackground,
  zIndex: "10",
  boxShadow: `0 -2px 5px #B3B3B3`,
  position: "fixed",
  left: "0",
  bottom: "0",
  width: "100%"
}


const ActionFooter = (props) => {
  const { location, history } = props
  const { rightDisabled, extraRightDisabled, show } = props
  const dispatch = useDispatch()

  const doLeftAction = () => {
    dispatch(action.callLeft())
  }
  const doRightAction = () => {
    dispatch(action.callRight())
  }
  const doExtraRightAction = () => {
    dispatch(action.callExtraRight())
  }

  // check if current url matches any of the declared paths.
  const checkIfUrlMatchPath = (url, path) => {
    return !!matchPath(url, {
      path: path,
      exact: true,
      strict: false,
    })
  }

  const getActions = (url) => {
    switch (true) {
      // PT //
      case checkIfUrlMatchPath(url, exercisePaths.EXERCISE()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('save'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: null,
          extraRightIcon: null,
          extraRightButtonColor: null,
        }
      case checkIfUrlMatchPath(url, programPaths.WORKOUT()):
      case checkIfUrlMatchPath(url, clientPaths.EDIT_CLIENT_WORKOUT()):
      case checkIfUrlMatchPath(url, clientPaths.EDIT_CLIENT_GROUP_WORKOUT()):
      case checkIfUrlMatchPath(url, programPaths.CREATE_WORKOUT_FROM_WORKOUT()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('save'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: i18next.t('preview'),
          extraRightIcon: null,
          extraRightButtonColor: null,
        }

      case checkIfUrlMatchPath(url, exercisePaths.CREATE_EXERCISE()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('create'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: null,
          extraRightIcon: null,
          extraRightButtonColor: null,
        }
      case checkIfUrlMatchPath(url, programPaths.CREATE_WORKOUT()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('create'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: i18next.t('preview'),
          extraRightIcon: null,
          extraRightButtonColor: null,
        }

      case checkIfUrlMatchPath(url, clientPaths.CREATE_CLIENT_WORKOUT_FROM_WORKOUT()):
      case checkIfUrlMatchPath(url, clientPaths.CREATE_CLIENT_WORKOUT()):
      case checkIfUrlMatchPath(url, clientPaths.ASSIGN_CLIENT_WORKOUT()):
      case checkIfUrlMatchPath(url, clientPaths.CREATE_CLIENT_GROUP_WORKOUT()):
      case checkIfUrlMatchPath(url, clientPaths.ASSIGN_CLIENT_GROUP_WORKOUT()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('assign_program'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: null,
          extraRightIcon: null,
          extraRightButtonColor: null,
        }
      // ---------- //

      // GYM //
      case checkIfUrlMatchPath(url, gymPaths.MACHINE()):
      case checkIfUrlMatchPath(url, gymPaths.EXERCISE()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('save'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: null,
          extraRightIcon: null,
          extraRightButtonColor: null,
        }
      case checkIfUrlMatchPath(url, gymPaths.WORKOUT()):
      case checkIfUrlMatchPath(url, gymPaths.WORKOUT_COLLECTION()):
      case checkIfUrlMatchPath(url, gymPaths.CLASS()):
      case checkIfUrlMatchPath(url, gymPaths.EDIT_MEMBER_WORKOUT()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('save'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: i18next.t('preview'),
          extraRightIcon: null,
          extraRightButtonColor: null,
        }

      case checkIfUrlMatchPath(url, gymPaths.ADD_MACHINE()):
      case checkIfUrlMatchPath(url, gymPaths.CREATE_EXERCISE()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('create'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: null,
          extraRightIcon: null,
          extraRightButtonColor: null,
        }
      case checkIfUrlMatchPath(url, gymPaths.CREATE_WORKOUT()):
      case checkIfUrlMatchPath(url, gymPaths.CREATE_COLLECTION()):
      case checkIfUrlMatchPath(url, gymPaths.CREATE_CLASS()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('create'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: i18next.t('preview'),
          extraRightIcon: null,
          extraRightButtonColor: null,
        }

      case checkIfUrlMatchPath(url, gymPaths.ADD_ZONE()):
      case checkIfUrlMatchPath(url, gymPaths.ADD_CARDIO()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('create'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: i18next.t('preview'),
          extraRightIcon: null,
          extraRightButtonColor: null,
        }

      case checkIfUrlMatchPath(url, gymPaths.CARDIO_MACHINE()):
      case checkIfUrlMatchPath(url, gymPaths.ZONE()):
      case checkIfUrlMatchPath(url, gymPaths.WORKOUTS()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('save'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: i18next.t('preview'),
          extraRightIcon: null,
          extraRightButtonColor: null,
        }

      case checkIfUrlMatchPath(url, gymPaths.CREATE_MEMBER_WORKOUT_FROM_WORKOUT()):
      case checkIfUrlMatchPath(url, gymPaths.CREATE_MEMBER_WORKOUT()):
      case checkIfUrlMatchPath(url, gymPaths.ASSIGN_MEMBER_WORKOUT()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('assign_program'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: null,
          extraRightIcon: null,
          extraRightButtonColor: null,
        }

      // ---------- //

      // PARTNER GYM PATHS //
      case checkIfUrlMatchPath(url, partnerGymPaths.MACHINE()):
      case checkIfUrlMatchPath(url, partnerGymPaths.CARDIO_MACHINE()):
      case checkIfUrlMatchPath(url, partnerGymPaths.EXERCISE()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('save'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: null,
          extraRightIcon: null,
          extraRightButtonColor: null,
        }
      case checkIfUrlMatchPath(url, partnerGymPaths.WORKOUT()):
      case checkIfUrlMatchPath(url, partnerGymPaths.WORKOUT_COLLECTION()):
      case checkIfUrlMatchPath(url, partnerGymPaths.CLASS()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('save'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: i18next.t('preview'),
          extraRightIcon: null,
          extraRightButtonColor: null,
        }

      case checkIfUrlMatchPath(url, partnerGymPaths.ADD_MACHINE()):
      case checkIfUrlMatchPath(url, partnerGymPaths.CREATE_EXERCISE()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('create'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: null,
          extraRightIcon: null,
          extraRightButtonColor: null,
        }
      case checkIfUrlMatchPath(url, partnerGymPaths.CREATE_WORKOUT()):
      case checkIfUrlMatchPath(url, partnerGymPaths.CREATE_COLLECTION()):
      case checkIfUrlMatchPath(url, partnerGymPaths.CREATE_CLASS()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('create'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: i18next.t('preview'),
          extraRightIcon: null,
          extraRightButtonColor: null,
        }

      case checkIfUrlMatchPath(url, partnerGymPaths.ADD_CARDIO()):
      case checkIfUrlMatchPath(url, partnerGymPaths.ADD_ZONE()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('create'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: i18next.t('preview'),
          extraRightIcon: null,
          extraRightButtonColor: null,
        }

      case checkIfUrlMatchPath(url, partnerGymPaths.ZONE()):
      case checkIfUrlMatchPath(url, partnerGymPaths.WORKOUTS()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('save'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: i18next.t('preview'),
          extraRightIcon: null,
          extraRightButtonColor: null,
        }

      // ---------- //
      // PARTNER PATHS //
      case checkIfUrlMatchPath(url, partnerPaths.EXERCISE()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('save'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: null,
          extraRightIcon: null,
          extraRightButtonColor: null,
        }
      case checkIfUrlMatchPath(url, partnerPaths.WORKOUT_COLLECTION()):
      case checkIfUrlMatchPath(url, partnerPaths.WORKOUT()):
      case checkIfUrlMatchPath(url, partnerPaths.CLASS()):
      case checkIfUrlMatchPath(url, partnerPaths.WORKOUTS()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('save'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: i18next.t('preview'),
          extraRightIcon: null,
          extraRightButtonColor: null,
        }

      case checkIfUrlMatchPath(url, partnerPaths.CREATE_EXERCISE()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('create'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: null,
          extraRightIcon: null,
          extraRightButtonColor: null,
        }
      case checkIfUrlMatchPath(url, partnerPaths.CREATE_COLLECTION()):
      case checkIfUrlMatchPath(url, partnerPaths.CREATE_WORKOUT()):
      case checkIfUrlMatchPath(url, partnerPaths.CREATE_CLASS()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('create'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: i18next.t('preview'),
          extraRightIcon: null,
          extraRightButtonColor: null,
        }
      // ---------- //

      // SU PATHS //
      case checkIfUrlMatchPath(url, suPaths.ZONE()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('save'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: i18next.t('preview'),
          extraRightIcon: null,
          extraRightButtonColor: null,
        }
      case checkIfUrlMatchPath(url, suPaths.ADD_ZONE()):
        return {
          leftText: i18next.t('cancel'),
          rightText: i18next.t('create'),
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: i18next.t('preview'),
          extraRightIcon: null,
          extraRightButtonColor: null,
        }
      // ---------- //

      default:
        return {
          leftText: null,
          rightText: null,
          rightIcon: null,
          rightButtonColor: "green",
          extraRightText: null,
          extraRightIcon: null,
          extraRightButtonColor: null,
        }
    }
  }

  const actionAttributes = getActions(location.pathname)
  const { leftText,
    rightText,
    rightIcon,
    rightButtonColor,
    extraRightText,
    extraRightIcon,
    extraRightButtonColor,
  } = actionAttributes

  const content = () => {
    return (
      <div
        style={{
          display: 'flex',
          flex: '1',
          alignItems: 'strech',
        }}
      >
        {leftText && <div
          style={{
            alignItems: 'center',
            display: 'inline-flex',
            cursor: 'pointer',
          }}
          onClick={doLeftAction}
        >
          {leftText ? leftText : "Left action"}
        </div>}
        <div
          style={{
            display: 'flex',
            flex: '1',
            justifyContent: 'flex-end',
          }}
        >
          {extraRightText &&
            <div style={{ paddingRight: rightText ? "7px" : "0px" }}>
              <Button
                style={{ borderRadius: "20px" }}
                basic
                disabled={extraRightDisabled}
                color={extraRightButtonColor}
                onClick={doExtraRightAction}
              >
                {extraRightIcon && <Icon name={extraRightIcon} />}
                {extraRightText ? extraRightText : "Extra action"}
              </Button>
            </div>}
          {rightText &&
            <Button
              color={rightButtonColor}
              disabled={rightDisabled}
              onClick={doRightAction}
            >
              {rightIcon && <Icon name={rightIcon} />}
              {rightText ? rightText : "Right action"}
            </Button>}

        </div>
      </div>
    )
  }

  if ((leftText || rightText) && show) {
    return (
      <div style={{ height: 0 }}>
        <div style={footerStyle}>
          <Grid centered padded>
            <Grid.Row verticalAlign="middle" textAlign="center">
              <Grid.Column
                mobile="16"
                tablet="16"
                computer="12"
                largeScreen="11"
                widescreen="9"
              >
                {content()}
              </Grid.Column>

            </Grid.Row>
          </Grid>
        </div>
      </div>
    )
  } else {
    return <></>
  }

}

const mapStateToProps = (state) => {
  const { rightDisabled, extraRightDisabled, show } = state.actionFooterReducer

  return {
    rightDisabled,
    extraRightDisabled,
    show,
  }
}

export default connect(mapStateToProps, {})(ActionFooter)

ActionFooter.propTypes = {
  rightDisabled: PropTypes.bool,
  extraRightDisabled: PropTypes.bool,
}