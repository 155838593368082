import { gymMachine as types } from '../actions/actiontypes'

const initState = {
  ongoing: 'nothing',
  machine: null,
  error: null,
  mode: 'view',
}

export function myGymMachineReducer(state = initState, action) {
  switch (action.type) {
    case 'STORE_RESET':
      return {
        ...initState,
      }
    case types.GET:
      return {
        ...state,
        ongoing: 'fetching',
        error: null,
      }
    case types.SAVE:
      return {
        ...state,
        ongoing: 'saving',
        error: null,
      }
    case types.RESPONSE:
      return {
        ...state,
        ongoing: 'nothing',
        machine: action.machine,
        mode: 'view',
      }
    case types.SAVE_RESPONSE:
      return {
        ...state,
        ongoing: 'nothing',
        machine: action.machine,
        mode: 'view',
      }
    case types.GET_FAIL:
    case types.SAVE_FAIL:
      return {
        ...state,
        ongoing: 'nothing',
        error: action.error,
      }
    case types.SET_MODE:
      return {
        ...state,
        mode: action.mode,
      }
    case types.REMOVE:
      return {
        ...state,
        ongoing: 'removing',
      }
    case types.REMOVE_RESPONSE:
      return {
        ...state,
        ongoing: 'removed',
      }
    case types.REMOVE_FAILED:
      return {
        ...state,
        ongoing: 'nothing',
        error: action.error,
      }

    default:
      return state
  }
}
