import { gymContentStatistics as types } from '../actions/actiontypes'
import { getToday, isValidDate } from 'util/util'
import moment from 'moment'

const setDate = ({ state, action }) => {
  const oldFrom = state.from
  const oldTo = state.to
  const newFrom = action.from
  const newTo = action.to

  if (!isValidDate(newFrom) && !isValidDate(newTo)) {
    if (isValidDate(oldFrom) && isValidDate(oldTo)) {
      return {
        ...state,
        from: oldFrom,
        to: oldTo,
      }
    } else {
      return {
        ...state,
        from: moment().subtract(14, 'd'),
        to: getToday(),
      }
    }
  }

  return {
    ...state,
    from: newFrom,
    to: newTo,
  }
}

const initialState = {
  ongoingRoutines: false,
  routines: [],
  ongoingRoutinesDistribution: false,
  distribution: [],
  from: moment().subtract(1, 'month').startOf('month').set({ hours: '00', minutes: '00' }).toDate(),
  to: moment().subtract(1, 'month').endOf('month').set({ hours: '23', minutes: '59' }).toDate(),
  ongoingExercises: false,
  exercises: [],
  requestedSite: null,
  lastRequestDateRoutines: null,
  lastRequestDateExercises: null,
  sortOn: 'month',
}

const getToggledContent = (content, id) => {
  const _content = content.slice()
  let m = content.find((m) => m._id === id)
  m.selected = !m.selected

  return _content
}

const getAllToggledContent = (content, toggledState) => {
  const _content = content.slice()
  _content.map((m) => (m.selected = !toggledState))

  return _content
}

export function gymContentStatisticsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_ROUTINES:
      return {
        ...state,
        ongoingRoutines: true,
        requestedSite: action.siteId,
        lastRequestDateRoutines: action.lastRequestDateRoutines,
      }
    case types.GET_ROUTINES_RESPONSE:
      if (moment(state.lastRequestDateRoutines).isSame(action.lastRequestDateRoutines)) {
        return {
          ...state,
          ongoingRoutines: false,
          routines: action.data,
        }
      } else {
        return { ...state }
      }
    case types.GET_ROUTINES_FAIL:
      return {
        ...state,
        ongoingRoutines: false,
      }
    case types.GET_ROUTINES_DISTRIBUTION:
      return {
        ...state,
        ongoingRoutinesDistribution: true,
        requestedSite: action.siteId,
        lastRequestDateRoutinesDistribution: action.lastRequestDateRoutinesDistribution,
      }
    case types.GET_ROUTINES_DISTRIBUTION_RESPONSE:
      if (moment(state.lastRequestDateRoutinesDistribution).isSame(action.lastRequestDateRoutinesDistribution)) {
        return {
          ...state,
          ongoingRoutinesDistribution: false,
          distribution: action.data,
        }
      } else {
        return { ...state }
      }
    case types.GET_ROUTINES_DISTRIBUTION_FAIL:
      return {
        ...state,
        ongoingRoutinesDistribution: false,
      }

    case types.SELECT_ROUTINE:
      return {
        ...state,
        routines: getToggledContent(state.routines, action.id),
      }
    case types.TOGGLE_SELECT_ALL_ROUTINES:
      return {
        ...state,
        routines: getAllToggledContent(state.routines, action.toggledState),
      }

    case types.GET_EXERCISES:
      return {
        ...state,
        ongoingExercises: true,
        requestedSite: action.siteId,
        lastRequestDateExercises: action.lastRequestDateExercises,
      }
    case types.GET_EXERCISES_RESPONSE:
      if (moment(state.lastRequestDateExercises).isSame(action.lastRequestDateExercises)) {
        return {
          ...state,
          ongoingExercises: false,
          exercises: action.data,
        }
      } else {
        return { ...state }
      }
    case types.GET_EXERCISES_FAIL:
      return {
        ...state,
        ongoingExercises: false,
      }

    case types.SELECT_EXERCISE:
      return {
        ...state,
        exercises: getToggledContent(state.exercises, action.id),
      }
    case types.TOGGLE_SELECT_ALL_EXERCISES:
      return {
        ...state,
        exercises: getAllToggledContent(state.exercises, action.toggledState),
      }

    case types.SET_SORT:
      return {
        ...state,
        sortOn: action.sortOn
      }

    case types.SET_DATE:
      return setDate({ state, action })

    default:
      return state
  }
}
